import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useSelector } from "react-redux";
import { StyledTableCell, StyledTableRow, useStyles } from "./style";
import FeatureInPlan from "./FeatureInPlan";
import withLanguage, { getLang } from "../../../../language/LanguageController";

const PlanFeaturesTable = ({ t }) => {

    const features = useSelector(state => state.subscription.features);
    const plans = useSelector(state => state.subscription.plans);
    const classes = useStyles();
    const lang = getLang();

    return (
        <TableContainer className={classes.container} component={Paper}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <StyledTableCell className={classes.tableHead}>{t("text_features")}</StyledTableCell>
                        {
                            plans.filter(plan => plan.show_on_feature_list == 1).map(plan => <StyledTableCell 
                                key={plan.plan_id}
                                className={classes.tableHead}
                                align="center">
                                {plan.name_local[lang] || plan.name}
                            </StyledTableCell>)
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        features.map(feature =>  {
                            if(feature.show_on_ui != 1) return null;
                            return (
                                <StyledTableRow key={feature.feature_id}>
                                    <StyledTableCell component="th" scope="row" stickyCell>
                                        {feature.name_local[lang] || feature.name}
                                    </StyledTableCell>
                                    {
                                        plans.filter(plan => plan.show_on_feature_list == 1).map(plan => <StyledTableCell 
                                            key={plan.plan_id}
                                            align="center">
                                            <FeatureInPlan feature={feature} plan={plan}/>
                                        </StyledTableCell>)
                                    }
                                </StyledTableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default withLanguage(PlanFeaturesTable);