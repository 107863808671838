// @ts-nocheck
import React, { Component } from 'react';
import DairyFormula from './DairyFormula';
import SimpleValidator from '../../../common/SimpleValidator';
import analytics from '../../../library/firebase/analytics';
import { getCustomersForSelect } from 'src/components/library/customer';
import { errorHandler } from 'src/components/library/response';
import { get } from 'src/components/library/request';
import { isEqual } from 'lodash';
import { fetchRateLists, updateRateListCustomers } from '../../../../actions/dairyRateList';
import { fetchSettings } from '../../../../actions/dairySettings';
import { copyObject } from '../../../library/util';
import { updateCustomersRatelist } from '../../../../actions/dairyCustomer';

class RateListController extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            columns: [],
            dairy_type: this.props.dairy.type,
            activeTabIndex: 0,
            selectedCustomersByRateLists: {},
            rateListPresets: [],
            rateChartSetupTypeCattle: "cow"
        };
        this.fetchRateListPresets = this.fetchRateListPresets.bind(this);
        this.updateRateListCustomers = this.updateRateListCustomers.bind(this);
        this.validator = new SimpleValidator();
    }

    componentDidMount() {
        analytics.setScreenName("RateList");
        this.fetchRateListPresets();
        this.setRows();
        this.props.dispatch(fetchRateLists(this.props.dairyId));
        this.props.dispatch(fetchSettings(this.props.dairyId, {
            key: "rate_list_change_limit"
        }));
        this.handleDefaultCustomerSelection();
    }

    componentDidUpdate(prevProps) {
        if(!isEqual(prevProps.rateLists, this.props.rateLists)) {
            this.handleDefaultCustomerSelection();
        }
    }

    getSnfOptions = () => {
        let options = [];
        for(let i = 8.1; i<=9.5; i += 0.1){
            options.push(<option key={i}>{i.toFixed(1)}</option>);
        }
        return options;
    }

    setRows = () => {
        try {
            let dairyType = this.state.dairy_type;
            let settings = this.props.settings['dairy_rate_' + dairyType];
            settings = (settings) ? settings : {};
            let dairyFormula = new DairyFormula(dairyType, 
                settings.milk_rate,
                settings.snf_rate,
                settings.snf_formula,
                settings.cow_std_snf,
                settings.buffalo_std_snf,
                settings.clr_rate,
                settings.is_deduction,
                settings
            );
            let i = 2.5, rows = [], hColumns = [];
            dairyType = parseInt(dairyType);
            while(i <= 15){
                let columns = [i.toFixed(1)];
                if([8,9,11].indexOf(dairyType) > -1 || settings.rate_by === "snf") {
                    let j = 7.5;
                    while(j <= 9.5){
                        j = parseFloat(j.toFixed(1));
                        let clData = dairyFormula.calculateRate(i, j);
                        if(hColumns.indexOf(j) === -1){
                            hColumns.push(j);
                        }
                        columns.push(clData);
                        j += 0.1;
                    }
                } else {
                    for(let j = 30; j >= 18; j--){
                        let clData = dairyFormula.calculateRate(i, j);
                        if(hColumns.indexOf(j) === -1){
                            hColumns.push(j);
                        }
                        columns.push(clData)
                    }
                }
                
                rows.push(columns);
                i += 0.1
            }
            this.setState({
                rows: rows,
                columns: hColumns
            });
        } catch(error) {
            console.log(error);
        }
    }

    onTabChange = (tabIndex) => {
        this.setState({
            activeTabIndex: tabIndex
        });
    }

    handleDefaultCustomerSelection = () => {
        const { rateLists = [], customers } = this.props;
        const selectedCustomersByRateLists = {}
        rateLists.forEach((rateList, index) => {
            const selectedCustomerIds = (rateList.customers && rateList.customers.length > 0) ? rateList.customers.map(selectedCustomer => selectedCustomer.dairy_customer_id) : [];
            if(selectedCustomerIds && selectedCustomerIds.length) {
                const filteredCustomers = customers.filter(customer => selectedCustomerIds.includes(customer.dairy_customer_id));
                const customersForSelect = getCustomersForSelect(filteredCustomers);
                selectedCustomersByRateLists[index] = customersForSelect;
            }
        });
        this.setState({
            selectedCustomersByRateLists
        });
    }

    handleCustomerSelection = (rateListIndex, customers = []) => {
        const selectedCustomersByRateLists = {...this.state.selectedCustomersByRateLists}
        const rateListCustomers = this.state.selectedCustomersByRateLists[rateListIndex]?.map(customer => customer.dairy_customer_id);
        const customersToAssignRateList = customers.map(customer => customer.dairy_customer_id);
        this.setState({
            selectedCustomersByRateLists: {
                ...selectedCustomersByRateLists,
                [rateListIndex]: [...customers]
            }
        }, () => {
            this.updateRateListCustomers(rateListIndex);
            this.updateCustomers(rateListCustomers, customersToAssignRateList, rateListIndex);
        });
    }

    async updateRateListCustomers (rateListIndex) {
        const { rateLists, dairyId, dispatch } = this.props;
        const { selectedCustomersByRateLists } = this.state;
        const rateListCustomers = selectedCustomersByRateLists?.[rateListIndex] || [];
        const rateListCustomersId = rateListCustomers.map(rateListCustomer => rateListCustomer.dairy_customer_id);
        const rateList = rateLists?.[rateListIndex] || false;
        if(rateList) {
            updateRateListCustomers(dispatch, dairyId, rateList.rate_list_id, {
                rate_list_id: rateList.rate_list_id,
                dairy_customer_ids: rateListCustomersId
            });
        }
    }

    updateCustomers = (rateListOldCustomerIds = [], customerIdsToAssignRateList, rateListIndex) => {
        const rateList = this.props.rateLists?.[rateListIndex] || false;
        if(rateList && rateList.rate_list_id) {
            const customerIdsToRemoveRateList = rateListOldCustomerIds.filter(dairyCustomerId => !customerIdsToAssignRateList.includes(dairyCustomerId));
            const customersToUpdate = [
                ...customerIdsToAssignRateList.map(dairyCustomerId => ({
                    dairy_customer_id: dairyCustomerId,
                    rate_list_id: rateList.rate_list_id
                })),
                ...customerIdsToRemoveRateList.map(dairyCustomerId => ({
                    dairy_customer_id: dairyCustomerId,
                    rate_list_id: null
                }))
            ];
            const customers = copyObject(this.props.customers).map(customer => {
                const customerToUpdate = customersToUpdate.find(customerToUpdate => customerToUpdate.dairy_customer_id == customer.dairy_customer_id);
                if(customerToUpdate) {
                    customer.rate_list_id = customerToUpdate.rate_list_id;
                }
                return customer;
            });
            this.props.dispatch(updateCustomersRatelist(customers));
        }
    }

    async fetchRateListPresets () {
        try {
            const { status, data, message } = await get("/dairy/rateListPreset", {
                withAuth: true
            });
            if(status) {
                this.setState({
                    rateListPresets: data
                });
            } else {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        }
    }

    onRateChartSetupTypeCattle = (rateChartSetupTypeCattle) => {
        this.setState({
            rateChartSetupTypeCattle
        });
    }
}

export default RateListController;