import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CustomersSelect from "../../../common/CustomersSelect";
import withLanguage from "../../../../language/LanguageController";
import MuiDatepicker from "../../../../common/MuiDatepicker";
import moment from "moment";

const Filter = ({ 
    t,
    customerType,
    startDate,
    endDate,
    customers,
    customer,
    customerChangeHandler,
    customerTypeChangeHandler,
    dateChangeHandler,
    customerTypeFilter,
    dateFilter
}) => {
    return (
        <div className="form-row">
            <div className="col-12">
                <div className="d-flex mb-2">
                    {customerTypeFilter && (<div className="btn-group mr-1 btn-group-sm" role="group" aria-label="Customer Type">
                        <button
                            className={`btn ${customerType === "seller" ? "bg-light-blue text-white active" : "btn-light"}`}
                            type="button"
                            onClick={() => customerTypeChangeHandler("seller")}>
                            {t("text_seller")}
                        </button>
                        <button
                            className={`btn ${customerType === "buyer" ? "bg-light-blue text-white active" : "btn-light"}`}
                            type="button"
                            onClick={() => customerTypeChangeHandler("buyer")}>
                            {t("text_buyer")}
                        </button>
                    </div>)}
                    <div className="flex-fill">
                        <CustomersSelect
                            allOption
                            customers={customers.filter(customer => customer.type === customerType)}
                            onChange={customerChangeHandler}
                            value={customer}/>
                    </div>
                </div>
                {dateFilter && (<div className="d-flex mb-2 justify-content-between">
                    <div className="input-group mr-1">
                        <div className="input-group-prepend">
                            <span className="input-group-text text-sm">{t("text_from")}</span>
                        </div>
                        <MuiDatepicker
                            value={startDate}
                            maxDate={endDate}
                            onChange={selectedDate => dateChangeHandler("start_date", selectedDate)}
                            name="start_date"
                            inputId="start_date"
                            inputClass="bg-white"
                            arrowButtons={false}
                        />
                    </div>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text text-sm">{t("text_to")}</span>
                        </div>
                        <MuiDatepicker
                            value={endDate}
                            minDate={startDate}
                            maxDate={moment().endOf("month")}
                            onChange={selectedDate => dateChangeHandler("end_date", selectedDate)}
                            name="end_date"
                            inputId="end_date"
                            inputClass="bg-white"
                            arrowButtons={false}
                        />
                    </div>
                </div>)}
            </div>
        </div>
    );
};

Filter.propTypes = {
    t: PropTypes.func.isRequired,
    customerType: PropTypes.string.isRequired,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    customers: PropTypes.array.isRequired,
    customer: PropTypes.object.isRequired,
    customerChangeHandler: PropTypes.func.isRequired,
    customerTypeChangeHandler: PropTypes.func.isRequired,
    dateChangeHandler: PropTypes.func
};

export default withLanguage(Filter);