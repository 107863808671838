import TableCell from "@material-ui/core/TableCell";
import makeStyles from "@material-ui/styles/makeStyles";
import { withStyles } from "@material-ui/core/styles";

const couponBtn = {
    padding: 5,
    marginLeft: 10,
    backgroundColor: "#64c568"
};

export const styles = theme => {
    return {
        addressPaper: {
            padding: "10px 13px"
        },
        addressButton: {
            marginTop: "10px",
            fontWeight: 600,
            color: "#fff",
            backgroundColor: "#64c568"
        },
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        table: {
            minWidth: "100%",
        },
        formControl: {
            margin: theme.spacing(0)
        },
        noBorderRadius: {
            borderRadius:0
        } 
    };
};

export const useStyles = makeStyles(() => ({
    couponApplyBtn: {
        ...couponBtn,
        fontWeight: 600,
        color: "#fff",
    },
    couponClearBtn: {
        ...couponBtn
    },
    couponPaper: {
        padding: "8px 8px 8px 0px",
        display: "flex",
        alignItems: "center"
    },
    couponInput: {
        marginLeft: 10,
        flex: 1,
    }
}));

export const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#9ab4ff",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);