import React from "react";
import { Card, CardContent, CardMedia, Grid, Typography } from "@material-ui/core";
import "./style.scss";

export default function Video({ video }) {
  return (
    <Grid item xs={12} md={6}>
        <Card>
            <CardMedia
                className="card-media"
                title="Image title">
                <iframe 
                    src={`https://www.youtube.com/embed/${video.id}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen />
            </CardMedia>
            <CardContent className="card-content">
                <Typography gutterBottom variant="h5" component="h2">
                    {video.title}
                </Typography>
                <Typography>
                    <span dangerouslySetInnerHTML={{
                        __html: video.description
                    }} />
                </Typography>
            </CardContent>
        </Card>
    </Grid>
  );
}