
import * as Sentry from "@sentry/react";
import html2canvas from "html2canvas";

export const convertToImage = async template => {
    try {
        const onsSplitterMask = document.querySelector("ons-splitter-mask");
        onsSplitterMask && onsSplitterMask.remove();

        const printDivId = "print_div_" + (new Date()).getTime();
        let printDiv = document.createElement("div");
        printDiv.id = printDivId;
        printDiv.style.position = "absolute";
        printDiv.style.top = "0px";
        printDiv.style.left = "3000px";
        printDiv.innerHTML = template;
        document.body.append(printDiv);
        const canvas = await html2canvas(printDiv, {
            scale: 1,
            windowHeight: printDiv.scrollHeight
        });
        const imgDataUrl = canvas.toDataURL();
        document.getElementById(printDivId).remove();
        return imgDataUrl;
    } catch (error) {
        console.error("error in converting html to image", error);
        Sentry.captureException(error);
    }
    return null;
};