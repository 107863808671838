// @ts-nocheck
import React, { Component } from 'react';
import PasswordMask from 'react-password-mask';
import { toast, loader } from "../../library/util";
import { Page } from 'react-onsenui';
import withLanguage from '../../language/LanguageController';
import { loginSuccess } from "../../../actions/user";
import { fetchAppConfiguration } from "../../../actions";
import { fetchSubscription } from "../../../actions/subscriptions";
import { connect } from 'react-redux';
import SimpleValidator from '../../common/SimpleValidator';
import { errorHandler } from '../../library/response';
import analytics from '../../library/firebase/analytics';
import request from '../../library/request';
import { trackUser } from '../../library/errorTracking';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import starPasswordImg  from "src/assets/img/star-password.png"

class LoginWithPassword extends Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loginWithOtp = this.loginWithOtp.bind(this);
    this.formId = "password-form";
    this.state = {
      password: "",
      btn_verify_password: false,
      btn_otp: false,
      mobile: props?.location?.state?.mobile || ""
    };

    this.validator = new SimpleValidator();
  }

  componentDidMount () {
    analytics.setScreenName("LoginWithPassword");
  }

  handleInput = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    this.setState({
      [name]: value
    });
  }

  async handleSubmit (event) {
    event.preventDefault();
    try {
      // validate form before submitting to server 
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate(); // rerender to show messages for the first time
        return false;
      }      
      analytics.logEvent("loginWithPassword");
      loader.show(this.props.t("text_verifying"));
      this.setState({
        btn_verify_password: true
      });
      const { location: { state: { customer_id, mobile }} } = this.props.history
      const { status, data, message } = await request.post('/auth/loginPassword', {
        withAuth: false,
        body: JSON.stringify({
          "customer_id": customer_id,
          "password": this.state.password
        })
      });
      if (status) {
        if(data.action === "otp") {
          this.props.history.push({
            pathname: "/verifyOtp",
            state: {
              mobile: mobile,
              customer_id: customer_id
            }
          });
        } else {
          analytics.setUserId(data.customer_id);
          analytics.setUserProperty("userName", data.firstname + " " + data.lastname);
          analytics.setUserProperty("userGroup", data.customer_group_id);
          trackUser(data);
          await this.props.loginSuccess(data);
          toast(this.props.t('msg_login_success'));
          this.props.history.replace(data.customer_group_id == 2 ? "/dairy" : "/");
        }
      } else {
        throw message;
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      loader.hide();
      this.setState({
        btn_verify_password: false
      });
    }
  }

  async loginWithOtp (event) {
    event.preventDefault();
    try {  
      analytics.logEvent("loginWithOtp");
      loader.show(this.props.t("text_verifying"));
      this.setState({
        btn_otp: true
      });
      const { mobile } = this.state;
      const { status, data, message } = await request.post("/auth/loginByOtp", {
        withAuth: false,
        body: JSON.stringify({
          mobile: mobile
        })
      });
      if (status) {
        this.props.history.push({
          pathname: "/verifyOtp",
          state: {
            mobile: mobile,
            customer_id: data.customer_id
          }
        });
      } else {
        throw message;
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      loader.hide();
      this.setState({
        btn_otp: false
      });
    }
  }

  render() {
    const { btn_verify_password, btn_otp, mobile } = this.state;
    const { t } = this.props;
    return (<Page className="page">
      <div className="login_mobile">
        <div className="clearfix">
          <div className="screen_mobile_number clearfix col-md-12">
            <div className="mt-40">
              <div className="m_login_otp_pass_header text-center mb-5">
                <h2 className="enter_code_heading">
                  {t('text_enter_password')}
                </h2>
                <img className="img-responsive text-center" 
                  src={starPasswordImg} alt="password" />
                <p>{t('text_login_password')}</p>
              </div>
              <div className="loginmobileform clearfix col-md-12 max-width-500 mx-auto">
                <div className="divSignupForm">
                  <form method="POST" id={this.formId} onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <label>{t("text_password")}</label>
                      <PasswordMask
                        id="password"
                        name="password"
                        inputClassName="form-control input-bottom-border"
                        buttonClassName="no-style"
                        value={this.state.password}
                        onChange={this.handleInput.bind(this)}
                        showButtonContent={<i className="fa fa-eye" />}
                        hideButtonContent={<i className="fa fa-eye-slash" />}
                      />
                      {this.validator.message("password", this.state.password, "required")}
                      <small>
                        <Link to={{
                          pathname: "/forgotPassword",
                          state: {
                            mobile,
                          }
                        }}>
                          {t("text_forgot_password")}?
                        </Link>
                      </small>
                    </div>
                    <div className="form-group">
                      <button 
                        className="btn btn-success btn-block mt-0"
                        disabled={btn_verify_password}
                      >
                        {btn_verify_password ? t('text_verifying') : t('btn_login')}
                      </button>
                    </div>
                    <div className='form-group'>
                      <button 
                        className="btn btn-outline-success btn-block mt-0 font-weight-bold" 
                        type="button"
                        disabled={btn_otp}
                        onClick={this.loginWithOtp}
                      >
                        {btn_otp ? t('text_verifying') : t('btn_login_with_otp')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>);
  }
}

const mapDispatchToProps = {
  loginSuccess,
  fetchAppConfiguration,
  fetchSubscription
};

export default connect(
  null, mapDispatchToProps
)(
  withRouter(withLanguage(LoginWithPassword))
);