import React from "react";
import { Ripple } from "react-onsenui";

const ListItem = props => {
    const { className, tappable, ...rest} = props;
    let _className = ["list-item list-item--material", className];
    return (<div className={_className.join(" ")} {...rest}>
        {tappable ? <Ripple /> : null}
        {props.children}
    </div>);
};

ListItem.defaultProps = {
    tappable: false
};

export default ListItem;