// @ts-nocheck
import React from "react";
import { SpeedDial, SpeedDialItem, Fab, Icon } from "react-onsenui";
import DiaryController from "./DiaryController";
import MilkTotal from "./MilkTotal";
import withLanguage from "../../language/LanguageController";
import MuiDatepicker from "../../common/MuiDatepicker";
import moment from "moment";
import { connect } from "react-redux";
import { DefaultLayout } from "../common/Layout";
import { withRouter } from "react-router";

class Diary extends DiaryController {

    render() {
        const { t, header, filter, history } = this.props;
        const { start_date, end_date } = this.state;
        return (
            <DefaultLayout
                bottomGutter={false}
                header={header}
                title={t("text_my_milk_diary")}>
                    {filter ? <div className="mb-2">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-sm px-2">{t("text_from")}</span>
                            </div>
                            <MuiDatepicker
                                value={start_date}
                                maxDate={end_date}
                                onChange={selectedDate => this.dateChangeHandler("start_date", selectedDate)}
                                name="start_date"
                                inputId="start_date"
                                inputClass="bg-white"
                                arrowButtons={false}
                            />
                            <div className="input-group-prepend input-group-append">
                                <span className="input-group-text text-sm px-2">{t("text_to")}</span>
                            </div>
                            <MuiDatepicker
                                value={end_date}
                                minDate={start_date}
                                maxDate={moment().endOf("month")}
                                onChange={selectedDate => this.dateChangeHandler("end_date", selectedDate)}
                                name="end_date"
                                inputId="end_date"
                                inputClass="bg-white"
                                arrowButtons={false}
                            />
                        </div>
                    </div> : null}
                    <MilkTotal
                        t={t}
                        today={this.state.today}
                        total={this.state.total}
                        customerId={this.props.customerId}
                        start_date={start_date}
                        end_date={end_date}
                        navigator={this.props.navigator}/>
                <SpeedDial position='bottom right' className="d-lg-none right-3 bottom-3">
                    <Fab
                        style={{backgroundColor: "#11b232", color: "#fff" }}>
                        <Icon icon='md-view-headline' />
                    </Fab>
                    <SpeedDialItem
                        modifier="with-text"
                        onClick={e => history.push("/diary/addMilk")}
                    >
                        {t("text_add_milk")}
                    </SpeedDialItem>
                    <SpeedDialItem
                        modifier="with-text" 
                        onClick={e => history.push("/diary/milkDetailTotal")}
                    >
                        {t("text_milk_total")}
                    </SpeedDialItem>
                </SpeedDial>
            </DefaultLayout>
        );
    }
}

Diary.displayName = "Diary";

Diary.defaultProps = {
    header: true,
    filter: true
};

const mapStateToProps = state => ({
    customerId: state.userReducer.user.customer_id
});

const DiaryComponent = connect(mapStateToProps)(
    withRouter(withLanguage(Diary))
);

export default DiaryComponent;