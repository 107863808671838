import React, { useEffect, useState } from "react";
import { Modal, Icon, Ripple } from "react-onsenui";
import { PrimaryButton } from "../../../common/buttons/PrimaryButton";
import ModalDialog from "../../../common/ModalDialog";
import analytics from "../../../library/firebase/analytics";

const CustomerLoanRecoverModal = ({t, validator, amount, isOpen, onClose, onSubmit, submitBtn}) => {

    const [loanAmount, setLoanAmount] = useState(amount);

    useEffect(() => {
        analytics.logEvent("CustomerLoanRecoverModal");
    });

    const amountHasError = validator.message("amount", loanAmount, `required|min:1,num|max:${amount},num`, {
        messages: {
            min: "the_recover_loan_amount_at_least_1.",
            max: `the_recover_loan_amount_may_not_be_greater_than_${amount}.`
        }
    });

    return (
        <ModalDialog
            isOpen={isOpen}
            onClose={onClose}
            title={t("text_recover_loan")}
            form={{
                id: "loan_recover_form",
                onSubmit
            }}
            content={
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="loan_recover_amount_input">{t("text_amount")}</label>
                            <input
                                name="amount"
                                id="loan_recover_amount_input"
                                type="number"
                                className={`form-control ${Boolean(amountHasError) && "is-invalid"}`}
                                step="0.01"
                                value={loanAmount}
                                onChange={e => setLoanAmount(e.target.value)}/>
                            {amountHasError}
                        </div>
                        <div className="form-group mb-0">
                            <label htmlFor="loan_recover_remark_input">{t("text_remark")}</label>
                            <textarea
                                name="remark"
                                id="loan_recover_remark_input"
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
            }
            dialogActions={
                <PrimaryButton
                    variant="contained"
                    disabled={submitBtn}
                    type="submit"
                >
                    {t("text_recover")}
                </PrimaryButton>
            }
        />
    );
};

export default CustomerLoanRecoverModal;