import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { dairySelectedToggle, fetchDairySuccess } from "../../../actions/dairy";
import { fetchDairySubscription } from "../../../actions/subscriptions";
import { PrimaryButton } from "../../common/buttons/PrimaryButton";
import ModalDialog from "../../common/ModalDialog/ModalDialog";
import { useLanguage } from "../../language/LanguageController";
import analytics from "../../library/firebase/analytics";
import { copyObject, loader } from "../../library/util";
import * as Sentry from "@sentry/react";

const DairySelectionModal = ({ isOpen, onClose }) => {
    const { t } = useLanguage();
    const dispatch = useDispatch();
    const history = useHistory();
    const { dairies = [], dairySelected, dairy } = useSelector(state => state.dairy);

    const dairyClickHandler  = async dairy => {
        analytics.logEvent("selectDairy");
        try {
            loader.show(t("text_loading"));
            await fetchDairySubscription(dispatch, dairy.dairy_id);
            dispatch(fetchDairySuccess(dairy));
            dispatch(dairySelectedToggle(true));
        } catch (error) {
            Sentry.captureException(error);
        } finally {
            loader.hide();
        }
    }

    useEffect(() => {
        if(dairySelected) {
            history.push(`/dairy/${dairy.dairy_id}`);
        }
    }, [history, dairySelected, dairy]);

    return (
        <ModalDialog
            isOpen={isOpen}
            onClose={onClose}
            title={t("text_select_dairy")}
            content={
                <>
                    <div className="row">
                        <div className="col-12 text-center">
                            <PrimaryButton
                                variant="contained"
                                size="large"
                                className="mb-4">
                                <Link to="/diary" className="text-white">
                                    {t("text_my_milk_diary")}
                                </Link>
                            </PrimaryButton>
                        </div>
                    </div>
                    {
                        dairies.map(dairy => {
                            return (
                                <div className="row" key={dairy.dairy_id}>
                                    <div className="col-12 text-center">
                                        <PrimaryButton
                                            variant="contained"
                                            size="large"
                                            className="mb-4"
                                            onClick={e => dairyClickHandler(copyObject(dairy))}>
                                            {dairy.name}
                                        </PrimaryButton>
                                    </div>
                                </div>
                            );
                        })
                    }
                </>
            }
        />
    );
};

export default DairySelectionModal;