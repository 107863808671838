import { copyObject } from "../components/library/util";
import SubscriptionModel from "../components/models/Subscription";
import PlansModel from "../components/models/Plans";
import OrdersModel from "../components/models/Orders";
import PlanFeaturesModel from "../components/models/PlanFeatures";

import {
    FETCH_PLANS_SUCCESS,
    FETCH_FEATURES_SUCCESS,
    FETCH_SUBSCRIPTION_SUCCESS,
    FETCH_ORDERS_SUCCESS,
    FETCH_ORDERS_BEGIN,
    FETCH_ORDERS_FAILED,
    FETCH_SUBSCRIPTION_REQUEST,
    FETCH_SUBSCRIPTION_FAILURE,
    SUBSCRIPTION_RESET,
} from "../_constants";

const plans = PlansModel.get();
const subs = SubscriptionModel.get();
const orders = OrdersModel.get();
const features = PlanFeaturesModel.get();

const initialState = {
    plans: plans || [],
    subscription: subs || {},
    orders: orders || [],
    features: features || [],
    fetching_payment_order: false,
    subscriptionFetched: false,
    subscriptionFecthing: true
};

function subscription(state = initialState, action) {
    switch (action.type) {
    case FETCH_PLANS_SUCCESS:
        PlansModel.save(action.data); //save plans detail into local db
        return {
            ...copyObject(state),
            plans: action.data
        };
    case FETCH_FEATURES_SUCCESS:
        PlanFeaturesModel.save(action.data); //save features detail into local db
        return {
            ...copyObject(state),
            features: action.data
        };
    case FETCH_SUBSCRIPTION_REQUEST: {
        return {
            ...copyObject(state),
            subscriptionFecthing: true
        }
    }
    case FETCH_SUBSCRIPTION_SUCCESS:
        SubscriptionModel.save(action.data || {}); //save subscription detail into local db
        return {
            ...copyObject(state),
            subscription: action.data || {},
            subscriptionFecthing: false,
            subscriptionFetched: true
        };
    case FETCH_SUBSCRIPTION_FAILURE: {
        return {
            ...copyObject(state),
            subscriptionFecthing: false
        }
    }
    case FETCH_ORDERS_BEGIN:
        return {
            ...copyObject(state),
            fetching_payment_order: action.fetching_payment_order
        };
    case FETCH_ORDERS_SUCCESS:
        OrdersModel.save(action.data); //save orders detail into local db
        return {
            ...copyObject(state),
            orders: action.data
        };
    case FETCH_ORDERS_FAILED:
        return {
            ...copyObject(state),
            fetching_payment_order: false
        };
    case SUBSCRIPTION_RESET: {
        return {
            ...copyObject(state),
            subscriptionFetched: false,
            subscriptionFecthing: true,
            subscription: {}
        }
    }
    default:
        return state;
    }
}

export const getSubscriptionAddons = (state) => {
    return state.subscription?.subscription?.add_ons || [];
}

export const getHasSubscription = (state) => {
    return Boolean(state.subscription?.subscription?.subscription_id);
}

export default subscription;
