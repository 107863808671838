// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useLanguage } from "../../../language/LanguageController";
import { useSelector } from 'react-redux';
import { PrimarySwitch } from 'src/components/core/Switch';
import { FormHelperText } from '@material-ui/core';

const AddMilkRateEditableSetting = () => {
    const { t } = useLanguage();
    const [rateEditable, setRateEditable] = useState(false);
    const { dairyPreference } = useSelector(state => state.dairySettings);

    useEffect(() => {
        setRateEditable(dairyPreference?.rate_editable?.value ? true : false);
    }, [dairyPreference]);

    const handleChange = () => {
        setRateEditable(!rateEditable);
    }

    return (
        <div className='form-group'>
            <div className='d-flex justify-content-between'>
                <div>
                    <label className='mb-0'>{t("text_set_rate_editable")}</label>
                    <FormHelperText>{t("text_set_rate_editable_help")}</FormHelperText>
                </div>
                <div>
                    <PrimarySwitch checked={rateEditable} onChange={handleChange} name="rate_editable" value={rateEditable ? 1 : 0} />
                </div>
            </div>
        </div>
    )
}

export default AddMilkRateEditableSetting
