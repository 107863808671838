import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import MuiDatepicker from "src/components/common/MuiDatepicker";
import BuffaloBlackIcon from "src/components/common/icons/BuffaloBlackIcon";
import BuffaloWhiteIcon from "src/components/common/icons/BuffaloWhiteIcon";
import CowBlackIcon from "src/components/common/icons/CowBlackIcon";
import CowWhiteIcon from "src/components/common/icons/CowWhiteIcon";
import { BrowserView } from "src/components/library/detect-device";
import { ABILITY_MILK_COLLECTION, PERMISSION_DETAIL } from "../../../../../_constants/permissions";
import { HasPermission, usePermission } from "../../../../common/AccessPermission";
import withLanguage from "../../../../language/LanguageController";

const Filter = ({
    t,
    startDate,
    endDate,
    cattle,
    shift,
    customerFilterHandler,
    dateChangeHandler,
    cattleChangeHandler,
    shiftChangeHandler
}) => {
    const { hasAccess } = usePermission();
    const customerMilkDetailAccess = hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL);
    return (
        <>
            <div className={customerMilkDetailAccess ? "col-lg-3" : "col-12"}>
                <div className="bg-light rounded shadow-sm">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text text-warning bg-light border-0">
                                <i className="fa fa-search"></i>
                            </span>
                        </div>
                        <label className="sr-only" htmlFor="search-bar">{t("text_search")}</label>
                        <input
                            type="text"
                            id="search-bar"
                            placeholder={t("text_search")}
                            className="form-control border-0 bg-light rounded-right"
                            onChange={customerFilterHandler}/>
                    </div>
                </div>
            </div>
            <BrowserView>
                <HasPermission ability={ABILITY_MILK_COLLECTION} access={PERMISSION_DETAIL}>
                    <div className="col-lg-4">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-sm px-2">{t("text_from")}</span>
                            </div>
                            <MuiDatepicker
                                value={startDate}
                                maxDate={endDate}
                                onChange={selectedDate => dateChangeHandler("start_date", selectedDate)}
                                name="start_date"
                                inputId="start_date"
                                inputClass="bg-white"
                                arrowButtons={false}
                            />
                            <div className="input-group-prepend input-group-append">
                                <span className="input-group-text text-sm px-2">{t("text_to")}</span>
                            </div>
                            <MuiDatepicker
                                value={endDate}
                                minDate={startDate}
                                maxDate={moment()}
                                onChange={selectedDate => dateChangeHandler("end_date", selectedDate)}
                                name="end_date"
                                inputId="end_date"
                                inputClass="bg-white"
                                arrowButtons={false}
                            />
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="btn-group mr-1" role="group" aria-label="Shift Selection">
                            <button
                                className={`btn ${shift == "0" ? "bg-light-blue text-white active" : "btn-light"}`}
                                type="button"
                                onClick={() => shiftChangeHandler("0")}>
                                <i className="fa fa-sun"></i>
                                &nbsp;{t("text_morning")}
                            </button>
                            <button
                                className={`btn ${shift == "1" ? "bg-light-blue text-white active" : "btn-light"}`}
                                type="button"
                                onClick={() => shiftChangeHandler("1")}>
                                <i className="fa fa-moon"></i>
                                &nbsp;{t("text_evening")}
                            </button>
                        </div>
                    </div>

                    <div className="col-lg-2 text-right">
                        <div className="btn-group mr-1" role="group" aria-label="Cattle Selection">
                            <button
                                className={`btn ${cattle === "cow" ? "bg-light-blue text-white active" : "btn-light"}`}
                                type="button"
                                onClick={() => cattleChangeHandler("cow")}>
                                {cattle === "cow" ? <CowWhiteIcon /> : <CowBlackIcon />} 
                                &nbsp;{t("text_cow")}
                            </button>
                            <button
                                className={`btn ${cattle === "buffalo" ? "bg-light-blue text-white active" : "btn-light"}`}
                                type="button"
                                onClick={() => cattleChangeHandler("buffalo")}>
                                {cattle === "buffalo" ? <BuffaloWhiteIcon /> : <BuffaloBlackIcon />}
                                &nbsp;{t("text_buffalo")}
                            </button>
                        </div>
                    </div>
                    
                </HasPermission>
            </BrowserView>
        </>
    );
};

Filter.displayName = "Filter";

Filter.propTypes = {
    t: PropTypes.func.isRequired,
    customerFilterHandler: PropTypes.func.isRequired
};

export default withLanguage(Filter);