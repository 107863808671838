import { useSelector } from "react-redux";
import hasAccess from "./hasAccess";

const usePermission = () => {
    const { dairy } = useSelector(state => state.dairy);

    const { permissions, is_owner } = dairy;

    return {
        hasAccess: hasAccess(is_owner, permissions)
    }
};

export default usePermission;