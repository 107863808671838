// @ts-nocheck
/* eslint-disable no-console */
import React, { Component } from "react";
import withLanguage from "../../language/LanguageController";
import { Button, Icon, Modal } from "react-onsenui";
import ModalImage from "../../../assets/img/trial_expired.png";
import { connect } from "react-redux";
import AppSubscriptionPage from "../../pages/subscription/AppSubscription";
import { checkSubscriptionIsActive } from "../../common/FeatureFlag/FeatureFlag";
import analytics from "../../library/firebase/analytics";
import ModalDialog from "../../common/ModalDialog/ModalDialog";
import { PrimaryButton } from "../../common/buttons/PrimaryButton";
import { withRouter } from "react-router";

let popupTimeout;
class SubscriptionExpiredModal extends Component {

    constructor (props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    componentDidMount () {
        popupTimeout = setTimeout(() => {
            this.showPopup();
        }, 1000*5)
    }

    componentWillUnmount () {
        clearTimeout(popupTimeout);
    }

    checkPageToShow = () => {
        const { pathname } = this.props.location
        return !pathname.includes("subscription");
    }

    showPopup = () => {
        const { subscription } = this.props;
        if(subscription.subscription_id && subscription.is_trial != 1) {
            if(!checkSubscriptionIsActive() && this.checkPageToShow()) {
                analytics.logEvent("SubscriptionExpiredModal - Displayed");
                this.setState({
                    isOpen: true
                });
            }
        }
    }

    hidePopup = () => {
        analytics.logEvent("SubscriptionExpiredModal - Close");
        this.setState({
            isOpen: false
        });
    }

    renew = () => {
        this.setState({
            isOpen: false
        }, () => {
            analytics.logEvent("SubscriptionExpiredModal - GotoSubscription");
            this.props.history.push("/subscription");
        });
    }

    render () {
        const { isOpen } = this.state;
        const { t } = this.props;
        return (
            <ModalDialog
                isOpen={isOpen}
                onClose={this.hidePopup}
                dividers={false}
                title={t("text_subscription_expired")}
                content={
                    <div className="text-center">
                        <div className="mb-3">
                            <img src={ModalImage} style={{maxWidth: "110px"}} alt="" className="mb-3"/>
                            <p>{t("text_subscription_expired_modal_text")}</p>
                        </div>
                        <PrimaryButton
                            onClick={this.renew}
                            className="mb-3"
                        >
                            {t("text_renew")}
                        </PrimaryButton>
                    </div>
                }
            />
        );
    }
}

const mapStateToProps = state => ({
    subscription: state.subscription.subscription
});

export default connect(mapStateToProps)(
    withRouter(withLanguage(SubscriptionExpiredModal))
);