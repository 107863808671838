// @ts-nocheck
import * as Sentry from "@sentry/react";
import { t } from "../../language/LanguageController";
import request from "../../library/request";
import { errorHandler } from "../../library/response";
import { delay, loader } from "../../library/util";
import RazorPay from "./RazorPay";

const doCheckout = async ({
    plan,
    duration,
    renew,
    coupon,
    user,
    redeemBalance,
    addons = [],
    dairyId
}, successCB) => {
    try {
        loader.show(t("text_loading"));
        const { status, data, message} = await createOrder({ plan, duration, renew, coupon, redeemBalance, addons, dairyId }); 
        if(!status) {
            throw message;
        }
        // return ;

        if(data.total > 0) {
            const checkoutOptions = {
                name: user.firstname + " " + user.lastname,
                mobile: user.telephone,
                email: user.email,
                amount: data.total,
                orderId: data.integration_order_id,
                address: user.address,
                orderDetail: JSON.stringify({
                    plan: plan?.name,
                    duration,
                    renew
                })
            }
            RazorPay.checkout(checkoutOptions, (response) => paymentSuccessCallback(data, successCB, dairyId), paymentFailedCallback);
        } else {
            const { status } = await changePlan(data);
            if(status) {
                paymentSuccessCallback(data, successCB, dairyId);
            }
        }
    } catch (err) {
        console.log(err);
        errorHandler(err);
    } finally {
        loader.hide();
    }
}

const changePlan = (order) => {
    const formData = new FormData();
    formData.append("order_id", order.order_id);
    return request.post("/subscription/change_plan", {
        withAuth: true,
        body: formData
    });
}

const createOrder = ({ plan, duration, renew, coupon, redeemBalance, addons = [], dairyId }) => {
    const formData = new FormData();
    if(plan?.plan_id) {
        formData.append("plan_id", plan.plan_id);
    }
    formData.append("plan_duration", duration);
    formData.append("coupon", coupon);
    formData.append("dairy_id", dairyId);
    formData.append("redeemRewardPoints", redeemBalance ? 1 : 0);
    formData.append("renew", renew ? 1 : 0);
    if(addons && addons?.length) {
        formData.append("addons[]", addons);
    }
    return request.post(`/subscription/orders/${dairyId}`, {
        withAuth: true,
        body: formData
    });
}

const paymentSuccessCallback = async (orderData, successCB, dairyId) => {
    try {
        loader.hide();
        loader.show(t("text_loading"));
        await fetchSubscription(orderData, successCB, dairyId);
    } catch(err) {
        Sentry.captureException(err);
        errorHandler(err);
    } finally {
        loader.hide();
    }
}

const fetchSubscription = async (orderData, successCB, dairyId) => {
    const {status, data: subscription} = await request.get(`/subscription/get/${dairyId}`, {
        withAuth: true
    });
    if(status) {
        if(subscription && subscription.order_id == orderData.order_id) {
            successCB(subscription);
        } else {
            await delay(5000);
            await fetchSubscription(orderData, successCB, dairyId);
        }
    }
}

const paymentFailedCallback = async (response) => {
    const { metadata: { order_id, payment_id } } = response.error
    const formData = new FormData();
    formData.append("order_id", order_id);
    formData.append("payment_id", payment_id);
    const reqResponse = await request.post("/subscription/payment/failed", {
        withAuth: true,
        body: formData
    });
}

export const getDurations = (plan = {}) => {
    return [
        {
            id: 4,
            name: {
                "en": "1 Year",
                "hi": "1 साल"
            },
            value: 12,
            price: plan.annually_price
        },
        {
            id: 3,
            name: {
                "en": "6 Months",
                "hi": "6 महीने"
            },
            value: 6,
            price: plan.half_yearly_price
        },
        {
            id: 2,
            name: {
                "en": "3 Months",
                "hi": "3 महीने"
            },
            value: 3,
            price: plan.quarterly_price
        },
        {
            id: 1,
            name: {
                "en": "1 Month",
                "hi": "1 महीना"
            },
            value: 1,
            price: plan.monthly_price
        }
    ];
}

export default {
    doCheckout
};
