// @ts-nocheck
import React, { useState, useEffect, useCallback } from "react";
import withLanguage, { useLanguage } from "../../language/LanguageController";
import PropTypes from "prop-types";
import "./style.scss";
import { connect } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import { DefaultLayout } from "../common/Layout";
import Video from "./Video";
import { getAsJSON } from "src/components/library/firebase/RemoteConfig";

const Help = () => {
    const { t } = useLanguage();
    const [videos, setVideos] = useState([]);

    const getVideos = useCallback(async () => {
        setVideos(await getAsJSON("liter_feature_videos"));
    }, [setVideos]);

    useEffect(() => {
        getVideos();
    }, [getVideos]);

    return (
        <DefaultLayout title={t("text_help")}>
            <Container maxWidth="md" className="help-container" disableGutters>
                <Grid container spacing={4} direction="row" className={"help-grid"}>
                    {videos.map(video => 
                        <Video key={video.id} video={video} />)}
                </Grid>
            </Container>
        </DefaultLayout>
    );
};

Help.propTypes = {
    t: PropTypes.func.isRequired,
    showMenu: PropTypes.func,
    navigator: PropTypes.object,
    user: PropTypes.object,
};

const mapStateToProps = state => {
    const { userReducer: { user = {} } } = state;
    return {
        user: user ? user : {}
    };
};

const HelpComp = connect(mapStateToProps)(withLanguage(Help));

const HelpPage = props => {
    return <HelpComp {...props} />;
};

export default HelpPage;