export const getDairyHeader = (reportName, dairyName, dairyOwnerName, dairyOwnerMobile) => {
    return `
    <head>
        <title>${reportName}</title>
    </head>
    <body>
    <table style="width: 100%;">
        <tbody>
            <tr>
                <td style="text-align: left;">
                    <img src="https://liter.live/assets/images/color-logo500px.png" alt="Liter Logo" style="max-width: 150px;">
                </td>
                <td style="text-align: right;">
                    <h1>
                        ${dairyName} <br>
                        ${dairyOwnerName} - ${dairyOwnerMobile}
                    </h1>
                </td>
            </tr>
        </tbody>
    </table>
    `;
}