// @ts-nocheck
import { captureException } from "src/components/library/errorTracking";
import request from "src/components/library/request"
import { getToken } from "src/components/library/util";
import appJson from "../../../../package.json";

export const updateFCMToken = async (deviceFCMToken, deviceFCMId) => {
    try {
        await request.post("/pushNotification/fcmToken", {
            "headers": {
                "Authorization": "Bearer " + getToken()
            },
            body: JSON.stringify({
                "fcm_token": deviceFCMToken,
                "device_id": deviceFCMId,
                "version": appJson.version
            })
        });
    } catch (err) {
        captureException(err);
    }
}