// @ts-nocheck
import React, {Component} from 'react';
import CommonHeader from '../common/CommonHeader';
import { Page } from 'react-onsenui';

import MobileNumber from './MobileNumber';
import withLanguage from '../../language/LanguageController';
import analytics from '../../library/firebase/analytics';
import PageBottomHelpContact from "../../common/PageBottomHelpContact";

class LoginForm extends Component {

  componentDidMount () {
    analytics.setScreenName("LoginForm");
  }

  pushPage = () => {
    analytics.logEvent("loginWithMobile");
    this.props.navigator.pushPage({
      component: MobileNumber, props: {
        key: MobileNumber.name
      }
    });
  }

  facebookLogin() {
    window.facebookConnectPlugin.login(["email","user_mobile_phone"], data => {
      console.log("Success", data)
    }, err => {
      console.log("Error", err)
    })
  }
  
  render() {
    const { t } = this.props;
    return (<Page className="page" renderFixed={() => <PageBottomHelpContact />}>
      <div className="app_inner_pages">
        <div className="clearfix">
          <div className="login_return">
            <CommonHeader pagetitle={
              <img src="./assets/img/Logo1/logo.png" />
            }/>
            <div className="loginmain_page clearfix col-md-12">
              <button
                className="login_screen_button numberbutton"
                onClick={this.pushPage}>
                <img className="img-responsive" 
                  src={'./assets/img/cellphone-line.png'}/>
                {t('mobile_number')}
              </button>
              <p className="text-center">
                <small>
                  <a href="https://liter.live/privacypolicy.html" target="_blank">
                    {t('term_text')}
                  </a>
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Page>);
  }
}

const LoginFormComp = withLanguage(LoginForm);

const LoginFormPage = (props) => {
  return <LoginFormComp {...props}/>
};

export default LoginFormPage;