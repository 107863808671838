export const getDependentPermissions = (
    featuresPermissionList,
    selectedPermissions
) => {
    let dependentPermissions = {};
    for(let selectedPermissionKey in selectedPermissions) {
        const featureAvailablePermissions = getListOfPermissionsByPermissionKey(
            featuresPermissionList,
            selectedPermissionKey
        );
        dependentPermissions = selectedPermissions[selectedPermissionKey].reduce(
            (dependentPermissionsInner, permission) => consolidateDependednPermissions(
                getListOfDependentPermissions(featureAvailablePermissions, permission),
                dependentPermissionsInner
            ),
            dependentPermissions
        );
    }
    return dependentPermissions;
};

export const combinePermissions = (existingPermissions, dependentPermissions) => {
    return consolidateDependednPermissions(dependentPermissions, existingPermissions);
}

const consolidateDependednPermissions = (
    listOfDependentPermissions,
    dependentPermissions
) => {
    for(const dependentPermissionKey in listOfDependentPermissions) {
        if(!dependentPermissions[dependentPermissionKey]) {
            dependentPermissions[dependentPermissionKey] = [];
        }
        dependentPermissions = listOfDependentPermissions[dependentPermissionKey].reduce(
            (dependentPermissionsInner, permission) => {
                if(!dependentPermissionsInner[dependentPermissionKey].includes(permission)) {
                    dependentPermissionsInner[dependentPermissionKey].push(permission);
                }
                return dependentPermissionsInner;
            },
            dependentPermissions
        );
    }
    return dependentPermissions;
}

const getListOfPermissionsByPermissionKey = (
    featuresPermissionList,
    selectedPermissionKey
) => {
    const featureAvailablePermissions = featuresPermissionList.find(
        featurePermissionList => featurePermissionList.feature_permission_key === selectedPermissionKey
    );

    return featureAvailablePermissions ? featureAvailablePermissions?.feature_permissions : [];
}

const getListOfDependentPermissions = (
    featureAvailablePermissions,
    permission
) => {
    const featureAvailablePermission = featureAvailablePermissions.find(featureAvailablePermission => 
        featureAvailablePermission.permission === permission && featureAvailablePermission.dependent
    );

    return featureAvailablePermission ? featureAvailablePermission?.dependent : {};
}