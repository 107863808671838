import { createSelector } from "@reduxjs/toolkit";
import { selectOfflineStore } from "../selectors";

export const selectCollectionQueueState = createSelector(
    selectOfflineStore,
    (state) => state.collectionQueue
);

export const selectCollectionQueue = createSelector(
    selectCollectionQueueState,
    (state) => state.data
);

export const selectIsQueueFetched = createSelector(
    selectCollectionQueueState,
    (state) => state.isQueueFetched
);

export const selectItemToUpdateMilkAnalysis = createSelector(
    selectCollectionQueue,
    (state) => state.reverse().find(({ analysis }) => !analysis)
);