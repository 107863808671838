import { PrimaryButton } from "../buttons/PrimaryButton";
import usePermission from "./usePermission";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import React from 'react';
import { makeStyles, Typography } from "@material-ui/core";
import { useLanguage } from "src/components/language/LanguageController";
import InnerHeaderSmall from "src/components/pages/common/InnerHeaderSmall";
import NoAccessMessage from "./NoAccessMessage";

export default function HasPermission({
  ability,
  access,
  children,
  showNoAccessMsg,
}) {
  const { hasAccess } = usePermission();

  return hasAccess(ability, access) ? ( children || null ) 
  : showNoAccessMsg ? ( <NoAccessMessage /> )
  : null;
}
