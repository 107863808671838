// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useLanguage } from "../../../language/LanguageController";
import { useSelector } from 'react-redux';
import { PrimarySwitch } from 'src/components/core/Switch';
import { FormHelperText } from '@material-ui/core';

const BillAmountSetting = () => {
    const { t } = useLanguage();
    const [billAmountMsg, setBillAmountMsg] = useState(false);
    const { dairyPreference } = useSelector(state => state.dairySettings);

    useEffect(() => {
        setBillAmountMsg(dairyPreference?.bill_amount_in_msg?.value ? true : false);
    }, [dairyPreference]);

    const handleChange = () => {
        setBillAmountMsg(!billAmountMsg);
    }

    return (
        <div className='form-group'>
            <div className='d-flex justify-content-between'>
                <div>
                    <label className='mb-0'>{t("text_send_bill_amount_in_message")}</label>
                    <FormHelperText>{t("text_message_enable_help")}</FormHelperText>
                </div>
                <div>
                    <PrimarySwitch checked={billAmountMsg} onChange={handleChange} name="bill_amount_in_msg" value={billAmountMsg ? 1 : 0} />
                </div>
            </div>
        </div>
    )
}

export default BillAmountSetting
