// @ts-nocheck
import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import withLanguage from "../../../language/LanguageController";
import analytics from "../../../library/firebase/analytics";
import { copyObject } from "../../../library/util";
import { DefaultLayout } from "../../common/Layout";

const ProductDetail = ({ t, header, bottomGutter, product: propProduct = {} }) => {
    const [product, setProduct] = useState(propProduct);
    const { product_id } = useParams();
    const { products = [] } = useSelector(state => state.productReducer);
    
    useEffect(() => {
        analytics.setScreenName("ProductDetail");
    }, []);

    useEffect(() => {
        if(product_id) {
            const product = product_id ? copyObject(products.find(product => product.id == product_id) || {}) : {};
            setProduct(product);
        }
    }, [products, product_id]);

    return (
        <DefaultLayout 
            title={t("text_product_detail")}
            toolbar={false}
            header={header}
            bottomGutter={bottomGutter}
            back
        >
            <div className="t-card shadow-sm mb-2">
                <Typography variant="h6" component="h2">
                    {product.name ? `${product.name} - ${product.code}` : ""}
                </Typography>
            </div>
            <div className="t-card shadow-sm mb-2 py-1">
                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-borderless mb-0 table-font-bold">
                            <tbody>
                                <tr>
                                    <td>{t("text_price")}:</td>
                                    <td>{product.price}</td>
                                    <td>{t("text_in_stock")}:</td>
                                    <td>{product.quantity}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="table_container">
                <table className="table table-striped table-bordered table-product-detail bg-white">
                    <thead>
                        <tr>
                            <th>{t("text_date")}</th>
                            <th>{t("text_old_qty")}</th>
                            <th>{t("text_new_qty")}</th>
                            <th>{t("text_total_qty")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {product.quantity_history && product.quantity_history.map(product => (
                            <tr key={product.id}>
                                <td>{product.date}</td>
                                <td>{product.old_quantity}</td>
                                <td>{product.new_quantity}</td>
                                <td>{product.total_quantity}</td>
                            </tr>
                        ))}
                        {!(product.quantity_history || []).length ? (
                            <tr>
                                <td colSpan="4" className="column-no-data">
                                    {t("text_no_data_available")}
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </table>
            </div>
        </DefaultLayout>
    );
}

ProductDetail.displayName = "ProductDetail";

ProductDetail.defaultProps = {
  header: true,
  bottomGutter: true
};

export default withLanguage(ProductDetail);
