import React, { Component } from 'react';
class InputCheckbox extends Component {
    render() {
        const { defaultChecked, ...rest } = this.props;
        return (
            <ons-checkbox
                class={"checkbox checkbox--material checkbox--form-check-input "}>
                <input 
                    type="checkbox"
                    className="checkbox__input checkbox--material__input 
                        checkbox--form-check-input__input"
                    {...rest}
                    defaultChecked={defaultChecked}/>
                <span className="checkbox__checkmark 
                    checkbox--material__checkmark 
                    checkbox--form-check-input__checkmark"></span>
            </ons-checkbox>
        );
    }
}

export default InputCheckbox;