// @ts-nocheck
import { DEFAULT_SNF_CONSTANT, DEFAULT_SNF_FACTOR } from "../../../../_constants/rateList";
import { copyObject } from "../../../library/util";

class DairyFormula {    
    constructor(dairyType, milkRate, snfRate, snfFormula, cowStdSNF, buffaloStdSNF, clrRate = {}, isDeduction = "1", rateListSettings = {}){
        this.dairyType = parseInt(dairyType);
        this.cowStdSNF = cowStdSNF;
        this.buffaloStdSNF = buffaloStdSNF;
        this.cFRate = 0; this.bFRate = 0; this.cFFrom = 0; this.cFTo = 0; this.snf = 0; this.clr = 0;
        this.milkRate = milkRate;
        this.isDeduction = isDeduction;
        this.rateListSettings = rateListSettings;
        this.rateListJSON = this.rateListSettings?.manual_rate_list ? JSON.parse(this.rateListSettings.manual_rate_list) : null;

        if (typeof milkRate == "object" && !Array.isArray(milkRate)) {
            let cowFat  = milkRate?.["cow"]?.["fat"];
            this.cFFrom = (cowFat) ? cowFat[0] : 0;
            this.cFTo   = (cowFat) ? cowFat[1] : 0;
            this.cFRate = milkRate?.["cow"]?.["rate"];
            this.bFRate = milkRate?.["buffalo"]?.["rate"];
        }

        this.cowSNFRate = (snfRate && snfRate.cow) ? snfRate.cow : [];
        this.buffaloSNFRate = (snfRate && snfRate.buffalo) ? snfRate.buffalo : [];

        if(this.cowSNFRate && !Array.isArray(this.cowSNFRate)) {
            this.cowSNFRate = [this.cowSNFRate];
        }

        if(this.buffaloSNFRate && !Array.isArray(this.buffaloSNFRate)) {
            this.buffaloSNFRate = [this.buffaloSNFRate];
        }

        this.snfFactor = (snfFormula && snfFormula.factor) ? parseFloat(snfFormula.factor) : 0.2;
        this.snfConstant = (snfFormula && snfFormula.constant) ? parseFloat(snfFormula.constant) : 0.7;

        this.clrRateType = (clrRate.type) ? clrRate.type : "fixed";
        this.clrRateValue = (clrRate.value) ? clrRate.value : 0;
    }

    calculateRate(fat, clr_snf = 0, rateType = 0) {
        let rate = 0, rateBy = "clr";
        if (this.dairyType === 5) {
            rate = this.fixRateByAnimalFormula(rateType);
        } else if (this.dairyType === 7) {
            rate = this.customRateList(fat, clr_snf);
            rateBy = this.rateListSettings.rate_by;
        } else if (this.dairyType === 8){
            rateBy = this.rateListSettings.rate_by;
            rate = this.excelRateList(fat, clr_snf);
        }
        const clrSnfKey = rateBy === "snf" ? "clr" : "snf";

        const fatSlot = this.getSlotByFat(this.rateListSettings.slots, fat);
        
        rate += this.addBonus(fatSlot);
        return {
            rate: (rate) ? parseFloat(rate).toFixed(2) : 0,
            [clrSnfKey]: this?.[clrSnfKey]?.toFixed(2) || 0
        };
    }

    addBonus(slot = null) {
        if(!slot) {
            return 0;
        }
        return (slot.is_bonus == 1) ? slot.bonus_amount / 100 : 0;
    }

    getSlotByFat(slots, fat) {
        if(slots && slots.length) {
            for(let slot of slots) {
                const fatRate = slot.fat_rate || {};
                if(parseFloat(fatRate.min) <= fat && fat <= parseFloat(fatRate.max)) {
                    return slot;
                }
            }
        }
    }

    fixRateByAnimalFormula(rateType = 0) {
        let cattleRates = this.milkRate || [];
        if(!Array.isArray(cattleRates)) {
            cattleRates = [cattleRates];
        }
        return parseFloat(cattleRates?.[rateType]?.rate);
    }

    directRateFormula(fat, clr){
        let fatRate = 0;
        fat = parseFloat(fat);
        fat = fat.toFixed(1);
        if(Array.isArray(this.milkRate)) {
            for(let milkRate of this.milkRate) {
                let minRate = parseFloat(milkRate.fat.min);
                let maxRate = parseFloat(milkRate.fat.max);
                if(fat >=  minRate&& fat <= maxRate){
                    fatRate = milkRate.rate;
                    break;
                }
            }
        }
        let rate = fat * fatRate / 100;

        if(this.isDeduction == "1") {
            this.snf = this.calculateSNF(clr, fat, 0.2, 0.7);
            let standardCLR = this.rateListSettings.std_clr || 30;
            let incDecValue = (clr > standardCLR) ? this.rateListSettings.increment_value : this.clrRateValue;
            let incDecType = this.clrRateType;
            if(clr > standardCLR) {
                incDecType = this.rateListSettings.increment_type;
            }
            if(incDecType == "fixed") {
                rate = rate + (clr - standardCLR) * incDecValue / 100;
            } else {
                if(clr > standardCLR) {
                    rate = rate * Math.pow((1 + incDecValue / 100), Math.abs(standardCLR - clr));
                } else {
                    rate = rate * Math.pow((1 - incDecValue / 100), Math.abs(standardCLR - clr));
                }
            }
        }

        let isBonus = this.rateListSettings.is_bonus || 0;
        if(isBonus == 1) {
            rate += (this.rateListSettings.bonus_amount / 100);
        } 
        return rate;
    }

    calculateCLR (snf = 0, fat = 0, snfFactor = 0.25, snfConstant = 0.7) {
        return (snf - (snfFactor * fat) - parseFloat(snfConstant)) * 4;
    }

    calculateSNF (clr = 0, fat = 0, snfFactor = 0.25, snfConstant = 0.7) {
        return (clr / 4) + (snfFactor * fat) + parseFloat(snfConstant);
    }

    customRateList(fat, clr_snf){
        let rate = 0;
        fat = parseFloat(fat);
        fat = fat.toFixed(1);
        const settings = this.rateListSettings ? this.rateListSettings : {};
        const fatRateSlots = settings.slots || [];

        for(let fatRateSlot of fatRateSlots) {
            const fatRate = fatRateSlot.fat_rate || {};

            if(parseFloat(fatRate.min) <= fat && fat <= parseFloat(fatRate.max)) {
                let snfFactor = 0.2, snfConstant = 0.7;
                if(settings.rate_by === "clr") {
                    snfFactor = fatRateSlot.snf_formula ? fatRateSlot.snf_formula.factor : 0.2;
                    snfConstant = fatRateSlot.snf_formula ? fatRateSlot.snf_formula.constant : 0.7;
                    this.snf = this.calculateSNF(clr_snf, fat, snfFactor, snfConstant);
                    this.clr = clr_snf;
                } else {
                    snfFactor = fatRateSlot.clr_formula ? fatRateSlot.clr_formula.factor : 0.2;
                    snfConstant = fatRateSlot.clr_formula ? fatRateSlot.clr_formula.constant : 0.7;
                    this.snf = clr_snf;
                    this.clr = this.calculateCLR(clr_snf, fat, snfFactor, snfConstant);
                }
        
                this.clr = parseInt(this.clr);
                this.snf = parseFloat(this.snf);

                let baseRate = parseFloat(fatRate.base_rate);
                let snfBaseRate = parseFloat(fatRate.snf_base_rate || 0);

                if(fatRateSlot.is_increment_by_fat == 1) {
                    // Increment into base rate per fat point fat base rate
                    if(fatRateSlot.increment_by_fat_type === "fixed") { // Incrementation by fix(paise) rate
                        baseRate = baseRate + (fat - fatRate.min) * fatRateSlot.increment_by_fat/10;
                    } else if(fatRateSlot.increment_by_fat_type === "percent") { // Increment by percentage
                        baseRate = baseRate * Math.pow((1 + fatRateSlot.increment_by_fat / 100), Math.abs(fat - fatRate.min) * 10);
                    }

                    // Increment into base rate per fat point snf base rate
                    if(fatRateSlot.rate_type === "fat_snf_ratio") { // Incrementation by fix(paise) rate
                        if(fatRateSlot.increment_by_fat_type === "fixed") {
                            snfBaseRate = snfBaseRate + (fat - fatRate.min) * fatRateSlot.increment_by_fat/10;
                        } else if(fatRateSlot.increment_by_fat_type === "percent") { // Increment by percentage
                            snfBaseRate = snfBaseRate * Math.pow((1 + fatRateSlot.increment_by_fat / 100), Math.abs(fat - fatRate.min) * 10);
                        }
                    }
                }

                const baseRateIncDecSnfCLR = [];
                if(fatRateSlot.is_deduction == "1") {
                    const incDecResult = this.incrementDecrementRate(fat, baseRate, fatRateSlot, true);
                    baseRate = incDecResult.rate;
                    
                    if(incDecResult.isIncDec) {
                        if(fatRateSlot.increment_decrement_by === "snf") {
                            baseRateIncDecSnfCLR.push(this.snf);
                        } else if(fatRateSlot.increment_decrement_by === "clr") {
                            baseRateIncDecSnfCLR.push(this.clr);
                        }
                    }

                    if(fatRateSlot.rate_type === "fat_snf_ratio") {
                        const result = this.incrementDecrementRate(fat, snfBaseRate, fatRateSlot, true);
                        snfBaseRate = result.rate;
                    }
                }

                switch(fatRateSlot.rate_type) {
                    case "ts" :
                        rate = this.totalSolidRate(fat, baseRate, this.snf, fatRateSlot);
                        break;
                    case "fat_snf_ratio" :
                        rate = this.fatSNFRatioRate(fat, this.snf, baseRate, snfBaseRate, fatRate, fatRateSlot);
                        break;
                    case "feq" :
                        let snfValue = fatRateSlot.is_fix_snf == "1" ? parseFloat(fatRateSlot.snf_value) : this.snf;
                        rate = this.fatEquivalentRate(fat, baseRate, snfValue, fatRateSlot);
                        break;
                    case "direct" :
                        rate = this.directByFatRate(fat, baseRate);
                        break;
                    case "fix" :
                        rate = this.fixRate(baseRate);
                        break;
                    default :
                        rate = 0;
                }
                
                if(fatRateSlot.is_deduction == "1") {
                    if(baseRateIncDecSnfCLR.indexOf(this[fatRateSlot.increment_decrement_by]) <= -1) {
                        const incDecResult = this.incrementDecrementRate(fat, rate, fatRateSlot);
                        rate = incDecResult.rate;
                    }
                }
                break;
            }
        }
        
        return rate;
    }

    getFixSNF (fat, fixSNFCLR = {}, fatRateSlot) {
        const isFixSNFCLR = fixSNFCLR.enabled == "1" || false;
        let snf;
        if(isFixSNFCLR) {
            if(fixSNFCLR.type === "clr") {
                const snfFactor = fatRateSlot.snf_formula ? fatRateSlot.snf_formula.factor : DEFAULT_SNF_FACTOR;
                const snfConstant = fatRateSlot.snf_formula ? fatRateSlot.snf_formula.constant : DEFAULT_SNF_CONSTANT;
                snf = this.calculateSNF(fixSNFCLR.value, fat, snfFactor, snfConstant);
            } else {
                snf = fixSNFCLR.value;
            }
        }

        return snf ? parseFloat(snf) : snf;
    }

    totalSolidRate (fat, tsRate, snf, fatRateSlot) {
        let nSNF = this.getFixSNF(fat, fatRateSlot.fix_snf_clr, fatRateSlot) || snf;
        const ts = nSNF + parseFloat(fat);
        const rate = ts * tsRate / 100;
        return rate;
    }

    fatSNFRatioRate (fat, snf, fatBaseRate, snfBaseRate, fatRateObj, fatRateSlot) {
        let nSNF = this.getFixSNF(fat, fatRateSlot.fix_snf_clr, fatRateSlot) || snf;
        let rate = 0;
        if(typeof fatRateObj.is_fat_snf_ratio === "undefined" || fatRateObj.is_fat_snf_ratio == 1) {
            rate = (fat * fatBaseRate/100) * fatRateObj.base_rate_ratio/100 + (nSNF * snfBaseRate/100) * fatRateObj.snf_base_rate_ratio/100;
        } else {
            rate = (fat * fatBaseRate/100) + (nSNF * snfBaseRate/100);
        }
        return rate;
    }

    fatEquivalentRate (fat, fatRate, snf, fatRateSlot) {
        let nSNF = this.getFixSNF(fat, fatRateSlot.fix_snf_clr, fatRateSlot) || snf;
        const fatEq = parseFloat(fat) + 2/3 * nSNF;
        const rate = fatEq * fatRate / 100;
        return rate;
    }

    directByFatRate (fat, fatRate) {
        return parseFloat(fat) * fatRate / 100;
    }

    fixRate (fatRate) {
        let rate = parseFloat(fatRate);
        return rate;
    }

    getDecrementSlot (decrements, clrSnf, isBaseRate = false) {
        let decrementSlot = null, slotIndex = 0, deductionOn = isBaseRate ? "base_rate" : "calculated_rate";
        for(let decrementSlotIndex in decrements) {
            const decrement = decrements[decrementSlotIndex];
            if(parseFloat(decrement.value) > clrSnf && decrement.on === deductionOn) {
                decrementSlot = decrement;
                slotIndex = decrementSlotIndex;
                const nextDecrementSlotIndex = decrementSlotIndex + 1;
                if(decrements.length <= nextDecrementSlotIndex) {
                    const nextDecrementSlot = decrements[nextDecrementSlotIndex];
                    if(nextDecrementSlot && nextDecrementSlot.value <= clrSnf) {
                        break;
                    }
                }
            }
        }

        return {
            decrementSlot,
            slotIndex
        };
    }

    getIncrementSlot (increments, clrSnf, isBaseRate = false) {
        let incrementSlot = null, slotIndex = 0, incrementOn = isBaseRate ? "base_rate" : "calculated_rate";
        for(let incrementSlotIndex in increments) {
            const increment = increments[incrementSlotIndex];
            if(parseFloat(increment.value) < clrSnf && increment.on === incrementOn) {
                incrementSlot = increment;
                slotIndex = incrementSlotIndex;
                const nextIncrementSlotIndex = incrementSlotIndex + 1;
                if(increments.length <= nextIncrementSlotIndex) {
                    const nextIncrementSlot = increments[nextIncrementSlotIndex];
                    if(nextIncrementSlot && nextIncrementSlot.value >= clrSnf) {
                        break;
                    }
                }
            }
        }

        return {
            incrementSlot,
            slotIndex
        };
    }

    lastDecrementSlotRate (rate, fat, clr_snf, slotIndex, rateType = "snf") {
        const lastSlotIndex = slotIndex - 1;
        if(lastSlotIndex >= 0) {
            const settings = copyObject(this.rateListSettings);
            settings.rate_by = rateType;
            const dairyFormula = new DairyFormula(this.dairyType,
                settings.milk_rate,
                settings.snf_rate,
                settings.snf_formula,
                settings.cow_std_snf,
                settings.buffalo_std_snf,
                settings.clr_rate,
                settings.is_deduction,
                settings
            );
            let lastSlotValue = rateType === "snf" ? parseFloat(clr_snf) : parseInt(clr_snf);
            const { rate: slotRate } = dairyFormula.calculateRate(fat, lastSlotValue);
            return slotRate;
        }
        
        return rate;
    }

    lastIncrementSlotRate (rate, fat, clr_snf, slotIndex, rateType = "snf") {
        const lastSlotIndex = slotIndex - 1;
        if(lastSlotIndex >= 0) {
            const settings = copyObject(this.rateListSettings);
            settings.rate_by = rateType;
            const dairyFormula = new DairyFormula(this.dairyType,
                settings.milk_rate,
                settings.snf_rate,
                settings.snf_formula,
                settings.cow_std_snf,
                settings.buffalo_std_snf,
                settings.clr_rate,
                settings.is_deduction,
                settings
            );
            let lastSlotValue = rateType === "snf" ? parseFloat(clr_snf) : parseInt(clr_snf);
            const { rate: slotRate } = dairyFormula.calculateRate(fat, lastSlotValue);
            return slotRate;
        }
        
        return rate;
    }

    checkIsInCalculatedRateSlot (snfClr, baseDecrementSlot, slots, isIncrement = false) {
        const filteredSlots = slots.filter(slot => slot.on === "calculated_rate");
        const { decrementSlot } = isIncrement ? this.getIncrementSlot (filteredSlots, snfClr) : this.getDecrementSlot (filteredSlots, snfClr);
        if(decrementSlot) {
            return decrementSlot.value < baseDecrementSlot.value;
        }

        return false;
    }

    incrementDecrementRate (fat, rate, fatRateSlot, isBaseRate = false) {
        let decrements = fatRateSlot.decrement || [];
        let increments = fatRateSlot.increment || [];
        let isIncDec = false;
        decrements = decrements.sort(function(a, b) {
            return b.value - a.value;
        });
        increments = increments.sort(function(a, b) {
            return a.value - b.value;
        });

        if(fatRateSlot.increment_decrement_by === "snf") { // Increment/Deduction by SNF
            let compoundDiff = Math.abs(this.snf - fatRateSlot.std_snf) * 10;
            if(parseFloat(fatRateSlot.std_snf) > this.snf) {
                // Deduction for SNF
                let filteredSlots = decrements;
                if(isBaseRate) {
                    filteredSlots = decrements.filter(slot => slot.on === "base_rate");
                } else {
                    filteredSlots = decrements.filter(slot => slot.on === "calculated_rate");
                }
                const { decrementSlot } = this.getDecrementSlot (filteredSlots, this.snf, isBaseRate);
                const slotIndex = decrements.indexOf(decrementSlot);
                if(decrementSlot) {
                    let inCalculatedSlot = false;
                    // If base rate then check slot has calculated deduction or not
                    if(isBaseRate) {
                        inCalculatedSlot = this.checkIsInCalculatedRateSlot(this.snf, decrementSlot, decrements);
                    }
                    // If SNF slot has calculated decution slot then does not calculated deduction by base rate
                    if(!inCalculatedSlot) {
                        // Calculate base rate for last slot
                        let slotRate = rate;
                        if(fatRateSlot.inc_dec_deduction_type === "by_slot"){
                            if(!isBaseRate) {
                                slotRate = this.lastDecrementSlotRate(rate, fat, decrementSlot.value, slotIndex, "snf");
                            }
                            compoundDiff = Math.abs(this.snf - decrementSlot.value) * 10;
                        }
                        if(this.isDecrementApplicable(fatRateSlot)) { // Check for decrement start from clr/snf
                            rate = this.decrementRate(slotRate, compoundDiff, decrementSlot);
                            isIncDec = true;
                        }
                    }
                }
            } else if(parseFloat(fatRateSlot.std_snf) < this.snf) {
                // Increment for SNF
                let filteredSlots = increments;
                if(isBaseRate) {
                    filteredSlots = increments.filter(slot => slot.on === "base_rate");
                } else {
                    filteredSlots = increments.filter(slot => slot.on === "calculated_rate");
                }
                const { incrementSlot } = this.getIncrementSlot (filteredSlots, this.snf, isBaseRate);
                const slotIndex = increments.indexOf(incrementSlot);
                if(incrementSlot) {
                    let inCalculatedSlot = false;
                    // If base rate then check slot has calculated increment or not
                    if(isBaseRate) {
                        inCalculatedSlot = this.checkIsInCalculatedRateSlot(this.snf, incrementSlot, increments);
                    }
                    // If SNF slot has calculated increment slot then does not calculated increment by base rate
                    if(!inCalculatedSlot) {
                        // Calculate base rate for last slot
                        let slotRate = rate;
                        if(fatRateSlot.inc_dec_increment_type === "by_slot"){
                            if(!isBaseRate) {
                                slotRate = this.lastIncrementSlotRate(rate, fat, incrementSlot.value, slotIndex, "snf");
                            }
                            compoundDiff = Math.abs(this.snf - incrementSlot.value) * 10;
                        }
                        if(this.isIncrementApplicable(fatRateSlot)) { // Check for increament start from clr/snf
                            rate = this.incrementRate(+slotRate, compoundDiff, incrementSlot);
                            isIncDec = true;
                        }
                    }
                }
            }
        } else if(fatRateSlot.increment_decrement_by === "clr") { // Increment/Deduction by CLR
            let compoundDiff = Math.abs(this.clr - fatRateSlot.std_clr);
            if(parseFloat(fatRateSlot.std_clr) > this.clr) {
                // Deduction for CLR
                let filteredSlots = decrements;
                if(isBaseRate) {
                    filteredSlots = decrements.filter(slot => slot.on === "base_rate");
                } else {
                    filteredSlots = decrements.filter(slot => slot.on === "calculated_rate");
                }
                const { decrementSlot } = this.getDecrementSlot (filteredSlots, this.clr, isBaseRate);
                const slotIndex = decrements.indexOf(decrementSlot);
                if(decrementSlot) {
                    let inCalculatedSlot = false;
                    // If base rate then check slot has calculated increment or not
                    if(isBaseRate) {
                        inCalculatedSlot = this.checkIsInCalculatedRateSlot(this.snf, decrementSlot, decrements);
                    }
                    // If SNF slot has calculated increment slot then does not calculated increment by base rate
                    if(!inCalculatedSlot) {
                        // Calculate base rate for last slot for calculated rate
                        let slotRate = rate;
                        if(fatRateSlot.inc_dec_deduction_type === "by_slot"){
                            if(!isBaseRate) {
                                slotRate = this.lastDecrementSlotRate(rate, fat, decrementSlot.value, slotIndex, "clr");
                            }
                            compoundDiff = Math.abs(this.clr - decrementSlot.value);
                        }
                        if(this.isDecrementApplicable(fatRateSlot)) { // Check for decrement start from clr/snf
                            rate = this.decrementRate(slotRate, compoundDiff, decrementSlot);
                        }
                        isIncDec = true;
                    }
                }
            } else if(parseFloat(fatRateSlot.std_clr) < this.clr) {
                // Increment for CLR
                let filteredSlots = increments;
                if(isBaseRate) {
                    filteredSlots = increments.filter(slot => slot.on === "base_rate");
                } else {
                    filteredSlots = increments.filter(slot => slot.on === "calculated_rate");
                }
                const { incrementSlot } = this.getIncrementSlot (filteredSlots, this.clr, isBaseRate);
                const slotIndex = increments.indexOf(incrementSlot);
                if(incrementSlot) {
                    let inCalculatedSlot = false;
                    // If base rate then check slot has calculated increment or not
                    if(isBaseRate) {
                        inCalculatedSlot = this.checkIsInCalculatedRateSlot(this.snf, incrementSlot, increments);
                    }
                    // If SNF slot has calculated increment slot then does not calculated increment by base rate
                    if(!inCalculatedSlot) {
                        // Calculate base rate for last slot
                        let slotRate = rate;
                        if(fatRateSlot.inc_dec_increment_type === "by_slot"){
                            if(!isBaseRate) {
                                slotRate = this.lastIncrementSlotRate(rate, fat, incrementSlot.value, slotIndex, "clr");
                            }
                            compoundDiff = Math.abs(this.clr - incrementSlot.value);
                        }
                        if(this.isIncrementApplicable(fatRateSlot)) { // Check for increment start from clr/snf
                            rate = this.incrementRate(slotRate, compoundDiff, incrementSlot, fatRateSlot);
                        }
                        isIncDec = true;
                    }
                }
            }
        }

        return {
            rate,
            isIncDec
        };
    }

    decrementRate (
        rate,
        compoundDiff,
        { by, type, rate: decRate }
    ) {
        if(type === "percent") {
            // Calculating percentag deduction of rate by snf diff for calculated rate
            if(by === "compound") {
                rate = rate * Math.pow((1 - parseFloat(decRate) / 100), compoundDiff);
            } else {
                rate -= rate * decRate / 100;
            }
        } else if(type === "fixed") {
            // Calculating fixed(paise) deduction of rate by snf diff for calculated rate/ base rate
            if(by === "compound") {
                rate -= compoundDiff * parseFloat(decRate) / 100;
            } else {
                rate -= parseFloat(decRate) / 100;
            }
        }

        return rate;
    }

    incrementRate (rate, compoundDiff, { by, type, rate: incRate }) {
        if(type === "percent") {
            // Calculating percentag deduction of rate by snf diff for calculated rate
            if(by === "compound") {
                rate = rate * Math.pow((1 + parseFloat(incRate) / 100), compoundDiff);
            } else {
                rate += rate * incRate / 100;
            }
        } else if(type === "fixed") {
            // Calculating fixed(paise) deduction of rate by snf diff for calculated rate/ base rate
            if(by === "compound") {
                rate += compoundDiff * parseFloat(incRate) / 100;
            } else {
                rate += parseFloat(incRate) / 100;
            }
        }

        return rate;
    }

    isDecrementApplicable = ({ inc_dec_deduction_start_by, inc_dec_deduction_start_from }) => {
        if(inc_dec_deduction_start_by === "clr") {
            return this.clr <= inc_dec_deduction_start_from;
        } else if(inc_dec_deduction_start_by === "snf") {
            return this.snf <= inc_dec_deduction_start_from;
        } else {
            return true;
        }
    }

    isIncrementApplicable = ({ inc_dec_increment_start_by, inc_dec_increment_start_from }) => {
        if(inc_dec_increment_start_by === "clr") {
            return this.clr >= inc_dec_increment_start_from;
        } else if(inc_dec_increment_start_by === "snf") {
            return this.snf >= inc_dec_increment_start_from;
        } else {
            return true;
        }
    }

    excelRateList = (fat, clr_snf) => { 
        const isFatDecimal = fat % 1 !== 0;
        const isClrSnfDecimal = clr_snf % 1 !== 0;
        if(this.rateListSettings?.rate_by === "clr") {
            this.snf = this.calculateSNF(clr_snf, fat, this.snfFactor, this.snfConstant);
        } else {
            this.clr = this.calculateCLR(clr_snf, fat, this.snfFactor, this.snfConstant);
        }
        let rateFatValue = {};
        if(isFatDecimal) {
            rateFatValue = this.rateListJSON?.[fat];
        } else {
            rateFatValue = this.rateListJSON?.[fat] || this.rateListJSON?.[parseFloat(fat).toFixed(1)]; 
        }

        if(isClrSnfDecimal) {
            return rateFatValue?.[clr_snf];
        } else {
            return rateFatValue?.[clr_snf] || rateFatValue?.[parseFloat(clr_snf).toFixed(1)]; 
        }
    }
}

export default DairyFormula;