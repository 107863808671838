export const calculateRewardBalance = (reward, subTotal, coupon) => {
    let balance = 0;
    if(coupon){
        subTotal -= coupon.discount;
    }
    if(reward && subTotal>0){
        let calculateBalance=reward.points/reward.point_price;
        balance =(subTotal<calculateBalance)?subTotal:calculateBalance;
    }
   return balance;
};

export const getPoints = (reward,balance) => {
    let points=0;
    if(reward){
     points=balance*reward.point_price;
    }
   return points;
}

export const calculateCoupon = (subTotal, coupon) => {
    let couponAmount = 0;
    if(subTotal > 0 && coupon){
        couponAmount = (subTotal < coupon.discount) ? subTotal : coupon.discount;
    }
    return couponAmount;
}