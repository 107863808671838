// @ts-nocheck
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { useLanguage } from "../../language/LanguageController";
import Timer from "./Timer";
import dealIcon from "./noun_Stopwatch_504631.png";
import "./style.css";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import analytics from "../../library/firebase/analytics";
import moment from "moment";
import ModalDialog from "../../common/ModalDialog";
import { PrimaryButton } from "../../common/buttons/PrimaryButton";
import { IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { useHistory, useLocation } from "react-router";

let timeoutValue;

const SubscriptionUpsaleModal = ({ onClose, onUseCoupon, isOpen, isCheckout = true, plans, subscription}) => {
    const [isPopUpOpen, setPopUpState] = useState(isOpen);
    const upsalePopup = useSelector(state => state.defaultReducer.upsale_popup);
    const upsalePopupAnywhere = useSelector(state => state.defaultReducer.upsale_popup_anywhere);
    const upsalePopupDetails = isCheckout ? upsalePopup : upsalePopupAnywhere;
    const location = useLocation();
    const history = useHistory();
    const { t, language: lang } = useLanguage();

    const gotoCheckout = (coupon) => {
        const plan = plans.find(plan => plan.name == "Premium");
        setPopUpState(false);
        analytics.logEvent("SubscriptionUpsaleModal - GotoCheckout");
        history.push({
            pathname: "/subscription/checkout",
            state: {
                plan: plan,
                duration: 12,
                defaultCoupon: coupon
            }
        });
    };

    const timerExpired = (e) => {
        analytics.logEvent("SubscriptionUpsaleModal - TimerExpired");
        if(isCheckout) {
            onClose(e);
        } else {
            setPopUpState(false);
        }
    };

    const closePopup = (e) => {
        e.persist();
        analytics.logEvent("SubscriptionUpsaleModal - Close");
        if(isCheckout) {
            onClose(e);
        } else {
            setPopUpState(false);
        }
    };

    const checkPagetoShow = () => {
        const { pathname } = location
        return !pathname.includes("subscription");
    };

    const checkShowPopup = (subscription) => {
        let upsalePopupModalTime = window.localStorage.getItem("upsalePopupModal");
        let showPopuo = false;
        if (upsalePopupModalTime) {
            const lastShowTime = moment(upsalePopupModalTime);
            const dayDiff = moment().diff(lastShowTime, "days");
            if(dayDiff >= 1) {
                showPopuo = true;
            }
        } else {
            showPopuo = true;
        }

        if (
            showPopuo
            && (!subscription.subscription_id || subscription.is_trial == 1)
            && checkPagetoShow()
        ) {
            window.localStorage.setItem("upsalePopupModal", moment().toISOString());
            setPopUpState(true);
        }
    };

    const useCoupon = (e) => {
        if(isCheckout) {
            onUseCoupon(upsalePopupDetails.coupon);
        } else {
            gotoCheckout(upsalePopupDetails.coupon);
        }
    };

    useEffect(() => {
        if(isPopUpOpen) {
            analytics.logEvent("SubscriptionUpsaleModal - Displayed");
        }
        if(!isCheckout) {
            if(timeoutValue) {
                clearTimeout(timeoutValue);
            }
            timeoutValue = setTimeout(() => {
                checkShowPopup(subscription);
            }, 1000);
        }
    }, [isPopUpOpen, subscription]);

    const isModalOpen = (isCheckout && isOpen) || isPopUpOpen;

    return (
        <ModalDialog
            isOpen={isModalOpen}
            dividers={false}
            content={
                <div className="subscription-upsale-modal text-center">
                    <IconButton aria-label="close" className="btn-modal-close" onClick={closePopup}>
                        <CloseIcon />   
                    </IconButton>
                    <div className="offer-circle-container">
                        <div className="offer-deal-text">
                            <img src={dealIcon} />&nbsp;
                            <span>{upsalePopupDetails?.title?.[lang]}</span>
                        </div>
                        <div className={"offer-circle"}>
                            <span>{upsalePopupDetails?.subtitle?.[lang]}</span>
                        </div>
                    </div>
                    <div className="lheight-1-5 mb-4 pl-3 pr-3">
                        <span dangerouslySetInnerHTML={{
                            __html: upsalePopupDetails?.text?.[lang]
                        }}/>
                    </div>
                    <div className="mb-4 coupon-code">
                        <span>{upsalePopupDetails.coupon}</span>
                    </div>
                    <div className="upsale-timer mb-2">
                        {isModalOpen && <Timer time={upsalePopupDetails.timer} onExpire={timerExpired}/>}
                    </div>
                </div>
            }
            dialogActions={
                <PrimaryButton
                    variant="contained"
                    className={"upsale-modal-btn"}
                    onClick={useCoupon}
                >
                    {t("text_use_code")}
                </PrimaryButton>
            }
        />
    );
};


SubscriptionUpsaleModal.propTypes = {
    getNavigator: PropTypes.func,
    onClose: PropTypes.func,
    onUseCoupon: PropTypes.func,
    language: PropTypes.string,
    isOpen: PropTypes.bool,
    isCheckout: PropTypes.bool,
    plans: PropTypes.array,
    subscription: PropTypes.object
};

const mapStateToProps = state => ({
    ...state.subscription
});

export default connect(mapStateToProps)(SubscriptionUpsaleModal);