import RefreshIcon from '@material-ui/icons/Refresh';
import React, { Fragment } from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { useMobile } from "src/components/library/detect-device";
import { getIsDairyOwner } from 'src/reducers/dairy';
import { PrimaryButton } from "../../../common/buttons/PrimaryButton";
import { useLanguage } from "../../../language/LanguageController";
import { selectLastPlanId } from 'src/reducers';

const InvoiceButtons = ({
    plan,
    days,
    is_trial,
    trial_days,
    is_reloading,
    onRenew,
    onUpgrade,
    referral_code,
    toogleAccountActivationModal,
    refreshSubscription,
}) => {
    const { t } = useLanguage();
    const isMobile = useMobile();
    const buttons = [];

    const is_owner = useSelector(getIsDairyOwner);
    const lastPlanId = useSelector(selectLastPlanId);

    if (plan && days.days_remaining <=15 && is_trial != 1 && is_owner) {
        buttons.push(
            <PrimaryButton 
                variant="contained"
                color="primary"
                type="button"
                size={isMobile ? "small" : "medium"}
                onClick={onRenew}
                className="mr-2 mb-2"
                key="renew_button"
            >
                <i className="fa fa-repeat" aria-hidden="true"></i>&nbsp;&nbsp;{t("text_renew")}
            </PrimaryButton>
        );
    }

    if(referral_code) {
        buttons.push(
           <PrimaryButton
                variant="contained"
                color="primary"
                type="button"
                size={isMobile ? "small" : "medium"}
                onClick={onUpgrade}
                className={`mb-2 ${is_trial == 1 ? "mb-2" : ""}`}
                key="view_plan_button"
            >
                {t("text_view_plan")}
            </PrimaryButton>
        );
        if(is_trial == 1 && is_owner) {
            buttons.push(
                <div key="activate_subscription_button">
                    <PrimaryButton
                        variant="contained"
                        color="primary"
                        type="button"
                        size={isMobile ? "small" : "medium"}
                        onClick={e => toogleAccountActivationModal(false)}
                        className="mb-2"
                    >
                        {t("text_activate_subscription")}
                    </PrimaryButton>
                </div>
            );
        }
    } else {
        if(is_trial == 1 && is_owner) {
            buttons.push(
                <PrimaryButton
                    variant="contained"
                    color="primary"
                    type="button"
                    size={isMobile ? "small" : "medium"}
                    onClick={onUpgrade}
                    className="mb-2"
                    key="subscribe_button"
                >
                    {t("text_subscribe")}
                </PrimaryButton>
            );
        } else if(plan && plan.plan_id !== lastPlanId && is_owner) {
            buttons.push(
                <PrimaryButton
                    variant="contained"
                    color="primary"
                    type="button"
                    size={isMobile ? "small" : "medium"}
                    onClick={onUpgrade}
                    className="mb-2"
                    key="change_plan_button"
                >
                    {t("text_change_plan")}
                </PrimaryButton>
            );
        } else if(!plan && is_owner){
            buttons.push(
                <PrimaryButton
                    variant="contained"
                    color="primary"
                    type="button"
                    size={isMobile ? "small" : "medium"}
                    onClick={onUpgrade}
                    className="mb-2"
                    key="update_button"
                >
                    {t("text_update")}
                </PrimaryButton>
            );
        }
    }

    
    buttons.push(
        <Fragment key="orders_button">
            <PrimaryButton
                variant="contained"
                color="primary"
                type="button"
                size={isMobile ? "small" : "medium"}
                className="ml-2 mb-2 d-md-none"
                component={Link}
                to="/subscription/orders"
                key="orders_button"
            >
                {t("text_orders")}
            </PrimaryButton>
            <PrimaryButton
                variant="contained"
                color="primary"
                type="button"
                size={isMobile ? "small" : "medium"}
                onClick={e => refreshSubscription(false)}
                className="ml-2 mb-2"
                disabled={is_reloading}
                key="refresh_button"
            >
                <RefreshIcon className={`${is_reloading && "fa-spin"} mr-1`}/>
                {t("text_refresh")}
            </PrimaryButton>
        </Fragment>
    );

    return buttons;
};

export default InvoiceButtons;