import React from "react";
import MilkDetailController from "./MilkDetailController";
import withLanguage from "../../language/LanguageController";
import MuiDatepicker from "../../common/MuiDatepicker";
import moment from "moment";
import CowWhiteIcon from "../../common/icons/CowWhiteIcon";
import CowBlackIcon from "../../common/icons/CowBlackIcon";
import BuffaloWhiteIcon from "../../common/icons/BuffaloWhiteIcon";
import BuffaloBlackIcon from "../../common/icons/BuffaloBlackIcon";
import { connect } from "react-redux";
import { DefaultLayout } from "../common/Layout";
import { DefaultScrollbar } from "../common/Scrollbar";

class MilkDetail extends MilkDetailController {

    render() {
        const { records, start_date, end_date, cattle, isBrowser } = this.state;
        const { t, header, filter } = this.props;
        return (
            <DefaultLayout
                back
                bottomGutter={false}
                header={header}
                onDeviceChange={this.onDeviceChange}
                title={t("text_milk_detail")}
                toolbar={false}>
                {filter ? <div className="mb-2">
                    <div className="input-group" role="group" aria-label="Date Selection">
                        <div className="input-group-prepend">
                            <span className="input-group-text text-sm px-2">{t("text_from")}</span>
                        </div>
                        <MuiDatepicker
                            value={start_date}
                            maxDate={end_date}
                            onChange={selectedDate => this.dateChangeHandler("start_date", selectedDate)}
                            name="start_date"
                            inputId="start_date"
                            inputClass="bg-white"
                            arrowButtons={false}
                        />
                        <div className="input-group-prepend input-group-append">
                            <span className="input-group-text text-sm px-2">{t("text_to")}</span>
                        </div>
                        <MuiDatepicker
                            value={end_date}
                            minDate={start_date}
                            maxDate={moment().endOf("month")}
                            onChange={selectedDate => this.dateChangeHandler("end_date", selectedDate)}
                            name="end_date"
                            inputId="end_date"
                            inputClass="bg-white"
                            arrowButtons={false}
                        />
                    </div>
                </div> : null}
                <div className="mb-2 text-center">
                    <div className="btn-group shadow-sm" role="group" aria-label="Cattle Selection">
                        <button
                            className={`btn ${cattle == "all" ? "bg-light-blue text-white active" : "btn-light"}`}
                            type="button"
                            onClick={() => this.cattleChangeHandler("all")}>
                            &nbsp;{t("text_all")}
                        </button>
                        <button
                            className={`btn ${cattle == "cow" ? "bg-light-blue text-white active" : "btn-light"}`}
                            type="button"
                            onClick={() => this.cattleChangeHandler("cow")}>
                            {cattle == "cow" ? <CowWhiteIcon /> : <CowBlackIcon />} 
                            &nbsp;{t("text_cow")}
                        </button>
                        <button
                            className={`btn ${cattle == "buffalo" ? "bg-light-blue text-white active" : "btn-light"}`}
                            type="button"
                            onClick={() => this.cattleChangeHandler("buffalo")}>
                            {cattle == "buffalo" ? <BuffaloWhiteIcon /> : <BuffaloBlackIcon />}
                            &nbsp;{t("text_buffalo")}
                        </button>
                    </div>
                </div>
                <DefaultScrollbar
                    isEnabled={isBrowser}
                    className="my-milk-diary-milk-detail-list-scrollbar">
                    <div className="t-card mb-2 shadow-sm">
                        <div className="title right card__title card--material__title mt-0 mb-0 clearfix">
                            <div className="d-account-h1 float-left mt-0 mb-0 capitalize">
                                {t("text_total")}
                            </div>
                        </div>
                        <div className={"content card__content card--material__content"}>
                            <div className="table-fix-header mt-1">
                                <table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>{t("text_milk")}</th>
                                            <th>{t("text_avg_milk")}</th>
                                            <th>{t("text_avg_fat")}</th>
                                            <th>{t("text_rate")}</th>
                                            <th>{t("text_amount")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {this.state.total.total_milk || 0}
                                            </td>
                                            <td>
                                                {this.state.total.avg_milk || 0}
                                            </td>
                                            <td>
                                                {this.state.total.avg_fat || 0}
                                            </td>
                                            <td>
                                                {this.state.total.rate || 0}
                                            </td>
                                            <td>
                                                {this.state.total.total_amount || 0}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {records.map((record, index) => {
                        return (<div className="t-card mb-2 shadow-sm" key={record.id}>
                            <div className="title right card__title card--material__title mt-0 mb-0 clearfix">
                                <div className="d-account-h1 float-left mt-0 mb-1 capitalize">
                                    {record.date} - {t("text_" + record.milk_type)} 
                                </div>
                                <div className="float-right">
                                    
                                </div>
                            </div>
                            <div className={"content card__content card--material__content"}>
                                <div className="table-fix-header mt-1">
                                    <table className="table mb-0 table-tr-td-ptb-8">
                                        <thead>
                                            <tr>
                                                <th className="pl-0">{t("text_day_time")}</th>
                                                <th>{t("text_liter")}</th>
                                                <th>{t("text_fat")}</th>
                                                <th>{t("text_rate")}</th>
                                                <th>{t("text_amount")}</th>
                                                <th className="pr-0"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="pl-0">
                                                    {t("text_morning")}
                                                </td>
                                                <td>
                                                    {record.liter_0 > 0 ? record.liter_0 : "-"}
                                                </td>
                                                <td>
                                                    {record.fat_0 > 0 ? record.fat_0 : "-"}
                                                </td>
                                                <td>
                                                    {record.rate_0 > 0 ? record.rate_0 : "-"}       
                                                </td>
                                                <td>
                                                    {record.total_0 > 0 ? record.total_0 : "-"}
                                                </td>
                                                <td className="pr-0 text-right">
                                                    {record.liter_0 > 0 ? (
                                                        <button className="btn btn-outline-danger btn-sm" onClick={e => this.deleteMilk(e, record, 0)}>
                                                            <i className="fa fa-trash"/>
                                                        </button>
                                                    ) : null}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pl-0">
                                                    {t("text_evening")}
                                                </td>
                                                <td>
                                                    {record.liter_1 > 0 ? record.liter_1 : "-"}
                                                </td>
                                                <td>
                                                    {record.fat_1 > 0 ? record.fat_1 : "-"}
                                                </td>
                                                <td>
                                                    {record.rate_1 > 0 ? record.rate_1 : "-"}
                                                </td>
                                                <td>
                                                    {record.total_1 > 0 ? record.total_1 : "-"}
                                                </td>
                                                <td className="pr-0 text-right">
                                                    {record.liter_1 > 0 ? (
                                                        <button className="btn btn-outline-danger btn-sm" onClick={e => this.deleteMilk(e, record, 1)}>
                                                            <i className="fa fa-trash"/>
                                                        </button>
                                                    ) : null}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>);
                    })}
                </DefaultScrollbar>
                {!records.length ? (
                    <div className="t-card shadow-sm mb-2">
                        <div className={"content card__content card--material__content"}>
                            <h5 className="text-center">
                                {t("text_no_data_available")}
                            </h5>
                        </div>
                    </div>
                ) : null}
            </DefaultLayout>
        );
    }
}

MilkDetail.displayName = "MilkDetail";

MilkDetail.defaultProps = {
    header: true,
    filter: true
};

const mapStateToProps = state => ({
    customerId: state.userReducer.user.customer_id
});

const MilkDetailComponent = connect(mapStateToProps)(withLanguage(MilkDetail));

export default MilkDetailComponent;