import {
    TOGGLE_REFERRAL_MODAL
} from "../_constants";

const initialState = {
    isOpen: false
};

function referralReducer(state = initialState, action) {
    switch (action.type) {
    case TOGGLE_REFERRAL_MODAL:
        return {
            ...state,
            isOpen: !state.isOpen,
        };
    default:
        return state;
    }
}

export default referralReducer;
