// @ts-nocheck
import React, { Component } from "react";
import { Ripple } from 'react-onsenui';
import { loader, toast } from "../../../../library/util";
import _ from 'lodash';
import { connect } from "react-redux";
import withLanguage from "../../../../language/LanguageController";
import moment from "moment";
import { errorHandler } from "../../../../library/response";
import SimpleValidator from "../../../../common/SimpleValidator";
import MuiDatepicker from "../../../../common/MuiDatepicker";
import analytics from "../../../../library/firebase/analytics";
import request from "../../../../library/request";
import CustomersSelect from "../../../common/CustomersSelect";
import { getCustomerLabel } from "../../../../library/customer";
import { DAIRY_CUSTOMER_CODE_DEBOUNCE } from '../../../../../_constants';
import { DefaultLayout } from "../../../common/Layout";
import Eventer from "src/components/library/Eventer";
import { checkFeatureInSubscription } from "../../../../common/FeatureFlag/FeatureFlag";
import { selectDairyCustomers } from "src/reducers/dairyCustomer";

class AdvancePaymentForm extends Component {

    constructor(props) {
        super(props);
        this.submitHandler = this.submitHandler.bind(this);
        let selectedCustomer = this.props.customers.length ? this.props.customers[0] : null;
        if(this.props.dairyCustomerId) {
            selectedCustomer = this
                .props
                .customers
                .find(customer => customer.dairy_customer_id === this.props.dairyCustomerId);
        }

        this.state = {
            "maxDate": moment(),
            "minDate": moment().subtract(1, 'month').startOf('month'),
            selectedDate: moment(),
            selectedCustomer: selectedCustomer ? {
                ...selectedCustomer,
                value: selectedCustomer.dairy_customer_id,
                label: getCustomerLabel(selectedCustomer)
            } : null,
            code: selectedCustomer ? selectedCustomer.code : "",
            payment_btn: false,
            isFeatureSubscribed: checkFeatureInSubscription("advance_payment", false)
        }

        this.validator = new SimpleValidator();
        this.handleCodeChange = _.debounce(this.handleCodeChange.bind(this), DAIRY_CUSTOMER_CODE_DEBOUNCE);
    }

    componentDidMount () {
        analytics.setScreenName("AdvancePaymentForm");
    }

    handleInput = (e) => {
        let target = e.target;
        let name = target.name;
        let value = target.value;
    
        this.setState({
            [name]: value
        });
    }

    dateChangeHandler = (date) => {
        this.setState({
            selectedDate: date
        });
    }

    customerChangeHandler = customer => {
        this.setState({
          selectedCustomer: customer
        }, () => {
          document.getElementById("customer_code").value = customer.code;
        });
    }

    handleCodeChange(event) {
        const code = event.target.value;
        const customer = this.props.customers.find(customer => customer.code == code);
        if(customer) {
            this.customerChangeHandler(customer);
        } else {
            this.setState({
                selectedCustomer: {
                    label: this.props.t("text_no_customer_for_code"),
                    value: null
                }
            });
        }
    }

    async submitHandler (e) {
        e.preventDefault();
        if(!checkFeatureInSubscription("advance_payment")) {
            return false;
        }
        try {
            // validate form before submitting to server 
            if (!this.validator.allValid()) {
                this.validator.showMessages();
                this.forceUpdate(); // rerender to show messages for the first time
                return false;
            }
            analytics.logEvent("addAvancePayment");
            loader.show(this.props.t('text_loading'));
            this.setState({
                payment_btn: true
            });
            const advanceForm = document.getElementById("customer_advance_payment_form");
            const { type } = this.state.selectedCustomer;
            const formData = new FormData(advanceForm);
            formData.append('customer_type', type);
            const { status, message, error_code } = await request.post('/dairies/' + this.props.dairyId + `/accounts/advancePayment`, {
                withAuth: true,
                body: formData,
                version: "v2"
            });
            if (status) {
                toast(message);
                advanceForm && advanceForm.reset();
                this.setState({
                    "amount": ""
                }, () => {
                    document.getElementById("customer_code").value = this.state.selectedCustomer.code;
                    this.validator.hideMessages();
                });
                Eventer.emit("advancePaymentAdded");
            } else if(error_code != 501) {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
            this.setState({
                payment_btn: false
            });
        }
    }

    render() {
        const { t, customers, header } = this.props;
        const { minDate, maxDate, selectedDate } = this.state;
        return (
            <DefaultLayout
                title={t("text_advance_payment")}
                toolbar={false}
                header={header}
                bottomGutter={false}
                back>
                <div className="t-card shadow-sm">
                    <form method="post" id="customer_advance_payment_form" onSubmit={this.submitHandler}>
                        <div className="row mb-3">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="advance_date">{t("text_date")}</label>
                                    <MuiDatepicker
                                        value={selectedDate}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        onChange={this.dateChangeHandler}
                                        name="date"
                                        id="advance_date"/>
                                    {this.validator.message("date", this.state.selectedDate, "required")}
                                </div>
                                <div className="form-row">
                                    <div className="col-3">
                                        <div className="form-group">
                                            <label htmlFor="customer_code">{t("text_code")}</label>
                                            <input
                                            className="form-control"
                                            type="number"
                                            id="customer_code"
                                            defaultValue={this.state.code}
                                            onChange={e => { e.persist(); this.handleCodeChange(e)}} />
                                        </div>  
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="advance_dairy_customer_id">{t("text_customer")}</label>
                                            <CustomersSelect
                                                name="dairy_customer_id"
                                                id="advance_dairy_customer_id"
                                                customers={customers}
                                                value={this.state.selectedCustomer}
                                                onChange={this.customerChangeHandler} />
                                            {this.validator.message("select_customer", this.state.selectedCustomer ? this.state.selectedCustomer.value : "", "required")}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="advance_amount">{t("text_amount")}</label>
                                    <input 
                                        name="amount"
                                        type="number"
                                        id="advance_amount"
                                        className="form-control"
                                        step="0.01"
                                        onChange={this.handleInput}/>
                                    {this.validator.message("amount", this.state.amount, "required|min:1,num")}
                                </div>
                                <div className="form-group mb-0">
                                    <label htmlFor="advance_remark">{t("text_remark")}</label>
                                    <textarea
                                        name="remark"
                                        id="advance_remark"
                                        className="form-control"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button
                                    disabled={this.state.payment_btn}
                                    className="btn btn-success btn-block">
                                    <Ripple/>
                                    {t("text_pay")}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </DefaultLayout>
        )
    }
}

AdvancePaymentForm.displayName = "AdvancePaymentForm";

AdvancePaymentForm.defaultProps = {
    header: true
};

const mapStateToProps = state => {
    return {
        customers: selectDairyCustomers(state),
        dairyId: state.dairy.dairy.id
    }
};
  
export default connect(mapStateToProps)(withLanguage(AdvancePaymentForm));