import React from 'react';
import { Icon } from 'react-onsenui';
import MilkDetail from './MilkDetail';
import analytics from '../../library/firebase/analytics';
import { generatePDF } from "../../library/pdfGenerator";
import { loader } from '../../library/util';
import { errorHandler } from '../../library/response';
import request from '../../library/request';
import config from "../../../config";
import { Link } from 'react-router-dom';

class MilkTotal extends React.Component {
    constructor(props) {
        super(props);
        this.downloadMilkTotal = this.downloadMilkTotal.bind(this);
    }
    componentDidMount () {
        analytics.setScreenName("MilkTotal");
    }

    spreadTodayMilk = () => {
        let todayMorningMilk = [], todayEveningMilk = [];
        for(let record of this.props.today){
            todayMorningMilk.push(
                <tr key={record.id}>
                    <td>{this.props.t("text_" + record.milk_type) || "-"}</td>
                    <td>{record.liter_0 || "-"}</td>
                    <td>{record.fat_0 || "-"}</td>
                    <td>{record.rate_0 || "-"}</td>
                    <td>{record.total_0 || "-"}</td>
                </tr>
            );
            todayEveningMilk.push(
                <tr key={record.id}>
                    <td>{this.props.t("text_" + record.milk_type) || "-"}</td>
                    <td>{record.liter_1 || "-"}</td>
                    <td>{record.fat_1 || "-"}</td>
                    <td>{record.rate_1 || "-"}</td>
                    <td>{record.total_1 || "-"}</td>
                </tr>
            );
        }
        return { todayMorningMilk, todayEveningMilk };
    }

    async downloadMilkTotal() {
        try {
            loader.show(this.props.t("text_downloading"));
            const {
                start_date,
                end_date,
                customerId
            } = this.props;
            const data = await request.post(`/diary/${customerId}/reports/milkTotal`, {
                withAuth: true,
                responseType: "text/html; charset=utf-8",
                body: JSON.stringify({
                    "start_date": start_date.format(config.date_format),
                    "end_date": end_date.format(config.date_format)
                })
            });
            const fileName = `Milk_Total_Report_${start_date.format("YYYY_MM_DD")}__${end_date.format("YYYY_MM_DD")}.pdf`;
            await generatePDF(data, fileName);
        } catch(error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    render() {
        let { todayMorningMilk, todayEveningMilk } = this.spreadTodayMilk();
        const { t } = this.props;
        return (
            <>
                <div className="t-card shadow-sm mb-2">
                    <div className="card__title card--material__title mb-0 mt-0">
                        {t("text_todays_milk")}
                    </div>
                    <div className="milkdetail_tab mt-2">
                        <ul className="nav nav-justified">
                            <li className="nav-item">
                                <a className="nav-link active" 
                                    data-toggle="pill"
                                    href="#diary_customer_milk_detail_0">
                                    {t("text_morning")}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" 
                                    data-toggle="pill"
                                    href="#diary_customer_milk_detail_1">
                                    {t("text_evening")}
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane container active" id="diary_customer_milk_detail_0">
                                <div className="table_container">
                                    <table className="table table-stripped mb-0 no-f-border">
                                        <thead>
                                            <tr>
                                                <th>{t("text_animal")}</th>
                                                <th>{t("text_liter")}</th>
                                                <th>{t("text_fat")}</th>
                                                <th>{t("text_rate")}</th>
                                                <th>{t("text_total")}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            {(todayMorningMilk.length) ? todayMorningMilk : (
                                                <tr>
                                                    <td colSpan={6} className="text-center">
                                                        {t("text_no_data_available")}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="tab-pane container" id="diary_customer_milk_detail_1">
                                <div className="table_container">
                                    <table className="table table-stripped mb-0 no-f-border">
                                        <thead>
                                            <tr>
                                                <th>{t("text_animal")}</th>
                                                <th>{t("text_liter")}</th>
                                                <th>{t("text_fat")}</th>
                                                <th>{t("text_rate")}</th>
                                                <th>{t("text_total")}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            {(todayEveningMilk.length) ? todayEveningMilk : (
                                                <tr>
                                                    <td colSpan={6} className="text-center">
                                                        {t("text_no_data_available")}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="t-card shadow-sm mb-2">
                    <div className="card-header p-0 bg-white d-flex justify-content-between border-0 pl-2">
                        <div className="title right card__title card--material__title mt-0 mb-0">
                            {t("text_milk_total")}
                        </div>
                        <div className="position-relative">
                            <Link
                                to="/diary/milkDetail"
                                className="btn d-sm-none"
                                type="button"
                                title="View"
                                aria-label="View">
                                <Icon icon="md-eye" />
                            </Link>
                            <button className="btn"
                                type="button"
                                title="Download"
                                aria-label="Download"
                                onClick={this.downloadMilkTotal}>
                                <Icon icon="md-download" />
                            </button>
                        </div>
                    </div>
                    <table className="table table-stripped mb-0 no-f-border">
                        <tbody>
                            <tr>
                                <td>{t("text_total_milk")}:</td>
                                <td className="text-center">{this.props.total.total_milk}</td>
                            </tr>
                            <tr>
                                <td>{t("text_total_amount")}:</td>
                                <td className="text-center">{this.props.total.total_amount}</td>
                            </tr>
                            <tr>
                                <td>{t("text_avg_milk")}:</td>
                                <td className="text-center">{this.props.total.avg_milk}</td>
                            </tr>
                            <tr>
                                <td>{t("text_avg_amount")}:</td>
                                <td className="text-center">{this.props.total.avg_amount}</td>
                            </tr>
                            <tr>
                                <td>{t("text_avg_rate")}:</td>
                                <td className="text-center">{this.props.total.avg_rate}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

export default MilkTotal;
