import React, { useEffect, useState, useCallback } from 'react';
import { DefaultLayout } from '../common/Layout';
import { useLanguage } from "./../../language/LanguageController";
import { useMobile } from 'src/components/library/detect-device';
import "../../../assets/css/_Reward.scss";
import Typography from "@material-ui/core/Typography";
import RewardBalanceNew from './RewardBalance';
import RewardHistory from './RewardHistory';

const RewardHistoryComponent = () => {

    const { t } = useLanguage();
    const isMobile = useMobile();

    return (
        <DefaultLayout
            title={t("text_reward_history")}
            sideNav={false}
            bottomGutter={false}
        >
            <div className="main-section-2">
                <div className="form-row mb-2">
                    <div className="col-12 col-md-12">
                        <div className="col px-0">
                            <RewardHistory />
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout >
    )
}

export default RewardHistoryComponent
