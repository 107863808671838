import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { withPermission } from "../../../common/AccessPermission";
import SubscriptionLock from "../../../common/FeatureFlag/SubscriptionLock";
import withLanguage from "../../../language/LanguageController";
import { currencyFormat } from "../../../library/util";
import { DefaultLayout } from "../../common/Layout";
import { DefaultScrollbar } from "../../common/Scrollbar";
import InnerNav from "../InnerNav";
import BillDatePicker from "./BillDatePicker";
import DairyAccountController from "./DairyAccountController";

class DairyAccount extends DairyAccountController {

    render() {
        const { t, billingCycle, header, bottomGutter } = this.props;
        const {
            sellers_bill,
            buyers_bill,
            net_receivable,
            isFeatureSubscribed
        } = this.state;
        return (
            <DefaultLayout
                title={t("text_dairy_bill")}
                toolbar={false}
                header={header}
                bottomGutter={bottomGutter}
                onDeviceChange={this.onDeviceChange}
                back>
                <div className="mb-2 bg-white shadow-sm">
                    <BillDatePicker
                        billingCycle={billingCycle}
                        onChange={this.onDateChange}
                        isFeatureSubscribed={isFeatureSubscribed}
                    />
                </div>
                <DefaultScrollbar
                    className="dairy-billing-dairy-bill-scrollbar">
                    <div className="t-card mb-2 shadow-sm">
                        <div className="content card__content card--material__content" onClick={this.gotoSellersBill}>
                            <div className="d-account-h1">{t("text_sellers_bill")}</div>
                            <table className="table mb-2">
                                <tbody>
                                    <tr>
                                        <td>{t("text_total_payable_amount")}</td>
                                        <td className="text-right">
                                            {currencyFormat(sellers_bill.payable)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("text_paid_amount")}</td>
                                        <td className="text-right">
                                            <span className="text-green">
                                                {currencyFormat(sellers_bill.paid)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("text_transferred_to_loan")}</td>
                                        <td className="text-right">
                                            <span className="text-green">
                                                {currencyFormat(sellers_bill.transferd_to_loan)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("text_remaining_amount")}</td>
                                        <td className="text-right">
                                            <span className="text-red">
                                                {currencyFormat(sellers_bill.payable_remaining)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("text_cash_recovered")}</td>
                                        <td className="text-right">
                                            <span className="text-green">
                                                {currencyFormat(sellers_bill.cash_recovered)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border-top-blue2x">{t("text_receivable")}</td>
                                        <td className="text-right border-top-blue2x">
                                            <span>
                                                {currencyFormat(sellers_bill.receivable)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("text_received")}</td>
                                        <td className="text-right">
                                            <span className="text-green">
                                                {currencyFormat(sellers_bill.received)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("text_amount_to_be_receive")}</td>
                                        <td className="text-right">
                                            <span className="text-red">
                                                {currencyFormat(sellers_bill.receivable_remaining)}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="t-card mb-2 shadow-sm" onClick={this.gotoBuyersBill}>
                        <div className="content card__content card--material__content">
                            <div className="d-account-h1">{t("text_buyers_bill")}</div>
                            <table className="table mb-2">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div>{t("text_total_receivable_amount")}</div>
                                        </td>
                                        <td className="text-right">
                                            {currencyFormat(buyers_bill.receivable)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>{t("text_milk_amount")}</div>
                                        </td>
                                        <td className="text-right">
                                            {currencyFormat(buyers_bill.milk_amount)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>{t("text_product_amount")}</div>
                                        </td>
                                        <td className="text-right">
                                            {currencyFormat(buyers_bill.product_amount)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("text_received_amount")}</td>
                                        <td className="text-right">
                                            <span className="text-green">
                                                {currencyFormat(buyers_bill.received)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("text_due_amount")}</td>
                                        <td className="text-right">
                                            <span className="text-red">
                                                {currencyFormat(buyers_bill.receivable_remaining)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td className="border-top-blue2x">{t("text_payable")}</td>
                                    <td className="text-right border-top-blue2x">
                                        
                                            <span className="text-green">
                                                {currencyFormat(buyers_bill.payable)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("text_paid_amount")}</td>
                                        <td className="text-right">
                                            <span className="text-green">
                                                {currencyFormat(buyers_bill.paid)}
                                            </span>
                                        </td>
                                    </tr>
                                   
                                    <tr>
                                        <td>{t("text_payable")} {t("text_remaining")}</td>
                                        <td className="text-right">
                                            <span className="text-green">
                                                {currencyFormat(buyers_bill.payable_remaining)}
                                            </span>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="t-card shadow-sm">
                        <div className="content card__content card--material__content">
                            <div className="d-account-h1">{t("text_net_receivable_due")}</div>
                            <table className="table mb-2">
                                <tbody>
                                    <tr>
                                        <td>{t("text_amount")}</td>
                                        <td className="text-right text-red">
                                            <b>
                                            {currencyFormat(net_receivable)}
                                            </b>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </DefaultScrollbar>
                <InnerNav 
                    menu="bill"
                    page="dairyAccount"
                    navigator={this.props.navigator}  />
                {!isFeatureSubscribed && <SubscriptionLock/>}
            </DefaultLayout>
        );
    }
}

DairyAccount.displayName = "DairyAccount";

DairyAccount.defaultProps = {
    header: true,
    bottomGutter: true
};

const mapStateToProps = state => {
    return {
        dairyId: state.dairy.dairy.id,
        billingCycle: state.dairy.dairy.billing_cycle
    };
};

export default compose(
    withRouter,
    withLanguage,
    withPermission,
    connect(mapStateToProps)
)(DairyAccount);