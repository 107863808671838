import React from 'react';
import PrintIcon from '@material-ui/icons/Print';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Menu, MenuItem } from '@material-ui/core';
import { useLanguage } from 'src/components/language/LanguageController';

const PrintBillMenu = ({ onPrint }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useLanguage();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <button
                className="btn"
                type="button"
                aria-controls="customer-bill-print-menu"
                title="Print"
                onClick={handleClick}
            >
                <PrintIcon />
                <ExpandMoreIcon />
            </button>
            <Menu
                id="customer-bill-print-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {handleClose(); onPrint(false);}}>{t("text_print_bill")}</MenuItem>
                <MenuItem onClick={() => {handleClose(); onPrint(true);}}>{t("text_print_bill_with_milk_statement")}</MenuItem>
            </Menu>
        </>
    )
};

export default PrintBillMenu;