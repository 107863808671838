import { productsTemplate } from "../../../library/smsTemplates";
import { currencyFormat, socialSharing } from "../../../library/util";
import moment from "moment";
import React from "react";
import { Icon } from "react-onsenui";
import withLanguage from "../../../language/LanguageController";
import analytics from "../../../library/firebase/analytics";
import { useAndroid } from "src/components/library/detect-device";
import Spinner from "src/components/core/Spinner";

const CustomerProducts = ({ t, products = [], productTotal = {}, selectedCustomer = {}, isLoading }) => {

    const shareProducts = e => {
        e.preventDefault();
        analytics.logEvent("shareProducts");
        const { name } = selectedCustomer;
        const { amount, quantity } = productTotal;
        let message = productsTemplate(name, products, amount, quantity);
        socialSharing(message);
    };

    const isAndroid = useAndroid();

    return (
        <div className="t-card mb-2 mx-0 shadow-sm">
            <div className="title right card__title card--material__title mt-0">
                {t("text_products")}
                {(products.length > 0) && (
                    <div className="float-right">
                        {currencyFormat(productTotal.amount)}{" "}
                        {isAndroid && <button className="btn"
                            type="button"
                            title={t("text_share")}
                            onClick={e => shareProducts(e)}>
                            <Icon icon="md-share" size={18} />
                        </button>}
                    </div>
                )}
            </div>
            <div className="content card__content card--material__content">
                {!isLoading ? <table className="table mb-2 size-12 table-pad-10-15">
                    <thead>
                        <tr>
                            <th>{t("text_date")}</th>
                            <th>{t("text_product")}</th>
                            <th>{t("text_qty")}</th>
                            <th>{t("text_price")}</th>
                            <th>{t("text_total")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            products.map(product => {
                                return (
                                    <tr key={product.id}>
                                        <td>{moment(product.date).format("Do MMM, YY")}</td>
                                        <td>{product.name}</td>
                                        <td>{product.quantity}</td>
                                        <td>{product.price}</td>
                                        <td>{product.total}</td>
                                    </tr>
                                );
                            })
                        }
                        {
                            !products.length ? (
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        {t("text_no_data_available")}
                                    </td>
                                </tr>
                            ) : null
                        }
                    </tbody>
                </table> : <Spinner/>}
            </div>
        </div>
    );
};

export default withLanguage(CustomerProducts);