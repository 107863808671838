// @ts-nocheck
import _ from 'lodash';
import moment from "moment";
import { Component } from 'react';
import { checkFeatureInSubscription } from '../../../../common/FeatureFlag/FeatureFlag';
import SimpleValidator from '../../../../common/SimpleValidator';
import Eventer from '../../../../library/Eventer';
import { getBillingStartEndDate } from '../../../../library/dateUtils';
import analytics from '../../../../library/firebase/analytics';
import request from '../../../../library/request';
import { errorHandler } from '../../../../library/response';
import { copyObject, loader, notification, toast } from "../../../../library/util";

const dummyRecord = [
    {
        "is_payable": 1,
        "payable": 1069.92,
        "last_due": "54.92",
        "month_payable": 1015,
        "paid": 0,
        "payable_remaining": "250.00",
        "transferd_to_loan": 0,
        "cash_recovered": 0,
        "receivable": 0,
        "received": 0,
        "receivable_remaining": 0,
        "dairy_id": "00001",
        "dairy_customer_id": "0001",
        "customer_name": "Dummy Customer",
        "code": "00"
    }
];

class DairySellersBillController extends Component {

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.payDairyCustomersBill = this.payDairyCustomersBill.bind(this);
        this.milkBillPayment = this.milkBillPayment.bind(this);
        this.receiveDairyCustomersBill = this.receiveDairyCustomersBill.bind(this);
        this.milkBillReceive = this.milkBillReceive.bind(this);
        const locationStartDate = props?.location?.state?.startDate || null;
        const startDate = locationStartDate ? moment(locationStartDate) : moment();
        this.state = {
            product_pay_modal: false,
            filter: '',
            payable_records: [],
            receivable_records: [],
            page_view: 'grid',
            all_payable_bill_checked: false,
            is_payable_selected: false,
            amount_to_pay: 0,
            loan_balance: 0,
            milk_payment_btn: false,
            milk_payment_modal: false,
            all_receivable_bill_checked: false,
            is_receivable_selected: false,
            amount_to_receive: 0,
            milk_receive_btn: false,
            milk_receive_modal: false,
            isBrowser: false,
            sortSelectedOption: {},
            isFeatureSubscribed: checkFeatureInSubscription("dairy_billing", false),
            ...getBillingStartEndDate(props.billingCycle, startDate)
        };
    }

    componentDidMount() {
        analytics.setScreenName("DairySellersBill");
        this.getData();
        Eventer.on("dairyBillDateChanged", this.dairyBillDateChanged);
    }

    componentWillUnmount() {
        Eventer.off("dairyBillDateChanged", this.dairyBillDateChanged);
    }

    dairyBillDateChanged = ({ detail }) => {
        this.onDateChange({
            startDate: detail.startDate,
            endDate: detail.endDate
        });
    }

    async getData() {
        if (!this.state.isFeatureSubscribed) {
            this.setState({
                payable_records: dummyRecord
            });
            return false;
        }
        try {
            loader.show(this.props.t("text_loading"));
            let { startDate, endDate } = this.state;
            startDate = startDate.format('DD-MM-YYYY');
            endDate = endDate.format('DD-MM-YYYY');
            const filterStr = encodeURI(`start_date=${startDate}&end_date=${endDate}`);
            const { status, data, message, error_code } = await request.get('/dairies/' + this.props.dairyId + `/sellers_bills?${filterStr}`, {
                withAuth: true,
                version: "v3"
            });
            if (status) {
                let payableRecords = data.filter(record => record.payable > 0);
                let receivableRecords = data.filter(record => record.receivable > 0);
                payableRecords.sort((a, b) => a.customer_name.localeCompare(b.customer_name)); 
                receivableRecords.sort((a, b) => a.customer_name.localeCompare(b.customer_name)); 
                this.setState({
                    payable_records: payableRecords,
                    receivable_records: receivableRecords
                });
            } else if (error_code != 501) {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    pushPage = (e, Compo, props) => {
        e.preventDefault();
        this
            .props
            .navigator
            .pushPage({
                component: Compo,
                props: props
            }, { animation: 'fade' });
    }

    goToBill = (event, dairyCustomerId) => {
        event.persist();
        event.stopPropagation();
        if (event.target.type !== "checkbox") {
            if (this.state.isMobile) {
                this.props.history.push(`/dairy/bill/customer/${dairyCustomerId}`);
            }
        }
    }

    changeView = e => {
        analytics.logEvent("dairySellersBillChangeView");
        this.setState({
            page_view: this.state.page_view === "grid" ? 'list' : 'grid'
        })
    }

    onDateChange = ({ startDate, endDate }) => {
        if (!checkFeatureInSubscription("dairy_billing")) {
            return false;
        }
        this.setState({
            startDate,
            endDate
        }, () => {
            this.getData();
        });
    }

    filterHandler = e => {
        this.setState({
            filter: e.target.value
        })
    }

    onPayableAllChecked = e => {
        const checked = e.target.checked;
        let records = copyObject(this.state.payable_records);
        let is_payable_selected = false;
        this.setState({
            all_payable_bill_checked: checked,
            payable_records: records.map(bill => {
                bill.is_checked = false;
                if (bill.payable_remaining != "0.00") {
                    bill.is_checked = checked
                    is_payable_selected = checked
                }
                return bill
            }),
            is_payable_selected: is_payable_selected
        })
    }


    handleSortingChange = (selectedOption) => {
        this.setState({
            sortSelectedOption: selectedOption
        });
    }

    sortBy = (receivePayableRecords) => {
        let { field, order } = this.state.sortSelectedOption;
        field = field === "name" ? "customer_name" : field;
        
        const orderByField = rec => {
            if (field === 'code') {
                return parseInt(rec[field]);
            } else if (field === 'customer_name') {
                return rec[field].toLowerCase();
            }
            return rec[field];
        };
        
        const sortedRecords = _.orderBy(receivePayableRecords, [orderByField], [order]);
        return sortedRecords;
    }

    onPayableChecked = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        let records = copyObject(this.state.payable_records);
        records[index].is_checked = e.target.checked;

        this.setState({
            payable_records: records,
            is_payable_selected: e.target.checked
        });
    }

    async payDairyCustomersBill(e) {
        e.preventDefault();
        try {
            analytics.logEvent("payDairyCustomersBill");
            loader.show(this.props.t("text_loading"));
            this.setState({
                milk_payment_btn: true
            });
            let { endDate } = this.state;

            const customers = this.state.payable_records.reduce((filtered, record) => {
                if (record.is_checked) {
                    filtered.push({
                        dairy_customer_id: record.dairy_customer_id,
                        amount: record.payable_remaining
                    })
                }
                return filtered;
            }, []);

            if (customers.length <= 0) {
                notification("Error", this.props.t("error_select_atleast_one_customer_to_pay_bill"));
                return false;
            }

            const { status, message } = await request.post('/dairies/' + this.props.dairyId + `/bills`, {
                withAuth: true,
                body: JSON.stringify({
                    customers: customers,
                    bill_date: endDate.format("YYYY-MM-DD")
                }),
                version: "v3"
            });
            if (status) {
                toast(message);
                this.setState({
                    all_payable_bill_checked: false,
                    milk_payment_btn: false,
                    is_payable_selected: false
                }, () => {
                    this.getData();
                    Eventer.emit("customerBillPaid");
                });
            } else {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
            this.setState({
                milk_payment_btn: false
            });
        }
    }

    toggleMilkPayModal = (e, customer = {}, index = null) => {
        e.stopPropagation();
        e.preventDefault();
        if (!this.state.milk_payment_modal) {
            this.milkPaymentValidator = new SimpleValidator();
        }
        this.setState({
            milk_payment_modal: !this.state.milk_payment_modal,
            amount_to_pay: customer.payable_remaining || 0,
            loan_balance: customer?.loan_balance || 0,
            dairy_customer_id: customer.dairy_customer_id || 0,
            selected_index: index
        });
    }

    async milkBillPayment(e) {
        e.preventDefault();
        try {
            // validate form before submitting to server 
            if (!this.milkPaymentValidator.allValid()) {
                this.milkPaymentValidator.showMessages();
                this.forceUpdate(); // rerender to show messages for the first time
                return false;
            }
            analytics.logEvent("milkBillPayment");
            loader.show(this.props.t("text_loading"));
            this.setState({
                milk_payment_btn: true
            });

            let { endDate, dairy_customer_id } = this.state;

            const formData = new FormData(document.getElementById("milk_bill_payment_form"));
            formData.append('bill_date', endDate.format("YYYY-MM-DD"));

            const { status, message } = await request.post('/dairies/' + this.props.dairyId + `/bills/pay/${dairy_customer_id}`, {
                withAuth: true,
                body: formData,
                version: "v3"
            });
            if (status) {
                toast(message);
                this.setState({
                    milk_payment_modal: false
                }, () => {
                    this.getData();
                    Eventer.emit("customerBillPaid");
                });
            } else {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
            this.setState({
                milk_payment_btn: false
            });
        }
    }

    onReceivableAllChecked = e => {
        const checked = e.target.checked;
        let records = copyObject(this.state.receivable_records);
        let is_receivable_selected = false;
        this.setState({
            all_receivable_bill_checked: checked,
            receivable_records: records.map(bill => {
                bill.is_checked = false;
                if (bill.receivable_remaining != "0.00") {
                    bill.is_checked = checked
                    is_receivable_selected = checked
                }
                return bill
            }),
            is_receivable_selected: is_receivable_selected
        })
    }

    onReceivableChecked = (e, index) => {
        e.stopPropagation();
        let records = copyObject(this.state.receivable_records);
        records[index].is_checked = e.target.checked;

        this.setState({
            receivable_records: records,
            is_receivable_selected: e.target.checked
        });
    }

    async receiveDairyCustomersBill(e) {
        e.preventDefault();
        try {
            analytics.logEvent("receiveDairyCustomersBill");
            loader.show(this.props.t("text_loading"));

            this.setState({
                milk_receive_btn: true
            });
            let { endDate, receivable_records } = this.state;

            const customers = receivable_records.reduce((filtered, record) => {
                if (record.is_checked) {
                    filtered.push({
                        dairy_customer_id: record.dairy_customer_id,
                        amount: record.payable_remaining
                    })
                }
                return filtered;
            }, []);

            if (customers.length <= 0) {
                notification("Error", this.props.t("error_select_atleast_one_customer_to_receive_bill"));
                return false;
            }

            const { status, message } = await request.post('/dairies/' + this.props.dairyId + `/bills`, {
                withAuth: true,
                body: JSON.stringify({
                    customers: customers,
                    bill_date: endDate.format("YYYY-MM-DD")
                }),
                version: "v3"
            });
            if (status) {
                toast(message);
                this.setState({
                    all_payable_bill_checked: false,
                    milk_payment_btn: false,
                    is_payable_selected: false
                }, () => {
                    this.getData();
                    Eventer.emit("customerBillReceived");
                });
            } else {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
            this.setState({
                milk_payment_btn: false
            });
        }
    }

    toggleMilkReceiveModal = (e, customer = {}, index = null) => {
        e.preventDefault();
        e.stopPropagation();
        if (!this.state.milk_receive_modal) {
            this.milkReceiveValidator = new SimpleValidator();
        }
        this.setState({
            milk_receive_modal: !this.state.milk_receive_modal,
            amount_to_receive: customer.receivable_remaining || 0,
            dairy_customer_id: customer.dairy_customer_id || 0,
            selected_index: index
        });
    }

    async milkBillReceive(e) {
        e.preventDefault();
        try {
            // validate form before submitting to server 
            if (!this.milkReceiveValidator.allValid()) {
                this.milkReceiveValidator.showMessages();
                this.forceUpdate(); // rerender to show messages for the first time
                return false;
            }
            analytics.logEvent("receiveMilkBill");
            loader.show(this.props.t("text_loading"));
            this.setState({
                milk_receive_btn: true
            });

            let { endDate, dairy_customer_id } = this.state;
            const formData = new FormData(document.getElementById("milk_bill_receive_form"));
            formData.append('bill_date', endDate.format("YYYY-MM-DD"));

            const { status, message } = await request.post('/dairies/' + this.props.dairyId + `/bills/receive/${dairy_customer_id}`, {
                withAuth: true,
                body: formData,
                version: "v3"
            });
            if (status) {
                toast(message);
                this.setState({
                    milk_receive_modal: false
                }, () => {
                    this.getData();
                    Eventer.emit("customerBillReceived");
                });
            } else {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
            this.setState({
                milk_receive_btn: false
            });
        }
    }

    onDeviceChange = deviceTypes => {
        this.setState({
            ...deviceTypes
        });
    }
}

export default DairySellersBillController;