import React from "react";
import { useLanguage } from "../../../../language/LanguageController";
import PropTypes from "prop-types";
import CowWhiteIcon from "../../../../common/icons/CowWhiteIcon";
import CowBlackIcon from "../../../../common/icons/CowBlackIcon";
import BuffaloWhiteIcon from "../../../../common/icons/BuffaloWhiteIcon";
import BuffaloBlackIcon from "../../../../common/icons/BuffaloBlackIcon";

const CattleSelection = ({ milk_animals = [], cattle, handleInput }) => {

    const { t } = useLanguage();
    const milkAnimalsLength = milk_animals.length;
    const focusTabIndex = milkAnimalsLength > 1 ? "" : -1;

    return (
        <div className="form-group clearfix">
            <div className="btn-group d-flex mb-2" role="group" aria-label={t("text_cattle")}>
                {milk_animals?.includes("cow") &&
                    <label
                        className={`btn mb-0 ${cattle === "cow" ? "bg-light-blue text-white active" : "btn-light"}`}
                        htmlFor="animal_type_cow"
                        role="button"
                    >
                        <input
                            className="sr-only"
                            type="radio"
                            id="animal_type_cow"
                            name="milk_type" 
                            value="cow"
                            onChange={handleInput}
                            checked={cattle === "cow"}
                            tabIndex={focusTabIndex}
                        />
                            {cattle === "cow" ? <CowWhiteIcon /> : <CowBlackIcon />} 
                        &nbsp;{t("text_cow")}
                    </label>
                }
                {milk_animals?.includes("buffalo") &&
                    <label
                        className={`btn mb-0 ${cattle === "buffalo" ? "bg-light-blue text-white active" : "btn-light"}`}
                        htmlFor="animal_type_buffalo"
                        role="button"
                    >
                        <input
                            className="sr-only"
                            type="radio"
                            id="animal_type_buffalo"
                            name="milk_type" 
                            value="buffalo"
                            onChange={handleInput}
                            checked={cattle === "buffalo"}
                            tabIndex={focusTabIndex}
                        />
                        {cattle === "buffalo" ? <BuffaloWhiteIcon width="33px" /> : <BuffaloBlackIcon width="30px" />}
                        &nbsp;{t("text_buffalo")}
                    </label>
                }
            </div>
        </div>
    );
};

CattleSelection.propTypes = {
    milk_animals: PropTypes.array.isRequired,
    cattle: PropTypes.string.isRequired,
    handleInput: PropTypes.func.isRequired
};

export default CattleSelection;