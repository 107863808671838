import { makeStyles } from "@material-ui/core";
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    editIcon: {
      color: green[600]
    },
}));

export const useStylesAvatar = makeStyles(({
  root: {
    backgroundColor: props => props.backgroundColor,
  },
}));

export default useStyles;