import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOffers } from "../../../../actions/offerStore";
import SingleOffer from "./SingleOffer";

const Offers = () => {
    const dispatch = useDispatch();
    const { offers } = useSelector(state => state.offerStore);

    useEffect(() => {
        fetchOffers(dispatch);
    }, [dispatch]);

    return (
        <div className="offers-container">
            <div className="form-row justify-content-center">
                {offers.length > 0 && offers.map(offer => <SingleOffer
                    key={offer.offer_id}
                    {...offer}
                />)}
            </div>
        </div>
    );
}

export default Offers;