// @ts-nocheck
import React from "react";
import { Icon } from "react-onsenui";
import { useSelector } from "react-redux";
import withLanguage from "../language/LanguageController";
import analytics from "../library/firebase/analytics";

const PageBottomHelpContact = ({ t, mainPage = false }) => {
    const { support_number, whatsapp_link, youtube_channel, youtube_ratelist_link } = useSelector(state => state.defaultReducer);
    return (
        <div className="page-bottom-contact-us fixed-bottom w-100 text-center text-nowrap overflow-auto">
            <a href={mainPage ? youtube_channel : youtube_ratelist_link } target="_blank" onClick={() => analytics.logEvent("youtube")} rel="noreferrer">
                <Icon icon="fa-youtube" className="icon-youtube"/> {t("text_video")}
            </a>
            <a href={whatsapp_link} target="_blank" onClick={() => analytics.logEvent("whatsApp")} rel="noreferrer">
                <Icon icon="fa-whatsapp" className="icon-whatsapp" /> {t("text_whatsapp")}
            </a>
            <a href={`tel:${support_number}`} onClick={() => analytics.logEvent("call")}>
                <Icon icon="fa-phone" className="icon-phone" rotate={90}/> {t("text_call")}
            </a>
        </div>
    );
};

export default withLanguage(PageBottomHelpContact);