import { utils, write, writeFileXLSX  } from 'xlsx';
import { openFile, saveBlob2File } from './fileHelper';

export const exportToExcel = async (expotData, fileName) => {
  try {
    const fileExtension = ".xlsx";
    const ws = utils.json_to_sheet(expotData);
    const wb = utils.book_new();
    Object.keys(ws).map((key) => {
      if(ws[key]?.v){
        let val=ws[key].v;
        ws[key].v=val.split("'").join("");
      }
    })
    utils.book_append_sheet(wb, ws, "Data");
    if(typeof cordova !== "undefined") {
      const fileBase64 = write(wb,  {
        bookType: "xlsx",
        type: "base64",
      });
      const base64Response = await fetch(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${fileBase64}`);
      const excelBlob = await base64Response.blob();
      await downloadExcel(excelBlob, fileName + fileExtension);
    } else {
      writeFileXLSX(wb, fileName + fileExtension);
    }
  } catch (error) {
      console.log("error", error);
  }
};

export const downloadExcel = async (fileBlob, fileName) => {
  const baseBath = encodeURI(window.cordova.file.externalApplicationStorageDirectory + 'files');
  // Save blob file to filesystem before opening
  const fileEntry = await saveBlob2File(baseBath, 'Download', fileName, fileBlob);
  // open file
  await openFile(fileEntry, fileBlob.mimeType || fileBlob.type);
}