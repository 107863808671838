import React from "react";
import { currencyFormat } from "../../../../library/util";
import PropTypes from "prop-types";

const DairyBuyersReceivableListView = ({ t, filter, records, onCustomerClick }) => {
    return (
        <div className="t-card">
            <div className={"content card__content card--material__content"}>
                <div className="table-fix-header">
                    <table className="table mb-0">
                        <thead>
                            <tr>
                                <th>{t("text_customer")}</th>
                                <th className="text-center">{t("text_milk")}</th>
                                <th className="text-center">{t("text_product")}</th>
                                <th className="text-center">{t("text_advance")}</th>
                                <th className="text-center">{t("text_received")}</th>
                                <th className="text-center">{t("text_due")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {records.map(row => {
                                if (filter && filter.length) {
                                    filter = filter.toLowerCase();
                                    const name = (row.customer_name || "").toLowerCase();
                                    const code = (row.code || "").toLowerCase();
                                    if (!name.includes(filter) && !code.includes(filter)) {
                                        return null;
                                    }
                                }
                                return (<tr key={row.dairy_customer_id} onClick={e => onCustomerClick(e, row.dairy_customer_id)}>
                                    <td>{`${row.code}-${row.customer_name}`}</td>
                                    <td className="text-center text-red">
                                        {(row.milk_amount) ? (
                                            <span className="text-red">{currencyFormat(row.milk_amount)}</span>
                                        ) : "-"}
                                    </td>
                                    <td className="text-center text-red">
                                        {(row.product_amount) ? (
                                            <span className="text-red">{currencyFormat(row.product_amount)}</span>
                                        ) : "-"}
                                    </td>
                                    <td className="text-center text-red">
                                        {(row.advance_payment) ? (
                                            <span className="text-red">{currencyFormat(row.advance_payment)}</span>
                                        ) : "-"}
                                    </td>
                                    <td className="text-center">
                                        {(row.received) ? (
                                            <span className="text-green">{currencyFormat(row.received)}</span>
                                        ) : "-"}
                                    </td>
                                    <td className="text-center">
                                        {(row.receivable_remaining) ? (
                                            <span className="text-red nowrap">{currencyFormat(row.receivable_remaining)}</span>
                                        ) : "-"}
                                    </td>
                                </tr>);
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

DairyBuyersReceivableListView.propTypes = {
    t: PropTypes.func,
    filter: PropTypes.string,
    records: PropTypes.array,
    onPayClick: PropTypes.func,
    onCustomerClick: PropTypes.func
};

export default DairyBuyersReceivableListView;