// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import withLanguage from '../../../language/LanguageController';
import PageBottomHelpContact from '../../../common/PageBottomHelpContact';
import { DefaultLayout } from '../../common/Layout';
import RateListTabs from "./RateListTabs";
import RateListController from "./RateListController";
import RateListTabContent from "./RateListTabContent";
import RateListTable from "./RateListTable";

class RateList extends RateListController {
    
    render() {
        const { t, rateLists } = this.props;
        const {
            activeTabIndex,
            selectedCustomersByRateLists,
            rateListPresets,
            rateChartSetupTypeCattle,
        } = this.state;

        return (
            <DefaultLayout
                back
                title={t("text_rate_list_settings")}
                toolbar={false}
                bottomGutter={false}
            >
                <RateListTabs
                    onTabChange={this.onTabChange}
                />
                {rateLists.map((rateList, index) => (
                    <RateListTabContent
                        key={rateList.rate_list_id}
                        active={index === activeTabIndex}
                        index={index}
                        validator={this.validator}
                        onCustomerChange={this.handleCustomerSelection}
                        selectedCustomersByRateLists={selectedCustomersByRateLists}
                        rateListPresets={rateListPresets}
                        onRateChartSetupTypeCattle={this.onRateChartSetupTypeCattle}
                        {...rateList}
                    />
                ))}
                {rateLists[activeTabIndex]?.rate_list_id
                    && rateLists[activeTabIndex]?.rate_list_type_id != 5
                    && (
                        <RateListTable
                            key={rateLists[activeTabIndex]?.rate_list_id}
                            rateList={rateLists[activeTabIndex]}
                            rateChartSetupTypeCattle={rateChartSetupTypeCattle}
                        />
                    )}
                <PageBottomHelpContact />
            </DefaultLayout>
        );
    }
}

RateList.displayName = "RateList";

const mapStateToProps = state => {
    return {
        settings: state.dairySettings.settings,
        dairyId: state.dairy.dairy.id,
        dairy: state.dairy.dairy,
        rateLists: state?.dairyRateList?.rateLists || [],
        customers: state?.dairy_customers?.customers || []
    }
};

RateList.defaultProps = {
    creation: false
};

export default connect(mapStateToProps)(withLanguage(RateList));