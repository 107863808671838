import * as Sentry from "@sentry/react";
import request from "./request";
import { getAppBuild } from "./util";

export const checkUpdate = async () => {
    try {
        const { status, data } = await request.get("/app/version");
        if(status) {
            const { app_build, app_force_update } = data;
            const currentBuild = getAppBuild();
            return {
                update: app_build > currentBuild,
                force_update: app_force_update
            };
        }
    } catch(error) {
        Sentry.captureException(error);
    }

    return {
        update: false,
        force_update: false
    };
};
