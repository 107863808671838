import withLanguage from "../../language/LanguageController";
import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import PropTypes from "prop-types";

const timerProps = {
    isPlaying: true,
    size: 100,
    strokeWidth: 6
};

const renderTime = (dimension, time) => {
    return (
      <div className="time-wrapper">
        <div className="time">{time}</div>
        <div>{dimension}</div>
      </div>
    );
};

const Timer = ({ t, onExpire, time }) => {
    return (
        <CountdownCircleTimer
            {...timerProps}
            duration={time}
            onComplete={onExpire}
            colors={[
                ["#2196f3", 0.33],
                ["#9c27b0", 0.33],
                ["#ff0000", 0.33]
            ]}
        >
            {({ remainingTime }) => renderTime(t("text_seconds"), remainingTime)}
        </CountdownCircleTimer>
    );
};

Timer.propTypes = {
    t: PropTypes.func.isRequired,
    onExpire: PropTypes.func.isRequired
};

export default withLanguage(Timer);