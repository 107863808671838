import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import MyImage from "../common/MyImage";
import moment from "moment";
import config from "../../../config";
import { useLanguage } from "../../language/LanguageController";
import { RedButton } from "../../common/buttons/RedButton";
import { PrimaryButton } from "../../common/buttons/PrimaryButton";
import defaultImg from "../../../assets/img/cow.png";
import { useDispatch } from "react-redux";
import { confirmGestation, deleteGestation } from "../../../actions/gestation";
import { confirmPop, loader, toast } from "../../library/util";
import analytics from "../../library/firebase/analytics";

const SingleGestation = ({ gestation }) => {
    const dispatch = useDispatch();
    const { t } = useLanguage();

    const onGestationDelete = async (gestationId) => {
        try {
          const buttonIndex = await confirmPop(t("text_delete_confirm"));
          if (buttonIndex === 1) {
            loader.show(t("text_deleting"));
            const message = await deleteGestation(dispatch, gestationId);
            message && toast(message);
          }
        } finally {
          loader.hide()
        }
    }

    const onGestationConfirm = async (gestationId) => {
        try {
            analytics.logEvent("confirmGestation");
            const buttonIndex = await confirmPop(t("text_gestation_confirm"));
            if (buttonIndex === 1) {
                loader.show(t("text_loading"));
                const message = await confirmGestation(dispatch, gestationId);
                message && toast(message);
            }
        } finally {
          loader.hide()
        }
    }

    return (
        <Card className="mb-2">
            <CardHeader
                avatar={
                    <div className="gestation-img-box">
                        <MyImage 
                            src={(gestation.image) ? gestation.image : defaultImg}
                            defaultSrc={defaultImg}
                            alt={gestation.name}
                            className="img-responsive" />
                    </div>
                }
                title={<p className="mb-2">{gestation.name} -- {t(`text_${gestation.animal_type}`)}</p>}
                subheader={
                    <div>
                        <span className="display-block">{t("text_breeding_date")} - {moment(gestation.breeding_date).format(config.display_date_format)}</span>
                        <span className="display-block">{t("text_calving_date")} - {moment(gestation.calving_date).format(config.display_date_format)}</span>
                        <span className="display-block">
                            {t("text_breed")} - {gestation.nasl}&nbsp;&nbsp;|&nbsp;&nbsp;{t("text_color")} - {gestation.color}
                        </span>
                   </div>
                }
            />
            <CardContent className="pt-0">
                <Typography variant="body1" component="p">
                    {gestation.description}
                </Typography>
            </CardContent>
            <CardActions className="pt-0 gestation-action">
                <RedButton
                    variant="contained"
                    size="small"
                    onClick={e => onGestationDelete(gestation.gestation_id)}
                >
                    {t("text_delete")}
                </RedButton>
                {gestation.is_confirm == 0 && gestation.breeding_days > 21 ? (
                    <PrimaryButton
                        variant="contained"
                        size="small"
                        onClick={e => onGestationConfirm(gestation.gestation_id)}
                    >
                        {t("text_confirm")}
                    </PrimaryButton>
                ) : null}
            </CardActions>
        </Card>
    );
};

export default SingleGestation;