import React, { Component } from 'react';
import { toast, loader, copyObject } from "../../../library/util";
import withLanguage from '../../../language/LanguageController';
import SimpleValidator from '../../../common/SimpleValidator';
import { errorHandler } from '../../../library/response';
import analytics from '../../../library/firebase/analytics';
import request from '../../../library/request';
import { DefaultLayout } from '../../common/Layout';
import { DefaultScrollbar } from "../../common/Scrollbar";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { addProduct, updateProduct } from '../../../../actions/product';
import Eventer from '../../../library/Eventer';

class ProductForm extends Component {
  constructor(props) {
    super(props);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
    const { product_id: productId = null } = props.match.params;
    let product = {};
    if(productId) {
      product = copyObject(this.props.products.find(product => product.id == productId) || {});
    }
    this.state = {
      ...product,
      product: product,
      quantity_total: product.quantity || 0,
      isEdit: product.id ? true : false,
      isSaving: false,
      quantity: 0
    };

    this.validator = new SimpleValidator();
  }

  componentDidMount() {
    if (this.props.match.params.product_id) {
      analytics.setScreenName("EditProductForm");
    } else {
      analytics.setScreenName("AddProductForm");
    }
  }

  inputChangeHandler = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;

    let stateData = {
      [name]: value
    };
    if (this.state.isEdit && name === "quantity") {
      let quantity = this.state.product.quantity;
      stateData['quantity_total'] = parseInt(quantity ? quantity : 0) + ((value) ? parseInt(value) : 0)
    }

    this.setState(stateData);
  }

  async formSubmitHandler(e) {
    e.preventDefault();
    try {
      // validate form before submitting to server 
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate(); // rerender to show messages for the first time
        return false;
      }
      loader.show(this.props.t("text_saving"));
      this.setState({
        isSaving: true
      });
      let url = `/dairies/${this.props.dairyId}/products${this.state.isEdit ? "/" + this.state.product.id : ""}`;
      if (this.state.isEdit) {
        analytics.logEvent("saveProduct");
      } else {
        analytics.logEvent("saveUpdateProduct");
      }
      let formData = new FormData(document.getElementById("productForm"));
      const { status, data, message, error_code } = await request.post(url, {
        withAuth: true,
        body: formData
      });

      if (status === true) {
        toast(message);
        if (this.state.isEdit) {
          this.props.updateProduct(this.state.product.id, data);
          this.setState({
            ...data,
            product: data,
            quantity_total: data.quantity,
            quantity: 0
          }, () => {
            this.validator.hideMessages();
            this.forceUpdate();
          });
        } else {
          this.props.addProduct(data);
          document.getElementById("productForm").reset();
          Eventer.emit("productAdded");
          this.setState({
            code: "",
            name: "",
            original_price: "",
            price: "",
            quantity: ""
          }, () => {
            this.validator.hideMessages();
            this.forceUpdate();
          });
        }
      } else if (error_code != 501) {
        throw message;
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      loader.hide();
      this.setState({
        isSaving: false
      });
    }
  }

  render() {
    const { isSaving, product = {}, isEdit } = this.state;
    const { t, header, bottomGutter } = this.props;
    return (
      <DefaultLayout
        title={(this.state.isEdit) ? t("text_edit_product") : t("text_add_new_product")}
        toolbar={false}
        header={header}
        bottomGutter={bottomGutter}
        back>
          {!isEdit ? (
          <div className="t-card shadow-sm mb-2 d-none d-sm-block">
            <h2 className="h5 font-weight-bold mb-0">{t("text_add_new_product")}</h2>
          </div>
          ) : null}
          <div className="form-row">
            <div className={`col-md-${header ? "6" : "12"} mx-auto`}>
              <DefaultScrollbar
                isEnabled={!header}
                className="dairy-product-form-scrollbar">
                <div className="t-card shadow-sm">
                  <form id="productForm"
                    onSubmit={this.formSubmitHandler}
                    encType={"multipart/form-data"}>
                    <div className="form-group">
                      <label>{t("text_product_name")}</label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="Name"
                        name="name"
                        maxLength={30}
                        onChange={this.inputChangeHandler}
                        defaultValue={product.name || ""} />
                      {this.validator.message('product_name', this.state.name, 'required|alpha_num_space')}
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label>{t("text_product_code")}</label>
                          <input
                            type="text"
                            className="form-control"
                            aria-describedby="Code"
                            name="code"
                            onChange={this.inputChangeHandler}
                            defaultValue={product.code || ""} />
                          {this.validator.message('product_code', this.state.code, 'required')}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label>{t("text_buying_price")}</label>
                          <input
                            type="number"
                            className="form-control"
                            name="original_price"
                            step="0.01"
                            onChange={this.inputChangeHandler}
                            defaultValue={product.original_price || ""} />
                          {this.validator.message('buying_price', this.state.original_price, 'required|numeric|min:0,num')}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label>{t("text_selling_price")}</label>
                          <input
                            type="number"
                            className="form-control"
                            aria-describedby="Price"
                            name="price"
                            step="0.01"
                            onChange={this.inputChangeHandler}
                            defaultValue={product.price || ""} />
                          {this.validator.message('selling_price', this.state.price, 'required|numeric|min:0,num')}
                        </div>
                      </div>
                    </div>
                    {this.state.isEdit ? (
                      <div className="form-group">
                        <label>{t("text_quantity")} </label>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            aria-describedby="Quantity"
                            readOnly
                            value={product.quantity || 0} />
                          <div className="input-group-prepend input-group-append">
                            <span className="input-group-text">+</span>
                          </div>
                          <input
                            type="number"
                            className="form-control"
                            aria-describedby="Quantity"
                            name="quantity"
                            value={this.state.quantity}
                            onChange={this.inputChangeHandler} />
                          <div className="input-group-prepend input-group-append">
                            <span className="input-group-text">=</span>
                          </div>
                          <input
                            type="number"
                            className="form-control"
                            aria-describedby="Quantity"
                            readOnly
                            value={this.state.quantity_total} />
                        </div>
                        {this.validator.message('quantity', this.state.quantity, 'numeric|min:0,num')}
                      </div>
                    ) : (
                      <div className="form-group">
                        <label>{t("text_quantity")} </label>
                        <input
                          type="number"
                          className="form-control"
                          aria-describedby="Quantity"
                          name="quantity"
                          step="0.1"
                          onChange={this.inputChangeHandler}
                          defaultValue={product.quantity || ""} />
                        {this.validator.message('quantity', this.state.quantity, 'required|numeric|min:1,num')}
                      </div>
                    )}
                    <div className="form-group">
                      <label>{t("text_description")}</label>
                      <textarea
                        className="form-control"
                        name="description"
                        rows={3}
                        onChange={this.inputChangeHandler}
                        defaultValue={product.description || ""} />
                    </div>
                    <div className="border-top pb-0 pt-2">
                      <button type="submit"
                        disabled={isSaving}
                        className="btn btn-success btn-block">
                        {isSaving ? t("text_saving") : t("text_save")}
                      </button>
                    </div>
                  </form>
                </div>
              </DefaultScrollbar>
            </div>
          </div>
      </DefaultLayout>
    );
  }
}

ProductForm.displayName = "ProductForm";

ProductForm.defaultProps = {
  header: true,
  bottomGutter: false
};

const mapStateToProps = state => {
  return {
    products: state.productReducer.products,
    dairyId: state.dairy.dairy.id,
  }
}

const mapDispatchToProps = {
  addProduct,
  updateProduct
}

export default connect(mapStateToProps, mapDispatchToProps)(withLanguage(withRouter(ProductForm)));
