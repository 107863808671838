import { getLang } from "../../../../language/LanguageController";
import React from "react";
import { Icon } from "react-onsenui";

const FeatureInPlan = ({ feature, plan }) => {
    const featureKey = feature.feature_key;
    const planFeatures = plan.plan_features || [];
    const planFeature = planFeatures.find(planFeature => planFeature.feature.feature_key === featureKey);
    const lang = getLang();
    if(planFeature) {
        if(planFeature.feature_value) {
            const featureLocalName = planFeature.feature_value_local ? planFeature.feature_value_local[lang] : null;
            return featureLocalName || planFeature.feature_value;
        } else {
            return <Icon icon="fa-check" style={{color: "#2eba4b"}}/>;
        }
    } else {
        return <Icon icon="fa-close" style={{color: "#ec2f2f"}} />;
    }
};


export default FeatureInPlan;