// @ts-nocheck

export const getPriceByDuration = (plan = {}, duration) => {
    switch (duration) {
        case 1:
        case "1":
            return Number(plan?.monthly_price || 0);
        case 3:
        case "3":
            return Number(plan?.quarterly_price || 0);
        case 6:
        case "6":
            return Number(plan?.half_yearly_price || 0);
        case 12:
        case "12":
            return Number(plan?.annually_price || 0);
        default:
            return 0
    }
};

export const getSubTotal = ({
    plan,
    duration,
    subscription,
    renew = false,
    addOns = []
}) => {
    const durationDays = getDaysForDuration(duration);
    const elapsedDays = subscription?.days?.days_elapsed || 0;
    const daysRemaining = durationDays - elapsedDays
    let total = getPriceByDuration(plan, duration);

    if(addOns.length) {
        const addOnTotal = Number(getAddOnsTotal(addOns, duration));
        if(!plan?.plan_id) {
            const addOnDayPrice = addOnTotal / durationDays
            total += addOnDayPrice * (daysRemaining > 0 ? daysRemaining : durationDays);
        } else {
            total += addOnTotal;
        }
    }

    return total;
};

export const getRemainingBalance = (subTotal, balance) => {
    return subTotal - (balance > 0 ? balance : 0);
}

export const calculateTotal = ({
    remainingBalance,
    coupon,
    redeemBalance = null
}) => {
    let total = remainingBalance;

    if(total > 0 && coupon) {
        total -= coupon.discount;
    }

    if(total > 0 && redeemBalance) {
        total -= redeemBalance.points/redeemBalance.point_price;
    }

    return total > 0 ? total : 0;
};

export const getAddOnsTotal = (addOns, duration) => {
    return addOns.reduce((addOnTotal, addOn) => {
        return Number(addOnTotal) + getPriceByDuration(addOn, duration);
    }, 0);
}

export const getExtraDays = (plan, duration, subscription, addOns = [], remainingBalance = 0) => {
    const planPrice = getPriceByDuration(plan, duration) || 0;
    const subscribedPlanPrice = subscription?.order?.price || 0;
    const addOnTotal = getAddOnsTotal(addOns, duration);

    if(Math.abs(remainingBalance) > 0) {
        const durationDays = getDaysForDuration(duration);
        const planDayPrice = plan?.plan_id ?  planPrice / durationDays : subscribedPlanPrice / durationDays;
        const addOnDayPrice = addOnTotal / durationDays;
        const dayPrice = planDayPrice + addOnDayPrice;
        const extraDays = Math.abs(remainingBalance / dayPrice);
        console.log("extraDays", extraDays);
        return parseInt(extraDays);
    }
};

export const getCheckoutAccountBalance = (subscription, selectedPlan = null, renew = false) => {
    if(subscription && subscription.balance > 0 && !renew) {
        if(selectedPlan?.plan_id) {
            return subscription.original_balance;
        }
        const originalBalance = subscription.original_balance;
        const subscribedPlanDuration = subscription.order.duration;
        const subscribedPlanPrice = subscription.order.price;
        const subscriptionDays = getDaysForDuration(subscribedPlanDuration);
        const planDayPrice = subscribedPlanPrice / subscriptionDays;
        const elapsedDays = subscription.days.days_elapsed;
        const subscriptionDaysAmount = subscriptionDays * planDayPrice;
        const elapsedDaysAmount = elapsedDays * planDayPrice;
        const remainingDaysAmount = subscriptionDaysAmount - elapsedDaysAmount;
        return originalBalance - remainingDaysAmount;
    }
    return 0;
};

export const getSubscriptionRemainingDays = (subscription, plan) => {
    if(Number(subscription?.is_trial) === 0 && !plan?.plan_id) {
        const subscribedPlanDuration = subscription?.order?.duration;
        const subscriptionDays = getDaysForDuration(subscribedPlanDuration);
        const elapsedDays = subscription?.days?.days_elapsed;
        return subscriptionDays - elapsedDays;
    }

    return 0;
}

const getDaysForDuration = (duration) => {
    return duration == 12 ? 365 : duration * 30;
};