import { ABILITY_ADVANCE_PAYMENT, ABILITY_DAIRY_BILL, ABILITY_DAIRY_CUSTOMERS, ABILITY_DAIRY_RATE_LIST, ABILITY_DAIRY_REPORT, ABILITY_DAIRY_SETTING, ABILITY_DAIRY_USERS, ABILITY_LOAN, ABILITY_MILK_COLLECTION, ABILITY_PRODUCT_MANAGEMENT, ABILITY_PRODUCT_SALE, PERMISSION_ADD, PERMISSION_CUSTOMER_REPORT, PERMISSION_DAIRY_REPORT, PERMISSION_DELETE, PERMISSION_DETAIL, PERMISSION_PAY, PERMISSION_PRINT, PERMISSION_PRINT_AND_SEND_SMS, PERMISSION_RECOVER, PERMISSION_SALE_ADD, PERMISSION_SALE_DELETE, PERMISSION_SALE_VIEW, PERMISSION_SEND_SMS, PERMISSION_UPDATE, PERMISSION_VIEW, PERMISSION_VIEW_CUSTOMER_BILL, PERMISSION_VIEW_DAIRY_BILL } from "../../../../../_constants/permissions";

const featuresPermissionList = [
    {
        "feature_permission_label": "text_dairy_customer_permissions",
        "feature_permission_key": "dairy_customers",
        "feature_permissions": [
            {
                "permission": "view",
                "label": "text_customer_view"
            },
            {
                "permission": "add",
                "label": "text_customer_add",
                "dependent": {
                    "dairy_customers": ["view"]
                }
            },
            {
                "permission": "update",
                "label": "text_customer_update",
                "dependent": {
                    "dairy_customers": ["view"]
                }
            },
            {
                "permission": "delete",
                "label": "text_customer_delete",
                "dependent": {
                    "dairy_customers": ["view"]
                }
            }
        ]
    },
    {
        "feature_permission_label": "text_milk_collection_permissions",
        "feature_permission_key": "milk_collection",
        "feature_permissions": [
            {
                "permission": "dashboard_view",
                "label": "text_dashboard_view",
                "default_selected": true,
                "dependent": {
                    "dairy_customers": ["view"]
                }
            },
            {
                "permission": "add",
                "label": "text_add_collection",
                "dependent": {
                    "dairy_customers": ["view"],
                    "dairy_ratelist": ["view"],
                    "dairy_setting": ["view"],
                    "milk_collection": ["dashboard_view"]
                }
            },
            {
                "permission": "update",
                "label": "text_edit_collection",
                "dependent": {
                    "dairy_customers": ["view"],
                    "dairy_ratelist": ["view"],
                    "dairy_setting": ["view"]
                }
            },
            {
                "permission": "delete",
                "label": "text_delete_collection",
                "dependent": {
                    "dairy_customers": ["view"],
                    "milk_collection": ["add", "update"]
                }
            },
            {
                "permission": PERMISSION_PRINT_AND_SEND_SMS,
                "label": "text_print_and_send_sms_collection",
                "dependent": {
                    "dairy_customers": ["view"],
                    "milk_collection": ["add", "update"]
                }
            },
            {
                "permission": "detail",
                "label": "text_detail_collection",
                "dependent": {
                    "dairy_customers": ["view"]
                }
            }
        ]
    },
    {
        "feature_permission_label": "text_milk_product_management_permissions",
        "feature_permission_key": "product_management",
        "feature_permissions": [
            {
                "permission": "view",
                "label": "text_view_product"
            },
            {
                "permission": "add",
                "label": "text_add_product",
                "dependent": {
                    "product_management": ["view"]
                }
            },
            {
                "permission": "update",
                "label": "text_update_product",
                "dependent": {
                    "product_management": ["view"]
                }
            },
            {
                "permission": "delete",
                "label": "text_delete_product",
                "dependent": {
                    "product_management": ["view"]
                }
            }
        ]
    },
    {
        "feature_permission_label": "text_milk_product_sale_permissions",
        "feature_permission_key": "product_sale",
        "feature_permissions": [
            {
                "permission": "sale_view",
                "label": "text_view_product_sale",
                "dependent": {
                    "dairy_customers": ["view"]
                }
            },
            {
                "permission": "sale_add",
                "label": "text_add_product_sale",
                "dependent": {
                    "dairy_customers": ["view"]
                }
            },
            {
                "permission": "sale_delete",
                "label": "text_delete_product_sale",
                "dependent": {
                    "dairy_customers": ["view"],
                    "product_sale": ["sale_view"]
                }
            }
        ]
    },
    {
        "feature_permission_label": "text_dairy_bill_permissions",
        "feature_permission_key": "dairy_bill",
        "feature_permissions": [
            {
                "permission": "view_dairy_bill",
                "label": "text_view_dairy_bill",
                "dependent": {
                    "dairy_setting": ["view"]
                }
            },
            {
                "permission": "view_customer_bill",
                "label": "text_view_customer_bill",
                "dependent": {
                    "dairy_customers": ["view"],
                    "dairy_setting": ["view"]
                }
            },
            {
                "permission": "pay",
                "label": "text_pay_customer_bill",
                "dependent": {
                    "dairy_customers": ["view"],
                    "dairy_bill": ["view_customer_bill"]
                }
            }
        ]
    },
    {
        "feature_permission_label": "text_advance_payment_permissions",
        "feature_permission_key": "advance_payment",
        "feature_permissions": [
            {
                "permission": "view",
                "label": "text_advance_payment_view",
                "dependent": {
                    "dairy_customers": ["view"]
                }
            },
            {
                "permission": "add",
                "label": "text_advance_payment_add",
                "dependent": {
                    "dairy_customers": ["view"],
                    "advance_payment": ["view"]
                }
            },
            {
                "permission": "delete",
                "label": "text_advance_payment_delete",
                "dependent": {
                    "dairy_customers": ["view"],
                    "advance_payment": ["view"]
                }
            }
        ]
    },
    {
        "feature_permission_label": "text_loan_permissions",
        "feature_permission_key": "loan",
        "feature_permissions": [
            {
                "permission": "view",
                "label": "text_loan_view",
                "dependent": {
                    "dairy_customers": ["view"]
                }
            },
            {
                "permission": "add",
                "label": "text_loan_add",
                "dependent": {
                    "dairy_customers": ["view"],
                    "loan": ["view"]
                }
            },
            {
                "permission": "recover",
                "label": "text_loan_recover",
                "dependent": {
                    "dairy_customers": ["view"],
                    "loan": ["view"]
                }
            }
        ]
    },
    {
        "feature_permission_label": "text_dairy_report_permissions",
        "feature_permission_key": "dairy_report",
        "feature_permissions": [
            {
                "permission": "dairy_report",
                "label": "text_dairy_report"
            },
            {
                "permission": "customer_report",
                "label": "text_customer_report",
                "dependent": {
                    "dairy_customers": ["view"]
                }
            }
        ]
    },
    {
        "feature_permission_label": "text_dairy_ratelist_permissions",
        "feature_permission_key": "dairy_ratelist",
        "feature_permissions": [
            {
                "permission": "view",
                "label": "text_ratelist_view",
                "dependent": {
                    "dairy_customers": ["view"]
                }
            },
            {
                "permission": "add",
                "label": "text_ratelist_add",
                "dependent": {
                    "dairy_customers": ["view"],
                    "dairy_ratelist": ["view"]
                }
            },
            {
                "permission": "update",
                "label": "text_ratelist_update",
                "dependent": {
                    "dairy_customers": ["view"],
                    "dairy_ratelist": ["view"]
                }
            },
            {
                "permission": "delete",
                "label": "text_ratelist_delete",
                "dependent": {
                    "dairy_customers": ["view"],
                    "dairy_ratelist": ["view"]
                }
            }
        ]
    },
    {
        "feature_permission_label": "text_dairy_setting_permissions",
        "feature_permission_key": "dairy_setting",
        "feature_permissions": [
            {
                "permission": "view",
                "label": "text_dairy_setting_view"
            },
            {
                "permission": "update",
                "label": "text_dairy_setting_update",
                "dependent": {
                    "dairy_setting": ["view"],
                }
            },
        ]
    },
    {
        "feature_permission_label": "text_dairy_users_permissions",
        "feature_permission_key": "dairy_users",
        "feature_permissions": [
            {
                "permission": "view",
                "label": "text_dairy_users_view"
            },
            {
                "permission": "add",
                "label": "text_dairy_users_add",
                "dependent": {
                    "dairy_users": ["view"]
                }
            },
            {
                "permission": "update",
                "label": "text_dairy_users_update",
                "dependent": {
                    "dairy_users": ["view"]
                }
            },
            {
                "permission": "delete",
                "label": "text_dairy_users_delete",
                "dependent": {
                    "dairy_users": ["view"]
                }
            }
        ]
    }
];

export const rolePermissionMap = {
    admin: {
        [ABILITY_DAIRY_CUSTOMERS]: [
            PERMISSION_VIEW,
            PERMISSION_ADD,
            PERMISSION_UPDATE,
            PERMISSION_DELETE
        ],
        [ABILITY_MILK_COLLECTION]: [
            PERMISSION_ADD,
            PERMISSION_UPDATE,
            PERMISSION_DELETE,
            PERMISSION_SEND_SMS,
            PERMISSION_PRINT,
            PERMISSION_DETAIL
        ],
        [ABILITY_PRODUCT_MANAGEMENT]: [
            PERMISSION_VIEW,
            PERMISSION_ADD,
            PERMISSION_UPDATE,
            PERMISSION_DELETE
        ],
        [ABILITY_PRODUCT_SALE]: [
            PERMISSION_SALE_VIEW,
            PERMISSION_SALE_ADD,
            PERMISSION_SALE_DELETE
        ],
        [ABILITY_DAIRY_BILL]: [
            PERMISSION_VIEW_DAIRY_BILL,
            PERMISSION_VIEW_CUSTOMER_BILL,
            PERMISSION_PAY
        ],
        [ABILITY_ADVANCE_PAYMENT]: [
            PERMISSION_VIEW,
            PERMISSION_ADD,
            PERMISSION_DELETE
        ],
        [ABILITY_LOAN]: [
            PERMISSION_VIEW,
            PERMISSION_ADD,
            PERMISSION_RECOVER
        ],
        [ABILITY_DAIRY_REPORT]: [
            PERMISSION_DAIRY_REPORT,
            PERMISSION_CUSTOMER_REPORT
        ],
        [ABILITY_DAIRY_RATE_LIST]: [
            PERMISSION_VIEW,
            PERMISSION_ADD,
            PERMISSION_UPDATE,
            PERMISSION_DELETE
        ],
        [ABILITY_DAIRY_SETTING]: [
            PERMISSION_VIEW,
            PERMISSION_UPDATE
        ],
        [ABILITY_DAIRY_USERS]: [
            PERMISSION_ADD,
            PERMISSION_UPDATE,
            PERMISSION_VIEW,
            PERMISSION_DELETE
        ]
    },
    dairy_manager: {
        [ABILITY_DAIRY_CUSTOMERS]: [
            PERMISSION_VIEW,
            PERMISSION_ADD,
            PERMISSION_UPDATE,
            PERMISSION_DELETE
        ],
        [ABILITY_MILK_COLLECTION]: [
            PERMISSION_ADD,
            PERMISSION_UPDATE,
            PERMISSION_DELETE,
            PERMISSION_SEND_SMS,
            PERMISSION_PRINT,
            PERMISSION_DETAIL
        ],
        [ABILITY_PRODUCT_MANAGEMENT]: [
            PERMISSION_VIEW,
            PERMISSION_ADD,
            PERMISSION_UPDATE,
            PERMISSION_DELETE
        ],
        [ABILITY_PRODUCT_SALE]: [
            PERMISSION_SALE_VIEW,
            PERMISSION_SALE_ADD,
            PERMISSION_SALE_DELETE
        ],
        [ABILITY_DAIRY_BILL]: [
            PERMISSION_VIEW_DAIRY_BILL,
            PERMISSION_VIEW_CUSTOMER_BILL,
            PERMISSION_PAY
        ],
        [ABILITY_ADVANCE_PAYMENT]: [
            PERMISSION_VIEW,
            PERMISSION_ADD,
            PERMISSION_DELETE
        ],
        [ABILITY_LOAN]: [
            PERMISSION_VIEW,
            PERMISSION_ADD,
            PERMISSION_RECOVER
        ],
        [ABILITY_DAIRY_REPORT]: [
            PERMISSION_DAIRY_REPORT,
            PERMISSION_CUSTOMER_REPORT
        ],
        [ABILITY_DAIRY_RATE_LIST]: [
            PERMISSION_VIEW,
            PERMISSION_ADD,
            PERMISSION_UPDATE,
            PERMISSION_DELETE
        ],
        [ABILITY_DAIRY_SETTING]: [
            PERMISSION_VIEW,
            PERMISSION_UPDATE
        ]
    },
    collection_user: {
        [ABILITY_DAIRY_CUSTOMERS]: [
            PERMISSION_VIEW,
            PERMISSION_ADD,
            PERMISSION_UPDATE
        ],
        [ABILITY_MILK_COLLECTION]: [
            PERMISSION_ADD,
            PERMISSION_UPDATE,
            PERMISSION_DELETE,
            PERMISSION_SEND_SMS,
            PERMISSION_PRINT,
            PERMISSION_DETAIL
        ],
        [ABILITY_PRODUCT_MANAGEMENT]: [
            PERMISSION_VIEW
        ],
        [ABILITY_PRODUCT_SALE]: [
            PERMISSION_SALE_VIEW,
            PERMISSION_SALE_ADD,
            PERMISSION_SALE_DELETE
        ],
        [ABILITY_DAIRY_RATE_LIST]: [
            PERMISSION_VIEW
        ],
        [ABILITY_DAIRY_SETTING]: [
            PERMISSION_VIEW
        ]
    }
}

export default featuresPermissionList;