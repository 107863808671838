import React, { Fragment, useState } from "react";
import { Checkbox } from "react-onsenui";
import withLanguage from "../../language/LanguageController";
import PropTypes from "prop-types";

const ReferralCode = ({ t, handleReferralCheckbox, handleInput }) => {
    const [isHaveReferral, setHaveReferral] = useState(false);
    return (
        <Fragment>
            <div className="form-group">
                <div className="form-check form-check-inline">
                    <Checkbox 
                        modifier="material form-check-input"
                        inputId="have-referral-code"
                        checked={isHaveReferral}
                        onChange={() => setHaveReferral(!isHaveReferral)} />
                    <label
                        className="form-check-label"
                        htmlFor="have-referral-code">
                            {t("text_referral_code_label")}
                    </label>
                </div>
            </div>
            {isHaveReferral ? (
                <div className="form-group">
                    <label
                        htmlFor="referral_code"
                        className="sr-only">
                            {t("text_referral_code")}
                    </label>
                    <input 
                        className="form-control input-bottom-border"
                        type="text"
                        placeholder={t("text_referral_code_placeholder")}
                        name="referral_code"
                        id="referral_code"
                        onChange={handleInput}/>
                </div>
            ) : null}
        </Fragment>
    );
};

ReferralCode.propTypes = {
    t: PropTypes.func.isRequired,
    have_referral: PropTypes.bool.isRequired,
    handleReferralCheckbox: PropTypes.func.isRequired,
    handleInput: PropTypes.func.isRequired
};

export default withLanguage(ReferralCode);