import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import * as Sentry from "@sentry/react";
import { orderBy } from "lodash";
import moment from "moment";
import React from 'react';
import { connect } from 'react-redux';
import { ORDER_ASC, ORDER_DESC } from 'src/_constants';
import { DefaultLayout } from 'src/components/pages/common/Layout';
import config from '../../../../../config';
import { checkFeatureInSubscription } from '../../../../common/FeatureFlag/FeatureFlag';
import SubscriptionLock from '../../../../common/FeatureFlag/SubscriptionLock';
import withLanguage from '../../../../language/LanguageController';
import { getCustomerById } from '../../../../library/customer';
import analytics from '../../../../library/firebase/analytics';
import request from '../../../../library/request';
import { currencyFormat, loader } from "../../../../library/util";
import InnerNav from '../../InnerNav';
import Filter from "./Filter";
import { selectDairyCustomers } from 'src/reducers/dairyCustomer';

class MilkPaymentDetail extends React.Component {

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.state = {
            records: [],
            total: {
                "milk": "0.00",
                "amount": "0.00",
                "purchase": "0.00",
                "payble": "0.00"
            },
            isFeatureSubscribed: checkFeatureInSubscription("dairy_billing", false),
            ...this.getDefaultFilter(),
            sortBy: 'code',
            sortOrder: ORDER_ASC
        };
    }

    getDefaultFilter() {
        const startDate = this.props.startDate ? this.props.startDate : moment().startOf('month');
        const endDate = this.props.endDate ? this.props.endDate : moment();
        return {
            start_date: startDate,
            end_date: endDate,
            customer_type: "seller"
        }
    }

    componentDidMount() {
        analytics.setScreenName("MilkPaymentDetail");
        this.getData();

        this.props.onRef && this.props.onRef(this);
    }

    async getData() {
        if (!this.state.isFeatureSubscribed) {
            return false;
        }
        try {
            loader.show(this.props.t("text_loading"));
            const { start_date, end_date, customer_type } = this.state;
            const { dairyId } = this.props;
            let filterStr = `?date=${start_date.format(config.date_format)}:${end_date.format(config.date_format)}`;
            if (customer_type) {
                filterStr += `&customer_type=${customer_type}`;
            }
            const { status, data } = await request.get('/dairies/' + dairyId + '/milkPayment' + filterStr, {
                withAuth: true,
                version: "v2"
            });
            if (status === true) {
                this.setState({
                    records: data.result,
                    total: data.total,
                });
            }
        } catch (error) {
            Sentry.captureException(error);
        } finally {
            loader.hide();
        }
    }

    dateChangeHandler = (name, newDate) => {
        if (!checkFeatureInSubscription("dairy_billing")) {
            return false;
        }
        this.setState({
            [name]: newDate
        }, () => this.getData());
    }

    customerTypeChangeHandler = (customerType) => {
        if (!checkFeatureInSubscription("dairy_billing")) {
            return false;
        }
        this.setState({
            customer_type: customerType
        }, () => this.getData());
    }

    onDeviceChange = (deviceDetails) => {
        this.setState({ ...deviceDetails });
    }

    handleSort = (column) => {
        const { sortBy, sortOrder } = this.state;
        if (sortBy === column) {
            this.setState({ sortOrder: sortOrder === ORDER_ASC ? ORDER_DESC : ORDER_ASC });
        } else {
            this.setState({ sortBy: column, sortOrder: ORDER_ASC });
        }
    }

    sortRecords = (records, sortBy, sortOrder, customers) => {
        let sortedRecords = [...records];
        if (sortBy) {
            sortedRecords = orderBy(sortedRecords, (record) => {
                let value = record[sortBy];
                if (sortBy === 'customer') {
                    const name = getCustomerById(customers, record.dairy_customer_id)?.name || '';
                    return name.toLowerCase(); 
                } else {
                    const parsedValue = !isNaN(parseFloat(value)) ? parseFloat(value.replace(/,/g, "")) : value;
                    return parsedValue === '-' ? null : parsedValue;
                }
            }, [sortOrder]);
        }
        return sortedRecords;
    }
    

    render() {
        const { t, header, filter, innerNav, bottomGutter, customers } = this.props;
        const { start_date, end_date, customer_type, isFeatureSubscribed, isBrowser, sortBy, sortOrder, } = this.state;
        const sortedRecords = this.sortRecords(this.state.records, sortBy, sortOrder, customers);
        const ascendingIcon = <ArrowDownwardSharpIcon className="mb-0.5 ml-1 text-white" fontSize="small" />;
        const descendingIcon = <ArrowUpwardIcon className="mb-0.5 ml-1 text-white " fontSize="small" />;

        const sortIcon = (column) => {
            if (sortBy === column) {
                return sortOrder === ORDER_ASC ? ascendingIcon : descendingIcon;
            }
            return null;
        };


        return (
            <DefaultLayout
                header={header}
                title={t("text_milk_payment_summary")}
                toolbar={false}
                bottomGutter={!isBrowser && bottomGutter}
                onDeviceChange={this.onDeviceChange}
                back
            >
                {filter && <Filter
                    startDate={start_date}
                    endDate={end_date}
                    customerType={customer_type}
                    dateChangeHandler={this.dateChangeHandler}
                    customerTypeChangeHandler={this.customerTypeChangeHandler} />}
                <div className="table_container table-responsive bg-light shadow-sm">
                    <table className="table table-striped table-borderless mb-0">
                        <thead>
                            <tr>
                                <th className="align-middle bg-success" onClick={() => this.handleSort('code')}>{t("text_code")} {sortBy === 'code' &&
                                    (sortOrder === ORDER_ASC ? (ascendingIcon) : (descendingIcon))}{!sortBy && (descendingIcon)}</th>
                                <th className="align-middle bg-success" onClick={() => this.handleSort('customer')}>{t("text_customer")} {sortIcon('customer')}</th>
                                <th className="align-middle" onClick={() => this.handleSort('total_milk')}>{t("text_total_milk")}{sortIcon('total_milk')}</th>
                                <th className="align-middle" onClick={() => this.handleSort('total_amount')}>{t("text_total_amount")}{sortIcon('total_amount')}</th>
                                <th className="align-middle" onClick={() => this.handleSort('total_purchase')} >{t("text_total_purchase")}{sortIcon('total_purchase')}</th>
                                <th className="align-middle" onClick={() => this.handleSort('advance_payment')}>{t("text_advance")}{sortIcon('advance_payment')}</th>
                                <th className="align-middle" onClick={() => this.handleSort('payable_amount')}>{t("text_payable_amount")}{sortIcon('payable_amount')}</th>
                                <th className="align-middle" onClick={() => this.handleSort('due_amount')}>{t("text_due_amount")}{sortIcon('due_amount')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedRecords.map((data) => {
                                const customer = getCustomerById(this.props.customers, data.dairy_customer_id);
                                return (<tr key={data.dairy_customer_id}>
                                    <td>{`${customer?.code}`}</td>
                                    <td>{`${customer?.name}`}</td>
                                    <td>{currencyFormat(data.total_milk, false)}</td>
                                    <td>{currencyFormat(data.total_amount)}</td>
                                    <td>{currencyFormat(data.total_purchase)}</td>
                                    <td>{data.advance_payment ? currencyFormat(data.advance_payment) : '-'}</td>
                                    <td>{currencyFormat(data.payable_amount)}</td>
                                    <td>{currencyFormat(data.due_amount)}</td>
                                </tr>);
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th className="text-right">{t("text_total")}</th>
                                <th className="text-right"></th>
                                <th>{currencyFormat(this.state.total.milk, false)}</th>
                                <th>{currencyFormat(this.state.total.amount)}</th>
                                <th>{currencyFormat(this.state.total.purchase)}</th>
                                <th>{currencyFormat(this.state.total.advance)}</th>
                                <th>{currencyFormat(this.state.total.payable)}</th>
                                <th>{currencyFormat(this.state.total.due)}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                {innerNav && <InnerNav
                    menu="bill"
                    page="milkPaymentDetail"
                    navigator={this.props.navigator}
                />}
                {!isFeatureSubscribed && <SubscriptionLock />}
            </DefaultLayout>
        );
    }
}

MilkPaymentDetail.defaultProps = {
    header: true,
    filter: true,
    innerNav: true,
    bottomGutter: true
};

const mapStateToProps = state => {
    return {
        customers: selectDairyCustomers(state),
        dairyId: state.dairy.dairy.id
    }
};

const MilkPaymentDetailComp = connect(mapStateToProps)(withLanguage(MilkPaymentDetail));

export default MilkPaymentDetailComp;