import { IconButton } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/GetApp';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ListIcon from '@material-ui/icons/List';
import PrintIcon from '@material-ui/icons/Print';
import SearchIcon from '@material-ui/icons/Search';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ANALYTIC_EVENTS, ANALYTIC_PROPERTIES, ORDER_ASC, ORDER_DEFAULT, ORDER_DESC } from 'src/_constants';
import { RedButton } from "src/components/common/buttons/RedButton";
import { PrimaryCheckbox } from 'src/components/core/Checkbox';
import WhiteCheckBox from 'src/components/core/Checkbox/WhiteCheckBox';
import { PrimaryTextField } from 'src/components/core/TextField';
import { useLanguage } from "src/components/language/LanguageController";
import Eventer from "src/components/library/Eventer";
import analytics from '../../../../library/firebase/analytics';
import request from '../../../../library/request';
import { errorHandler } from '../../../../library/response';
import { confirmPop, currencyFormat, loader, toast, } from "../../../../library/util";

const {
    
    SORT_BY,
    SORT_ORDER,
    PAGE_NAME,
    COLUMN,
    NEXT_SORT_ORDER,
    MILK_COLLECTION_PAGE
} = ANALYTIC_PROPERTIES;
const {
    SORT_BUTTON_CLICKEVENT
} = ANALYTIC_EVENTS;

const DairyMilkDetailTableView = ({ customers = [], shift, cattle, view, filterChangeHandler, customerType, downloadCollectionDetail, printMilkBill, changeView, editMilk, deleteMilk, isFeatureSubscribed, startDate }) => {
    const { t } = useLanguage();
    const handleDelete = async (e, customer) => {
        e.preventDefault();
        deleteMilk(customer);
        setSelectedItems((prev) => prev.filter(item => item.id !== customer.id));
    };
    const [sortBy, setSortBy] = useState("code");
    const [sortOrder, setSortOrder] = useState(ORDER_DEFAULT);
    const ascendingIcon = <ArrowUpwardIcon className="mb-0.5 ml-1 text-white" fontSize="small" />;
    const descendingIcon = <ArrowDownwardSharpIcon className="mb-0.5 ml-1 text-white" fontSize="small" />;
    const defaultIcon = <ImportExportIcon className="mb-0.5 ml-1 text-white" fontSize="small" />;
    const checkboxRef = useRef(null);
    const dairy_id = useSelector((state) => state.dairy.dairy.id)
    const [isChecked, setIsChecked] = useState(false);
    const [selectedItems, setSelectedItems] = useState([])
    const [isDeleting, setIsDeleting] = useState(false);
    const [allCustomer, setAllCustomer] = useState({ milk_type: cattle, day_time: shift, customerType: customerType, dairy_id: dairy_id, selectedItems: [] })
    var ids = []
    const handleSelectAll = () => {
        if (isChecked === true) {
            setIsChecked(false);
            setSelectedItems([]);
        } else {
            setIsChecked(true);
            const selectedCustomers = ids.map((id) => customers.find((o) => o.id === id));
            const x = selectedCustomers.map(item => ({
                id: item.id,
                dairy_customer_id: +(item.dairy_customer_id),
                date: item.date
            }));
            setSelectedItems(x);
        }
    };

    const determineCheckboxState = () => {
        if (selectedItems.length === 0) {
            setIsChecked(false);
        } else if (selectedItems.length === ids.length) {
            setIsChecked(true);
        } else {
            setIsChecked("indeterminate");
        }
    };

    const clearFunc = () => {
        window.location.reload()
    }

    useEffect(() => {
        determineCheckboxState();
        setAllCustomer((prev) => ({
            ...prev,
            selectedItems: [...selectedItems]
        }));
    }, [selectedItems])

    const handleClick = (id, customer, e) => {
        const selectedCustomers = {
            id: customer.id,
            dairy_customer_id: +(customer.dairy_customer_id),
            date: customer.date
        };

        if (!selectedItems.some(item => item.id === id)) {
            setSelectedItems([...selectedItems, selectedCustomers]);
        } else {
            setSelectedItems(selectedItems.filter(item => item.id !== id));
        }
    };

    function getIds() {
        for (var i = 0; i < customers.length; i++) {
            if (customers[i].id)
                ids.push(customers[i].id)
        }
    }
    getIds()

    const deleteAllHandler = async () => {
        let confirmed = false;
        try {
            analytics.logEvent("deleteMilk");
            confirmed = await confirmPop(t("text_delete_milk_confirm"));
            if (confirmed) {
                setIsDeleting(true);
                loader.show(t("text_deleting"));
                const { status, message } = await request.post('/dairies/' + dairy_id + '/milk/delete/multiplemilkentry', {
                    withAuth: true,
                    version: "v2",
                    body: JSON.stringify(allCustomer)
                });

                if (status === true) {
                    toast(message);
                    Eventer.emit("milkDeleted", {
                        shift,
                        cattle,
                        customerType: customerType,
                        date: startDate.toString()
                    });
                    setIsChecked(false);
                    setSelectedItems([]);
                } else {
                    throw message;
                }
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
            setIsDeleting(false);
        }
    };

    const sortedCustomers = () => {
        const customersWithEntries = _.filter(customers, customer => customer.liter && parseFloat(customer.liter) !== 0);
        const customersWithoutEntries = _.filter(customers, customer => !customer.liter || parseFloat(customer.liter) === 0);
    
        const sortedWithEntries = _.orderBy(customersWithEntries, [(customer) => {
            if (sortBy === 'name') {
                if (sortOrder === ORDER_DEFAULT) {
                    return new Date(customer[`date_time_${shift}`]);
                } else {
                    return customer.name.toLowerCase();
                }
            } else if (sortBy === 'amount') {

                if (sortOrder === ORDER_DEFAULT) {
                    return new Date(customer[`date_time_${shift}`]);
                } else {
                    return parseFloat(customer.total);
                }
            } else {
                if (sortOrder === ORDER_DEFAULT) {
                    return _.reverse(new Date(customer[`date_time_${shift}`]))
                }
                else {
                    return parseFloat(customer[sortBy]);
                }
            }
        }, (customer) => new Date(customer[`date_time_${shift}`])], [sortOrder, ORDER_DEFAULT]);
        if (sortOrder === ORDER_DEFAULT) {
            sortedWithEntries.reverse();
        }

        const combinedSortedCustomers = _.concat(sortedWithEntries, customersWithoutEntries);

        return combinedSortedCustomers;
    };


    const sortCustomers = (column) => {

        const getNextSortOrder = (currentSortOrder) => {
            switch (currentSortOrder) {
                case ORDER_DEFAULT:
                    return ORDER_ASC;
                case ORDER_ASC:
                    return ORDER_DESC;
                case ORDER_DESC:
                    return ORDER_DEFAULT;
                default:
                    return ORDER_DEFAULT;
            }
        };

        const nextSortOrder = sortBy === column ? getNextSortOrder(sortOrder) : ORDER_ASC;
        setSortBy(column);
        setSortOrder(nextSortOrder);
        analytics.logEvent(SORT_BUTTON_CLICKEVENT, {
            [SORT_BY]: column,
            [SORT_ORDER]: nextSortOrder,
            [PAGE_NAME]: MILK_COLLECTION_PAGE
        });
    };


    const deletTotalEntryNot = (cattle !== "TotalCowBuffalo" && shift !== "TotalMorningEvening");
    return (
        <>
            <div d-none d-sm-block>
                <div className='d-flex'>
                    <div className="select-all my-1 d-none d-lg-block" >
                        {deletTotalEntryNot ?
                            (
                                <RedButton
                                    className="RedButton"
                                    disabled={isChecked && !isDeleting ? false : true}
                                    variant="contained"
                                    type="button"
                                    onClick={deleteAllHandler}
                                    startIcon={<DeleteIcon />}>
                                    {t("text_delete")}
                                </RedButton>) : null}
                    </div>
                    <div className='d-sm-block d-lg-none'>
                        {deletTotalEntryNot && (
                            <IconButton
                                aria-label="delete"
                                disabled={isChecked && !isDeleting ? false : true}
                                variant="contained"
                                type="button"
                                onClick={deleteAllHandler}
                                color="secondary">
                                <DeleteIcon />
                            </IconButton>)}
                    </div>

                    <PrimaryTextField
                        className='ml-2 mr-2 mt-1'
                        id="outlined-full-width"
                        size='small'
                        placeholder={t("text_search")}
                        onChange={filterChangeHandler}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment >
                                    <IconButton className='d-none d-lg-block'>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <IconButton aria-label={t("text_download_now")} onClick={downloadCollectionDetail}>
                        <DownloadIcon />
                    </IconButton>
                    <IconButton
                        aria-label={t("text_print")}
                        onClick={printMilkBill}
                    >
                        <PrintIcon />
                    </IconButton>
                    <IconButton
                        aria-label={view === "list" ? t("text_switch_table_view") : t("text_switch_list_view")}
                        onClick={changeView}>
                        {view === "list" ? <ListIcon /> : <ViewCompactIcon />}
                    </IconButton>
                </div>
            </div>
            <div className="table_container table-responsive dairy-milk-detail-scrollbar">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr className='content-justify '>
                            {deletTotalEntryNot ?
                                <th className="nowrap width20">
                                    {isChecked === "indeterminate" ? (
                                        <WhiteCheckBox
                                            indeterminate
                                            className="checkbox_input"
                                            name="selectAll"
                                            id="selectAll"
                                            checked={isChecked === true}
                                            onChange={handleSelectAll} />
                                    ) : (
                                        <WhiteCheckBox
                                            className="checkbox_input"
                                            name="selectAll"
                                            id="selectAll"
                                            checked={isChecked === true}
                                            onChange={handleSelectAll}
                                        />
                                    )}
                                </th> : null}
                            <th className='align-middle' onClick={() => sortCustomers('code')}>{t("text_code")}{sortBy === 'code' && (sortOrder === ORDER_ASC ? descendingIcon : sortOrder === ORDER_DESC ? ascendingIcon : sortOrder === ORDER_DEFAULT ? defaultIcon : null)}</th>
                            <th className='align-middle' onClick={() => sortCustomers('name')}>{t("text_customer_name")}{(sortBy === 'name') && (sortOrder === ORDER_ASC ? descendingIcon : sortOrder === ORDER_DESC ? ascendingIcon : defaultIcon)}</th>
                            <th className='align-middle' onClick={() => sortCustomers('liter')}>{t("text_liter")}{sortBy === 'liter' && (sortOrder === ORDER_ASC ? descendingIcon : sortOrder === ORDER_DESC ? ascendingIcon : defaultIcon)}</th>
                            <th className='align-middle' onClick={() => sortCustomers('fat')}>{t("text_fat")}{(sortBy === 'fat') && (sortOrder === ORDER_ASC ? descendingIcon : sortOrder === ORDER_DESC ? ascendingIcon : defaultIcon)}</th>
                            <th className='align-middle' onClick={() => sortCustomers('clr')}>{t("text_clr")}{sortBy === 'clr' && (sortOrder === ORDER_ASC ? descendingIcon : sortOrder === ORDER_DESC ? ascendingIcon : defaultIcon)}</th>
                            <th className='align-middle' onClick={() => sortCustomers('snf')}>{t("text_snf")}{sortBy === 'snf' && (sortOrder === ORDER_ASC ? descendingIcon : sortOrder === ORDER_DESC ? ascendingIcon : defaultIcon)}</th>
                            <th className='align-middle' onClick={() => sortCustomers('rate')}>{t("text_rate")}{sortBy === 'rate' && (sortOrder === ORDER_ASC ? descendingIcon : sortOrder === ORDER_DESC ? ascendingIcon : defaultIcon)}</th>
                            <th className='align-middle' onClick={() => sortCustomers('amount')}>{t("text_amount")}{sortBy === 'amount' && (sortOrder === ORDER_ASC ? descendingIcon : sortOrder === ORDER_DESC ? ascendingIcon : defaultIcon)}</th>
                            {deletTotalEntryNot ?
                                <th className="text-right align-middle">{t("text_action")}</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedCustomers().map(customer => {
                            return (
                                <tr key={customer.dairy_customer_id}>
                                    {deletTotalEntryNot ?
                                        <td>
                                            {customer.liter ?
                                                <PrimaryCheckbox
                                                    className=""
                                                    name={customer.name}
                                                    id={customer.id}
                                                    checked={selectedItems.map(item => item.id).includes(customer.id)}
                                                    onChange={(e) => handleClick(customer.id, customer, e)}
                                                /> :
                                                null
                                            }
                                        </td> : null}
                                    <td className='align-middle'>
                                        {`${customer.code} `}
                                    </td>
                                    <td className='align-middle'>
                                        {` ${customer.name}`}
                                    </td>
                                    <td className='align-middle'>
                                        {isFeatureSubscribed && customer.liter && customer.liter != 0 ? currencyFormat(customer.liter, false) : '-'}
                                    </td>
                                    <td className='align-middle'>
                                        {isFeatureSubscribed && customer.fat ? customer.fat : '-'}
                                    </td>
                                    <td className='align-middle'>
                                        {isFeatureSubscribed && customer.clr ? customer.clr : '-'}
                                    </td>
                                    <td className='align-middle'>
                                        {isFeatureSubscribed && customer.snf ? customer.snf : '-'}
                                    </td>
                                    <td className='align-middle'>
                                        {isFeatureSubscribed && customer.liter && customer.liter != 0 ? currencyFormat(customer.rate) : '-'}
                                    </td>
                                    <td className='align-middle'>
                                        {isFeatureSubscribed && customer.liter && customer.liter != 0 ? currencyFormat(customer.total) : '-'}
                                    </td>
                                    {deletTotalEntryNot &&
                                        <td className="text-right nowrap align-middle">
                                            {isFeatureSubscribed && customer.liter ? (
                                                <>
                                                    <button
                                                        className="btn btn-outline-success btn-sm mr-1"
                                                        onClick={e => editMilk(e, customer)}>
                                                        <i className="fa fa-edit" />
                                                    </button>
                                                    <button
                                                        disabled={(cattle === "TotalCowBuffalo" || shift === "TotalMorningEvening")}
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={e => handleDelete(e, customer)}>
                                                        <i className="fa fa-trash" />
                                                    </button>
                                                </>
                                            ) : "-"}
                                        </td>}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default DairyMilkDetailTableView;
