import request from "../components/library/request";
import { errorHandler } from "../components/library/response";
import { FETCH_ADDON_FAILED, FETCH_ADDON_REQUEST, FETCH_ADDON_SUCCESS } from "../_constants/addons";

export const fetchAddonRequest = () => ({
    type: FETCH_ADDON_REQUEST
});

export const fetchAddonSuccess = payload => ({
    type: FETCH_ADDON_SUCCESS,
    payload
});

export const fetchAddonFailed = () => ({
    type: FETCH_ADDON_FAILED
});

export const fetchAddons = async (dispatch) => {
    try {
        const { status, data, message } = await request.get("/subscription/addons");
        if(status) {
            dispatch(fetchAddonSuccess(data));
        } else {
            throw message;
        }
    } catch(error) {
        dispatch(fetchAddonFailed());
        errorHandler(error);
    }
}