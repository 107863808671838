// @ts-nocheck
/* eslint-disable react/prop-types */

import React, { Fragment } from "react";
import { Box, Card, Grid, Button, InputBase, Paper } from "@material-ui/core";
import withLanguage from "../../../language/LanguageController";
import "./style.css";

const CouponComponenet = ({ t, applyCoupon, clearCoupon, onCouponCodeChange, coupon, couponCode}) => {
    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} >
                <Box className="mb-2">
                    <Card>
                        <Paper className="coupon-paper">
                            <InputBase
                                className="coupon-input"
                                placeholder={t("text_enter_coupon_placeholder")}
                                id="input-checkout-coupon"
                                readOnly={coupon ? true : false}
                                value={couponCode}
                                onChange={onCouponCodeChange}
                            />
                            {
                                coupon ? (
                                    <Fragment>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            color="secondary"
                                            className="coupon-clear-btn"
                                            onClick={clearCoupon}>
                                            {t("text_clear")}
                                        </Button>
                                    </Fragment>
                                ) : (
                                    <Button
                                        variant="contained"
                                        size="small"
                                        disableElevation
                                        className="coupon-apply-btn"
                                        onClick={applyCoupon}>
                                        {t("text_apply")}
                                    </Button>
                                )
                            }
                        </Paper>
                    </Card>
                </Box>
            </Grid>
        </Grid>
    );
};

export default withLanguage(CouponComponenet);