import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Tooltip, Cell, ResponsiveContainer, Legend } from 'recharts';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useState, useEffect, } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Loader from '../../common/Loader';
import MuiDatepicker from 'src/components/common/MuiDatepicker';
import { useBrowser, useMobile } from "src/components/library/detect-device";
import withLanguage from 'src/components/language/LanguageController';


const COLORS = ['#85c1e9', '#3498db', '#82e0aa', '#2ecc71'];
const COLORS2 = ['#2874a6', '#239b56'];
const COLORS3 = ['#F4D03F']

 const PieGraph=({t}) =>{

  const chartdata = useSelector(
    (state) => state.dairy?.daily_total
  );

  const deviceTypes = {
    isMobile: useMobile(),
    isBrowser: useBrowser(),
  };

  const filterDate = moment()
  const maxDate = moment()
  const [filter_date, setFilter_Date] = useState(filterDate.format("DD-MM-YYYY"))
  const [filterD, setFilterD] = React.useState("collection")
  const [newDate, setNewDate] = useState(filterDate)
  const [filter, setFilter] = React.useState("liter")

  const dateChangeHandler = (selectedDate) => {
    setNewDate(selectedDate)
    setFilter_Date(selectedDate.format("DD-MM-YYYY"))
  }

  const filterByDate = chartdata[filterD].find((el) => el.date === filter_date)
  const [openD, setOpenD] = React.useState(false);;
  const [open, setOpen] = React.useState(false);
  const [keyMorningCow, setKeyMorningCow] = useState(+filterByDate?.morningCowMilk);
  const [keyMorningBuffalo, setKeyMorningBuffalo] = useState(+filterByDate?.morningBuffaloMilk);
  const [keyMorningTotal, setKeyMorningTotal] = useState(+filterByDate?.morningTotalMilk);
  const [keyEveningCow, setKeyEveningCow] = useState(+filterByDate?.eveningCowMilk);
  const [keyEveningBuffalo, setKeyEveningBuffalo] = useState(+filterByDate?.eveningBuffaloMilk);
  const [keyEveningTotal, setKeyEveningTotal] = useState(+filterByDate?.eveningTotalMilk);
  const [keyDailytotal, setKeyDailytotal] = useState(+filterByDate?.dailyTotalMilk);
  const [labelMorningCow, setLabelMorningCow] = useState("Morning Cow Milk");
  const [labelMorningBuffalo, setLabelMorningBuffalo] = useState("Morning Buffalo Milk");
  const [labelMorningTotal, setLabelMorningTotal] = useState("Morning Total Milk");
  const [labelEveningCow, setLabelEveningCow] = useState("Evening Cow Milk");
  const [labelEveningBuffalo, setLabelEveningBuffalo] = useState("Evening Buffalo Milk");
  const [labelEveningTotal, setLabelEveningTotal] = useState("Evening Total Milk");
  const [labelDailytotal, setLabelDailytotal] = useState("Daily Total Milk");
  const [filterOpen, setFilterOpen] = useState(false);
  useEffect(() => {
    setKeyMorningCow(+filterByDate?.morningCowMilk);
    setKeyMorningBuffalo(+filterByDate?.morningBuffaloMilk);
    setKeyMorningTotal(+filterByDate?.morningTotalMilk);
    setKeyEveningCow(+filterByDate?.eveningCowMilk);
    setKeyEveningBuffalo(+filterByDate?.eveningBuffaloMilk);
    setKeyEveningTotal(+filterByDate?.eveningTotalMilk);
    setKeyDailytotal(+filterByDate?.dailyTotalMilk);

  }, [filterByDate])
  const dataInner = [
    { name: labelMorningCow, value: keyMorningCow },
    { name: labelMorningBuffalo, value: keyMorningBuffalo },
    { name: labelEveningCow, value: keyEveningCow },
    { name: labelEveningBuffalo, value: keyEveningBuffalo }
  ]
  const dataMiddle = [
    { name: labelMorningTotal, value: keyMorningTotal },
    { name: labelEveningTotal, value: keyEveningTotal },
  ];
  const dataOuter = [
    { name: labelDailytotal, value: keyDailytotal },
  ];

  const chartstyle = {
    height: "400px",
    display: "flex",
    alignItems: " center",
    justifyContent: "center",

  };
  const chartstyleMobile = {
    display: "flex",
    alignItems: " center",
    justifyContent: "center",
    height: "300px"
  };

  const useStyles = makeStyles((theme) => ({
    div: {
      width: "300px",
      display: "flex",
      alignItems: " center",
      justifyContent: "center",
      flexDirection: "row-reverse",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }));

  const classes = useStyles();
  const handleChange = (event) => {
    setFilter(event.target.value);
    setGraphFilter(event.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChanged = (event) => {
    setFilterD(event.target.value)
  };


  const handleCloseD = () => {
    setOpenD(false);
  };

  const handleOpenD = () => {
    setOpenD(true);
  };

  const handlePieData = () => {
    setFilterOpen(!filterOpen)
  }

  const setGraphFilter = (data) => {
    switch (data) {
      case "fat":
        setKeyMorningCow(+filterByDate?.avgMorningCowFat);
        setKeyMorningBuffalo(+filterByDate?.avgMorningBuffaloFat);
        setKeyMorningTotal(+filterByDate?.avgMorningTotalFat);
        setKeyEveningCow(+filterByDate?.avgEveningCowFat);
        setKeyEveningBuffalo(+filterByDate?.avgEveningBuffaloFat);
        setKeyEveningTotal(+filterByDate?.avgEveningTotalFat);
        setKeyDailytotal(+filterByDate?.avgDailyTotalFat)
        break;

      case "liter":
        setKeyMorningCow(+filterByDate?.morningCowMilk);
        setKeyMorningBuffalo(+filterByDate?.morningBuffaloMilk);
        setKeyMorningTotal(+filterByDate?.morningTotalMilk);
        setKeyEveningCow(+filterByDate?.eveningCowMilk);
        setKeyEveningBuffalo(+filterByDate?.eveningBuffaloMilk);
        setKeyEveningTotal(+filterByDate?.eveningTotalMilk);
        setKeyDailytotal(+filterByDate?.dailyTotalMilk)

        break;
      case "clr":
        setKeyMorningCow(+filterByDate?.avgMorningCowCLR);
        setKeyMorningBuffalo(+filterByDate?.avgMorningBuffaloCLR);
        setKeyMorningTotal(+filterByDate?.avgMorningTotalCLR);
        setKeyEveningCow(+filterByDate?.avgEveningCowCLR);
        setKeyEveningBuffalo(+filterByDate?.avgEveningBuffaloCLR);
        setKeyEveningTotal(+filterByDate?.avgEveningTotalCLR);
        setKeyDailytotal(+filterByDate?.avgDailyTotalCLR);

        break;
      case "snf":
        setKeyMorningCow(+filterByDate?.avgMorningCowSNF);
        setKeyMorningBuffalo(+filterByDate?.avgMorningBuffaloSNF);
        setKeyMorningTotal(+filterByDate?.avgMorningTotalSNF);
        setKeyEveningCow(+filterByDate?.avgEveningCowSNF);
        setKeyEveningBuffalo(+filterByDate?.avgEveningBuffaloSNF);
        setKeyEveningTotal(+filterByDate?.avgEveningTotalSNF);
        setKeyDailytotal(+filterByDate?.avgDailyTotalSNF)

        break;
      case "rate":
        setKeyMorningCow(+filterByDate?.avgMorningCowRate);
        setKeyMorningBuffalo(+filterByDate?.avgMorningBuffaloRate);
        setKeyMorningTotal(+filterByDate?.avgMorningTotalRate);
        setKeyEveningCow(+filterByDate?.avgEveningCowRate);
        setKeyEveningBuffalo(+filterByDate?.avgEveningBuffaloRate);
        setKeyEveningTotal(+filterByDate?.avgEveningTotalRate);
        setKeyDailytotal(+filterByDate?.avgDailyTotalRate)

        break;
      case "total":
        setKeyMorningCow(+filterByDate?.morningCowAmount);
        setKeyMorningBuffalo(+filterByDate?.morningBuffaloAmount);
        setKeyMorningTotal(+filterByDate?.morningTotalAmount);
        setKeyEveningCow(+filterByDate?.eveningCowAmount);
        setKeyEveningBuffalo(+filterByDate?.eveningBuffaloAmount);
        setKeyEveningTotal(+filterByDate?.eveningTotalAmount);
        setKeyDailytotal(+filterByDate?.dailyTotalAmount)

        break;
      case "entries":
        setKeyMorningCow(+filterByDate?.morningCowEntries);
        setKeyMorningBuffalo(+filterByDate?.morningBuffaloEntries);
        setKeyMorningTotal(+filterByDate?.morningTotalEntries);
        setKeyEveningCow(+filterByDate?.eveningCowEntries);
        setKeyEveningBuffalo(+filterByDate?.eveningBuffaloEntries);
        setKeyEveningTotal(+filterByDate?.eveningTotalEntries);
        setKeyDailytotal(+filterByDate?.dailyTotalEntries)

        break;
      default:
        break;
    }
  };

  useEffect(() => { setGraphFilter(filter) }, [filter, filterD, filter_date]);
  return (
    <>
      <div className="d-md-flex justify-content-md-between ">
        <div className='col-md-4 col-sm-12  mt-3 '>
          <MuiDatepicker
            className="datepicked"
            value={newDate}
            maxDate={maxDate}
            onChange={dateChangeHandler}
            name="filter_date"
          />
        </div>
        <div className="w-md-20 d-flex w-sm-100 justify-content-center my-0" id="select_option" >
          <div >
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-controlled-open-select-label">
                Select filter
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={filter}
                onChange={handleChange}
              >
                <MenuItem value="liter">Liter</MenuItem>
                <MenuItem value="fat">Fat</MenuItem>
                <MenuItem value="clr">CLR</MenuItem>
                <MenuItem value="snf">SNF</MenuItem>
                <MenuItem value="rate">Rate</MenuItem>
                <MenuItem value="total">Total</MenuItem>
                <MenuItem value="entries">Entries</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-controlled-open-select-label">
                Select filter
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={openD}
                onClose={handleCloseD}
                onOpen={handleOpenD}
                value={filterD}
                onChange={handleChanged}
                defaultValue={filterD}>
                <MenuItem value="collection">Collection</MenuItem>
                <MenuItem value="distribution">Distribution</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>


      <div style={!deviceTypes.isMobile ? chartstyle : chartstyleMobile}>
        {chartdata.collection.length == 0 && chartdata.distribution.length == 0 ? (
          <Loader />
        ) : ((filterByDate && chartdata[filterD].length != 0 ? (
          <ResponsiveContainer width="95%" height="100%" id="pieGraph">

            <PieChart onClick={deviceTypes.isMobile && handlePieData}>
              {!deviceTypes.isMobile && <Legend
                layout="horizontal" align="center" verticalAlign="bottom" />}
              <Pie
                data={dataInner}
                cx="50%"
                cy="50%"
                labelLine={false}

                label={({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  value,
                  index }) => {

                  const RADIAN = Math.PI / 180;
                  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);

                  return (

                    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                      {value.toFixed(2)}
                    </text>

                  )
                }}
                fill="#8884d8"
                dataKey="value"
                outerRadius="40%"
              >
                {dataInner.map((entry, index) => {
                  return (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  )
                }
                )}

              </Pie>
              <Pie
                data={dataMiddle}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius="47%"
                outerRadius="63%"
                fill="#82ca9d"
                label={({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  value,
                  index }) => {
                  const RADIAN = Math.PI / 180;
                  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);

                  return (
                    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                      {value.toFixed(2)}
                    </text>
                  );
                }}

              >
                {dataMiddle.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS2.length]} />
                ))}
              </Pie>
              <Pie
                data={dataOuter}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius="70%"
                outerRadius="85%"
                fill="#82ca9d"
                label={({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  value,
                  index }) => {
                  const RADIAN = Math.PI / 180;
                  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);
                  return (
                    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                      {value.toFixed(2)}
                    </text>
                  );
                }}

              >

                {dataOuter.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS3[index % COLORS3.length]} />
                ))}
              </Pie>

            </PieChart>
          </ResponsiveContainer>) : <p className='no_data'>{t("text_no_data_available")}</p>)
        )
        }

      </div>
      {deviceTypes.isMobile && filterOpen && (
        <div className='pie_container'>
          <p>
            <span>{labelMorningCow}</span>
            <span>{filterByDate?.morningCowMilk}</span>
          </p>
          <p>
            <span>{labelMorningBuffalo}</span>{" "}
            <span>{filterByDate?.morningBuffaloMilk}</span>
          </p>
          <p>
            <span>{labelMorningTotal}</span>
            <span>{filterByDate?.morningTotalMilk}</span>
          </p>
          <p>
            <span>{labelEveningCow}</span>{" "}
            <span>{filterByDate?.eveningCowMilk}</span>
          </p>
          <p>
            <span>{labelEveningBuffalo}</span>{" "}
            <span>{filterByDate?.eveningBuffaloMilk}</span>
          </p>
          <p>
            <span>{labelEveningTotal}</span>{" "}
            <span>{filterByDate?.eveningTotalMilk}</span>
          </p>
          <p>
            <span>{labelDailytotal}</span>{" "}
            <span>{filterByDate?.dailyTotalMilk}</span>
          </p>
        </div>
      )}
    </>
  );
}

export default withLanguage(PieGraph)