import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import BuffaloWhiteIcon from "src/components/common/icons/BuffaloWhiteIcon";
import CowWhiteIcon from "src/components/common/icons/CowWhiteIcon";
import { getShift } from "src/components/library/milkUtils";
import MuiDatepicker from "../../../../common/MuiDatepicker";
import BuffaloBlackIcon from "../../../../common/icons/BuffaloBlackIcon";
import CowBlackIcon from "../../../../common/icons/CowBlackIcon";
import withLanguage from "../../../../language/LanguageController";
import SplitButton from "./DropdownButton";

const Filter = ({
    t,
    cattle,
    shift,
    startDate,
    endDate,
    customerType,
    customerTypeChangeHandler,
    dateChangeHandler,
    cattleChangeHandler,
    shiftChangeHandler
}) => {

    const [cattleState, setCattleState] = useState(cattle);
    const [shiftState, setShiftState] = useState(getShift());

    useEffect(() => {
        setCattleState(cattle);
    }, [cattle]);

    useEffect(() => {
        setShiftState(shift);
    }, [shift]);

    const sellerBuyer = [
        { value: "seller", shift: "seller", label: t("text_seller") },
        { value: "buyer", shift: "buyer", label: t("text_buyer") }
    ];

    const CattleChange = [
        { value: "cow", shift: "cow", label: t("text_cow"), icon: <CowBlackIcon className="mr-2" />, selectedIcon: <CowWhiteIcon className="mr-2" /> },
        { value: "buffalo", shift: "buffalo", label: t("text_buffalo"), icon: <BuffaloBlackIcon className="mr-2" />, selectedIcon: <BuffaloWhiteIcon className="mr-2" /> },
        { value: "TotalCowBuffalo", shift: "TotalCowBuffalo", totalLabel: t("text_total"), icon3: <><CowBlackIcon className="mr-2" /> + </>, icon2: <><BuffaloBlackIcon className=" ml-2 mr-2" /></>, selectedIcon: <><CowWhiteIcon className="mr-2" /> + </>, selectedIcon2: <><BuffaloWhiteIcon className=" ml-2 mr-2" /></> }
    ];

    const shiftChange = [
        { value: "morning", shift: 0, label: t("text_morning"), icon: <i className="fa fa-sun mr-2"></i>, selectedIcon: <i className="fa fa-sun mr-2"></i> },
        { value: "evening", shift: 1, label: t("text_evening"), icon: <i className="fa fa-moon mr-2"></i>, selectedIcon: <i className="fa fa-moon mr-2"></i> },
        { value: "TotalMorningEvening", shift: "TotalMorningEvening", label: "+", totalLabel: t("text_total"), icon3: <i className="fa fa-sun mr-2"></i>, icon2: <i className="fa fa-moon mr-2 ml-2"></i>, selectedIcon: <i className="fa fa-sun mr-2"></i>, selectedIcon2: <i className="fa fa-moon mr-2 ml-2"></i> }
    ];

    const handleShiftChange = (newShift) => {
        const shiftValue = newShift === "morning" ? 0 : newShift === "evening" ? 1 : newShift;
        setShiftState(shiftValue);
        shiftChangeHandler(shiftValue);
    };

    const shiftValue = shiftState === 0 ? "morning" : shiftState === 1 ? "evening" : shiftState;

    return (
        <>
            <div className="d-flex justify-content-between mb-2">
                <div className="flex-fill d-none d-sm-block">
                    <span className="d-inline-flex">
                        <div>
                            <SplitButton options={sellerBuyer} onClick={customerTypeChangeHandler} defaultValue={customerType} />
                        </div>
                        <div>
                            <SplitButton options={shiftChange} onClick={handleShiftChange} defaultValue={shiftValue} />
                        </div>
                        <div>
                            <SplitButton options={CattleChange} onClick={cattleChangeHandler} defaultValue={cattleState} />
                        </div>
                        
                    </span>
                    
                </div>
                <div className="flex-fill d-sm-none">
                    <div>
                        <SplitButton options={sellerBuyer} onClick={customerTypeChangeHandler} />
                    </div>
                </div>
                <div>
                    <MuiDatepicker
                        value={startDate}
                        maxDate={endDate}
                        onChange={selectedDate => dateChangeHandler("start_date", selectedDate)}
                        name="start_date"
                        inputClass="bg-white"
                    />
                </div>
            </div>
            <div className="d-flex d-sm-none justify-content-between mt-1 mb-2">

            <div>
                    <SplitButton options={shiftChange} onClick={handleShiftChange} defaultValue={shiftValue} />
                </div>
                <div>
                    <SplitButton options={CattleChange} onClick={cattleChangeHandler} defaultValue={cattleState} />
                </div>
                
            </div>
        </>
    );
};

Filter.propTypes = {
    t: PropTypes.func.isRequired,
    customerType: PropTypes.string.isRequired,
    cattle: PropTypes.string.isRequired,
    shift: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    customerTypeChangeHandler: PropTypes.func.isRequired,
    dateChangeHandler: PropTypes.func.isRequired,
    cattleChangeHandler: PropTypes.func.isRequired,
    shiftChangeHandler: PropTypes.func.isRequired,
    filterChangeHandler: PropTypes.func.isRequired
};

export default withLanguage(Filter);
