import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Fab } from '@material-ui/core';

const PrimaryFab = withStyles(theme => ({
    root: {
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
          backgroundColor: green[600],
        },
    },
}))((props) => <Fab color="default" {...props} />);

export default PrimaryFab;