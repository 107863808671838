// @ts-nocheck
import React, { Fragment } from "react";
import CustomerAccountController from "./CustomerAccountController";
import { connect } from "react-redux";
import InnerNav from "../../InnerNav";
import withLanguage from "../../../../language/LanguageController";
import BillDatePicker from "../BillDatePicker";
import AdvancePaymentModal from '../../modalPopup/AdvancePaymentModal';
import ProductModal from '../../modalPopup/ProductModal';
import CustomerBillMilkTotalModal from '../../modalPopup/CustomerBillMilkTotalModal';
import { currencyFormat } from "../../../../library/util";
import { DefaultLayout } from "src/components/pages/common/Layout";

class CustomerAccount extends CustomerAccountController {

    renderFixedComponents = () => {
        let { startDate, endDate } = this.state;
        let { dairyId, dairyCustomerId } = this.props;

        return (
            <Fragment>
                {this.state.product_modal ? (
                    <ProductModal
                        t={this.props.t}
                        isOpen={this.state.product_modal}
                        onClose={this.toggleProductModal}
                        dairyId={dairyId}
                        dairyCustomerId={dairyCustomerId}
                        startDate={startDate}
                        endDate={endDate}/>
                ) : null}
                {this.state.advance_payment_modal ? (
                    <AdvancePaymentModal
                        t={this.props.t}
                        isOpen={this.state.advance_payment_modal}
                        onClose={this.toggleAdvancePaymentModal}
                        dairyId={dairyId}
                        dairyCustomerId={dairyCustomerId}
                        startDate={startDate}
                        endDate={endDate}/>
                ) : null}
                {this.state.milk_total_modal ? (
                    <CustomerBillMilkTotalModal
                        isOpen={this.state.milk_total_modal}
                        onClose={this.toggleMilkTotalModal}
                        dairyId={dairyId}
                        dairyCustomerId={dairyCustomerId}
                        startDate={startDate}
                        endDate={endDate}/>
                ) : null}
            </Fragment>
        );
    }

    render() {
        const {
            milk_amount,
            advance_payment,
            last_due,
            product_amount,
            payable_amount,
            net_payable,
            paid_amount,
            remaining,
            transferd_to_loan,
            cash_recovered
        } = this.state;
        const {
            t,
            dairyId,
            dairyCustomerId,
            customerId,
            dairyType,
            billingCycle,
            header,
            innerNavigation,
            bottomGutter
        } = this.props;

        return (
            <DefaultLayout
                back
                bottomGutter={bottomGutter}
                header={header}
                title={t("text_your_bill")}
                toolbar={false}
                onDeviceChange={this.onDeviceChange}
            >
                <div className="t-card shadow-sm">
                    <div className="title right card__title card--material__title mt-0">
                        <BillDatePicker
                            billingCycle={billingCycle}
                            onChange={this.onDateChange}
                        />
                    </div>
                    <div className="content card__content card--material__content">
                        <div className="d-account-h1">
                            {t("text_milk_bill")}
                        </div>
                        <table className="table mb-2">
                            <tbody>
                                <tr>
                                    <td>
                                        {t("text_last_due")}
                                    </td>
                                    <td className="text-right">
                                        <span className="text-green">{currencyFormat(last_due)}</span>
                                    </td>
                                </tr>
                                <tr onClick={this.toggleMilkTotalModal}>
                                    <td className="border-black">{t("text_milk_amount")}</td>
                                    <td className="border-black text-right">{currencyFormat(milk_amount)}</td>
                                </tr>
                                
                                <tr onClick={this.toggleProductModal}>
                                    <td>
                                        {t("text_product_purchase")}
                                    </td>
                                    <td className="text-right">
                                        <span className="text-red">{currencyFormat(product_amount)}</span>
                                    </td>
                                </tr>
                                <tr onClick={this.toggleAdvancePaymentModal}>
                                    <td>{t("text_advance")}</td>
                                    <td className="text-right">
                                        <span className="text-red">{currencyFormat(advance_payment)}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{dairyType == "seller" ? t("text_payable_amount") : t("text_receivable_amount")}</td>
                                    <td className="text-right">{currencyFormat(payable_amount)}</td>
                                </tr>
                                <tr>
                                    <td className="border-black">{dairyType == "seller" ? t("text_net_payable") : t("text_net_receivable")}</td>
                                    <td className="text-right border-black">{currencyFormat(net_payable)}</td>
                                </tr>
                                {transferd_to_loan != "0.00" ? (
                                    <tr>
                                        <td>{t("text_transferred_to_loan")}</td>
                                        <td className="text-right">{currencyFormat(transferd_to_loan)}</td>
                                    </tr>
                                ) : null}
                                {cash_recovered != "0.00" ? (
                                    <tr>
                                        <td>{dairyType == "seller" ? t("text_cash_recovered") : t("text_received_amount")}</td>
                                        <td className="text-right">
                                            <span className="text-green">{currencyFormat(cash_recovered)}</span>
                                        </td>
                                    </tr>
                                ) : null}
                                {dairyType == "seller" ? (
                                <tr>
                                    <td>{dairyType == "seller" ? t("text_paid_amount") : t("text_received_amount")}</td>
                                    <td className="text-right">
                                        <span className="text-green">{currencyFormat(paid_amount)}</span>
                                    </td>
                                </tr>
                                ) : null}
                                <tr>
                                    <td>
                                        <div>
                                            {t("text_remaining_amount")}
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        <span className="text-red">
                                            {currencyFormat(remaining)}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {innerNavigation ? <InnerNav 
                    menu="kisan_customer"
                    page="bill"
                    navigator={this.props.navigator}
                    dairyCustomerId={dairyCustomerId}
                    customerId={customerId}
                    dairyId={dairyId}
                    dairyType={dairyType} /> : null}
                {this.renderFixedComponents()}
            </DefaultLayout>
        );
    }
}

CustomerAccount.displayName = "CustomerAccount";

CustomerAccount.defaultProps = {
    bottomGutter: true,
    header: true,
    innerNavigation: true
};

const mapStateToProps = state => ({
    dairyId: state.dairy.dairy.dairy_id,
    dairyCustomerId: state.dairy.dairy.dairy_customer_id,
    dairyType: state.dairy.dairy.type,
    billingCycle: state.dairy.dairy.billing_cycle
});

export default connect(mapStateToProps)(withLanguage(CustomerAccount));