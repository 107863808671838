// @ts-nocheck
import React from "react";
import { DatePicker } from "@material-ui/pickers";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider,   } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import config from "../../config/";
import { Fragment } from "react";
import moment from "moment";
import { useBrowser } from "../library/detect-device";
import { useDispatch } from "react-redux";
import { togglePremiumPopup } from "../../actions";

const materialTheme = createTheme ({
    overrides: {
        MuiDialog: {
            root: {
                zIndex: 10001
            }
        },
        MuiFormControl: {
            root: {
                maxWidth: "100%"
            } 
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#6e78f7",
                backgroundImage: "linear-gradient(61deg, #0ba52a 8%, #6e78f7 91%)"
            }
        },
        MuiPickersToolbarText: {
            toolbarTxt: {
                color: "#fff"
            }
        },
        MuiInput: {
            underline: {
                "&::after": {
                    borderBottom: "2px solid #6e78f7"
                }
            }
        },
        MuiPickersDay: {
            daySelected: {
                backgroundColor: "#6e78f7",
                "&:hover": {
                    backgroundColor: "#6e78f7",
                }
            } 
        },
        MuiButton: {
            textPrimary: {
                color: "#6e78f7"
            }
        }
    },
});

const renderInput = ({
    label,
    value,
    onClick,
    name,
    id,
    inputClass = "",
    arrowButtons,
    incrementDate,
    decrementDate,
    inputRef
}) => {
    const elements = [];
    if(arrowButtons) {
        elements.push(
            <div className="input-group-prepend" key="previous-date">
                <button
                    className="btn btn-outline-secondary border-secondary-light"
                    type="button"
                    onClick={decrementDate}>
                    <i className="fa fa-chevron-left"></i>
                </button>
            </div>
        );
    }
    elements.push(
        <input
            key="date"
            type="text"
            readOnly
            className={`form-control MuiInput--datepicker text-center ${inputClass}`}
            id={id}
            value={value}
            onClick={onClick}
            name={name}
            ref={inputRef}
        />
    );
    if(arrowButtons) {
        elements.push(
            <div className="input-group-append" key="next-date">
                <button
                    className="btn btn-outline-secondary border-secondary-light"
                    type="button"
                    onClick={incrementDate}>
                    <i className="fa fa-chevron-right"></i>
                </button>
            </div>
        );
    }
    return (
        <Fragment>
            {label ? <label htmlFor={id}>{label}</label> : null}
            {arrowButtons 
                ? <div className="input-group">{elements}</div>
                : elements}
        </Fragment>
    );
};

const MuiDatepicker = (props) => {
    const dispatch = useDispatch();
    const { label, arrowButtons, minDateFeatureLimit, ...restProps} = props;

    const incrementDate = () => {
        let allowChange = true;
        const currentDate = moment(restProps.value);
        if(restProps.maxDate) {
            allowChange = currentDate.isBefore(restProps.maxDate, 'day') ? true : false;
        }
        if(allowChange) {
            const newDate = currentDate.add(1, 'd');
            restProps.onChange(newDate);
        }
    };

    const decrementDate = () => {
        let allowChange = true;
        const currentDate = moment(restProps.value);
        if(restProps.minDate){
            allowChange = currentDate.isAfter(restProps.minDate, 'day') ? true : false;
            if(!allowChange && minDateFeatureLimit) {
                dispatch(togglePremiumPopup());
            }
        }
        if(allowChange) {
            const newDate = currentDate.subtract(1, 'd');
            restProps.onChange(newDate);
        }
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={materialTheme}>
                <DatePicker
                    animateYearScrolling
                    inputVariant="outlined"
                    TextFieldComponent={inputProps => renderInput({
                        ...inputProps,
                        label,
                        arrowButtons,
                        incrementDate,
                        decrementDate,
                    })}
                    {...restProps}
                />
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
};

MuiDatepicker.defaultProps = {
    format: config.display_date_format,
    arrowButtons: true,
    minDateFeatureLimit: false
};

export default MuiDatepicker;
