import React, { useState } from "react";
import { Switch } from "react-onsenui";
import withLanguage from "../../../language/LanguageController";

const DefaultCLR = ({ t, rate_settings = {}, validator}) => {
    const [clrType, setClrType] = useState(rate_settings.default_clr_type);
    const [defaultCLRValue, setDefaultCLRValue] = useState(rate_settings.default_clr_value || "");
    return (
        <div className="form-group position-relative">
            <div className="form-row">
                <div className="col-6">
                    <label>{t("text_default_clr")}</label>
                </div>
                <div className="col-6 text-right">
                    {t("text_custom")}&nbsp;
                    <Switch
                        onChange={e => setClrType(e.target.checked  ? "fixed" : "custom")}
                        checked={clrType === "fixed"}
                    />
                    &nbsp;{t("text_fixed")}
                    <input
                        type="hidden"
                        name="rate_settings[default_clr_type]"
                        value={clrType}
                    />
                </div>
            </div>
            <input
                type="number"
                className="form-control"
                name="rate_settings[default_clr_value]"
                step="0.1"
                value={defaultCLRValue}
                onChange={e => setDefaultCLRValue(e.target.value)}
            />
            {validator.message("default_clr", defaultCLRValue, `numeric|min:18,num|max:40,num${clrType === "fixed" ? "|required" : ""}`)}
        </div>
    );
};

export default withLanguage(DefaultCLR);
