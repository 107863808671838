// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { addDairy } from "../../../actions/dairy";
import SimpleValidator from "../../common/SimpleValidator";
import { useLanguage } from "../../language/LanguageController";
import analytics from "../../library/firebase/analytics";
import { errorHandler } from "../../library/response";
import { DefaultLayout } from "../common/Layout";

const AddDairy = () => {
  const { t } = useLanguage();
  const validator = useRef(new SimpleValidator());
  const [name, setName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const { dairy } = useSelector((state) => state.dairy);
  const [isError, setIsError] = useState(false);
  const [redirectHomePage, setRedirectHomePage] = useState(false);

  useEffect(() => {
    analytics.setScreenName("AddDairy");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!validator.current.allValid()) {
        validator.current.showMessages();
        setIsError(!isError);
        return false;
      }
      analytics.logEvent("AddDairy");
      setIsSaving(true);
      await addDairy(dispatch, user.customer_id);
      setRedirectHomePage(true);
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsSaving(false);
    }
  };

  if (redirectHomePage) {
    return <Redirect to="/dairy/selection" />;
  }

  return (
    <DefaultLayout title={t("text_add_new_dairy")} sideNav={false}>
      <div className="form-row">
        <div className="col-12 col-sm-6 mx-auto max-width-500">
          <div className="t-card shadow-sm">
            <div className="form_add_dairy">
              <form method="post" id="AddDairyForm" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="input_dairy_name">
                    {t("text_dairy_name")}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    id="input_dairy_name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {validator.current.message(
                    "name",
                    name,
                    "required|alpha_num_space"
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="input_dairy_description">
                    {t("text_description")}
                  </label>
                  <textarea
                    className="form-control"
                    name="description"
                    id="input_dairy_description"
                    cols={40}
                    rows={5}
                  />
                </div>
                <div className="form-group mb-0">
                  <input
                    className="form-control"
                    type="submit"
                    disabled={isSaving}
                    value={isSaving ? t("text_saving") : t("text_save")}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default AddDairy;
