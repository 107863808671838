import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useLanguage } from "../../language/LanguageController";
import { readExcelFileToJSON } from "../../library/fileHelper";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#4caf50',
    borderStyle: 'dashed',
    backgroundColor: '#e4efe2',
    color: '#000000',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const ExcelFileImporter = ({ onChange }) => {
    const { t } = useLanguage();
    
    const onDrop = async files => {
        if (files && files[0]) {
            const { header, data } = await readExcelFileToJSON(files[0]);
            onChange({
                header: header,
                records: data
            });
        }
    };
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop,
        accept: {
            '.application/vnd.ms-excel': [".xls"],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [".xlsx"],
        },
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <div className="mb-3">
            <label>
                {t("text_excel_import")}
            </label>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} accept={"xlsx"} />
                <p className="mb-2">{t("text_drag_and_drop_title")}</p>
                <em>({t("text_excel_rate_list_import_file_type")})</em>
            </div>
        </div>
    )
};

export default ExcelFileImporter;