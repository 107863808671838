import React from "react";

export default function MyImage(props) {
    let defaultSrcSet = false;
    let defaultSrc = props.defaultSrc;
    const onLoadError = e => {
        const target = e.target;
        if(defaultSrc && !defaultSrcSet){
            target.src = defaultSrc;
            defaultSrcSet = true;
        }
    };
    let attrs = {...props};
    delete attrs.defaultSrc;
    return <img {...attrs} onError={onLoadError} />;
}