import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { useEffect, useRef, useState } from 'react';
import { PrimaryButton } from 'src/components/common/buttons/PrimaryButton';

export default function SplitButton({ options, onClick, defaultValue, defaultCustomer }) {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        const defaultIndex = options.findIndex(option => option.value === defaultValue || option.value === defaultCustomer );
        if (defaultIndex !== -1) {
            setSelectedIndex(defaultIndex);
        }

    }, [defaultValue,  defaultCustomer, options]);

    const handleMenuItemClick = (value, index) => {
        setSelectedIndex(index);
        setOpen(false);
        onClick(value);
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <Grid container alignItems="center">
            <Grid item xs={12}>
                <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" className='mr-1'>
                    <PrimaryButton
                        ref={anchorRef}
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        {options[selectedIndex].selectedIcon}{options[selectedIndex].label}{options[selectedIndex].selectedIcon2}{options[selectedIndex].totalLabel}<ArrowDropDownIcon />
                    </PrimaryButton>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 999 }}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                onClick={() => handleMenuItemClick(option.value, index)}
                                            >
                                                {option.icon}{option.icon3}{option.label}{option.icon2}{option.totalLabel}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
}