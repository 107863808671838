// @ts-nocheck

import * as Sentry from "@sentry/react";

let otpResolver;

export const watchOTP = () => {
    console.log("Watch OTP");
    const otpPromise = new Promise((resolve, reject) => {
        if(typeof window.SMSReceive !== "undefined") {
            window.SMSReceive.startWatch(() => {
                console.log("smsReceive: Start watching OTP");
                otpResolver = resolve;
            }, (error) => {
                Sentry.captureException(error);
                reject(new Error("smsReceive: Failed to start watching OTP"));
            });
        } else {
            console.log("SMSReceive is not defined");
        }
    });
    /* Initialize incoming SMS event listener */
    document.removeEventListener("onSMSArrive", onSMSArrive);
    document.addEventListener("onSMSArrive", onSMSArrive);
    return otpPromise;
};

export const stopWatch = () => {
    if(typeof window.SMSReceive !== "undefined") {
        window.SMSReceive.stopWatch(() => {
            console.log("smsReceive: watching stopped");
        }, error => {
            Sentry.captureException(error);
            console.warn("smsReceive: failed to stop watching");
        });
    } else {
        console.log("SMSReceive is not defined");
    }
};

const onSMSArrive = function (event) {
    console.log("onSMSArrive()");
    const incomingSMS = event.data;
    const parsedOtp = parseOtp(incomingSMS);
    if(parsedOtp) {
        otpResolver(parsedOtp);
        document.removeEventListener("onSMSArrive", onSMSArrive);
        stopWatch();
    }
};

const parseOtp = (incomingSMS) => {
    console.log("parseOtp: incomingSMS", incomingSMS);
    const { address, body } = incomingSMS;
    if(address.includes("LTROTP")) {
        const matchedOtpAry = body.match(/\d{6}/g);
        if(matchedOtpAry.length) {
            return matchedOtpAry[0];
        }
    }
};

