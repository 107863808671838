// @ts-nocheck
import React from "react";
import { Fragment } from "react";
import store from "../../../store";
import { togglePremiumPopup } from "../../../actions";
import config from "../../../config";
import moment from "moment";
import * as Sentry from "@sentry/react";
import { getSubscriptionAddons } from "src/reducers/subscription";
import { selectDairyCustomers } from "src/reducers/dairyCustomer";

class FeatureFlagHOC extends React.Component {
    constructor (props) {
        super(props);
        this.onClick = null;
        const { onClick, feature, checkLimit, ...restProp} = this.props.compoProps;
        this.onClick = onClick;
        this.feature = feature;
        this.restProp = restProp;
        this.checkLimit = checkLimit || false;
    }

    handleOnClick = (e) => {
        if(this.onClick && this.feature) {
            if(checkFeatureInSubscription(this.feature)){
                if(this.checkLimit){
                    if(checkFeatureLimit(this.feature)){
                        this.onClick(e);
                    }
                } else {
                    this.onClick(e);
                }
            }
        } else if(this.onClick) {
            this.onClick(e);
        }
    }
    
    render () {
        const WrappedComponent = this.props.component
        const { onClick, feature, checkLimit, ...restProp} = this.props.compoProps;
        return <Fragment>
            <WrappedComponent
                onClick={this.handleOnClick}
                {...restProp}
            />
        </Fragment>
    }
}

const FeatureFlagHOCS = FeatureFlagHOC;

const featureFlag = (Component) => {
    return props => {
        return (
            <Fragment>
                <FeatureFlagHOCS component={Component} compoProps={props}/>
            </Fragment>
        );
    };
};

export default featureFlag;

const getPlanFeatures = () => {
    const state = store.getState();
    const { subscription } = state.subscription;
    return (subscription
        && subscription.plan
        && subscription.plan.plan_features) ? subscription.plan.plan_features : [];
}

export const checkFeatureInSubscription = (feature, showPopup = true) => {
    // Check if subscription is expired or not
    let isFeatureIn = false;
    if(checkSubscriptionIsActive()) {
        const planFeatures = getPlanFeatures();
        isFeatureIn = planFeatures.some(plansFeature => plansFeature.feature.feature_key === feature);
        if(!isFeatureIn) {
            isFeatureIn = checkIsFeatureInAddons(feature);
        }
    } else {
        isFeatureIn = checkFeatureInFree(feature);
    }

    if(!isFeatureIn && showPopup) {
        store.dispatch(togglePremiumPopup({
            feature
        }));
    }

    return isFeatureIn;
};

const checkIsFeatureInAddons = (feature) => {
    const addOns = getSubscriptionAddons(store.getState());
    return addOns.some(addOn => addOn.add_on_key === feature);
}

const checkFeatureInFree = (feature) => {
    const state = store.getState();
    const freePlanFeaures = state.defaultReducer.free_plan_feature;
    return freePlanFeaures ? freePlanFeaures[feature] : false;
}

export const checkSubscriptionIsActive = () => {
    const state = store.getState();
    const { subscription } = state.subscription;
    return isSubscriptionActive(subscription)
};

export const isSubscriptionActive = (subscription = {}) => {
    const todayDate = moment().format(config.db_date_format);
    if(subscription && subscription.subscription_id) {
        if(subscription.is_trial == 1 
            && subscription.status === "active") {
            const endDate = subscription.trial_end_date;
            if(endDate != "0000-00-00" && moment(todayDate).isSameOrBefore(endDate)) {
                return true;
            }
        } else if(subscription.status === "active") {
            const endDate = subscription.end_date;
            if(moment(todayDate).isSameOrBefore(endDate)) {
                return true;
            }
        }
    }

    return false;
}

export const calculateFeatureMinDate = (feature) => {
    const state = store.getState();
    // Check if subscription is expired or not
    let featureValue = "last 1 day";

    if(checkSubscriptionIsActive()) {
        const planFeatures = getPlanFeatures();
        const planFeature = planFeatures.find(plansFeature => plansFeature.feature.feature_key === feature);
        featureValue = planFeature ? planFeature.feature_value : "last 1 day";
    } else {
        const freePlanFeaures = state.defaultReducer.free_plan_feature;
        featureValue = freePlanFeaures && freePlanFeaures[feature] || "last 1 day";
    }

    featureValue = featureValue.split(" ");
    let date = moment().subtract(featureValue[1], featureValue[2]);
    if(featureValue[2] === "month" || featureValue[2] === "months") {
        date = date.startOf('month');
    }
    if(feature === "add_old_milk" || feature === "edit_milk") {
        return date;
    }
    return date.format(config.date_format);
}

export const checkFeatureLimit = (feature, showPopup = true, data = null) => {
    const state = store.getState();
    let featureLimit = getFeatureLimit(feature);
    if(featureLimit === "unlimited") {
        return true;
    }
    
    let result = null;    
    switch(feature) {
        case "customer_limit":
            featureLimit = !featureLimit ? 15 : featureLimit;
            const customers = selectDairyCustomers(state);
            result = customers.length < featureLimit;
            break;
        case "rate_list_change_limit": 
            featureLimit = !featureLimit ? 5 : featureLimit;
            const limit = state.dairySettings.settings.rate_list_change_limit || 0;
            result = limit < featureLimit; 
            break;
        default:
            console.log("invalid feature!");
    }
    
    if(result === false && showPopup) {
        store.dispatch(togglePremiumPopup({
            feature: feature,
            message: data ? data.message : null
        }));
    }

    return result;
}

export const getFeatureLimit = (feature) => {
    const state = store.getState();
    let featureLimit;

    if(checkSubscriptionIsActive()) {
        const planFeatures = getPlanFeatures();
        const planFeature = planFeatures.find(plansFeature => plansFeature.feature.feature_key === feature);
        if(planFeature) {
            featureLimit = planFeature.feature_value
        }
    } else {
        const freePlanFeaures = state.defaultReducer.free_plan_feature;
        featureLimit = freePlanFeaures ? freePlanFeaures[feature] : 5;
    }

    switch(feature) {
        case "customer_limit":
            return featureLimit || 15;
        case "rate_list_change_limit": 
            return featureLimit || 5;
        default:
            return featureLimit;
    }
    
}

export const checkIsAdFree = () => {
    return checkFeatureInSubscription("ad_free", false);
}

export const isFeatureEnabled = (feature) => {
    try {
        return config.features[feature] || false;
    } catch(error) {
        Sentry.captureException(error);
    }
    return false;
}