import React from "react";
import withLanguage from "../../language/LanguageController";
import CowWhite from "./../../../assets/icons/cow-white.svg";

const CowWhiteIcon = ({ t, dispatch, ...restProps }) => {
    return <img src={CowWhite} alt={t("text_cow")} {...restProps} />;
};

CowWhiteIcon.defaultProps = {
    width: "25px"
};

export default withLanguage(CowWhiteIcon);