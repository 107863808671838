import React, { useEffect, useState } from "react";
import analytics from "../../library/firebase/analytics";
import withLanguage from "../../language/LanguageController";
import { loader } from "../../library/util";
import subscriptionService from "../../services/subscription";
import { useDispatch } from "react-redux";
import { fetchSubscription } from "../../../actions/subscriptions";
import { errorHandler } from "../../library/response";
import ModalDialog from "../../common/ModalDialog";
import { PrimaryButton } from "../../common/buttons/PrimaryButton";

const SubscriptionWithActivationKeyModal = ({ t, isOpen, onClose, renew }) => {

    const [isLoading, toggleIsLoading] = useState(false);
    const [activationKey, setActivationKey] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        if(isOpen) {
            analytics.logEvent("SubscriptionWithActivationKeyModal");
        }
    }, [isOpen]);

    const activateAccount = async event => {
        event.preventDefault();
        try {
            loader.show(t("text_loading"));
            toggleIsLoading(true);
            const { status, message } = await subscriptionService.activateAccount(activationKey, renew);
            if (status) {
                await dispatch(fetchSubscription());
                setActivationKey("");
                onClose();
            } else {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
            toggleIsLoading(false);
        }
    };

    return (
        <ModalDialog
            title={ renew ? t("text_renew_subscription") : t("text_account_activation")}
            isOpen={isOpen}
            onClose={() => {
                setActivationKey("");
                onClose();
            }}
            content={
                <div className="form-group">
                    <label htmlFor="activation_key">{t("text_activation_key")}</label>
                    <input
                        name="activation_key"
                        type="text"
                        id="activation_key"
                        className="form-control"
                        value={activationKey}
                        onChange={e => setActivationKey(e.target.value)}
                        placeholder={t("text_activation_key_placeholder")}
                    />
                </div>
            }
            dialogActions={
                <PrimaryButton
                    variant="contained"
                    disabled={isLoading}
                    onClick={activateAccount}>
                    {isLoading ? t("text_loading") : t("text_activate")}
                </PrimaryButton>
            }
        />
    );
};

export default withLanguage(SubscriptionWithActivationKeyModal);