// @ts-nocheck
import { Typography } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchAddons } from "src/actions/addons";
import {
  fetchDairies,
  fetchDairyError,
  fetchDairySuccess,
} from "../../../actions/dairy";
import {
  fetchDairySubscription,
  fetchFeatures,
  fetchOrders,
  fetchPlans,
} from "../../../actions/subscriptions";
import { checkSubscriptionIsActive } from "../../common/FeatureFlag/FeatureFlag";
import { swalPopup } from "../../common/SwalPopup";
import withLanguage from "../../language/LanguageController";
import analytics from "../../library/firebase/analytics";
import request from "../../library/request";
import SubscriptionWithActivationKeyModal from "../../modalPopups/subscriptionWithActivationKeyModal";
import { DefaultLayout } from "../common/Layout";
import { DefaultScrollbar } from "../common/Scrollbar";
import Addons from "./Addons";
import Invoice from "./Invoice/index";
import Offers from "./Offers";
import OrderDetailsPopup from "./OrderDetailsPopup";
import OrdersList from "./Orders/OrdersList";

class AppSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentData: {},
      renew: false,
      is_reloading: false,
      account_activation_modal: false,
    };
  }

  componentDidMount() {
    analytics.setScreenName("AppSubscription");
    const { paymentSuccess = false } = this.props.history.location.state || {};
    // previously after payout success it fetch by customer ID
    const { dairyId } = this.props;

    if (dairyId) {
      this.props.dispatch(fetchOrders(paymentSuccess, dairyId));
    }

    this.props.dispatch(fetchPlans());
    this.props.dispatch(fetchFeatures());
    fetchAddons(this.props.dispatch);
    if (paymentSuccess) {
      this.showOrderSuccess();
      this.fetchDairy(dairyId);
    }

    if (this.props.history?.location?.state?.paymentSuccess) {
      let state = { ...this.props.history.location.state };
      delete state.paymentSuccess;
      this.props.history.replace({ ...this.props.history.location, state });
    }
  }

  fetchDairy = async (customerId) => {
    const { dispatch } = this.props;
    const { dairyId } = this.props;
    try {
      const { status, data } = await request.get("/dairies/" + dairyId, {
        withAuth: true,
        version: "v2",
      });
      if (status === true) {
        dispatch(fetchDairySuccess(data));
        console.log("fetchDairyData", data);
      } else {
        dispatch(fetchDairySuccess({}));
      }
    } catch (error) {
      dispatch(fetchDairyError());
    }
  };

  refreshSubscription = (isFetchDairy = false) => {
    (async () => {
      this.setState({ is_reloading: true });
      const dairyId = this.props.dairyId;
      if (!dairyId) {
        console.log("dairyId is undefined");
        this.setState({ is_reloading: false });
        return;
      }
      await fetchDairySubscription(this.props.dispatch, dairyId);
      const fetchOrdersAction = fetchOrders(false, dairyId);
      await fetchOrdersAction(this.props.dispatch);
      if (isFetchDairy === true) {
        await fetchDairies(this.props.dispatch, dairyId);
      }
      this.setState({ is_reloading: false });
    })();
  };

  showOrderSuccess = () => {
    analytics.logEvent("showOrderSuccess");
    const { t } = this.props;
    const {
      order = {},
      plan = {},
      add_ons = [],
      subscription_plans = [],
    } = this.props.subscription;
    let { order_id, integration_payment_response } = order;
    if (typeof integration_payment_response === "string") {
      integration_payment_response = JSON.parse(integration_payment_response);
    }
    const Content = () => (
      <OrderDetailsPopup
        orderId={order_id}
        order={order}
        plan={plan}
        paymentDetail={integration_payment_response || {}}
        t={t}
        addOns={add_ons}
        plans={subscription_plans}
      />
    );
    swalPopup(Content, {
      title: integration_payment_response
        ? t("text_payment_successfull")
        : t("text_plan_changed_success"),
      icon: "success",
      confirmButtonText: t("text_close"),
      confirmButtonColor: "#4caf50",
      showCancelButton: false,
      allowOutsideClick: false,
      customClass: {
        title: "payment-success-title",
      },
    }).then((value) => {
      console.log("button clicked", value);
    });
  };

  renew = (event) => {
    event.preventDefault();
    analytics.logEvent("renewSubscription");
    if (this.props.user.referral_code) {
      this.toogleAccountActivationModal(true);
    } else {
      const { subscription, plans, addOns } = this.props;
      const subscribedPlan = plans.find(
        (plan) => plan.plan_id === subscription.plan.plan_id
      );
      const subscribedAddons = addOns.map((addOn) => addOn.add_on_id);
      const duration = subscription?.order?.duration || 12;
      this.props.history.push({
        pathname: this.state.isMobile
          ? "/subscription/billingCycle"
          : "/subscription/checkout",
        state: {
          plan: subscribedPlan,
          duration: duration,
          selectedDuration: duration,
          minDuration: 0,
          selectedAddons: subscribedAddons,
          renew: true,
        },
      });
    }
  };

  buyNowAddOn = (addOnId) => {
    const { subscription } = this.props;
    let duration = 12;
    if (subscription.order) {
      duration = subscription.order.duration;
    }
    this.props.history.push({
      pathname: "/subscription/checkout",
      state: {
        selectedAddons: [addOnId],
        duration: duration,
        selectedDuration: duration,
        minDuration: 0,
      },
    });
  };

  upgrade = (event) => {
    event.preventDefault();
    analytics.logEvent("changePlan");
    const { subscription } = this.props;
    this.props.history.push({
      pathname: "/subscription/plans",
      state: {
        upgrade: subscription.plan ? true : false,
      },
    });
  };

  toogleAccountActivationModal = (renew = false) => {
    this.setState({
      account_activation_modal: !this.state.account_activation_modal,
      renew: renew,
    });
  };

  renderModal = () => {
    return (
      <SubscriptionWithActivationKeyModal
        isOpen={this.state.account_activation_modal}
        onClose={this.toogleAccountActivationModal}
        renew={this.state.renew}
      />
    );
  };

  onDeviceChange = (deviceDetail) => {
    this.setState({
      ...deviceDetail,
    });
  };

  render() {
    const { t, subscription, user, showReferralToDealerCustomer } = this.props;
    const {
      add_ons,
      is_trial,
      balance = 0,
      days = {},
      trial_days = {},
      end_date = null,
      trial_end_date,
      order = {},
      plan = null,
      activation_key_id,
      activation_key,
      subscription_plans = [],
    } = subscription;
    const { is_reloading, isTablet, isMobile } = this.state;
    const isSubActive = checkSubscriptionIsActive();
    return (
      <DefaultLayout
        title={t("text_subscription")}
        sideNav={false}
        bottomGutter={false}
        onDeviceChange={this.onDeviceChange}
      >
        <div className="main-section-2">
          <div className="form-row mb-2">
            <div className="col-12 col-md-6">
              <div className="form-row mb-2">
                <div className="col">
                  <Invoice
                    user={user}
                    plan={plan}
                    activation_key_id={activation_key_id}
                    activation_key={activation_key}
                    is_trial={is_trial}
                    order={order}
                    trial_end_date={trial_end_date}
                    end_date={end_date}
                    balance={balance}
                    isSubActive={isSubActive}
                    trial_days={trial_days}
                    days={days}
                    showReferralToDealerCustomer={showReferralToDealerCustomer}
                    toogleAccountActivationModal={
                      this.toogleAccountActivationModal
                    }
                    refreshSubscription={this.refreshSubscription}
                    onRenew={this.renew}
                    onUpgrade={this.upgrade}
                    is_reloading={is_reloading}
                    subscriptionPlans={subscription_plans}
                  />
                </div>
              </div>
              {add_ons.length === 0 &&
                Number(plan?.plan_id) === 4 &&
                isSubActive && (
                  <div className="form-row mb-2">
                    <div className="col">
                      <Addons
                        showSelect={false}
                        buyNow
                        onBuyNow={this.buyNowAddOn}
                      />
                    </div>
                  </div>
                )}
              <div className="form-row">
                <div className="col">
                  <div className="form-row justify-content-center">
                    <Typography
                      component="h2"
                      variant={isMobile ? "h5" : "h4"}
                      className="text-blue-light mb-2"
                    >
                      {t("text_exciting_offers_just_for_you")}
                    </Typography>
                    <div className="col-12">
                      <div className="form-row justify-content-center">
                        <Offers />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isTablet && (
              <div className="col-12 col-md-6">
                <Typography
                  component="h2"
                  variant={isMobile ? "h5" : "h4"}
                  className="mb-2"
                >
                  {t("text_orders")}
                </Typography>
                <div className="col px-0">
                  <DefaultScrollbar
                    className="shadow-sm subscription-orders-list-scrollbar"
                    isEnabled={undefined}
                  >
                    <OrdersList />
                  </DefaultScrollbar>
                </div>
              </div>
            )}
          </div>
        </div>
        {this.renderModal()}
      </DefaultLayout>
    );
  }
}

AppSubscription.displayName = "AppSubscription";

const mapStateToProp = (state) => ({
  dairy: state.dairy.dairy,
  dairyId: state.dairy?.dairy?.id,
  paymentData: state.dairy.subscription,
  subscription: state.subscription.subscription,
  plans: state.subscription.plans,
  addOns: state.subscription?.subscription?.add_ons || [],
  features: state.subscription.features,
  fetching_payment_order: state.subscription.fetching_payment_order,
  user: state.userReducer.user,
  showReferralToDealerCustomer:
    state.defaultReducer.show_referral_to_dealer_customer,
});

export default connect(mapStateToProp)(
  withRouter(withLanguage(AppSubscription))
);
