import React, { Fragment } from "react";
import { Modal, Icon as OnsenIcon } from "react-onsenui";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import withLanguage from "../../../language/LanguageController";

const SelectContactModal = ({ t, onClose, onSelect, isOpen, contacts }) => {
    return (
        <Modal isOpen={isOpen} animation="fade" className="SalePremiumPopup">
            <div className="modal_content">
                <div className="modal_header">
                    <div className="modal_title">
                        {t("text_choose_number")}
                    </div>
                    <button type="button" className="modal_close" onClick={onClose}>
                        <OnsenIcon icon="md-close"/>
                    </button>
                </div>
                <div className="modal_body text-center">
                    <List component="nav" aria-label="main mailbox folders" dense>
                        {contacts.map((contact, index) => 
                            <Fragment key={contact.value}>
                                <ListItem
                                    button 
                                    disableGutters
                                    divider={index !== (contacts.length - 1)}
                                    onClick={() => onSelect(contact.value)}>
                                    <ListItemIcon>
                                        <Icon className="fa fa-user-circle" fontSize="large"/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={contact.value}
                                        secondary={contact.type} />
                                </ListItem>
                            </Fragment>
                        )}
                    </List>
                </div>
            </div>
        </Modal>
    );
};

SelectContactModal.propTypes = {
    t: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    contacts: PropTypes.array.isRequired
};

export default withLanguage(SelectContactModal);