/* eslint-disable no-console */
"use strict";

class Model {
    constructor(key){
        this.key = key;
    }

    static get(key = null) {
        let itemKey = this.tableName;
        if(key){
            itemKey += "." + key;
        }
        let data = window.localStorage.getItem(itemKey);
        try {
            return JSON.parse(data);
        } catch (e) {
            console.error(e);
        }

        return data;
    }

    get(key = null) {
        let itemKey = this.key;
        if(key){
            itemKey += "." + key;
        }
        let data = window.localStorage.getItem(itemKey);
        try {
            return JSON.parse(data);
        } catch (e) {
            console.error(e);
        }

        return data;
    }

    static save(data) {
        try {
            window.localStorage.setItem(this.tableName, JSON.stringify(data));
        } catch (error) {
            console.error(error);
        }
    }

    set(key, data){
        try {
            window.localStorage.setItem(this.key + "." + key, JSON.stringify(data));
        } catch (error) {
            console.error(error);
        }
    }

    static delete() {
        window.localStorage.removeItem(this.key);
    }
}

export default Model;