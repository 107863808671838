import React from 'react';
import moment from "moment";
import { connect } from 'react-redux';
import withLanguage from '../../../../language/LanguageController';
import analytics from '../../../../library/firebase/analytics';
import request from '../../../../library/request';
import config from '../../../../../config';
import MuiDatepicker from '../../../../common/MuiDatepicker';
import { loader } from '../../../../library/util';
import { errorHandler } from '../../../../library/response';
import CowWhiteIcon from '../../../../common/icons/CowWhiteIcon';
import CowBlackIcon from '../../../../common/icons/CowBlackIcon';
import BuffaloWhiteIcon from '../../../../common/icons/BuffaloWhiteIcon';
import BuffaloBlackIcon from '../../../../common/icons/BuffaloBlackIcon';
import { DefaultLayout } from 'src/components/pages/common/Layout';
import { withRouter } from 'react-router';
import { getBillingStartEndDate } from 'src/components/library/dateUtils';
import { currencyFormat } from "../../../../library/util";

class KisanCustomerMilkDetail extends React.Component {

    constructor(props) {
        super(props);
        this.getMilk = this.getMilk.bind(this);
        const dairyCustomerId = this.props?.match?.params?.dairyCustomerId;
        this.state = {
            records: [],
            total: {},
            dairy_customer_id: dairyCustomerId,
            milk_animals: (this.props.dairy.milk_animals).split(','),
            ...this.getDefaultFilter()
        };
    }

    getDefaultFilter(){
        const { location } = this.props;
        let milkAnimal = (this.props.dairy.milk_animals).split(',');

        const { startDate, endDate } = getBillingStartEndDate(this.props.dairy.billing_cycle, moment());
        const locationStartDate = location?.state?.startDate || null;
        const locationEndDate = location?.state?.endDate || null;
        const filterStartDate = locationStartDate ? moment(locationStartDate) : startDate;
        const filterEndDate = locationEndDate ? moment(locationEndDate) : endDate;
        
        return {
            dairy_customer_id: this.props.dairyCustomerId,
            start_date: filterStartDate,
            end_date: filterEndDate,
            cattle: milkAnimal[0],
            shift: "0"
        }
    }

    pushPage = (e, Compo, propsData) => {
        e.preventDefault();
        propsData.key = Compo.name
        this.props.navigator.pushPage({
            component: Compo, 
            props: propsData
        });
    }

    componentDidMount() {
        analytics.setScreenName("CustomerCustomerMilkDetail");
        this.getMilk();
    }

    async getMilk () {
        try {
            loader.show(this.props.t("text_loading"));
            const { start_date, end_date, cattle, shift, dairy_customer_id } = this.state;
            const { dairyId } = this.props;
            let filterStr = '?milk_type=' + cattle;
                filterStr += '&date=' + start_date.format(config.date_format) + ':' + end_date.format(config.date_format);
                filterStr += '&day_time=' + shift;
            const { status, data } = await request.get('/dairies/' + dairyId + '/milk/' + dairy_customer_id + filterStr, {
                withAuth: true,
                version: "v2"
            });
            if(status === true){
                this.setState({
                    records: data.result,
                    total: data.total
                }); 
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    dateChangeHandler = (name, selectedDate) => {
        this.setState({
            [name]: selectedDate
        }, () => this.getMilk());
    }

    cattleChangeHandler = cattle => {
        this.setState({
            cattle: cattle
        }, () => this.getMilk());
    }

    shiftChangeHandler = shift => {
        this.setState({
            shift: shift
        }, () => this.getMilk());
    }

    render() {
        const { t, header } = this.props;
        const { start_date, end_date, cattle, shift } = this.state;
        return (
            <DefaultLayout
                back
                bottomGutter={false}
                header={header}
                title={this.props.dairy.name}
                toolbar={false}>
                <div className="mb-2">
                    <div className="input-group" role="group" aria-label="Date Selection">
                        <div className="input-group-prepend">
                            <span className="input-group-text text-sm px-2">{t("text_from")}</span>
                        </div>
                        <MuiDatepicker
                            value={start_date}
                            maxDate={end_date}
                            onChange={selectedDate => this.dateChangeHandler("start_date", selectedDate)}
                            name="start_date"
                            inputId="start_date"
                            inputClass="bg-white"
                            arrowButtons={false}
                        />
                        <div className="input-group-prepend input-group-append">
                            <span className="input-group-text text-sm px-2">{t("text_to")}</span>
                        </div>
                        <MuiDatepicker
                            value={end_date}
                            minDate={start_date}
                            maxDate={moment().endOf("month")}
                            onChange={selectedDate => this.dateChangeHandler("end_date", selectedDate)}
                            name="end_date"
                            inputId="end_date"
                            inputClass="bg-white"
                            arrowButtons={false}
                        />
                    </div>
                </div>
                <div className="mb-2 clearfix">
                    <div className="float-left">
                        <div className="btn-group btn-group-sm" role="group" aria-label="Cattle Selection">
                            <button
                                className={`btn ${cattle === "cow" ? "bg-light-blue text-white active" : "btn-light"}`}
                                type="button"
                                onClick={() => this.cattleChangeHandler("cow")}>
                                {cattle === "cow" ? <CowWhiteIcon /> : <CowBlackIcon />} 
                                &nbsp;{t("text_cow")}
                            </button>
                            <button
                                className={`btn ${cattle === "buffalo" ? "bg-light-blue text-white active" : "btn-light"}`}
                                type="button"
                                onClick={() => this.cattleChangeHandler("buffalo")}>
                                {cattle === "buffalo" ? <BuffaloWhiteIcon /> : <BuffaloBlackIcon />}
                                &nbsp;{t("text_buffalo")}
                            </button>
                        </div>
                    </div>
                    <div className="float-right">
                        <div className="btn-group btn-group-sm" role="group" aria-label="Shift Selection">
                            <button
                                className={`btn ${shift == "0" ? "bg-light-blue text-white active" : "btn-light"}`}
                                type="button"
                                onClick={() => this.shiftChangeHandler("0")}>
                                <i className="fa fa-sun"></i>
                                &nbsp;{t("text_morning")}
                            </button>
                            <button
                                className={`btn ${shift == "1" ? "bg-light-blue text-white active" : "btn-light"}`}
                                type="button"
                                onClick={() => this.shiftChangeHandler("1")}>
                                <i className="fa fa-moon"></i>
                                &nbsp;{t("text_evening")}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="bg-light shadow-sm">
                    <div className="table_container">
                        <table className="table table-striped table-borderless mb-0">
                            <thead>                                            
                                <tr>
                                    <th style={{width:"80px"}}>{t("text_date")}</th>
                                    <th>{t("text_liter")}</th>
                                    <th>{t("text_fat")}</th>
                                    <th>{t("text_clr")}</th>
                                    <th>{t("text_snf")}</th>
                                    <th>{t("text_price")}</th>
                                    <th>{t("text_amount")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.records.map((data, index) => (
                                    <tr key ={data.id}>
                                        <td className="text-nowrap">
                                        {data.date === null ? "-" : data.date}
                                        </td>
                                        <td>
                                        {(data.liter === null ? "-" : currencyFormat(data.liter, false))}
                                        </td>
                                        <td>
                                        {(data.fat === null ? "-" : data.fat)}
                                        </td>
                                        <td>
                                        {(data.clr === null ? "-" : data.clr)}
                                        </td>
                                        <td>
                                        {(data.snf === null ? "-" : data.snf)}
                                        </td>
                                        <td>
                                        {(data.rate === null ? "-" : currencyFormat(data.rate))}       
                                        </td>
                                        <td>
                                        {(data.total === null ? "-" : currencyFormat(data.total))}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>{t("text_total")}</th>
                                    <th>{currencyFormat(this.state.total.total_milk, false)}</th>
                                    <th>{this.state.total.fat_avg_total}</th>
                                    <th>{this.state.total.clr_avg_total}</th>
                                    <th>{this.state.total.snf_avg_total}</th>
                                    <th>{currencyFormat(this.state.total.rate)}</th>
                                    <th>{currencyFormat(this.state.total.total)}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </DefaultLayout>
        );
    }
}

KisanCustomerMilkDetail.displayName = "KisanCustomerMilkDetail";

KisanCustomerMilkDetail.defaultProps = {
    header: true
};

const mapStateToProps = state => ({
    dairy: state.dairy.dairy,
    dairyName: state.dairy.dairy.name,
    dairyId: state.dairy.dairy.dairy_id,
    dairyCustomerId: state.dairy.dairy.dairy_customer_id,
    dairyType: state.dairy.dairy.type
});

export default connect(mapStateToProps)(
    withRouter(withLanguage(KisanCustomerMilkDetail))
);