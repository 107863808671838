import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

export const PrimaryButton = withStyles(theme => ({
    root: props => {
        if(props.variant === "outlined") {
            return {
                color: green[500],
                borderColor: green[500],
                textTransform: ({ uppercase = true }) => uppercase ? "uppercase" : "none",
                "&:hover": {
                    color: green[500]
                },
            }
        } else {
            return {
                color: "#fff",
                backgroundColor: green[500],
                textTransform: ({ uppercase = true }) => uppercase ? "uppercase" : "none",
                "&:hover": {
                    backgroundColor: green[700],
                    color: "#fff"
                },
            }
        }
    }
}))(Button);