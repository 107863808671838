import { initializeApp } from 'firebase/app';
import { initRemoteConfig } from "../RemoteConfig";

const projectId = "liter-ce1e9";
const firebaseApp = initializeApp({
    apiKey: 'AIzaSyD8xAwvBajYcpw07W4RbsruUZg8Z0u4KaM',
    authDomain: `${projectId}.firebaseapp.com`,
    databaseURL: `https://${projectId}.firebaseio.com`,
    projectId: projectId,
    storageBucket: `${projectId}.appspot.com`,
    messagingSenderId: '819156957312',
    appId: '1:819156957312:android:91921d9816e9e2cd19ce65',
    measurementId: 'G-measurement-id',
});

export const initFirebaseWeb = () => {
    initRemoteConfig(firebaseApp);
}