import React, { useCallback } from "react";
import config from "../../../../../config";
import withLanguage from "../../../../language/LanguageController";
import PropTypes from "prop-types";
import { Avatar, IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVert from '@material-ui/icons/MoreVert';
import { HasPermission, usePermission } from "src/components/common/AccessPermission";
import { ABILITY_DAIRY_CUSTOMERS, PERMISSION_UPDATE, PERMISSION_DELETE } from "src/_constants/permissions";

const SingleCustomer = ({
    t,
    customer,
    showCustomerMilkDetail,
    editCustomer,
    changeStatus,
    deleteConfirm,
    active
}) => {
    const { hasAccess } = usePermission();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };
    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };
    const handleChangeStatus = useCallback((event) => {
        event.stopPropagation();
        setAnchorEl(null);
        changeStatus(
            customer.dairy_customer_id,
            customer.status === "0"
        );
    }, [changeStatus, customer.dairy_customer_id, customer.status]);

    const actionPermission = hasAccess(ABILITY_DAIRY_CUSTOMERS, PERMISSION_UPDATE) || hasAccess(ABILITY_DAIRY_CUSTOMERS, PERMISSION_DELETE);

    return (
        <div className={`t-card mb-2 shadow-sm ${active ? "active-customer bg-light-blue text-white" : ""}`} onClick={(e) => showCustomerMilkDetail(e, customer)}>
            {actionPermission && <div className="dropdown gesation-cicular-dropdown dropdown_zindex vert-menu">
                <IconButton
                    aria-controls={`product-menu-${customer.dairy_customer_id}`}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={`vert-menu ${active && "text-white"}`}
                    size="small"
                >
                    <MoreVert/>
                </IconButton>
                <Menu
                    id={`product-menu-${customer.dairy_customer_id}`}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >   
                    <HasPermission ability={ABILITY_DAIRY_CUSTOMERS} access={PERMISSION_UPDATE}>
                        <MenuItem onClick={e => editCustomer(e, customer.dairy_customer_id)}>
                            {t("text_edit")}
                        </MenuItem>
                        <MenuItem onClick={handleChangeStatus}>
                            {customer.status === "0" ? t("text_activate") : t("text_inactive")}
                        </MenuItem>
                    </HasPermission>
                    <HasPermission ability={ABILITY_DAIRY_CUSTOMERS} access={PERMISSION_DELETE}>
                        <MenuItem onClick={e => deleteConfirm(e, customer.dairy_customer_id)}>
                            {t("text_delete")}
                        </MenuItem>
                    </HasPermission>
                </Menu>
            </div>}
            <div className="row mx-0">
                <div className="col-3 text-center pt-2 px-0 d-flex flex-column justify-content-center">
                    <Avatar
                        src={(customer.profile_pic) ? config.image_base_url + "user/" + customer.profile_pic : "./broken-image.png"}
                        alt={customer.name}
                        className="customer-avatar mx-auto mb-2"
                    />
                    <p className={"text-center mb-0 small " + (customer.status == 1 ? "text-green" : "text-red")}>
                        {customer.status == 1 ? t("text_active") : t("text_inactive")}
                    </p>
                </div>
                <div className="col-9">
                    <div className="customer_info">
                        <div className="customer_name">
                            <h3>{customer.name}</h3>
                        </div>
                        {customer.mobile ? (
                            <p>{t("text_contact")}: {customer.mobile}</p>
                        ) : null}
                        <p>{t("text_code")}: {customer.code}</p>
                        <p>{t("text_animals")}: {(animals => {
                            animals = animals.split(",");
                            animals = animals.map(animal => t(`text_${animal}`));
                            return animals.join(",");
                        })(customer.milk_animals)}</p>
                        <p>{t("text_type")}: {t(`text_${customer.type}`)}</p>
                        <p>{t("text_rate")}: {!customer.rate ? t("text_general") : t("text_personal")}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

SingleCustomer.propTypes = {
    t: PropTypes.func.isRequired,
    customer: PropTypes.object.isRequired,
    showCustomerMilkDetail: PropTypes.func.isRequired,
    editCustomer: PropTypes.func.isRequired,
    changeStatus: PropTypes.func.isRequired,
    deleteConfirm: PropTypes.func.isRequired
};

export default withLanguage(SingleCustomer);