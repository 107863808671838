/* eslint-disable no-console */
import * as Sentry from "@sentry/react";
import { 
    FETCH_PLANS_SUCCESS,
    FETCH_FEATURES_SUCCESS,
    FETCH_SUBSCRIPTION_SUCCESS,
    FETCH_ORDERS_BEGIN,
    FETCH_ORDERS_SUCCESS,
    FETCH_ORDERS_FAILED,
    FETCH_SUBSCRIPTION_REQUEST,
    FETCH_SUBSCRIPTION_FAILURE,
    DELETE_SUBSCRIPTION_ADDON_REQUEST,
    DELETE_SUBSCRIPTION_ADDON_SUCCESS,
    DELETE_SUBSCRIPTION_ADDON_FAILURE,
    SUBSCRIPTION_RESET
} from "../_constants";
import { loader } from "../components/library/util";
import request from "../components/library/request";
import { t } from "../components/language/LanguageController";
/*
 * action creators
 */

export const fetchPlansSuccess = data => ({
    type: FETCH_PLANS_SUCCESS,
    data: data
});

export const fetchFeaturesSuccess = data => ({
    type: FETCH_FEATURES_SUCCESS,
    data: data
});

export const fetchOrdersBegin = data => ({
    type: FETCH_ORDERS_BEGIN,
    data: {
        fetching_payment_order: data
    }
});

export const fetchOrdersSuccess = data => ({
    type: FETCH_ORDERS_SUCCESS,
    data: data
});

export const fetchOrdersFailed = () => ({
    type: FETCH_ORDERS_FAILED,
});

export const fetchPlans = () => {
    return async dispatch => {
        try {
            loader.show(t("text_loading"));
            const { status, data } = await request.get("/subscription/plans", {
                withAuth: true
            });
            if(status) {
                dispatch(fetchPlansSuccess(data));
            }
        } catch (err) {
            Sentry.captureException(err);
        } finally {
            loader.hide();
        }
    }
}

export const fetchFeatures = () => {
    return async dispatch => {
        try {
            loader.show(t("text_loading"));
            const { status, data } = await request.get("/subscription/features", {
                withAuth: true
            });
            if(status) {
                dispatch(fetchFeaturesSuccess(data));
            }
        } catch (err) {
            Sentry.captureException(err);
        } finally {
            loader.hide();
        }
    }
}

export const fetchSubscriptionRequest = () => ({
    type: FETCH_SUBSCRIPTION_REQUEST
});

export const fetchSubscriptionSuccess = data => ({
    type: FETCH_SUBSCRIPTION_SUCCESS,
    data: data
});

export const fetchSubscriptionError = () => ({
    type: FETCH_SUBSCRIPTION_FAILURE,
});

export const fetchSubscription = async (dispatch) => {
    try {
        dispatch(fetchSubscriptionRequest());
        const { status, data } = await request.get("/subscription/get", {
            withAuth: true
        });
        if(status) {
            dispatch(fetchSubscriptionSuccess(data));
        }
    } catch (err) {
        dispatch(fetchSubscriptionError());
    }
}

    export const fetchDairySubscription = async (dispatch, dairyId) => {
    try {
        dispatch(fetchSubscriptionRequest());
        const { status, data } = await request.get(`/subscription/get/${dairyId}`, {
            withAuth: true
        });
        if(status) {
            dispatch(fetchSubscriptionSuccess(data));
        }
    } catch (err) {
        Sentry.captureException(err);
    }
}

export const fetchOrders = (fetchingPaymentOrder = false, dairyId) => {
    return async dispatch => {
        try {
            dispatch(fetchOrdersBegin(fetchingPaymentOrder));
            const { status, data } = await request.get(`/subscription/orders/${dairyId}`, {
                withAuth: true
            });
            if(status) {
                dispatch(fetchOrdersSuccess(data));
            } else {
                dispatch(fetchOrdersFailed());
            }
        } catch (err) {
            Sentry.captureException(err);
        }
    }
}

export const deleteDairySubscriptionAddOnRequest = () => ({
    type: DELETE_SUBSCRIPTION_ADDON_REQUEST
});

export const deleteDairySubscriptionAddOnSuccess = () => ({
    type: DELETE_SUBSCRIPTION_ADDON_SUCCESS
});

export const deleteDairySubscriptionAddOnFailure = () => ({
    type: DELETE_SUBSCRIPTION_ADDON_FAILURE
});

export const deleteDairySubscriptionAddOn = async (dairyId, addOnId) => {
    try {
        return await request.delete(`/subscription/addons/${dairyId}/${addOnId}`, {
            withAuth: true
        });
    } catch (err) {
        Sentry.captureException(err);
    }
}

export const resetSubscription = () => ({
    type: SUBSCRIPTION_RESET
});