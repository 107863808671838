/* eslint-disable no-console */
import React from "react";
import SimpleReactValidator from "simple-react-validator";
import { t } from "../language/LanguageController";
import { extractNumbers, replaceAll } from "../library/util";

class SimpleValidator extends SimpleReactValidator {
    constructor(props = {}) {
        super({
            element: (message, className) => {
                return <div className={`srv-validation-message ${className}`}>{this.parseMessage(message)}</div>;
            },
            validators: {
                strong_password: {  // name the rule
                  message: ':attribute_strong_password',
                  rule: (value, params, validator) => {
                     //check for lowercase letter
                    if(!/[a-z]/.test(value)) {
                        return false;
                    }

                    //check for uppercase letter
                    if(!/[A-Z]/.test(value)) {
                        return false;
                    }

                    //check for numbers
                    if(!/[\d]/.test(value)) {
                        return false;
                    }

                    // check for special characters
                    if(!/[!"#$%&\'()*+,-.\/:;<=>?@[\/\]^_`{|}~]/.test(value)) {
                        return false;
                    }

                    // all checks passed
                    return true;
                  },
                  messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
                  required: true  // optional
                },
                alpha: {
                    // (^[A-Z]*$)|([\u0900-\u100F])|(\s)
                    // ([\[A-Z\]*$])|([\u0900-\u100F])|(\s)
                    // ([A-Z\s])|([\u0900-\u100F\s])
                    message: 'The :attribute may only contain letters.',
                    rule: val => {
                        // return this.helpers.testRegex(val,/^[A-Z]*$/i)
                        return this.helpers.testRegex(val,/^[A-Z\u0900-\u100F]*$/gi);
                    }
                },
                alpha_space: {
                    message: 'The :attribute may only contain letters and spaces.',
                    rule: val => {
                        // return this.helpers.testRegex(val,/^[A-Z\s]*$/i);
                        return this.helpers.testRegex(val,/^[A-Z\u0900-\u100F\s]*$/gi);
                    }
                },
                alpha_num: {
                    message: 'The :attribute may only contain letters and numbers.',
                    rule: val => {
                        // this.helpers.testRegex(val,/^[A-Z0-9]*$/i)
                        return this.helpers.testRegex(val,/^[A-Z0-9\u0900-\u100F]*$/gi);
                    }
                },
                alpha_num_space: {
                    message: 'The :attribute may only contain letters, numbers, and spaces.',
                    rule: val => {
                        // this.helpers.testRegex(val,/^[A-Z0-9\s]*$/i);
                        return this.helpers.testRegex(val,/^[A-Z0-9\u0900-\u100F\s]*$/gi);
                    }
                },
                alpha_num_dash: {
                    message: 'The :attribute may only contain letters, numbers, and dashes.',
                    rule: val => {
                        // this.helpers.testRegex(val,/^[A-Z0-9_-]*$/i);
                        return this.helpers.testRegex(val,/^[A-Z0-9\u0900-\u100F_-]*$/i);
                    }
                },
                alpha_num_dash_space: {
                    message: 'The :attribute may only contain letters, numbers, dashes, and spaces.',
                    rule: val => {
                        // this.helpers.testRegex(val,/^[A-Z0-9_-\s]*$/i);
                        return this.helpers.testRegex(val,/^[A-Z0-9\u0900-\u100F_-\s]*$/i);
                    }
                },
            },
            ...props
        });
    }

    clearAll () {
        this.visibleFields = [];
        this.hideMessages();
    }

    parseMessage = message => {
        let messageKey = `error_${replaceAll(message.toLowerCase(), ' ', '_')}`;
        let number;
        if(messageKey.includes("r_password_confirmation")) {
            messageKey = "error_r_password_confirmation.";
        } else if(messageKey.includes("error_the_selected_password_confirmation_must_be")) {
            messageKey = "error_r_password_confirmation.";
        } else if(messageKey.includes("error_the_selected_new_password_must_not_be")){
            messageKey = "error_the_selected_new_password_must_not_be."
        } else if(messageKey.includes("error_the_decrement_snf_may_not_be_greater")) {
            number = extractNumbers(messageKey);
            messageKey = "error_the_decrement_snf_may_not_be_greater."
        } else if(messageKey.includes("error_the_decrement_clr_may_not_be_greater")) {
            number = extractNumbers(messageKey);
            messageKey = "error_the_decrement_clr_may_not_be_greater."
        } else if(messageKey.includes("error_the_increment_snf_must_be_at_least")) {            
            number = extractNumbers(messageKey);
            messageKey = "error_the_increment_snf_must_be_at_least."
        } else if(messageKey.includes("error_the_increment_clr_must_be_at_least")) {            
            number = extractNumbers(messageKey);
            messageKey = "error_the_increment_clr_must_be_at_least."
        } else if(messageKey.includes("error_the_recover_loan_amount_may_not_be_greater_than")) {            
            number = extractNumbers(messageKey);
            messageKey = "error_the_recover_loan_amount_may_not_be_greater."
        } else if(messageKey.includes("error_the_amount_may_not_be_greater_than")) {            
            number = extractNumbers(messageKey);
            messageKey = "error_the_amount_may_not_be_greater."
        } else if(messageKey.includes("error_the_quantity_may_not_be_greater_than")) {            
            number = extractNumbers(messageKey);
            messageKey = "error_the_quantity_may_not_be_greater."
        } else if(messageKey.includes("error_the_deduction_start_from_may_not_be_greater_than")) {            
            number = extractNumbers(messageKey);
            messageKey = "error_the_deduction_start_from_may_not_be_greater_than."
        } else if(messageKey.includes("error_the_increment_start_from_must_be_at_least")) {            
            number = extractNumbers(messageKey);
            messageKey = "error_the_increment_start_from_must_be_at_least."
        }
        message = t(messageKey);
        if(number) {
            message = replaceAll(message, "{number}", number);
        }
        return message;
    }

    message(field, inputValue, validations, options = {}) {
        this.errorMessages[field] = null;
        this.fields[field] = true;
        if (!Array.isArray(validations)) {
          validations = validations.split('|');
        }
        var rules = options.validators ? {...this.rules, ...options.validators} : this.rules;
        for (let validation of validations) {
          let [value, rule, params] = this.helpers.normalizeValues(inputValue, validation);
          if (!this.helpers.passes(rule, value, params, rules)) {
            this.fields[field] = false;
            let message = this.helpers.message(rule, field, options, rules);
    
            if (params.length > 0 && rules[rule].hasOwnProperty('messageReplace')) {
              message = rules[rule].messageReplace(message, params);
            }
    
            this.errorMessages[field] = message;
            if (this.messagesShown || this.visibleFields.indexOf(field) != -1) {
              return this.helpers.element(message, options);
            }
          }
        }
      }

    showMessageFor = field => {
        if (this.visibleFields.indexOf(field) === -1) {
          this.visibleFields.push(field);
        }
        this.helpers.forceUpdateIfNeeded();
    }
}

export default SimpleValidator;