import {
    FETCH_CUSTOMER_REWARD,
    FETCH_CUSTOMER_REWARD_HISTORY,
    TOGGLE_REFER_MODAL
} from "../_constants/reward";
import { copyObject } from "../components/library/util";

const initialState = {
    reward: [],
    rewardHistory: [],
    openReferralModal: [],
    doNotShowRefer: null,
};

function reward(state = initialState, action) {
    switch (action.type) {
        case FETCH_CUSTOMER_REWARD: //For Customer Rewards
            return {
                ...copyObject(state),
                reward: action.data
            };

        case FETCH_CUSTOMER_REWARD_HISTORY: //For Customer Rewards History
            return {
                ...copyObject(state),
                rewardHistory: action.data
            };

        case TOGGLE_REFER_MODAL: //For Customer Rewards History
            return {
                ...copyObject(state),
                openReferralModal: action.data
            };

        default:
            return state;
    }
}

export default reward;