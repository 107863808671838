import { FormControl, NativeSelect } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import BootstrapInput from 'src/components/common/bootstrapInput';
import { useLanguage } from 'src/components/language/LanguageController';

const useStyles = makeStyles((theme) => ({
    margin: {
        marginBottom: theme.spacing(0),
    },
}));

function SortButton(props) {
    const classes = useStyles();
    const { t } = useLanguage();

    const options = [
        { value: 'NameAsc', label: t("sort_by_name_asc"), field: 'name', order: 'asc' },
        { value: 'NameDsc', label: t("sort_by_name_dsc"), field: 'name', order: 'desc' },
        { value: 'CodeAsc', label: t("sort_by_code_asc"), field: 'code', order: 'asc' },
        { value: 'CodeDsc', label: t("sort_by_code_dsc"), field: 'code', order: 'desc' }
    ];
    const handleChange = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = options.find(option => option.value === selectedValue);
        if (selectedOption && props.onSortChange) {
            props.onSortChange(selectedOption);
        }
    };
    return (
        <div>
            <FormControl className={classes.margin}>
                <NativeSelect
                    onChange={handleChange}
                    input={<BootstrapInput />}
                >
                    {options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </NativeSelect>
            </FormControl>
        </div>
    );
}
export default SortButton;