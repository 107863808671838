
import defaultReducer from "../reducers";
import productReducer from "../reducers/product";
import dairyCustomerProduct from "../reducers/dairyCustomerProduct";
import dairySettings from "../reducers/dairySettings";
import dairyCustomers from "../reducers/dairyCustomer";
import dairyReducer from "../reducers/dairy";
import userReducer from "../reducers/user";
import subscriptionReducer from "../reducers/subscription";
import referralReducer from "../reducers/referral";
import dairyRateList from "../reducers/dairyRateList";
import offerStore from "../reducers/offerStore";
import gestationStore from "../reducers/gestationStore";
import reward from "../reducers/reward";
import dairyUsers from "../reducers/dairyUsers";
import addonsReducer from "../reducers/addonsReducer";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import offlineStore from "./offline-store"

const combineReducer = combineReducers({
    defaultReducer,
    userReducer,
    productReducer,
    dairyCustomerProduct,
    dairySettings,
    dairy: dairyReducer,
    dairy_customers: dairyCustomers,
    subscription: subscriptionReducer,
    referral: referralReducer,
    dairyRateList,
    offerStore,
    gestationStore,
    reward,
    dairyUsers,
    addonsReducer,
    offlineStore
});

const store = configureStore({
    reducer: (state, action) => {
        return combineReducer(state, action);
    }
});

export default store;