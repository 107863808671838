// @ts-nocheck
import React, {Component} from 'react';
import * as Sentry from "@sentry/react";
import { copyObject, loader, notification } from '../library/util';
import { fetchDairiesSuccess, fetchDairySuccess, fetchDairyPayment, dairySelectedToggle } from '../../actions/dairy';
import { fetchSubscription, fetchPlans, fetchDairySubscription } from "../../actions/subscriptions";
import { connect } from 'react-redux';
import withLanguage from '../language/LanguageController';
import analytics from '../library/firebase/analytics';
import request from '../library/request';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import { DefaultLayout } from './common/Layout';
import DairySelectionModal from '../modalPopups/dairySelectionModal/DairySelectionModal';
import bottleImg from "src/assets/img/bottle.png";
import cowImg from "src/assets/img/cow.png";

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.getDairyDetail = this.getDairyDetail.bind(this);
    this.selectDairyPage = this.selectDairyPage.bind(this);
    this.state = {
      error: null,
      is_dairy_loading: true,
      isLoaded: false,
      bannerdata: [],
      blogdata: [],
      weather: '',
      dairies: [],
      isDairySelectionModal: false
    };
  }

  pushPage = (e, NextPage) => {
    this.props.navigator.pushPage({
      component: NextPage, 
      props: {
        key: NextPage.name
      }
    }, {
      animation: 'fade'
    });
  }

  resetPage = (e, NextPage) => {
    this.props.navigator.resetPage({
      component: NextPage, 
      props: {
        key: NextPage.name
      }
    }, {
      animation: 'fade'
    });
  }

  componentDidMount() {
    analytics.setScreenName("LandingPage");
    if(this.props.user.customer_group_id === "1"){
      this.getDairyDetail();
      this.props.dairySelectedToggle(false);
      
    } else if(this.props.user.customer_group_id === "2") {
      fetchSubscription(this.props.dispatch);
    }
  }

  async getDairyDetail () {
    try {
      const { status, data } = await request.get('/customers/' + this.props.user.customer_id + '/dairies', {
        withAuth: true,
        version: "v2"
      });
      if (status === true) {
        this.setState({
          is_dairy_loading: false
        }, () => {
          this.props.fetchDairiesSuccess(data);
        });
      } else {
        this.setState({
          is_dairy_loading: false
        })
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async selectDairyPage (e) {
    e.preventDefault();
    const { user: { customer_group_id } } = this.props;
    const { dairies } = this.props;

    if(customer_group_id == "1"){
      if(this.state.is_dairy_loading){
        notification(this.props.t("text_info"), this.props.t("text_dairy_detail_loading"));
        return;
      }
      let dairyCount = dairies.length;
      if(dairyCount === 1) {
        let dairy = copyObject(dairies[0]);
        loader.show(this.props.t("text_loading"))
        await fetchDairySubscription(this.props.dispatch, dairy.dairy_id);
        loader.hide();
        this.props.fetchDairySuccess(dairy);
        this.props.history.push(`/dairy/${dairy.dairy_id}`);
      } else if(dairyCount < 1) {
        this.props.history.push("/diary");
      } else if(dairyCount > 1){
        this.setState({
          isDairySelectionModal: true
        });
      }
    } else {
      this.props.history.push("/dairy");
    }
  }

  render() {
    const { t } = this.props;
    const { isDairySelectionModal } = this.state;
    return (
      <DefaultLayout>
        <div className="row">
          <div className="col-12 col-md-4 mx-auto">
            <div className="card" onClick={this.selectDairyPage}>
                <img src={bottleImg}
                  className="img-responsive mx-auto mb-2"
                  alt={t('dairy')}
                  style={{width: "55px"}}/>
                <div className="card-body p-0 text-center">
                  <h5 className="card-title mb-1">{t('dairy')}</h5>
                  <small>{t('dairy_text')}</small>
                </div>
            </div>
            <div className="card">
                <img
                  src={cowImg}
                  className="img-responsive mx-auto"
                  alt={t('dairy')}
                  style={{width: "55px"}}/>
                <div className="card-body p-0 text-center">
                  <h5 className="card-title mb-1">{t('text_gestation')}</h5>
                  <small>{t('text_gestation_card_text')}</small>
                </div>
                <Link to="/gestation" className="stretched-link" />
            </div>
          </div>
        </div>
        <DairySelectionModal
          isOpen={isDairySelectionModal}
          onClose={() => {
            this.setState({
              isDairySelectionModal: false
            });
          }}
        />
      </DefaultLayout>
    );
  }
}

const mapStateToProps = state => {
  const { userReducer: { user = {} } } = state;
  return {
    dairies: state.dairy.dairies,
    user: user ? user : {}
  }
}

const mapDispatchToProp = {
  fetchDairiesSuccess,
  fetchDairySuccess,
  fetchDairyPayment,
  fetchPlans,
  dairySelectedToggle
}

export default connect(mapStateToProps, mapDispatchToProp)(withRouter(withLanguage(LandingPage)));