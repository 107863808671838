import Model from "./Model";

class Plans extends Model{
    constructor(){
        super("plans");
    }

    static get tableName() {
        return "plans"; // define child's tableName here
    }
}

export default Plans;