import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectOfflineStoragePassword } from "src/store/customerPreferences";

const useDesktopIntegration = () => {

    const offlineStoragePassword = useSelector(selectOfflineStoragePassword);

    useEffect(() => {
        window?.literAPI?.offlineDataStore?.api?.openDatabase?.(offlineStoragePassword);
    }, [offlineStoragePassword]);

}

export default useDesktopIntegration;