// @ts-nocheck
import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import useMenu from "./useMenu";
import useStyles from "./DesktopSideNavigation.style";
import DesktopSideNavigationMenuItem from './DesktopSideNavigationMenuItem';

const DesktopSideNavigation = () => {
    const classes = useStyles();
    const menuItems = useMenu();

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerContainer}>
                <List>
                    {menuItems.map(menuItem => 
                        menuItem.hasAccess && <DesktopSideNavigationMenuItem
                            key={menuItem.key}
                            menuItem={menuItem}
                        />
                    )}
                </List>
            </div>
        </Drawer>
    );
};

export default DesktopSideNavigation;