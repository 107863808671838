/* eslint-disable no-console */
/*
 * action types
 */

import { 
    LOGIN_SUCCESS,
    SET_LOGIN,
    PROFILE_UPDATE_SUCCESS,
    ADDRESS_UPDATE_SUCCESS,
    UPDATE_PROFILE_PIC,
    UPDATE_AUTH_DETAIL
} from "../_constants";
import store from "../store/index";
import { loader, toast } from "../components/library/util";
import request from "../components/library/request";
import { errorHandler } from "../components/library/response";
import { t } from "../components/language/LanguageController";
import analytics from "src/components/library/firebase/analytics";
import UserModel from "src/components/models/User";
import { updateCustomerPreference } from ".";

export const loginSuccess = user => {
    return async dispatch => {
        dispatch({ 
            type: LOGIN_SUCCESS, 
            data: user
        });
        const language = store.getState().defaultReducer.language
        updateCustomerPreference("language", { language });
    }
};

export const setLogin = isLogin => ({ 
    type: SET_LOGIN, 
    data: isLogin
});

export const updateProfile = user => ({ 
    type: PROFILE_UPDATE_SUCCESS, 
    data: user
});

export const updateProfilePic = picture => ({
    type: UPDATE_PROFILE_PIC,
    data: picture
});

export const updateAuth = data => {
    store.dispatch({
        type: UPDATE_AUTH_DETAIL,
        data: data
    });
};

export const updateAddressSuccess = data => ({
    type: ADDRESS_UPDATE_SUCCESS,
    data: data
});

export const updateAddress = () => {
    return async dispatch => {
        try {
            loader.show(t("text_saving"));
            const { data, status, message } = await request.post("/customer/profile/address", {
                body: new FormData(document.getElementById("address_form")),
                withAuth: true
            });
            if(status) {
                dispatch(updateAddressSuccess(data));
                toast(message);
            } else {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }
}

export const updateProfilePicture = (formData, customerId) => {
    return async dispatch => {
        try {
            loader.show(t("text_saving"));
            const { status, data, message } = await request.post(`/customer/profile/picture/${customerId}`, {
                withAuth: true,
                body: formData
            });            
            if (status) {
                toast(message);
                dispatch(updateProfilePic(data));
            } else {
                throw message
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }
}

export const fetchCustomer = () => {
    return async dispatch => {
        try {
            loader.show(t("text_loading"));
            const { data, status, message } = await request.get("/customer", {
                withAuth: true
            });
            if (status) {
                dispatch(updateProfile(data));
            } else {
                throw message
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }
}

export const logoutUser = async (event) => {
    event.preventDefault();
    analytics.logEvent("logout");
    try {
        await request.get("/auth/logout");
    }  finally {
        (new UserModel()).logout();
        window.location.reload();
    }
}

export const logoutAll = async (event) => {
    event.preventDefault();
    analytics.logEvent("logoutAll");
    try {
        await request.get("/auth/logoutAll");
    } finally {
        (new UserModel()).logout();
        window.location.reload();
    }
}