import React from 'react'
import Typography from "@material-ui/core/Typography";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box } from "@material-ui/core";
import { useLanguage } from "../../language/LanguageController";
const RewardFAQ = ({ reward }) => {
    const { t, language } = useLanguage();
    return (
        <>
            <div className='mt-3 mb-3'>
                <Typography component="span" variant="h6" className='font-weight-bold'>{t("test_frequently_ask_quesions")}</Typography>
                <Box className='mt-2'>
                    {reward?.rewardFAQ?.map((faq, index) => {
                        let quesionLang =(faq.quesion[language])?language:'en';
                        let answerLang =(faq.answer[language])?language:'en';
                        return (<Accordion key={index} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id={"panel" + index + "a-header"}
                            >
                                <Typography>
                                    <b>{t("text_que")}: </b>{faq.quesion[quesionLang]}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <b>{t("text_answer")}: </b>{faq.answer[answerLang]}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>)
                    })}
                </Box>
            </div>
        </>
    )
}

export default RewardFAQ
