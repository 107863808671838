import { IconButton } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import DownloadIcon from '@material-ui/icons/GetApp';
import ListIcon from '@material-ui/icons/List';
import PrintIcon from '@material-ui/icons/Print';
import SearchIcon from '@material-ui/icons/Search';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import React from "react";
import { PrimaryTextField } from 'src/components/core/TextField';
import { useLanguage } from "src/components/language/LanguageController";
import { currencyFormat } from "src/components/library/util";
import { DefaultScrollbar } from "src/components/pages/common/Scrollbar";

const DairyMilkDetailListView = ({
    customers = [],
    shift ,
    cattle,
    customer,
    editMilk,
    deleteMilk,
    isFeatureSubscribed,
    filterChangeHandler,
    downloadCollectionDetail,
    printMilkBill,
    view,
    changeView
}) => {
    const { t } = useLanguage();
    const handleDelete = async (e, customer) => {
        e.preventDefault();
        deleteMilk(customer)
    };

    const deletTotalEntryNot = (cattle !== "TotalCowBuffalo" && shift !== "TotalMorningEvening")
    return (
        <>

            <DefaultScrollbar
                isEnabled={isFeatureSubscribed}
                className="dairy-milk-detail-scrollbar ">
                <div d-none d-sm-block>
                    <div className='d-flex mb-1'>
                        <PrimaryTextField
                            className='ml-2 mr-2 mt-1'
                            id="outlined-full-width"
                            size='small'
                            placeholder={t("text_search")}
                            onChange={filterChangeHandler}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment >
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <IconButton aria-label={t("text_download_now")} onClick={downloadCollectionDetail}>
                            <DownloadIcon />
                        </IconButton>
                        <IconButton
                            aria-label={t("text_print")}
                            onClick={printMilkBill}
                        >
                            <PrintIcon />
                        </IconButton>
                        <IconButton
                            aria-label={view === "list" ? t("text_switch_table_view") : t("text_switch_list_view")}
                            onClick={changeView}>
                            {view === "list" ? <ListIcon /> : <ViewCompactIcon />}
                        </IconButton>
                    </div>
                </div>
                {customers.map(customer => (
                    <div className="t-card shadow-sm mb-2" key={customer.dairy_customer_id}>
                        <div className="title right card__title card--material__title mt-0 mb-0 clearfix">
                            <div className="d-account-h1 float-left mt-0 mb-1 capitalize">
                                {`${customer.code} - ${customer.name}`}
                            </div>
                            {customer.liter && deletTotalEntryNot ? (
                                <div className="float-right">
                                    {/* {customer.edited === "0" ? ( */}
                                    <button
                                        disabled={!isFeatureSubscribed}
                                        className="btn btn-outline-success btn-sm mr-1"
                                        onClick={e => editMilk(e, customer)}>
                                        <i className="fa fa-edit" />
                                    </button>
                                    <button
                                        disabled={!isFeatureSubscribed}
                                        className="btn btn-outline-danger btn-sm"
                                        onClick={e => handleDelete(e, customer)}>
                                        <i className="fa fa-trash" />
                                    </button>
                                </div>
                            ) : null}
                        </div>

                        <div className={"content card__content card--material__content"}>
                            <div className="table-fix-header mt-1">
                                <table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>{t("text_liter")}</th>
                                            <th>{t("text_fat")}</th>
                                            <th>{t("text_clr")}</th>
                                            <th>{t("text_snf")}</th>
                                            <th>{t("text_rate")}</th>
                                            <th>{t("text_amount")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {isFeatureSubscribed && customer.liter && customer.liter != 0 ? currencyFormat(customer.liter, false) : '-'}
                                            </td>
                                            <td>
                                                {isFeatureSubscribed && customer.fat ? customer.fat : '-'}
                                            </td>
                                            <td>
                                                {isFeatureSubscribed && customer.clr ? customer.clr : '-'}
                                            </td>
                                            <td>
                                                {isFeatureSubscribed && customer.snf ? customer.snf : '-'}
                                            </td>
                                            <td>
                                                {isFeatureSubscribed && customer.liter && customer.liter != 0 ? currencyFormat(customer.rate) : '-'}
                                            </td>
                                            <td>
                                                {isFeatureSubscribed && customer.total && customer.liter != 0 ? currencyFormat(customer.total) : '-'}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                ))}
            </DefaultScrollbar>
        </>
    );
};

export default DairyMilkDetailListView;