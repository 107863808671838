import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLanguage } from "src/components/language/LanguageController";
import InnerHeaderSmall from "src/components/pages/common/InnerHeaderSmall";
import { PrimaryButton } from "../buttons/PrimaryButton";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    minHeight: "100vh",
    padding: "20px",
  },
  centeredButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  },
});

export default function NoAccessMessage() {
  const history = useHistory();
  const { t } = useLanguage();
  const classes = useStyles();

  return (
    <>
      <InnerHeaderSmall />
      <div className={classes.container}>
        <Typography variant="h3" className={classes.centeredButton}>
          {t("text_no_permission")}
        </Typography>
        <PrimaryButton
          variant="contained"
          color="primary"
          className={classes.centeredButton}
          onClick={() => history.replace("/")}
        >
          {t("text_go_to_homepage")}
        </PrimaryButton>
      </div>
    </>
  );
}
