// @ts-nocheck
import React, {Component} from 'react';
import LoginForm from './signin/LoginForm';
import Slider from "react-slick";
import { Page } from 'react-onsenui';
import analytics from '../library/firebase/analytics';
import { getLanguages } from '../../config/language';
import { t } from '../language/LanguageController';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

const settings = {
  className: "center_mode_slide",
  infinite: false,
  fade: false,
  slidesToShow: 1,
  slidesToScroll: 1     
};

class AppSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: "hi",
      languages: getLanguages()
    }
  }
  
  next = () => {
    this.slider.slickNext();
  }
  
  pushPage = () => {
    this.props.navigator.resetPage({
      component: LoginForm, props: {
        key: LoginForm.name
      }
    });
  }

  componentDidMount() {
    analytics.setScreenName("first_app_slider");
    window.localStorage.setItem("show_intro_slider", 1);
  }

  languageSubmitHandler = e => {
    e.preventDefault();
    analytics.logEvent("chooseLanguage", {
      language: this.state.language
    });
    window.localStorage.setItem("language", this.state.language);
    this.setState({
      redirect: "/login"
    });
  }

  inputChangeHandler = e => {
    this.setState({
      language: e.target.value
    })
  }

  render() {
    const { languages, language, redirect } = this.state;

    if(redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <Page className="page">
        <div className="App clearfix">
          <div className="clearfix">
              <div className="page_container">
                  <div className="clearfix">
                    <section className="section_splash_screen">
                        <Slider ref={c => (this.slider = c)} {...settings}>
                          <div className="slidercontent_parent">
                            <div className="language_header">
                              <div className="inner_header d-flex align-items-center justify-content-center">
                                <h3 className="text-center text-white">{t("text_choose_language", language)}</h3>
                              </div>
                            </div>
                            <div className="choose_language choose_language_box mt-65">
                              <form
                                onSubmit={this.languageSubmitHandler} 
                                className="language_choose_selection">
                                <div className="language_list theme_radio_design">
                                  <div className="app-slider-languages-list">
                                    {languages.map(lang => (
                                      <div className="inputGroup">
                                        <input 
                                          id={lang.code}
                                          name="language"
                                          type="radio" 
                                          checked={language === lang.code}
                                          onChange={this.inputChangeHandler}
                                          value={lang.code}/>
                                        <label htmlFor={lang.code}>{lang.display_name}</label>
                                      </div> 
                                    ))}
                                  </div>
                                  <button 
                                    type="submit" 
                                    className="btn btn-primary width100p">
                                      {t("text_continue", language)}
                                    </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </Slider>
                    </section>
                  </div>
              </div>
          </div>
        </div>
      </Page>);
  }
}

export default AppSlider;
