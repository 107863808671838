// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Icon } from "react-onsenui";
import { useLanguage } from "../../language/LanguageController";
import { useSelector } from "react-redux";
import analytics from "../../library/firebase/analytics";
import { DefaultLayout } from '../common/Layout';
import Logo from "../../../assets/img/Logo1/logo-color.png";
import { selectSupportNumber, selectWhatsappLink } from "src/reducers";
import appJson from "../../../../package.json";

const ContactUs = () => {
    const [appVersion, setAppVersion] = useState("");

    const { t } = useLanguage();
    const getAppVersion = async () => {
        let appVersion = appJson.version;
        if (window.literAPI) {
            appVersion = await window?.literAPI.appVersion()
        } else if(window.AppVersion){
            appVersion = window.AppVersion.version
        }
        setAppVersion(appVersion);
    }

    useEffect(() => {
        analytics.setScreenName("ContactUs");
        getAppVersion();
    }, []);

    const supportNumber = useSelector(selectSupportNumber);
    const whatsAppLink = useSelector(selectWhatsappLink);

    return (
        <DefaultLayout title={t("text_contact_us")}>
            <section className="contact-us-banner mt-5">
                <div className="">
                    <img src={Logo} alt="Liter" width="120" />
                </div>
                <div className="version mt-3">
                    {t("text_version")}: {appVersion}
                </div>
                <div className="contact text-center mt-3">
                    <h2>{t("text_contact")}</h2>
                    <div className="mt-2 mb-3">
                        <b>{t("text_time")}: </b> 10.00 AM - 6.00 PM
                    </div>
                    <div>
                        <a
                            href="mailto:support@liter.live"
                            className="mr-4"
                            onClick={() => analytics.logEvent("mailto")}
                        >
                            <Icon icon="fa-envelope" size={20}/>&nbsp;&nbsp;{t("text_email")}
                        </a>
                        <a
                            href={whatsAppLink}
                            target="_blank"
                            rel="noreferrer"
                            className=" mr-4"
                            onClick={() => analytics.logEvent("whatsApp")}
                        >
                            <Icon icon="fa-whatsapp" size={20}/>&nbsp;&nbsp;{t("text_whatsapp")}
                        </a>
                        <a href={`tel:${supportNumber}`} onClick={() => analytics.logEvent("call")}>
                            <i className="fa fa-phone fa-rotateY-180" style={{fontSize: "20px"}}/>&nbsp;&nbsp;{t("text_call")}
                        </a>
                    </div>
                </div>
                <div className="copyright text-center mt-3">
                    &#169; {(new Date()).getFullYear()} LoopSys Technologies.
                </div>
            </section>
        </DefaultLayout>
    );
}
export default ContactUs;