/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import request from "../../../library/request";

const DairyTopProducts = ({ t, dairyId }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { status, data } = await request.get(
          "/dairies/" + dairyId + "/topProducts",
          {
            withAuth: true,
          }
        );
        if (status) {
          setProducts(data);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    })();
  }, []);

  return (
    <div className="t-card mb-5 mt-n1 mt-ms-0 shadow-sm">
      <div className="title right card__title card--material__title mt-0 mb-0 clearfix">
        <div className="d-account-h1 pull-left mb-2">
          {t("text_top_products")}
        </div>
      </div>
      <div className="content card__content card--material__content clearfix">
        <table className="table">
          <thead>
            <tr>
              <th>{t("text_product")}</th>
              <th>{t("text_in_stock")}</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => {
              return (
                <tr key={product.id}>
                  <td className="text-break">{product.name}</td>
                  <td>{product.quantity}</td>
                </tr>
              );
            })}
            {!products.length ? (
              <tr>
                <td colSpan="2" className="text-center">
                  {t("text_no_data_available")}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DairyTopProducts;
