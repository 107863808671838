import { useEffect } from "react";

const RazorpayPaymentButton = ({ id, button_script, button_id }) => {

    const formId = `${id}_rzp_payment_form`;

    useEffect(() => {
        const rzpPaymentForm = document.getElementById(formId);
        if (!rzpPaymentForm.hasChildNodes() && button_script && button_id) {
            const script = document.createElement("script");
            script.src = button_script;
            script.async = true;
            script.dataset.payment_button_id = button_id;
            rzpPaymentForm.appendChild(script);
        }
    }, [button_script, button_id, formId]);

    return <form id={formId}></form>
};

export default RazorpayPaymentButton;