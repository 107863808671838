// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLanguage } from "../../../language/LanguageController";
import { FormHelperText, Box } from '@material-ui/core'; 
import { PrimarySwitch } from 'src/components/core/Switch';
import { getMilkEntryAutoPrint } from 'src/reducers/dairySettings';
import { updateSettings } from 'src/actions/dairySettings';

const AutomaticPrintSetting = () => {
    const { t } = useLanguage();
    const dispatch = useDispatch();
    const milkEntryAutoPrintEnabled = useSelector(getMilkEntryAutoPrint);
    const [autoPrintEnabled, setAutoPrintEnabled] = useState(false);

    useEffect(() => {
        setAutoPrintEnabled(milkEntryAutoPrintEnabled);
    }, [milkEntryAutoPrintEnabled]);

    const handleToggle = () => {
        const newValue = !autoPrintEnabled;
        setAutoPrintEnabled(newValue);
    };

    return (
        <Box className="form-group">
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <label className="mb-0">{t("text_allow_automatic_print")}</label>
                    <FormHelperText>{t("text_enable_auto_print_help")}</FormHelperText>
                </div>
                <div>
                    <PrimarySwitch
                        checked={autoPrintEnabled}
                        onChange={handleToggle}
                        name="auto_print_on_milk_entry"
                        value={autoPrintEnabled ? 1 : 0}
                    />
                </div>
            </div>
        </Box>
    );
};

export default AutomaticPrintSetting;
