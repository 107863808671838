import React from "react";
import { Fragment } from "react";
import { Icon, Radio } from 'react-onsenui';

const IncrementDecrementSlot = ({t, index, type, incDecBy, slot, stdSnf, 
    stdCLR, prevSlot, onDelete, onSlotChange, validator}) => {

    const handleInputChange = event => {
        onSlotChange(type, index, {
            name: event.target.getAttribute("data-name"),
            value: event.target.value
        });
    }

    const prevValue = prevSlot && prevSlot.value ? prevSlot.value : (incDecBy=== "snf" ? stdSnf : stdCLR);

    return (
        <Fragment>
            <tr>
                <td colSpan={3} className="pb-0">
                    <div className="form-group mb-0">
                        <div className="form-check form-check-inline mb-1">
                            <Radio
                                inputId={`${type}_on_base_rate_${slot.id}`}
                                name={`${type}[${index}][on]`}
                                value="base_rate"
                                modifier='material form-check-input'
                                defaultChecked={slot.on === "base_rate"}/>
                            <label
                                htmlFor={`${type}_on_base_rate_${slot.id}`}
                                className="form-check-label">
                                {t("text_base_rate")}
                            </label>
                        </div>
                        <div className="form-check form-check-inline mr-0">
                            <Radio
                                inputId={`${type}_on_calculated_rate_${slot.id}`}
                                name={`${type}[${index}][on]`}
                                value="calculated_rate"
                                modifier='material form-check-input'
                                defaultChecked={slot.on === "calculated_rate"}/>
                            <label 
                                htmlFor={`${type}_on_calculated_rate_${slot.id}`}
                                className="form-check-label">
                                {t("text_calculated_rate")}
                            </label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="pb-0 no-border-top">
                    <div className="form-group mb-0">
                        <div className="input-group">
                            <span className="input-group-prepend">
                                <span className="input-group-text">{type === "increment" ? ">" : "<"}</span>
                            </span>
                            <input 
                                type="number"
                                className="form-control"
                                name={`${type}[${index}][value]`}
                                data-name="value"
                                value={slot.value}
                                readOnly={index === 0}
                                onChange={handleInputChange} />
                        </div>
                        {index !== 0 ? (() => {
                            let minMaxValue;
                            if( incDecBy == "snf") {
                                minMaxValue = (+prevSlot.value + (type === "increment" ? 0.1 : -0.1)).toFixed(1);
                            } else {
                                minMaxValue = parseInt(prevSlot.value + (type === "increment" ? 1 : -1));
                            }
                            return validator.message(
                                `${type}_${incDecBy}`,
                                slot.value,
                                `required|numeric|${type === "increment" ? "min" : "max"}:${minMaxValue},num`) 
                        })(): null}
                        <input type="hidden" name={`${type}[${index}][id]`} defaultValue={slot.id}/>
                    </div>
                </td>
                <td className="pb-0 pr-0 pl-0 no-border-top">
                    <div className="form-group mb-0">
                        <div className="input-group">
                            <input 
                                type="number"
                                className="form-control"
                                name={`${type}[${index}][rate]`}
                                data-name="rate"
                                value={slot.rate}
                                onChange={handleInputChange} />
                            <select 
                                className="form-control pl-0 pr-0"
                                name={`${type}[${index}][type]`}
                                data-name="type"
                                value={slot.type}
                                onChange={handleInputChange}>
                                <option value="fixed">{t("text_pe")}</option>
                                <option value="percent">{"%"}</option>
                            </select>
                        </div>
                        {validator.message(`${type}_${incDecBy}_rate`, slot.rate, `required|numeric`)}
                    </div>
                </td>
                <td className="pb-0 no-border-top">
                    {index !== 0 ?  
                        <Icon className="text-red mt-3" icon="times" onClick={e => onDelete(e, index)}/>
                        : null}
                </td>
            </tr>
            
            <tr>
                <td colSpan={3} className="no-border-top border-top-bottom">
                    <div className="form-group mb-0">
                        <div className="form-check form-check-inline">
                            <Radio
                                inputId={`${type}_on_compound_${slot.id}`}
                                name={`${type}[${index}][by]`}
                                value="compound"
                                modifier='material form-check-input'
                                defaultChecked={slot.by ? slot.by === "compound" : true}/>
                            <label 
                                htmlFor={`${type}_on_compound_${slot.id}`}
                                className="form-check-label">
                                {t("text_compound")}
                            </label>
                        </div>
                        <div className="form-check form-check-inline mr-0">
                            <Radio
                                inputId={`${type}_on_fixed_${slot.id}`}
                                name={`${type}[${index}][by]`}
                                value="fixed"
                                modifier='material form-check-input'
                                defaultChecked={slot.by === "fixed"}/>
                            <label
                                htmlFor={`${type}_on_fixed_${slot.id}`}
                                className="form-check-label">
                                {t("text_fix")}
                            </label>
                        </div>
                    </div>
                </td>
            </tr>
        </Fragment>
    );
}

export default IncrementDecrementSlot;