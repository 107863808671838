// @ts-nocheck
import React, { Component } from 'react';
import moment from "moment";
import { Radio, Page } from 'react-onsenui';
import * as Sentry from "@sentry/react";
import { toast, loader } from '../../library/util';
import InnerHeaderSmall from '../common/InnerHeaderSmall';
import withLanguage from '../../language/LanguageController';
import SimpleValidator from '../../common/SimpleValidator';
import { errorHandler } from '../../library/response';
import MuiDatepicker from '../../common/MuiDatepicker';
import analytics from '../../library/firebase/analytics';
import request from '../../library/request';
import config from '../../../config';
import { connect } from 'react-redux';
import { DefaultLayout } from '../common/Layout';
import Eventer from 'src/components/library/Eventer';

class AddMilk extends Component {

  constructor(props) {
    super(props);
    this.getMilk = this.getMilk.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
    this.state = this.getInitialState();
    this.validator = new SimpleValidator();
  }

  getInitialState = () => {
    return {
      "readOnly": false,
      "day_time": "morning",
      "date": moment(),
      "maxDate": moment(),
      "milk_type": "cow",
      "milk_animals": "cow,buffalo",
      "liter": '',
      "fat": '',
      "rate": '',
      "total": ''
    };
  }

  componentDidMount() {
    analytics.setScreenName("AddMilk");
    this.getMilk();
    Eventer.on("milkDeleted", this.getMilk);
  }

  componentWillUnmount() {
    Eventer.off("milkDeleted", this.getMilk);
  }

  handleInput = e => {
    const target = e.target,
        name = target.name;
    
    this.setState({
      [name]: target.value
    }, () => {
      if(name == "liter" || name == "rate"){
        let total = this.state.liter * this.state.rate;
        total = (total) ? total.toFixed(2) : total;
        this.setState({
          total: total
        });
      } else if(name == "milk_type" 
        || name == "date"
        || name == "day_time"){
        this.getMilk()
      }
    });
  }

  dateChangeHandler = (selectedDate) => {
    this.validator.clearAll();
    this.setState({
      date: selectedDate
    }, _ => {
      this.getMilk();
    });
  }

  async getMilk () {
    try {
      const dayTimeI = this.state.day_time === "morning" ? 0 : 1;
      let stateData = {
        "liter": '',
        "fat": '',
        "rate": '',
        "total": '',
        "readOnly": false
      };
      let filterStr = '?milk_type=' + this.state.milk_type;
          filterStr += '&date=' + this.state.date.format(config.date_format) + ':' + this.state.date.format(config.date_format);
          filterStr += '&day_time=' + this.state.day_time;
      const { status, data } = await request.get('/diary/' + this.props.customerId + filterStr, {
        withAuth: true
      });
      if(status === true && data.result[0] && data.result[0]['liter_' + dayTimeI] > 0){
        stateData = {
          "liter": data.result[0]['liter_' + dayTimeI],
          "fat": data.result[0]['fat_' + dayTimeI],
          "rate": data.result[0]['rate_' + dayTimeI],
          "total": data.result[0]['total_' + dayTimeI],
          "readOnly": true
        };
      };
      this.setState(stateData );
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async formSubmitHandler (e) {
    e.preventDefault();
    try {
      // validate form before submitting to server 
      if (!this.validator.allValid()) {
        console.log("Error in Add milk form");
        this.validator.showMessages();
        this.forceUpdate(); // rerender to show messages for the first time
        return false;
      }
      analytics.logEvent("addMilk");
      loader.show(this.props.t("text_saving"));
      const { status, message } = await request.post("/diary/" + this.props.customerId, {
        withAuth: true,
        body: new FormData(document.getElementById("milkForm"))
      });
      if (status === true) {
        toast(message);
        this.setState({
          readOnly: true
        });
        Eventer.emit("milkAdded");
      } else {
        throw message;
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      loader.hide();
    }
  }

  validateField = e => {
    if(this.validator.fieldValid(e.target.name)) {
      this.validator.hideMessageFor(e.target.name);
    } else {
      this.validator.showMessageFor(e.target.name);
    }
    this.forceUpdate();
  }

  render() {
    const { t, header } = this.props;
    return (      
      <DefaultLayout
        back
        bottomGutter={false}
        header={header}
        title={t("text_add_milk")}
        toolbar={false}>
        <div className="t-card shadow-sm">
          <form id="milkForm" onSubmit={this.formSubmitHandler}>
            <div className="form-group date-group">
              <label>{t("text_date")}</label>
              <MuiDatepicker
                value={this.state.date}
                maxDate={this.state.maxDate}
                onChange={this.dateChangeHandler}
                name="date"
              />
            </div>
            <div className="form-group">
              <label className="d-block" >{t("text_animal")}: </label>
              <div className="form-check form-check-inline">
                <Radio 
                  modifier="material form-check-input" 
                  inputId="animal_type_cow"
                  name="milk_type" 
                  value="cow"
                  checked={this.state.milk_type === "cow"}
                  onChange={this.handleInput}/>
                <label htmlFor="animal_type_cow" 
                  className="form-check-label">
                    {t("text_cow")}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <Radio 
                  modifier="material form-check-input"
                  inputId="animal_type_buffalo"
                  name="milk_type"
                  value="buffalo"
                  checked={this.state.milk_type === "buffalo"}
                  onChange={this.handleInput}/>
                <label htmlFor="animal_type_buffalo"
                  className="form-check-label">
                    {t("text_buffalo")}
                </label>
              </div>
            </div>
            <div className="form-group">
              <label className="d-block" >{t("text_day_time")}: </label>
              <div className="form-check form-check-inline">
                <Radio 
                  modifier="form-check-input"
                  inputId="day_time_morning"
                  name="day_time" 
                  value="morning"
                  required
                  checked={this.state.day_time === "morning"}
                  onChange={this.handleInput}/>
                <label 
                  className="form-check-label"
                  htmlFor="day_time_morning">
                    {t("text_morning")}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <Radio 
                  className="form-check-input"
                  id="day_time_evening"
                  name="day_time"
                  value="evening"
                  required
                  checked={this.state.day_time === "evening"}
                  onChange={this.handleInput}/>
                <label 
                  className="form-check-label"
                  htmlFor="day_time_evening">
                    {t("text_evening")}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label>{t("text_liter")}</label>
                  <input type="number" 
                    className="form-control"
                    name="liter"
                    required
                    min="0.10"
                    step="0.01"
                    max="9999.99"
                    value={this.state.liter}
                    readOnly={this.state.readOnly}
                    onBlur={this.validateField}
                    onChange={this.handleInput} />
                  {this.validator.message('liter', this.state.liter, 'required|numeric|min:0.10,num|max:9999.99,num')}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>{t("text_fat")}</label>
                  <input type="number" 
                    className="form-control"
                    name="fat"
                    min="2.5"
                    max="15"
                    step="0.1"
                    value={this.state.fat}
                    readOnly={this.state.readOnly}
                    onBlur={this.validateField}
                    onChange={this.handleInput} />
                  {this.validator.message('fat', this.state.fat, 'numeric|min:2.5,num|max:15,num')}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label>{t("text_rate")}</label>
                  <input type="number" 
                    className="form-control"
                    name="rate"
                    required
                    step="0.01"
                    value={this.state.rate}
                    readOnly={this.state.readOnly}
                    onBlur={this.validateField}
                    onChange={this.handleInput} />
                  {this.validator.message('rate', this.state.rate, 'required|numeric|min:0.10,num|max:9999.99,num')}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>{t("text_total")}</label>
                  <input type="text"
                    className="form-control"
                    name="total"
                    required
                    readOnly
                    value={this.state.total}
                    onChange={this.handleInput} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <button
                  type="submit"
                  disabled={this.state.readOnly}
                  className="btn btn-success btn-block">
                  {t("text_save")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </DefaultLayout>
    );
  }
}

AddMilk.displayName = "AddMilk";

AddMilk.defaultProps = {
  header: true
};

const mapStateToProps = state => ({
  customerId: state.userReducer.user.customer_id
});

export default connect(mapStateToProps)(withLanguage(AddMilk));
