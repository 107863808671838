import { copyObject } from "../components/library/util";
import { 
    ADD_DAIRY_USERS_SUCCESS,
    DELETE_DAIRY_USERS_SUCCESS,
    FETCH_DAIRY_USERS_FAILURE,
    FETCH_DAIRY_USERS_REQUEST,
    FETCH_DAIRY_USERS_SUCCESS,
    UPDATE_DAIRY_USERS_SUCCESS
} from "../_constants/dairyUsers";

const initialState = {
    users: [],
    is_fetching: true,
};

function dairyUsers(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_DAIRY_USERS_REQUEST: {
            return {
                ...state,
                is_fetching: true
            }
        }
        case FETCH_DAIRY_USERS_SUCCESS: {
            return {
                ...state,
                users: payload,
                is_fetching: false,
            };
        }
        case FETCH_DAIRY_USERS_FAILURE: {
            return {
                ...state,
                is_fetching: false,
            };
        }
        case ADD_DAIRY_USERS_SUCCESS: {
            return {
                ...state,
                users: [...state.users, payload]
            }
        }
        case UPDATE_DAIRY_USERS_SUCCESS: {
            const users = copyObject(state.users);
            const index = users.findIndex(user => user.customer_dairy_id === payload.dairyUserId);
            users[index] = payload.data;
            return {
                ...state,
                users: [...users]
            }
        }
        case DELETE_DAIRY_USERS_SUCCESS: {
            const users = copyObject(state.users);
            const index = users.findIndex(user => user.customer_dairy_id === payload.dairyUserId);
            return {
                ...state,
                users: [
                    ...users.slice(0, index),
                    ...users.slice(index + 1)
                ]
            }
        }
        default:
            return state;
    }
}

export const getUserId = state => state.userReducer?.user?.customer_id;
export const getUserGroupId = state => state.userReducer?.user?.customer_group_id;

export default dairyUsers;
