/* eslint-disable no-console */
import moment from "moment";
import config from "../../config";
import { t } from "../language/LanguageController";
import { parseNumber, toFixed } from "../library/math";
import { currencyFormat, ucFirst } from "../library/util";
import { printReceipt } from "../services/printerService";

const printFonts = () => {
    return `
    <style>
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
        table {
            font-family: 'Poppins', sans-serif;
        }
    </style>
    `;
}

export const milkDetailReceipt = async (
    dairyName,
    customerName,
    code,
    date,
    liter,
    fat,
    clr,
    snf,
    rate,
    total,
    dayTime,
    milkType,
    billTotal,
    dairyPreference
) => {
    date = moment(date, "DD-MM-YYYY").format(config.display_date_format);
    let template = printFonts() + "<table style='width:410px;font-size:25px;line-height:30px;padding-bottom:20px;'>\n";
    
    template += `<tr><td>${dairyName}</td></tr>\n`;
    template += "<tr><td style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    template += `<tr><td>${code} - ${customerName}</td></tr>\n`;
    template += "<tr><td style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    template += `<tr><td>${date} - ${ucFirst(t("text_" + dayTime))} - ${ucFirst(t("text_" + milkType))}</td></tr>\n`;
    template += "<tr><td style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    template += `<tr><td><div style='display:inline-block;width:150px'>${t("text_milk")}</div><div style='display:inline-block;'>: ${liter} ${t("text_ltr")}</div></td></tr>\n`;
    if(fat) {
        template += `<tr><td><div style='display:inline-block;width:150px'>${t("text_fat")}</div><div style='display:inline-block;'>: ${fat}</div></td></tr>\n`;
    }
    if(clr) {
        template += `<tr><td><div style='display:inline-block;width:150px'>${t("text_clr")}</div><div style='display:inline-block;'>: ${clr ? clr : ""}</div></td></tr>\n`;
    }
    if(snf) {
        template += `<tr><td><div style='display:inline-block;width:150px'>${t("text_snf")}</div><div style='display:inline-block;'>: ${snf ? snf : ""}</div></td></tr>\n`;
    }
    template += `<tr><td><div style='display:inline-block;width:150px'>${t("text_rate")}</div><div style='display:inline-block;'>: ${rate} / ${t("text_ltr")}</div></td></tr>\n`;
    template += "<tr><td style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    template += `<tr><td><div style='display:inline-block;'>${t("text_total")}</div><div style='display:inline-block;'>: ${total} /-</div></td></tr>\n`;
    template += "<tr><td style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    if(dairyPreference?.bill_amount_in_msg?.value == 1 ){
        template += `<tr><td style="line-height:2;"><div style='display:inline-block;'>${t("text_total_milk")}</div><div style='display:inline-block;'>: ${billTotal?.total_milk} ${t("text_ltr")}</div></td></tr>\n\n`;
        template += `<tr><td><div style='display:inline-block;'>${t("bill_amount")}</div><div style='display:inline-block;'>: ${billTotal?.total_amount} /-</div></td></tr>\n\n`;
        template += "<tr><td style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    }
    template += templateFooter();

    await printReceipt(template);
};

export const customerBillReceipt = async (dairyName, customerName, customer_id, customerType, billingCycle, bill, cowMilkRecords = [], buffaloMilkRecords = []) => {
    const { startDate, endDate, products, milk_detail: milkDetail, advance } = bill;
    let date;
    if(billingCycle === "monthly") {
        date = startDate.format('MMM, YYYY');
    } else {
        date = `${startDate.format("Do")} - ${endDate.format("Do, MMM YY")}`;
    }

    let template = printFonts() + "<table style='width:410px;font-size:23px;line-height:30px;padding-bottom:20px;'>\n";
    
    template += `<tr><td colspan='2' style="padding-top:5px;">${dairyName}</td></tr>`;
    template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    template += `<tr><td colspan='2'>${billingCycle === "monthly" ? t("text_bill_receipt_month") : t("text_bill_receipt_date")}: ${date}</td></tr>\n`;
    template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    template += `<tr><td colspan='2'>LTR${customer_id} - ${customerName}</td></tr>\n`;
    template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    template += `<tr><td>${t("text_last_due")}</td><td>: ${toFixed(bill.last_due)}</td></tr>\n`;
    template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    template += `<tr><td>${t("text_milk_amount")}</td><td>: ${toFixed(bill.milk_amount)}</td></tr>\n`;
    template += `<tr><td>${t("text_product_purchase")}</td><td>: ${toFixed(bill.product_amount)}</td></tr>\n`;
    template += `<tr><td>${t("text_advance")}</td><td>: ${toFixed(bill.advance_payment)}</td></tr>\n`;
    if(customerType === "seller") {
        template += `<tr><td>${t("text_payable_amount")}</td><td>: ${toFixed(bill.payable_amount)}</td></tr>\n`;
    } else {
        template += `<tr><td>${t("text_receivable_amount")}</td><td>: ${toFixed(bill.payable_amount)}</td></tr>\n`;
    }
    template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    if(customerType === "seller") {
        template += `<tr><td>${t("text_net_payable")}</td><td>: ${toFixed(bill.net_payable)}</td></tr>\n`;
    } else {
        template += `<tr><td>${t("text_net_receivable")}</td><td>: ${toFixed(bill.net_payable)}</td></tr>\n`;
    }
    if(bill.transferd_to_loan != "0.00") {
        template += `<tr><td>${t("text_transferred_to_loan")}</td><td>: ${toFixed(bill.transferd_to_loan)}</td></tr>\n`;
    }
    if(bill.cash_recovered != "0.00") {
        if(customerType === "seller") {
            template += `<tr><td>${t("text_cash_recovered")}</td><td>: ${toFixed(bill.cash_recovered)}</td></tr>\n`;
        } else {
            template += `<tr><td>${t("text_received_amount")}</td><td>: ${toFixed(bill.cash_recovered)}</td></tr>\n`;
        }
    }
    if(customerType === "seller") {
        template += `<tr><td>${t("text_paid")}</td><td>: ${toFixed(bill.paid_amount)}</td></tr>\n`;
    } else {
        template += `<tr><td>${t("text_received")}</td><td>: ${toFixed(bill.paid_amount)}</td></tr>\n`;
    }
    template += `<tr><td>${t("text_remaining")}</td><td>: ${toFixed(bill.remaining)}</td></tr>\n`;
    template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    template += "</table>";

    if(milkDetail) {
        template += "<table style='width:410px;font-size:23px;line-height:30px;padding-bottom:20px;'>\n";
        template += `<tr><td colspan='2'>${t("text_milk_total")}</td></tr>\n`;
        template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
        template += `<tr><td>${t("text_total_milk")}</td><td>: ${toFixed(milkDetail?.total?.total_milk)}</td></tr>\n`;
        template += `<tr><td>${t("text_total_amount")}</td><td>: ${toFixed(milkDetail?.total?.total_amount)}</td></tr>\n`;
        template += `<tr><td>${t("text_avg_milk")}</td><td>: ${toFixed(milkDetail?.total?.avg_milk)} / ${t("text_day")}</td></tr>\n`;
        template += `<tr><td>${t("text_avg_amount")}</td><td>: ${toFixed(milkDetail?.total?.avg_amount)} / ${t("text_day")}</td></tr>\n`;
        template += `<tr><td>${t("text_avg_rate")}</td><td>: ${toFixed(milkDetail?.total?.avg_rate)} / ${t("text_ltr")}</td></tr>\n`;
        template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
        
        if(milkDetail 
            && milkDetail.cow_total 
            && milkDetail.cow_total.total_milk > 0 
            && milkDetail.buffalo_total.total_milk > 0) {
            template += `<tr><td colspan='2'>${t("text_cow_milk_total")}</td></tr>\n`;
            template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
            template += `<tr><td>${t("text_total_milk")}</td><td>: ${toFixed(milkDetail.cow_total.total_milk)}</td></tr>\n`;
            template += `<tr><td>${t("text_total_amount")}</td><td>: ${toFixed(milkDetail.cow_total.total_amount)}</td></tr>\n`;
            template += `<tr><td>${t("text_avg_milk")}</td><td>: ${toFixed(milkDetail.cow_total.avg_milk)} / ${t("text_day")}</td></tr>\n`;
            template += `<tr><td>${t("text_avg_amount")}</td><td>: ${toFixed(milkDetail.cow_total.avg_amount)} / ${t("text_day")}</td></tr>\n`;
            template += `<tr><td>${t("text_avg_rate")}</td><td>: ${toFixed(milkDetail.cow_total.avg_rate)} / ${t("text_ltr")}</td></tr>\n`;
            template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
            template += `<tr><td colspan='2'>${t("text_buffalo_milk_total")}</td></tr>\n`;
            template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
            template += `<tr><td>${t("text_total_milk")}</td><td>: ${toFixed(milkDetail.buffalo_total.total_milk)}</td></tr>\n`;
            template += `<tr><td>${t("text_total_amount")}</td><td>: ${toFixed(milkDetail.buffalo_total.total_amount)}</td></tr>\n`;
            template += `<tr><td>${t("text_avg_milk")}</td><td>: ${toFixed(milkDetail.buffalo_total.avg_milk)} / ${t("text_day")}</td></tr>\n`;
            template += `<tr><td>${t("text_avg_amount")}</td><td>: ${toFixed(milkDetail.buffalo_total.avg_amount)} / ${t("text_day")}</td></tr>\n`;
            template += `<tr><td>${t("text_avg_rate")}</td><td>: ${toFixed(milkDetail.buffalo_total.avg_rate)} / ${t("text_ltr")}</td></tr>\n`;
            template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
        }
        template += "</table>";
    }

    if(products.length) {
        template += "<table style='width:410px;font-size:23px;line-height:30px;padding-bottom:20px;'>\n";
        template += `<tr><td>${t("text_products")}</td><td>${toFixed(bill.productTotal)}</td></tr>\n`;
        template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
        template += `<tr><td colspan='2'>${t("text_date")}--${t("text_price")}--${t("text_qty")}--${t("text_total")}</td></tr>\n`;
        template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
        for(let product of products) {
            let productDate = moment(product.date).format("D/MM");
            template += `<tr><td colspan='2'>${product.name}</td></tr>\n`;
            template += `<tr><td colspan='2'>${productDate}--${product.price}--${product.quantity}--${toFixed(product.total)}</td></tr>\n`;
            template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
        }
        template += "</table>";
    }

    if(advance.length) {
        template += "<table style='width:410px;font-size:23px;line-height:30px;padding-bottom:20px;'>\n";
        template += `<tr><td colspan='2'>${t("text_advance_payment")}</td></tr>\n`;
        template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>---------------------------------</td></tr>\n";
        template += `<tr><td>${t("text_date")}</td><td>${t("text_amount")}</td></tr>\n`;
        template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>---------------------------------</td></tr>\n";
        for(let singleAdvance of advance) {
            let advanceDate = moment(singleAdvance.date).format("D/MM");
            template += `<tr><td>${advanceDate}</td><td>${singleAdvance.amount}</td></tr>\n`;
            if(singleAdvance.remark) {
                template += `<tr><td colspan='2'>${t("text_remark")}: ${singleAdvance.remark}</td></tr>\n`;
            }
            template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>---------------------------------</td></tr>\n";
        }
        template += "</table>";
    }

    if(cowMilkRecords.length) {
        template += "<table style='width:410px;font-size:23px;line-height:30px;padding-bottom:20px;'>\n";
        template += `<tr><td colspan='2'>${t("text_milk_detail")} ${(cowMilkRecords.length > 0 && buffaloMilkRecords.length > 0) ?  " - " + t("text_cow") : ""}</td></tr>\n`;
        template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>---------------------------------</td></tr>\n";
        template += "</table>";
        template += renderMilkStatement(cowMilkRecords);
        template += `<table style='width:410px;font-size:23px;line-height:1;'><tr><td style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr></table>`;
    }    

    if(buffaloMilkRecords.length) {
        template += "<table style='width:410px;font-size:23px;line-height:30px;padding-bottom:20px;'>\n";
        template += `<tr><td colspan='2'>${t("text_milk_detail")} ${(cowMilkRecords.length > 0 && buffaloMilkRecords.length > 0) ? " - " + t("text_buffalo") : ""}</td></tr>\n`;
        template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>---------------------------------</td></tr>\n";
        template += "</table>";
        template += renderMilkStatement(buffaloMilkRecords);
        template += `<table style='width:410px;font-size:23px;line-height:1;'><tr><td style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr></table>`;
    }

    template += "<table style='width:410px;font-size:23px;line-height:1;'>\n";
    template += templateFooter();

    await printReceipt(template);
};

export const customerMilkDetailReceipt = async (dairyName, customerName, code, milkType, records, startDate, endDate) => {
    startDate = moment(startDate, "DD-MM-YYYY").format("DD/MM/YY");
    endDate = moment(endDate, "DD-MM-YYYY").format("DD/MM/YY");
    let template = printFonts() + "<table style='width:410px;font-size:23px;line-height:1.5;'>\n";
    template += `<tr><td colspan='3'>${dairyName}</td></tr>\n`;
    template += "<tr><td colspan='3' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    template += `<tr><td colspan='3'>${code} - ${customerName}</td></tr>\n`;
    template += "<tr><td colspan='3' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    template += `<tr><td colspan='3'>${t("text_milk_detail_receipt")}</td></tr>\n`;
    template += "<tr><td colspan='3' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    template += `<tr><td colspan='3'>${t(`text_${milkType}`)}: ${startDate}-${endDate}</td></tr>\n`;
    template += "<tr><td colspan='3' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    template += "</table>";

    template += renderMilkStatement(records);
    
    template += "<table style='width:410px;font-size:23px;line-height:1;'>\n";
    template += templateFooter();

    await printReceipt(template);
};

export const renderMilkStatement = (records = []) => {
    const tdStyle = `border: 1px solid #3c3c3c;`;
    let template ="<table class='table-bordered' style='width:360px;font-size:22px;text-align:center;line-height:1.5;' cellspacing='0' cellpadding='1'>\n"
    template +="<tr>\n"
    template +=`<th style='${tdStyle}border-bottom:none;'>${t("text_date")}</th>\n`
    template +=`<th style='${tdStyle}border-bottom:none;'>${t("text_milk")}</th>\n`
    template +=`<th style='${tdStyle}border-bottom:none;'>${t("text_fat")}</th>\n`
    template +=`<th style='${tdStyle}border-bottom:none;'>${t("text_clr")}</th>\n`
    template +=`<th style='${tdStyle}border-bottom:none;'>${t("text_rate")}</th>\n`
    template +=`<th style='${tdStyle}border-bottom:none;'>${t("text_total")}</th>\n`
    template +="</tr>\n"

    records.forEach((record, index) => {
        const isLastChild = records.length - 1 === index;
        
        template +="<tr>\n"
        template +=`<td style='white-space: nowrap;${tdStyle}border-bottom:none;'>${moment(record.date).format("DD/MM")} ${t("text_morning_short")} </td>\n`
        template +=`<td style='${tdStyle}border-bottom:none;'>${record.liter_0 ? record.liter_0 : " - "}</td>\n`
        template +=`<td style='${tdStyle}border-bottom:none;'>${record.fat_0 ? record.fat_0 : " - "}</td>\n`
        template +=`<td style='${tdStyle}border-bottom:none;'>${record.clr_0 ? record.clr_0 : " - "}</td>\n`
        template +=`<td style='${tdStyle}border-bottom:none;'>${record.rate_0 ? record.rate_0 : " - "}</td>\n`
        template +=`<td style='${tdStyle}border-bottom:none;'>${record.total_0 ? record.total_0 : " - "}</td>\n`
        template +="</tr>\n"
        template +="<tr>\n"
        template +=`<td style='white-space: nowrap;${tdStyle}'>${moment(record.date).format("DD/MM")} ${t("text_evening_short")} </td>\n`
        template +=`<td style='${tdStyle}border-bottom:${isLastChild ? "1px solid #3c3c3c" : "none"};'>${record.liter_1 ? record.liter_1 : " - "}</td>\n`
        template +=`<td style='${tdStyle}border-bottom:${isLastChild ? "1px solid #3c3c3c" : "none"};'>${record.fat_1 ? record.fat_1 : " - "}</td>\n`
        template +=`<td style='${tdStyle}border-bottom:${isLastChild ? "1px solid #3c3c3c" : "none"};'>${record.clr_1 ? record.clr_1 : " - "}</td>\n`
        template +=`<td style='${tdStyle}border-bottom:${isLastChild ? "1px solid #3c3c3c" : "none"};'>${record.rate_1 ? record.rate_1 : " - "}</td>\n`
        template +=`<td style='${tdStyle}border-bottom:${isLastChild ? "1px solid #3c3c3c" : "none"};'>${record.total_1 ? record.total_1 : " - "}</td>\n`
        template +="</tr>\n";
    });
        
    template +="</table>\n";

    return template;
}

export const milkSummaryReceipt = async (dairyName, date, milkType, dayTime, records) => {
    let template = printFonts() + "<table style='width:410px;font-size:23px;line-height:30px;padding-bottom:20px;'>\n";
    template += `<tr><td>${dairyName}</td></tr>\n`;
    template += "<tr><td style='font-family: monospace;font-size: 19px;'>-----------------------------------</td></tr>\n"; 
    template += `<tr><td>${date} - ${ucFirst(dayTime == 0 ? t("text_morning") : dayTime == 1 ? t("text_evening") : t("text_morning") + " + " + t("text_evening"))} - ${ucFirst(t("text_" + milkType))}</td></tr>\n`;
    template += "<tr><td style='font-family: monospace;font-size: 19px;'>-----------------------------------</td></tr>\n";
    template += "</table>";

    const tdStyle = `border: 1px solid #3c3c3c;`;
    template +="<table style='width:410px;font-size:20px;line-height:30px;text-align:center;padding-bottom:20px;' cellspacing='0'>\n"
    template +=`<tr><th style="${tdStyle}text-align:left;" colspan="5">${t("text_code")} - ${t("text_name")}</th></tr>\n`;
    template +="<tr>\n"
    template +=`<th style="${tdStyle}">${t("text_milk")}</th>\n`
    template +=`<th style="${tdStyle}">${t("text_fat")}</th>\n`
    template +=`<th style="${tdStyle}">${t("text_clr")}</th>\n`
    template +=`<th style="${tdStyle}">${t("text_rate")}</th>\n`
    template +=`<th style="${tdStyle}">${t("text_total")}</th>\n`
    template +="</tr>\n"

    records.forEach((record, index) => {
        template +=`<tr><td style="${tdStyle};border-bottom:none;text-align:left;" colspan="5">${record.code} - ${record.name}</td></tr>\n`;
        template +="<tr>\n"
        template +=`<td style="${tdStyle}">${record.liter + t("text_ltr")}</td>\n`
        template +=`<td style="${tdStyle}">${record.fat || ""}</td>\n`
        template +=`<td style="${tdStyle}">${record.clr ? record.clr : ""}</td>\n`
        template +=`<td style="${tdStyle}">${record.rate}</td>\n`
        template +=`<td style="${tdStyle}">${currencyFormat(record.total)}</td>\n`
        template +="</tr>\n"
    });
    template +="</table>\n"

    template += "<table style='width:410px;font-size:23px;line-height:1;'>\n";
    template += templateFooter();

    await printReceipt(template);
};

export const printCustomerMilkTotal = async (dairyName, customerName, code, customerType, { startDate, endDate }, data) => {
    const { products, advance, total, product_total, advanceTotal } = data;
    const date = `${startDate.format("Do")} - ${endDate.format("Do, MMM YY")}`;
    
    const purchaseTotal = parseNumber(product_total.amount) + parseNumber(advanceTotal);
    const finalAmount = parseNumber(total.total_amount) - purchaseTotal;
    let template = printFonts() + "<table style='width:410px;font-size:23px;line-height:30px;padding-bottom:20px;'>\n";
    
    template += `<tr><td colspan='2' style="padding-top:5px;">${dairyName}</td></tr>`;
    template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    template += `<tr><td colspan='2'>${t("text_date")}: ${date}</td></tr>\n`;
    template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    template += `<tr><td colspan='2'>${code} - ${customerName}</td></tr>\n`;
    template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    template += `<tr><td>${t("text_total_milk")}</td><td>: ${toFixed(total.total_milk)}</td></tr>\n`;
    template += `<tr><td>${t("text_total_amount")}</td><td>: ${toFixed(total.total_amount)}</td></tr>\n`;
    template += `<tr><td>${t("text_avg_milk")}</td><td>: ${toFixed(total.avg_milk)}</td></tr>\n`;
    template += `<tr><td>${t("text_avg_amount")}</td><td>: ${toFixed(total.avg_amount)}</td></tr>\n`;
    template += `<tr><td>${t("text_avg_rate")}</td><td>: ${toFixed(total.avg_rate)}</td></tr>\n`;
    template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    template += "</table>";

    if(products.length) {
        template += "<table style='width:359px;font-size:23px;line-height:30px;padding-bottom:20px;'>\n";
        template += `<tr><td>${t("text_products")}</td><td>${toFixed(product_total.amount)}</td></tr>\n`;
        template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
        template += `<tr><td colspan='2'>${t("text_date")}--${t("text_price")}--${t("text_qty")}--${t("text_total")}</td></tr>\n`;
        template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
        for(let product of products) {
            let productDate = moment(product.date).format("D/MM");
            template += `<tr><td colspan='2'>${product.name}</td></tr>\n`;
            template += `<tr><td colspan='2'>${productDate}--${product.price}--${product.quantity}--${toFixed(product.total)}</td></tr>\n`;
            template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
        }
        template += "</table>";
    }

    if(advance.length) {
        template += "<table style='width:359px;font-size:23px;line-height:30px;padding-bottom:20px;'>\n";
        template += `<tr><td colspan='2'>${t("text_advance_payment")}</td></tr>\n`;
        template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>---------------------------------</td></tr>\n";
        template += `<tr><td>${t("text_date")}</td><td>${t("text_amount")}</td></tr>\n`;
        template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>---------------------------------</td></tr>\n";
        for(let singleAdvance of advance) {
            let advanceDate = moment(singleAdvance.date).format("D/MM");
            template += `<tr><td>${advanceDate}</td><td>${singleAdvance.amount}</td></tr>\n`;
            if(singleAdvance.remark) {
                template += `<tr><td colspan='2'>${t("text_remark")}: ${singleAdvance.remark}</td></tr>\n`;
            }
            template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>---------------------------------</td></tr>\n";
        }
        template += "</table>";
    }

    template += "<table style='width:359px;font-size:23px;line-height:30px;padding-bottom:20px;'>\n";
    template += `<tr><td>${t("text_total_amount")}</td><td>: ${toFixed(total.total_amount)}</td></tr>\n`;
    template += `<tr><td>${t("text_product_purchase")}</td><td>: ${toFixed(product_total.amount)}</td></tr>\n`;
    template += `<tr><td>${t("text_advance")}</td><td>: ${toFixed(advanceTotal)}</td></tr>\n`;
    template += `<tr><td>${ (customerType === "seller" ? t("text_payable_amount") : t("text_receivable_amount")) }</td><td>: ${toFixed(finalAmount)}</td></tr>\n`;
    template += "<tr><td colspan='2' style='font-family: monospace;font-size: 19px;'>----------------------------------</td></tr>\n";
    template += "</table>";

    template += "<table style='width:410px;font-size:23px;line-height:1;'>\n";
    template += templateFooter();

    await printReceipt(template);
};

const templateFooter = () => {
    let template = "<tr><td style='padding-bottom:50px;'>app.liter.live</td></tr>\n";
    template += "<tr><td>     </td></tr>\n";
    template += "</table>";

    return template;
};
