import React from 'react';
import moment from "moment";
import { loader } from "../../../../library/util";
import { errorHandler } from '../../../../library/response';
import { getBillingStartEndDate } from '../../../../library/dateUtils';
import analytics from '../../../../library/firebase/analytics';
import request from '../../../../library/request';

class CustomerLoanHistoryController extends React.Component {

    constructor(props) {
        super(props);
        this.getLoanHistory = this.getLoanHistory.bind(this);
        this.getSanctionedLoans = this.getSanctionedLoans.bind(this);
        this.state = {
            loan_total: 0,
            loan_history: [],
            loans: [],
            ...getBillingStartEndDate("monthly", moment())
        }
    }

    componentDidMount () {
        analytics.setScreenName("KisanCustomerLoanHistory");
        this.getLoanHistory();
        this.getSanctionedLoans();
    }

    async getLoanHistory () {
        try {
            loader.show(this.props.t("text_loading"));
            const { startDate } = this.state;
            const { dairyCustomerId, dairyId } = this.props;
            const filterStr = encodeURI(`date=${startDate.format('MMMM YYYY')}`);
            const { status, data, message } = await request.get(`/dairies/${dairyId}/accounts/loan/history/${dairyCustomerId}?${filterStr}`, {
                withAuth: true,
                version: "v2"
            });
            if (status) {
                let { loan_history } = data;
                this.setState({
                    loan_history: loan_history
                });
            } else {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    async getSanctionedLoans () {
        try {
            loader.show(this.props.t("text_loading"));
            const { dairyId, dairyCustomerId } = this.props;
            const { status, data, message } = await request.get(`/dairies/${dairyId}/accounts/loan/sanctioned/${dairyCustomerId}`, {
                withAuth: true,
                version: "v2"
            });
            if (status) {
                const { loans, loan_total } = data;
                this.setState({
                    loans: loans,
                    loan_total: loan_total
                });
            } else {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    onDateChange = ({ startDate, endDate }) => {
        this.setState({
            startDate,
            endDate
        }, () => {
            this.getLoanHistory();
        });
    }
}

export default CustomerLoanHistoryController;