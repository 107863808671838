import React, { useState, useEffect, useCallback } from 'react';
import * as Sentry from "@sentry/react";
import config from '../../../../../config';
import withLanguage from '../../../../language/LanguageController';
import { currencyFormat } from '../../../../library/util';
import request from '../../../../library/request';
import Spinner from 'src/components/core/Spinner';

const BillMilkTotal = ({
    t,
    dairyId,
    dairyCustomerId,
    startDate,
    endDate,
    isFeatureSubscribed
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [totalData, setTotalData] = useState({
        total: {},
        cow_total: {},
        buffalo_total: {}
    });

    const getMilkTotal = useCallback(async () => {
        try {
            setIsLoading(true);
            let filterStr = '?milk_type=';
            filterStr += '&date=' + startDate.format(config.db_date_format) + ':' + endDate.format(config.db_date_format);
            filterStr += '&day_time=0';
            const { status, data } = await request.get('/dairies/' + dairyId + '/milk/' + dairyCustomerId + '/total' + filterStr, {
                withAuth: true,
                version: "v2"
            });
            if (status === true) {
                let { total, cow_total, buffalo_total } = data
                setTotalData({
                    total: total,
                    cow_total: cow_total,
                    buffalo_total: buffalo_total
                });
            }
        } catch (error) {
            Sentry.captureException(error);
        } finally {
            setIsLoading(false);
        }

    }, [dairyId, dairyCustomerId, startDate, endDate]);

    useEffect(() => {
        if(dairyCustomerId && startDate && endDate && isFeatureSubscribed){
            getMilkTotal();
        }
        if(!isFeatureSubscribed) {
            setIsLoading(false);
        }
    }, [getMilkTotal, dairyCustomerId, startDate, endDate, isFeatureSubscribed]);

    const { total, cow_total, buffalo_total } = totalData;

    if(isLoading) {
        return <div className="mt-2"><Spinner /></div>
    }

    return (
        <>
            <div className="mb-2">
                <table className="table table-stripped mb-0 no-f-border">
                    <tbody>
                        <tr>
                            <td>{t("text_total_milk")}:</td>
                            <td className="text-right">{total.total_milk} {t("text_l")}</td>
                        </tr>
                        <tr>
                            <td>{t("text_total_amount")}:</td>
                            <td className="text-right">{currencyFormat(total.total_amount)}</td>
                        </tr>
                        <tr>
                            <td>{t("text_avg_milk")}:</td>
                            <td className="text-right">{total.avg_milk} {t("text_l")}</td>
                        </tr>
                        <tr>
                            <td>{t("text_avg_amount")}:</td>
                            <td className="text-right">{currencyFormat(total.avg_amount)}</td>
                        </tr>
                        <tr>
                            <td>{t("text_avg_rate")}:</td>
                            <td className="text-right">{currencyFormat(total.avg_rate)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {parseFloat(cow_total.total_milk) > 0 && parseFloat(buffalo_total.total_milk) > 0 ? (
                <>
                    <hr />
                    <div className="mb-2 mt-2">
                        <div className="title right card__title card--material__title mt-0 mb-1">
                            {t("text_cow_milk_total")}
                        </div>
                        <table className="table table-stripped mb-0 no-f-border">
                            <tbody>
                                <tr>
                                    <td>{t("text_total_milk")}:</td>
                                    <td className="text-right">{cow_total.total_milk} {t("text_l")}</td>
                                </tr>
                                <tr>
                                    <td>{t("text_total_amount")}:</td>
                                    <td className="text-right">{currencyFormat(cow_total.total_amount)}</td>
                                </tr>
                                <tr>
                                    <td>{t("text_avg_milk")}:</td>
                                    <td className="text-right">{cow_total.avg_milk} {t("text_l")}</td>
                                </tr>
                                <tr>
                                    <td>{t("text_avg_amount")}:</td>
                                    <td className="text-right">{currencyFormat(cow_total.avg_amount)}</td>
                                </tr>
                                <tr>
                                    <td>{t("text_avg_rate")}:</td>
                                    <td className="text-right">{currencyFormat(cow_total.avg_rate)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            ) : null}
            {parseFloat(cow_total.total_milk) > 0 && parseFloat(buffalo_total.total_milk) > 0 ? (
                <>
                    <hr />
                    <div className="mb-2 mt-2">
                        <div className="title right card__title card--material__title mt-0 mb-1">
                            {t("text_buffalo_milk_total")}
                        </div>
                        <table className="table table-stripped mb-0 no-f-border">
                            <tbody>
                                <tr>
                                    <td>{t("text_total_milk")}:</td>
                                    <td className="text-right">{buffalo_total.total_milk} {t("text_l")}</td>
                                </tr>
                                <tr>
                                    <td>{t("text_total_amount")}:</td>
                                    <td className="text-right">{currencyFormat(buffalo_total.total_amount)}</td>
                                </tr>
                                <tr>
                                    <td>{t("text_avg_milk")}:</td>
                                    <td className="text-right">{buffalo_total.avg_milk} {t("text_l")}</td>
                                </tr>
                                <tr>
                                    <td>{t("text_avg_amount")}:</td>
                                    <td className="text-right">{currencyFormat(buffalo_total.avg_amount)}</td>
                                </tr>
                                <tr>
                                    <td>{t("text_avg_rate")}:</td>
                                    <td className="text-right">{currencyFormat(buffalo_total.avg_rate)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            ) : null}
        </>
    )
};

BillMilkTotal.displayName = "BillMilkTotal";

BillMilkTotal.defaultProps = {
    isFeatureSubscribed: true
};

export default withLanguage(BillMilkTotal);