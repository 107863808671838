import React from "react";
import withLanguage from "../../language/LanguageController";
import CowBlack from "./../../../assets/icons/cow.svg";

const CowBlackIcon = ({ t, dispatch, ...restProps }) => {
    return <img src={CowBlack} alt={t("text_cow")} {...restProps} />;
};

CowBlackIcon.defaultProps = {
    width: "25px"
};

export default withLanguage(CowBlackIcon);