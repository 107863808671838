import {
    LOGIN_SUCCESS, PROFILE_UPDATE_SUCCESS,
    UPDATE_PROFILE_PIC,
    UPDATE_AUTH_DETAIL,
    ADDRESS_UPDATE_SUCCESS,
    SET_LOGIN
} from "../_constants";
import User from "../components/models/User";
import { copyObject } from "../components/library/util";

const user = new User();

const initialState = {
    user: user.get(),
    is_login: false
};

function userReducer(state = initialState, action) {
    switch (action.type) {
    case LOGIN_SUCCESS: 
        User.save(action.data); //save user detail into local db
        return {
            ...copyObject(state),
            user: action.data,
            is_login: true,
        };
    case SET_LOGIN:
        return {
            ...copyObject(state),
            is_login: action.data
        };
    case PROFILE_UPDATE_SUCCESS:
        return {
            ...copyObject(state),
            user: User.update(action.data) //save user detail into local db
        };
    case UPDATE_PROFILE_PIC:
        return {
            ...copyObject(state),
            user: User.update(action.data) //save user detail into local db
        };
    case UPDATE_AUTH_DETAIL:
        return {
            ...copyObject(state),
            user: User.update(action.data) //save user detail into local db
        };
    case ADDRESS_UPDATE_SUCCESS:
        var stateData = copyObject(state);
        stateData.user.address = action.data;
        User.update(stateData.user);
        return {
            ...stateData
        };
    default:
        return state;
    }
}

export default userReducer;
