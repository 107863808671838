// @ts-nocheck
import React, { useState } from 'react';
import ImportRateListConfirmation from 'src/components/modalPopups/ImportRateListConfirmation';
import ExcelFileImporter from "../../../common/ExcelFileImporter";

function ImportExcelFile({ onImport, rateBy }) {
    const [rates, setRates] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [columns, setColumns] = useState([]);
    
    const onImportConfirm = (ratesData) => {
        onImport(ratesData);
        setIsOpen(false);
    }

    const handleImportChange = ({ header, records}) => {
        let fatCLRSNFRates = {};
        records.forEach((fatRateData) => {
            let fat;
            fatRateData.forEach((value, index) => {
                if(index === 0) {
                    fat = value;
                    fatCLRSNFRates[fat] = {}
                } else {
                    fatCLRSNFRates[fat][header[index]] = value;
                }
            });
        });
        setRates(fatCLRSNFRates);
        setColumns([...header.slice(1).sort()]);
        setIsOpen(true);
    };

    return (
        <>
            <ImportRateListConfirmation
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                ratesData={rates}
                onConfirm={onImportConfirm}
                rateBy={rateBy === "snf" ? "SNF" : "CLR"}
                columns={columns}
            />
            <ExcelFileImporter onChange={handleImportChange} />
        </>
    );
}
export default ImportExcelFile;