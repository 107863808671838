export const ABILITY_DAIRY_CUSTOMERS = "dairy_customers";
export const ABILITY_MILK_COLLECTION = "milk_collection";
export const ABILITY_PRODUCT_MANAGEMENT = "product_management";
export const ABILITY_PRODUCT_SALE = "product_sale";
export const ABILITY_DAIRY_BILL = "dairy_bill";
export const ABILITY_ADVANCE_PAYMENT = "advance_payment";
export const ABILITY_LOAN = "loan";
export const ABILITY_DAIRY_REPORT = "dairy_report";
export const ABILITY_DAIRY_RATE_LIST = "dairy_ratelist";
export const ABILITY_DAIRY_SETTING = "dairy_setting";
export const ABILITY_DAIRY_USERS = "dairy_users";

export const PERMISSION_VIEW = "view";
export const PERMISSION_ADD = "add";
export const PERMISSION_UPDATE = "update";
export const PERMISSION_DELETE = "delete";
export const PERMISSION_SEND_SMS = "send_sms";
export const PERMISSION_PRINT_AND_SEND_SMS = "print_and_send_sms";
export const PERMISSION_PRINT = "print";
export const PERMISSION_DETAIL = "detail";
export const PERMISSION_SALE_VIEW = "sale_view";
export const PERMISSION_SALE_ADD = "sale_add";
export const PERMISSION_SALE_DELETE = "sale_delete";
export const PERMISSION_VIEW_DAIRY_BILL = "view_dairy_bill";
export const PERMISSION_VIEW_CUSTOMER_BILL = "view_customer_bill";
export const PERMISSION_PAY = "pay";
export const PERMISSION_RECOVER = "recover";
export const PERMISSION_DAIRY_REPORT = "dairy_report";
export const PERMISSION_CUSTOMER_REPORT = "customer_report";
