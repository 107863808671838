/* eslint-disable no-console */
import React, { Fragment, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage, toggleLanguagePopup } from "../../../actions/index";
import { useState } from "react";
import analytics from "../../library/firebase/analytics";
import { getLanguages } from "../../../config/language";
import LanguageSelectionRow from "./LanguageSelectionRow";
import { t } from "../../language/LanguageController";
import { Dialog, DialogActions, DialogContent, Divider, IconButton, List } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { PrimaryButton } from "../buttons/PrimaryButton";

const LanguageSelectorPopup = () => {
    const dispatch = useDispatch();
    const { language, isLanguagePopup } = useSelector(state => state.defaultReducer);
    const [lang, setLang] = useState(language);

    const setLanguage = () => {
        analytics.logEvent("changeLanguage", {
            language: lang
        });
        dispatch(changeLanguage(lang));
        dispatch(toggleLanguagePopup(false));
    };

    const handleClose = () => {
        dispatch(toggleLanguagePopup(false))
    }

    const languages = useMemo(() => getLanguages(), []);

    useEffect(() => {
        if(isLanguagePopup)
            analytics.logEvent("LanguageModal");
    }, [isLanguagePopup]);

    return (
        <Dialog
            open={isLanguagePopup}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="form-dialog-title"
            scroll="paper"
        >
            <MuiDialogTitle id="form-dialog-title">
                {t("text_choose_language", lang)}
                <IconButton aria-label="close" className="btn-modal-close" onClick={handleClose}>
                    <CloseIcon />   
                </IconButton>
            </MuiDialogTitle>
            <DialogContent dividers>
                <List>
                    {languages.map((language, index) =>
                        <Fragment key={language.code}>
                            <LanguageSelectionRow
                                language={language}
                                setLang={setLang}
                                selectedLang={lang}
                            />
                            {(languages.length - 1 !== index) && <Divider variant="inset" component="li" />}
                        </Fragment>
                    )}
                </List>
            </DialogContent>
            <DialogActions>
                <PrimaryButton onClick={setLanguage}>
                    {t("text_continue", lang)}
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
};

export default LanguageSelectorPopup;