import _ from 'lodash';
import moment from 'moment';
import React, { Component, createRef } from 'react';
import {
    Fab,
    Icon,
    SpeedDial
} from 'react-onsenui';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from "react-router-dom";
import { compose } from 'redux';
import SortButton from 'src/components/core/SortingButton/SortButton';
import { getBillingStartEndDate } from 'src/components/library/dateUtils';
import { ABILITY_DAIRY_CUSTOMERS, ABILITY_MILK_COLLECTION, PERMISSION_ADD, PERMISSION_DETAIL } from '../../../../../_constants/permissions';
import { changeStatus, deleteCustomer, fetchCustomers } from '../../../../../actions/dairyCustomer';
import { withPermission } from '../../../../common/AccessPermission';
import HasPermission from '../../../../common/AccessPermission/HasPermission';
import featureFlag from '../../../../common/FeatureFlag';
import { checkFeatureInSubscription } from '../../../../common/FeatureFlag/FeatureFlag';
import withLanguage from '../../../../language/LanguageController';
import { showInterstitialAd } from '../../../../library/admob';
import { BrowserView, MobileView, TabletView } from '../../../../library/detect-device';
import analytics from '../../../../library/firebase/analytics';
import { confirmPop } from '../../../../library/util';
import { DefaultLayout } from "../../../common/Layout";
import { DefaultScrollbar } from "../../../common/Scrollbar";
import CustomerAccount from '../../account/CustomerAccount';
import CustomerPaymentHistory from '../../account/CustomerPaymentHistory';
import CustomerLoanHistory from '../../loan/CustomerLoanHistory';
import CustomerMilkDetail from '../../milk/CustomerMilkDetail';
import CustomerMilkTotal from '../../milk/CustomerMilkTotal';
import Filter from "./Filter";
import SingleCustomer from "./SingleCustomer";
import { selectDairyCustomers } from 'src/reducers/dairyCustomer';

class CustomersList extends Component {
    constructor(props) {
        super(props);
        let customer = props.customers.length ? props.customers[0] : {};
        const dairyCustomerId = props?.match?.params?.dairyCustomerId;
        if (dairyCustomerId) {
            customer = props.customers.find(customer => customer.dairy_customer_id == dairyCustomerId);
        }
        this.state = {
            toastShown: false,
            deleteDialogShown: false,
            toastMsg: "",
            add_customer: false,
            dataAvailable: false,
            addmilk: false,
            customer: {},
            dairyCustomerId: customer.dairy_customer_id,
            is_edit: false,
            add_milk_modal: false,
            filter: '',
            add_loan_modal: false,
            advance_payment_modal: false,
            sortBy: 'name',
            sortOrder: 'asc',
            open: false,
            selectedIndex: 0,
            ...this.getDefaultFilter()
        };
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.customerMilkTotalRef = createRef();
        this.customerMilkDetailRef = createRef();
        this.handleSort = this.handleSort.bind(this);
    }

    componentDidMount() {
        analytics.setScreenName("CustomersList");
        this.props.fetchCustomers(this.props.dairy.id);
        showInterstitialAd();
    }

    getDefaultFilter() {
        const { startDate, endDate } = getBillingStartEndDate(this.props.billingCycle, moment());
        return {
            start_date: startDate,
            end_date: endDate.isAfter(moment()) ? moment() : endDate,
            cattle: 'cow',
            shift: moment().format("HH") >= 15 ? "1" : "0",
        };
    }

    showMenu = () => {
        this.props.showMenu();
    };

    showAddMilkModal = (e) => {
        e.preventDefault();
        this.setState({
            add_milk_modal: true
        });
    };

    hideAddMilkModal = (e) => {
        e.preventDefault();
        this.setState({
            add_milk_modal: false
        });
    };

    customerFilterHandler = (e) => {
        console.log("filter handler", e.target.value);
        this.setState({
            filter: e.target.value
        });
    };

    dateChangeHandler = (name, newDate) => {
        this.setState({
            [name]: newDate
        }, () => {
            this.customerMilkTotalRef.current.dateChangeHandler(name, newDate);
            if (checkFeatureInSubscription("customer_daily_milk_summary", false)) {
                this.customerMilkDetailRef?.current?.dateChangeHandler(name, newDate);
            }
        });
    };

    cattleChangeHandler = cattle => {
        if (checkFeatureInSubscription("customer_daily_milk_summary")) {
            this.setState({
                cattle: cattle,
            }, () => {
                this.customerMilkDetailRef?.current?.cattleChangeHandler(cattle);
            });
        }
    };

    shiftChangeHandler = shift => {
        if (checkFeatureInSubscription("customer_daily_milk_summary")) {
            this.setState({
                shift: shift
            }, () => {
                this.customerMilkDetailRef?.current?.shiftChangeHandler(shift);
            });
        }
    };

    closeModal = () => {
        let modal = document.querySelector('ons-modal');
        modal.hide();
        this.setState({
            is_edit: false
        });
    };

    editCustomer = (event, dairyCustomerId) => {
        event.stopPropagation();
        analytics.logEvent("editCustomer");
        let customer = this.props.customers.find(customer => customer.dairy_customer_id == dairyCustomerId);
        let customerIndex = this.props.customers.indexOf(customer);
        this.setState({
            customer: customer,
            customer_index: customerIndex,
            is_edit: true
        });

        this.props.history.push(`/customers/edit/${customer.dairy_customer_id}`);
    };

    async deleteConfirm(e, dairyCustomerId) {
        e.stopPropagation();
        analytics.logEvent("deleteCustomer");
        let customerIndex = this.props.customers.findIndex(customer => customer.dairy_customer_id === dairyCustomerId);
        let message = this.props.t("text_customer_delete_confirm");
        let confirm = await confirmPop(message);
        if (confirm == 1) {
            this.props.deleteCustomer(this.props.dairyId, dairyCustomerId, customerIndex);
        }
    }

    changeStatus = (dairyCustomerId, status) => {
        analytics.logEvent("changeStatus");
        let customerIndex = this.props.customers.findIndex(customer => customer.dairy_customer_id === dairyCustomerId);
        this.props.changeStatus(this.props.dairyId, dairyCustomerId, customerIndex, status);
    };

    showAddCustomer = () => {
        this.setState({
            show_cutomer_modal: true
        });
    };

    onDeviceChange = (deviceTypes) => {
        this.setState({ ...deviceTypes });
    };

    showCustomerMilkDetail = (e, customer, detailPage = false) => {
        let parentClass = e.target.parentElement.className;

        if (
            (parentClass.indexOf('dropdown_zindex') == -1
                && parentClass.indexOf('dropdown-toggle') == -1
                && parentClass.indexOf('dropdown-menu') == -1) || detailPage
        ) {
            analytics.logEvent("showCustomerMilkDetail");
            if (this.state.isMobile) {
                this.props.history.push(`/milk/customer/${customer.dairy_customer_id}`);
            } else {
                this.props.history.replace(`/milk/customer/${customer.dairy_customer_id}`);
                this.setState({
                    dairyCustomerId: customer.dairy_customer_id
                });
            }
        }
    };

    gotoAccounts = (e, dairyCustomerId) => {
        e.preventDefault();
        analytics.logEvent("gotoAccounts");
        this.props.navigator.pushPage({
            component: CustomerAccount,
            props: {
                key: CustomerAccount.name,
                dairyCustomerId: dairyCustomerId,
                dairyId: this.props.dairy.id
            }
        }, {
            animation: 'fade'
        });
    };

    gotoLoanHistory = (e, dairyCustomerId) => {
        e.preventDefault();
        analytics.logEvent("gotoLoanHistory");
        this.props.navigator.pushPage({
            component: CustomerLoanHistory,
            props: {
                key: CustomerLoanHistory.name,
                dairyCustomerId: dairyCustomerId,
                dairyId: this.props.dairy.id
            }
        }, {
            animation: 'fade'
        });
    };

    gotoPaymentHistory = (e, dairyCustomerId) => {
        e.preventDefault();
        analytics.logEvent("gotoPaymentHistory");
        this.props.navigator.pushPage({
            component: CustomerPaymentHistory,
            props: {
                key: CustomerPaymentHistory.name,
                dairyCustomerId: dairyCustomerId,
                dairyId: this.props.dairy.id
            }
        }, {
            animation: 'fade'
        });
    };
    gotoPage = (e, Compo, props) => {
        e.preventDefault();
        analytics.logEvent("goto" + props.key);
        this.props.navigator.pushPage({
            component: Compo,
            props: props
        }, {
            animation: 'fade'
        });
    }

    renderFixed = () => {
        const FabWithFeatureFlag = featureFlag(Fab);
        return (
            <HasPermission ability={ABILITY_DAIRY_CUSTOMERS} access={PERMISSION_ADD}>
                <SpeedDial position='bottom right'>
                    <FabWithFeatureFlag
                        feature={"customer_limit"}
                        checkLimit
                        style={{ backgroundColor: "#11b232" }}>
                        <Link to="/customers/add" className="text-white">
                            <Icon icon="md-plus" />
                        </Link>
                    </FabWithFeatureFlag>
                </SpeedDial>
            </HasPermission>
        );
    }

    handleSort = (sortOption) => {
        this.setState({
            sortBy: sortOption.field,
            sortOrder: sortOption.order
        });
    };

    filterCustomers = (customer) => {
        return (
            customer.filter(customer =>
                customer.name.toLowerCase().includes(this.state.filter.toLowerCase()) ||
                customer.code.toLowerCase().includes(this.state.filter.toLowerCase())
            )
        );
    };

    filterAndSortCustomers = (customers,filter, sortBy, sortOrder) => {
        return _.orderBy(
            this.filterCustomers(customers),
            [customer => sortBy === 'code' ? parseInt(customer[sortBy]) : customer[sortBy].toLowerCase()],
            [sortOrder]
        );
    };

    render() {
        const { customers, t, hasAccess } = this.props;
        const { filter, dairyCustomerId, start_date, end_date, cattle, shift, sortBy, sortOrder } = this.state;
        const customerMilkDetailAccess = hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL);

        const activeCustomers = customers.filter(customer => customer.status == 1);
        const inactiveCustomers = customers.filter(customer => customer.status != 1);

        const sortedActiveCustomers = this.filterAndSortCustomers(activeCustomers, filter, sortBy, sortOrder);
        const sortedInactiveCustomers = this.filterAndSortCustomers(inactiveCustomers, filter, sortBy, sortOrder);

        const sortedCustomers = [
            ...sortedActiveCustomers,
            ...sortedInactiveCustomers
        ];

        return (
            <DefaultLayout
                title={this.props.dairy.name}
                onDeviceChange={this.onDeviceChange}
                bottomGutter={false}
                toolbar={false}
                back>
                <div className="form-row mb-2">
                    <Filter
                        customerFilterHandler={this.customerFilterHandler}
                        dateChangeHandler={this.dateChangeHandler}
                        cattleChangeHandler={this.cattleChangeHandler}
                        shiftChangeHandler={this.shiftChangeHandler}
                        startDate={start_date}
                        endDate={end_date}
                        cattle={cattle}
                        shift={shift}
                    />
                </div>
                <div className="form-row " >
                    <div className={customerMilkDetailAccess ? "col-12 col-md-5 col-lg-3" : "col-12"}>
                        <div className="mb-2 p-2 px-3 bg-light rounded shadow-sm clearfix">
                            <div className="float-left">
                                <b>{t("text_active")}:</b> {activeCustomers.length}
                            </div>
                            <div className="float-right">
                                <b>{t("text_inactive")}:</b> {inactiveCustomers.length}
                            </div>
                        </div>
                        <div className="flex justify-center mb-2">
                            <SortButton onSortChange={this.handleSort} selectedOption={{ value: sortBy }} />
                        </div>
                        <div className="">
                            <DefaultScrollbar
                                className="customer-list-scrollbar">
                                {sortedCustomers?.map(customer =>
                                    <SingleCustomer
                                        key={customer.dairy_customer_id}
                                        customer={customer}
                                        showCustomerMilkDetail={customerMilkDetailAccess ? this.showCustomerMilkDetail : () => { }}
                                        editCustomer={this.editCustomer}
                                        changeStatus={this.changeStatus}
                                        deleteConfirm={this.deleteConfirm}
                                        active={dairyCustomerId == customer.dairy_customer_id} />
                                )}
                                {!sortedCustomers?.length ? (
                                    <div className="nodata_available">
                                        <p>{t("text_no_data_available")}</p>
                                    </div>
                                ) : ''}
                            </DefaultScrollbar>
                        </div>
                    </div>
                    {customerMilkDetailAccess && <TabletView>
                        <div className="col-md-7 col-lg-4">
                            <DefaultScrollbar
                                className="customer-list-milk-total-scrollbar">
                                <CustomerMilkTotal
                                    key={dairyCustomerId}
                                    ref={this.customerMilkTotalRef}
                                    header={false}
                                    filter={(this.state.isMobile || this.state.isTablet) && !this.state.isBrowser}
                                    dairyCustomerId={dairyCustomerId}
                                    startDate={start_date}
                                    endDate={end_date}
                                    cattle={cattle}
                                    shift={shift} />
                            </DefaultScrollbar>
                        </div>
                    </TabletView>}
                    {customerMilkDetailAccess && <BrowserView>
                        <div className="col-md-7 col-lg-5">
                            <DefaultScrollbar
                                className="customer-list-milk-detail-scrollbar">
                                <CustomerMilkDetail
                                    key={dairyCustomerId}
                                    ref={this.customerMilkDetailRef}
                                    header={false}
                                    filter={false}
                                    dairyCustomerId={dairyCustomerId}
                                    startDate={start_date}
                                    endDate={end_date}
                                    cattle={cattle}
                                    shift={shift}
                                    bottomGutter={false}
                                />
                            </DefaultScrollbar>
                        </div>
                    </BrowserView>}
                </div>
                <MobileView>
                    {this.renderFixed()}
                </MobileView>
            </DefaultLayout>
        );
    }
}

const mapStateToProps = state => {
    return {
        products: state.productReducer.products,
        settings: state.dairySettings.settings,
        customers: selectDairyCustomers(state),
        dairyId: state.dairy.dairy.id,
        dairy: state.dairy.dairy,
        billingCycle: state.dairy.dairy.billing_cycle
    };
};

const mapDispatchToProps = {
    fetchCustomers,
    deleteCustomer,
    changeStatus
};

export default compose(
    withRouter,
    withLanguage,
    withPermission,
    connect(mapStateToProps, mapDispatchToProps)
)(CustomersList);
