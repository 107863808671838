import {  IconButton, Menu, MenuItem } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { checkFeatureInSubscription } from "../../../common/FeatureFlag/FeatureFlag";
import withLanguage from "../../../language/LanguageController";
import analytics from "../../../library/firebase/analytics";
import MoreVert from '@material-ui/icons/MoreVert';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { HasPermission, usePermission } from "../../../common/AccessPermission";
import { ABILITY_PRODUCT_MANAGEMENT, PERMISSION_DELETE, PERMISSION_UPDATE } from "../../../../_constants/permissions";

const SingleProduct = ({ t, product, index, viewProductHandler, deleteProduct, active }) => {
    const history = useHistory();
    const { hasAccess } = usePermission();
    const editProduct = e => {
        e.stopPropagation();
        if (checkFeatureInSubscription("product_management")) {
          analytics.logEvent("editProduct");
          history.push(`/dairy/product/edit/${product.id}`);
        }
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };
    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };
    
    return (
        <div className={`t-card shadow-sm mb-2 ${active ? "bg-light-blue text-white" : ""}`} onClick={e => viewProductHandler(e, product)}>
            <div className="row">
                <div className="col-8">
                    <h6 className="card-title mt-0">
                        {product.name} - {product.code}
                    </h6>
                    <p className="card-text mb-1 small">{t("text_price")}: {product.price}</p>
                    <p className="card-text small">{t("text_in_stock")}: {product.quantity}</p>
                </div>
                {(
                    hasAccess(ABILITY_PRODUCT_MANAGEMENT, PERMISSION_UPDATE)
                    || hasAccess(ABILITY_PRODUCT_MANAGEMENT, PERMISSION_DELETE)
                ) && <div className="dropdown gesation-cicular-dropdown dropdown_zindex vert-menu">
                    <IconButton
                        aria-controls={`product-menu-${product.id}`}
                        aria-haspopup="true"
                        onClick={handleClick}
                        className={`vert-menu ${active && "text-white"}`}
                        size="small"
                    >
                        <MoreVert/>
                    </IconButton>
                    <Menu
                        id={`product-menu-${product.id}`}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <HasPermission ability={ABILITY_PRODUCT_MANAGEMENT} access={PERMISSION_UPDATE}>
                            <MenuItem onClick={e => editProduct(e)}>
                                <Edit className="mr-3 text-secondary"/>
                                {t("text_edit")}
                            </MenuItem>
                        </HasPermission>
                        <HasPermission ability={ABILITY_PRODUCT_MANAGEMENT} access={PERMISSION_DELETE}>
                            <MenuItem onClick={e => deleteProduct(e, index)}>
                                <Delete className="mr-3 text-secondary"/>
                                {t("text_delete")}
                            </MenuItem>
                        </HasPermission>
                    </Menu>
                </div>}
            </div>
        </div>
    );
};

export default withLanguage(SingleProduct);