// @ts-nocheck
import React, { useState, useEffect, useCallback, useRef } from "react";
import withLanguage,  { useLanguage } from "../../language/LanguageController";
import ModalDialog from "../../common/ModalDialog";
import fbIcon from "../../../assets/img/reward/fb.png";
import referralIconOne from "../../../assets/img/reward/referralIcon2.png";
import AnnounceImg from "../../../assets/img/reward/announce5.png";
import whatsappImg from "../../../assets/img/reward/whatsapp.png";
import emailImg from "../../../assets/img/reward/email.png";
import shareImg from "../../../assets/img/reward/share.png";
import Typography from "@material-ui/core/Typography";
import { useMobile, useBrowser, useAndroid } from "src/components/library/detect-device";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import { toggleReferralModal } from 'src/actions/reward';
import { updateCustomerPreference,fetchCustomerPreference } from 'src/actions';
import { PLAY_STOR_LINK } from 'src/_constants';
import analytics from "../../library/firebase/analytics";
import { socialSharing } from "src/components/library/util";

const ReferModal = () => {
    const { t } = useLanguage();
    const isAndroid = useAndroid();
    const isMobile = useMobile();
    const isBrowser = useBrowser();
    const { user } = useSelector(state => state.userReducer);
    const { openReferralModal } = useSelector(state => state.reward);
    const {customerPreference} = useSelector(state => state.defaultReducer);
    const timerData = useRef(null);
    const dispatch = useDispatch();
    
    const onClose = () => {
        analytics.logEvent("ReferralModal - Close");
        dispatch(toggleReferralModal());
    };

    const setTimer = useCallback(() => {
        if(!customerPreference?.do_not_show_referral_again){
            let referPopupModalTime = window.localStorage.getItem("referPopupModal");
            if (referPopupModalTime) {
                const lastShowTime = moment(referPopupModalTime);
                const dayDiff = moment().diff(lastShowTime, "days");
                if (dayDiff >= 1) {
                    analytics.logEvent("ReferralModal - Displayed");
                    dispatch(toggleReferralModal(true));
                    window.localStorage.setItem("referPopupModal", moment().toISOString());
                }
            } else {
                analytics.logEvent("ReferralModal - Displayed");
                dispatch(toggleReferralModal(true));
                window.localStorage.setItem("referPopupModal", moment().toISOString());
            }
        }
    }, [customerPreference, dispatch]);

    useEffect(() => {
        fetchCustomerPreference(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if(timerData.current) {
            clearTimeout(timerData.current);
        }
        timerData.current = setTimeout(() => {
            setTimer();
        }, 1000 * 10);
    }, [setTimer]);

    let playstoreLink = PLAY_STOR_LINK;
    let shareText = t("text_refer_desscription").replace("<Strong>{referral_code}</Strong>", t("text_code_2") +" "+ user?.referral?.code);
    let shareTitle = t("text_refer_us_to_your_friends");
    
    const shareData = useCallback(() => {
        analytics.logEvent("ReferUsToYourFreinds - Share");
        if(isAndroid) {
            socialSharing(shareText, shareTitle);
        } else {
            navigator.share({
                title: shareTitle,
                text: shareText,
                url: playstoreLink
            });
        }
    }, [playstoreLink, shareText, shareTitle, isAndroid]);

    const doNotShow = () => {
        analytics.logEvent("ReferralModal - DoNotShowAgain");
        updateCustomerPreference('do_not_show_referral_again', 1);
        onClose();
    }

    const onWhatsapp = () => {
        analytics.logEvent("ReferUsToYourFreinds - ShareOnWhatsapp");
    }

    const onMail = () => {
        analytics.logEvent("ReferUsToYourFreinds - ShareOnMail");
    }

    return (
        <ModalDialog
            isOpen={openReferralModal.isOpen}
            dividers={false}
            maxWidth={'lg'}
            onClose={onClose}
            content={
                <div className="row justify-content-center pb-3 mt-0">
                    <div className={`col-md-5 ${isBrowser ? "pb-4 pl-5" : "p-2"}`}>
                        <Box className="text-center">
                            <img src={referralIconOne} width={isMobile ? "50px" : "80px"} alt="blank" />
                        </Box>
                        <Box className="text-center mt-2">
                            <Typography component="span" variant={isMobile ? "h6" : "h4"} className="font-weight-bold">
                                {shareTitle}
                            </Typography>
                        </Box>
                        <Box className="text-center mt-3">
                            <Typography
                                component="span"
                                variant="subtitle1"
                                className={isMobile ? " " : "popup-paragraph"}
                                dangerouslySetInnerHTML={{ __html: t("text_refer_desscription").replace("{referral_code}", t("text_code_2") +" "+ user?.referral?.code) }}>
                            </Typography>
                        </Box>
                        <Box className={isMobile ? "pl-1" : "pl-5 ml-2"}>
                            <Box className="d-flex justify-content-start mt-3">
                                <Box>
                                    <img src={whatsappImg} width={"28px"} alt="blank" />
                                </Box>
                                <Box className={isMobile ? "pl-2" : "pl-4"}>
                                    <a href={`https://wa.me/?text=${shareText + ' ' + playstoreLink}`} target="_blank" onClick={onWhatsapp} rel="noreferrer">
                                        <Typography component="span" variant="subtitle1" className="">
                                            {t("text_share_as_whatsapp_message")}
                                        </Typography>
                                    </a>
                                </Box>
                            </Box>
                            <Box className="d-flex justify-content-start mt-3 " >
                                <Box>
                                    <img src={emailImg} width={"28px"} alt="blank" />
                                </Box>
                                <Box className={isMobile ? "pl-2" : "pl-4"}>
                                    <a href={`mailto:?Subject=${shareTitle}&Body=${shareText + ' ' + playstoreLink}`} target="_blank" onClick={onMail} rel="noreferrer">
                                        <Typography component="span" variant="subtitle1" className="" >
                                            {t("text_share_as_email")}
                                        </Typography>
                                    </a>
                                </Box>
                            </Box>
                            <Box className="d-flex justify-content-start mt-3 " >
                                <Box>
                                    <img src={shareImg} width={"30px"} alt="blank" />
                                </Box>
                                <Box className={isMobile ? "pl-2" : "pl-4"}>
                                    <Typography component="span" variant="subtitle1" onClick={shareData} className="mb-2 share-btn-link font-weight-bold">
                                        {t("text_refer_your_friends")}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                    {isBrowser && <div className="col-md-5 text-center">
                        <img src={AnnounceImg} width={"75%"} alt="blank" />
                    </div>}
                    {!openReferralModal.manual && <div className={`col-md-12 text-right ${isBrowser ? " " : "mt-4"}`}>
                        <Typography component="span" variant="subtitle2" className="share-btn-link" onClick={doNotShow}>
                            {t("text_do_not_show_again")}
                        </Typography>
                    </div>}
                </div>
            }
        />
    );
};

export default withLanguage(ReferModal);