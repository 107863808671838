import React from "react";
import withLanguage from '../../../../language/LanguageController';
import AdvancePaymentForm from "./AdvancePaymentForm";
import AdvancePaymentPage from "./AdvancePayment";
import { DefaultLayout } from "../../../common/Layout";
import { usePermission } from "../../../../common/AccessPermission";
import { ABILITY_ADVANCE_PAYMENT, PERMISSION_ADD } from "../../../../../_constants/permissions";

const AdvancePaymentManagement = ({ t }) => {
    const { hasAccess } = usePermission();
    const addAdvanceAccess = hasAccess(ABILITY_ADVANCE_PAYMENT, PERMISSION_ADD);
    return (
        <DefaultLayout
            title={t("text_advance_payment")}
            toolbar={false}
            bottomGutter={false}
            back>
            <div className="form-row">
                {addAdvanceAccess && <div className="col-sm-4">
                    <AdvancePaymentForm header={false} />
                </div>}
                <div className={addAdvanceAccess ? "col-sm-8" : "col-sm-8 mx-auto"}>
                    <AdvancePaymentPage header={false} />
                </div>
            </div>
        </DefaultLayout>
    )
};

AdvancePaymentManagement.displayName = "AdvancePaymentManagement";

export default withLanguage(AdvancePaymentManagement);