// @ts-nocheck
import React, { useEffect, useState } from "react";
import { getRateChartColumnsRows } from "src/components/helpers/rateListHelper";
import { downloadMakePDFNew } from '../../../library/pdfGenerator.js';
import { exportToExcel } from '../../../library/ExportToExcel';
import { useLanguage } from "../../../language/LanguageController";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "src/components/common/buttons/PrimaryButton";
import LoaderIcon from "src/components/common/icons/LoaderIcon";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import { toast } from "../../../library/util.js";

const RateListTable = ({ rateList, rateChartSetupTypeCattle }) => {
    const { t } = useLanguage();
    const { dairy } = useSelector(state => state.dairy);
    const { user } = useSelector(state => state.userReducer);
    const { configuration, rate_chart_setup_type } = rateList;
    const [{ columns, rows }, setColumnsRows] = useState({
        columns: [],
        rows: []
    });
    const [rateBy, setRateBy] = useState("clr");
    const [isDownload, setIsDownload] = useState(false);
    const [isExporting, setIsExporting] = useState(false);


    useEffect(() => {
        const rateChartBy = rate_chart_setup_type === "by_cattle" ? rateChartSetupTypeCattle : rate_chart_setup_type;
        const rateListTypeId = parseInt(configuration?.[rateChartBy]?.rate_list_type_id);
        const settings = configuration?.[rateChartBy]?.[rateListTypeId] || {};
        setRateBy(settings.rate_by);
        const { columns, rows } =  getRateChartColumnsRows(rateListTypeId, settings);
        
        setColumnsRows({
            columns,
            rows
        })
    }, [configuration, rate_chart_setup_type, rateChartSetupTypeCattle]);
    
    const tableData = () => {
        let rateValBy = (rateBy === "snf") ? "SNF" : "CLR";
        let width = ['*'];
        let col = [{ text: 'FAT/' + rateValBy, bold: true }];
        columns.forEach(element => {
            col.push({ text: element, bold: true });
            width.push('*');
        });

        let rowdata = [col];
        rows.forEach((row, rowIndex) => {
            let colval = [];
            row.forEach((column, colIndex) => {
                let val = (colIndex === 0) ? column : column.rate;
                colval.push(val);
            })
            rowdata.push(colval);
        })
        return { "width": width, "rowdata": rowdata };
    }


    const genratePDF = async () => {
        setIsDownload(true);
        let Data = tableData();
        let tblData = {
            pageOrientation: (rateBy === "snf") ? "landscape" : "portrait",
            data: Data.rowdata,
            fileName: 'rate-list',
            columnsWidth: Data.width,
            logo: "https://ik.imagekit.io/ashvin27/logo_green_tagline_GtVGtgOqL.png",
            fontSize: 10,
            dairyName: dairy.name,
            nameAndNumber: user.firstname + ' ' + user.lastname + ' - ' + user.telephone
        }
        await downloadMakePDFNew(tblData);
        setIsDownload(false);
    }

    const exportToExcelSheet = async () => {
        setIsExporting(true);
        let exportData = [];
        let rateValBy = (rateBy === "snf") ? "SNF" : "CLR";

        rows.map(row => {
            let array = {};
            array['FAT/'+rateValBy] = row[0];
            columns.map((item, index) => {
                array["'"+item+"'"] = row[index + 1].rate;
            });
            exportData.push(array);
        });

        exportToExcel(exportData, 'rate-list-csv');
        setIsExporting(false);
    }

    const downloadSample = () => {
        //after device is ready
        const fail = function (message) {    
            console.log(message);
        }
        const success = function (data) {
            console.log("succes");
        }
        const options = {
            setDestinationInExternalPublicDir: true  // set true if you want to use public dir instead of files dir
        };
        if(typeof cordova !== undefined) {
            // eslint-disable-next-line no-undef
            cordova.plugins.DownloadManager.download("https://dev.liter.live/excel_sample_files/rate_list_clr_sample.xlsx", "rate_list_clr_sample.xlsx", "", success, fail, options);
            // eslint-disable-next-line no-undef
            cordova.plugins.DownloadManager.download("https://dev.liter.live/excel_sample_files/rate_list_snf_sample.xlsx", "rate_list_snf_sample.xlsx", "", success, fail, options);
            toast(t("text_download_started_notification"));
        }
    }

    return (
        <div className="t-card shadow-sm mb-5">
            <div className="text-right mb-2">
                <PrimaryButton
                    variant="contained"
                    size="small"
                    disabled={isDownload}
                    onClick={genratePDF}
                    aria-label={t("text_download_as_pdf")}
                >
                    {isDownload && <LoaderIcon spin={isDownload} width={0.8} />}
                    <PictureAsPdfIcon />
                    <GetAppIcon />
                </PrimaryButton>

                <PrimaryButton
                    variant="contained"
                    size="small"
                    disabled={isExporting}
                    onClick={exportToExcelSheet}
                    className="ml-1"
                    aria-label={t("text_export_to_excel")}
                >
                    {isExporting && <LoaderIcon spin={isExporting} width={0.8} />}
                    <i className="fa fa-file-excel-o font-size-20 mr-1" aria-hidden="true"></i>
                    <GetAppIcon />
                </PrimaryButton>
                <PrimaryButton
                    variant="contained"
                    size="small"
                    className="ml-1"
                    onClick={downloadSample}
                >
                    Download Samples
                </PrimaryButton>
            </div>
            <div className="table_container table-responsive bg-light shadow-sm" >
                <table className="table table-striped table-borderless" id="pdf-view">
                    <thead>
                        <tr>
                            <th>FAT/{rateBy === "snf" ? "SNF" : "CLR"}</th>
                            {
                                columns.map(column => {
                                    return <th key={column}>{column}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rows.map((row, rowIndex) => {
                                return (<tr key={rowIndex}>
                                    {
                                        row.map((column, colIndex) => {
                                            let val = (colIndex === 0) ? column : column.rate;
                                            return <td key={colIndex}>{val}</td>;
                                        })
                                    }
                                </tr>)
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default RateListTable;