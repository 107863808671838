export function parseNumber(value, locale = navigator.language) {
    if(!value) return value;
    const example = Intl.NumberFormat(locale).format("1.1");
    const cleanPattern = new RegExp(`[^-+0-9${ example.charAt( 1 ) }]`, "g");
    if(typeof value != "string") {
        value = value.toString();
    }
    const cleaned = value.replace(cleanPattern, "");
    const normalized = cleaned.replace(example.charAt(1), ".");
  
    return parseFloat(normalized);
}

export function formatNumber(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function toFixed (number, decimalPlaces = 2) {
    if(typeof number === "string") {
        var match= number.match(/[0-9,.-]*/);
        if (match!==null) {
            number = parseFloat( match[0].replace(/,/g, "") ); // replace , thousands separator
        }
        number = parseFloat(number);
    }
    
    return number?.toFixed(decimalPlaces) || 0;
}