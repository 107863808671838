/* eslint-disable no-undef */
// @ts-nocheck
import * as Sentry from "@sentry/react";

export const getFCMToken = () => {
    if(typeof FirebasePlugin != "undefined") {
        return new Promise((resolve, reject) => {
            FirebasePlugin.getToken(resolve, reject);
        });
    }
}

export const getFCMId = () => {
    if(typeof FirebasePlugin != "undefined") {
        return new Promise((resolve, reject) => {
            FirebasePlugin.getId(resolve, reject);
        });
    }
}

export const onMessageReceived = (callback) => {
    if(typeof FirebasePlugin != "undefined") {
        FirebasePlugin.onMessageReceived(callback, function(error) {
            Sentry.captureException(error);
        });
    }
}

export const onTokenRefresh = (callback) => {
    if(typeof FirebasePlugin != "undefined") {
        FirebasePlugin.onTokenRefresh(callback, function(error) {
            Sentry.captureException(error);
        });
    }
}