import {
    DAIRY_SELECTED_TOGGLE,
    FETCH_DAIRIES_SUCCESS,
    FETCH_DAIRY_DAILY_TOTAL_SUCCESS,
    FETCH_DAIRY_FAILURE,
    FETCH_DAIRY_PAYMENT_FAILED,
    FETCH_DAIRY_PAYMENT_SUCCESS,
    FETCH_DAIRY_REQUEST,
    FETCH_DAIRY_SUCCESS,
    FETCH_DAIRY_TODAY_TOTAL_SUCCESS,
    FETCH_KISAN_DAIRY_FAILURE,
    FETCH_KISAN_DAIRY_REQUEST,
    FETCH_KISAN_DAIRY_SUCCESS,
    SELECT_DAIRY,
    UPDATE_DAIRY
} from "../_constants";
import { checkFeatureInSubscription } from "../components/common/FeatureFlag/FeatureFlag";
import { copyObject } from "../components/library/util";
import SubscriptionModal from "../components/models/Subscription";

const subscriptionModal = new SubscriptionModal();
const subscription = subscriptionModal.get();

const initialState = {
    dairy: {},
    dairies: [],
    is_fetching_payment: true,
    subscription: subscription,
    today_total: {
        collection: {},
        distribution: {}
    },
    daily_total: {
        collection: [],
        distribution: []
    },
    isFetchingDairy: true,
    isFetchingKisanDairy: false,
    dairySelected: false,
    error: false,
    fetchDairyError: false
};

function dairyReducer(state = initialState, action) {
    switch (action.type) {
    case FETCH_DAIRY_REQUEST: {
        return {
            ...copyObject(state),
            isFetchingDairy: true,
        };
    }
    case FETCH_DAIRY_SUCCESS: 
    case UPDATE_DAIRY:
        let dairy = action.data;
        return {
            ...copyObject(state),
            dairy: {
                ...copyObject(state.dairy),
                ...dairy
            },
            isFetchingDairy: false,
            fetchDairyError: false,
        };
    case FETCH_DAIRIES_SUCCESS: {
        const selectedDairyId = localStorage.getItem("selected_dairy_id");
        const newState = {
            ...copyObject(state),
            dairies: action.data,
            isFetchingDairy: false,
            fetchDairyError: false,
            error: false,
        };

        let selectedDairy = null;
        if(selectedDairyId) {
            selectedDairy = action.data.find(record => record.id == selectedDairyId);
        }
        if(selectedDairy) {
            newState.dairy = copyObject(selectedDairy);
            newState.dairySelected = true;
        } else if(action.data.length === 1) {
            newState.dairy = copyObject(action.data[0]);
            newState.dairySelected = true;
        }

        return newState;
    }
    case FETCH_DAIRY_FAILURE: {
        return {
            ...copyObject(state),
            isFetchingDairy: false,
            fetchDairyError: true,
            error: action.payload
        };
    }
    case FETCH_DAIRY_TODAY_TOTAL_SUCCESS:
        return {
            ...copyObject(state),
            today_total: action.data
        };
    case FETCH_DAIRY_DAILY_TOTAL_SUCCESS:
        return {
            ...copyObject(state),
            daily_total: action.data
        };
    case FETCH_DAIRY_PAYMENT_SUCCESS:
        SubscriptionModal.save(action.data);
        return {
            ...copyObject(state),
            is_fetching_payment: false,
            subscription: action.data
        };
    case FETCH_DAIRY_PAYMENT_FAILED:{
        return {
            ...copyObject(state),
            is_fetching_payment: false,
            subscription: {}
        };
    }
    case FETCH_KISAN_DAIRY_REQUEST: {
        return {
            ...copyObject(state),
            isFetchingKisanDairy: true,
        };
    }
    case FETCH_KISAN_DAIRY_SUCCESS: {
        const dairy = action.data;
        if(!checkFeatureInSubscription("billing_cycle", false)) {
            dairy.billing_cycle = "monthly";
        }
        return {
            ...copyObject(state),
            dairy: dairy,
            isFetchingKisanDairy: false,
        };
    }
    case FETCH_KISAN_DAIRY_FAILURE: {
        return {
            ...copyObject(state),
            isFetchingKisanDairy: false,
        };
    }
    case DAIRY_SELECTED_TOGGLE: {
        return {
            ...copyObject(state),
            dairySelected: action.data
        }
    }
    case SELECT_DAIRY: {
        localStorage.setItem("selected_dairy_id", action.payload.id);
        return {
            ...copyObject(state),
            dairy: action.payload,
            dairySelected: true
        }
    }
    default:
        return state;
    }
}

export const getDairyCount = state => state?.dairy?.dairies?.length;

export const getHasDairy = state => Boolean(state?.dairy?.dairy?.id);

export const getDairyId = state => state?.dairy?.dairy?.id;

export const getDairyName = state => state?.dairy?.dairy?.name;

export const getIsDairyOwner = state => state?.dairy?.dairy?.is_owner == 1;

export default dairyReducer;
