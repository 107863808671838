import React from "react";
import { Drawer, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { toggleSideBarClose } from "../../../../actions/index";
import SideBar from '../../SideBar';

const useStyles = makeStyles({
    paper: {
        width: "80%"
    }
});

export default function MainNavigationDrawer () {
    const classes = useStyles();

    const { isSidebarOpen } = useSelector(state => state.defaultReducer);
    const dispatch = useDispatch();
    return (
        <Drawer
            classes={classes}
            anchor={"left"}
            open={isSidebarOpen}
            onClose={() => dispatch(toggleSideBarClose())}
        >
            <SideBar />
        </Drawer>
    )
}