import React from "react";
import GraphSlider from "./GraphSlider";
import PieGraph from "./PieGraph";
import BarGraphSlider from "./BarGraphSlider";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import "./Bar_Graph.css"
function AllGraphs() {
  const [filter, setFilter] = useState("graphSlider");
  const [open, setOpen] = useState(false);
  const useStyles = makeStyles((theme) => ({
    div: {
      display: "flex",
      flexDirection: "row-reverse",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }));
  const classes = useStyles();
  const handleChange = (event) => {
    setFilter(event.target.value);

  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => { }, []);

  return (
    <div>
      <div className={classes.div} >
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-controlled-open-select-label">
            Select filter
          </InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={filter}
            onChange={handleChange}
            defaultValue={filter}
          >
            <MenuItem value="graphSlider">Graph</MenuItem>
            <MenuItem value="barGraphSlider"> Bar Graph</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div>
        {filter === "graphSlider" ? <div className="form-row">
          <div className="col-12 mb-4 allGraph">
            <GraphSlider />
          </div>
        </div> :
          <div className="form-row">
            <div className="col-12 mb-4 allGraph">
              <BarGraphSlider />
            </div>
          </div>}
      </div>
      <hr />
      <div>
        <div className="form-row">
          <div className="col-12 mb-4 allGraph mt-4">
            <PieGraph />
          </div>
        </div>
      </div>
    </div>
  )
}
export default AllGraphs;