import React from 'react';
import SimpleValidator from '../../common/SimpleValidator';
import withLanguage from "../../language/LanguageController";
import { connect } from "react-redux";
import { updateAddress } from "../../../actions/user";
import analytics from '../../library/firebase/analytics';
import { DefaultLayout } from '../common/Layout';

class AddressForm extends React.Component {

    constructor (props) {
        super(props);
        const { address } = props;
        this.state = {
            isSaving: false,
            ...address
        };
        this.validator = new SimpleValidator();
    }

    componentDidMount () {
        analytics.setScreenName("AddressForm");
    }

    handleInputChange = e => {
        let target = e.target;
        let name = target.name;
        let value = target.value;
    
        this.setState(() => {
            return {
                [name]: value
            };
        });
    }

    saveAddress = e => {
        e.preventDefault();
        // validate form before submitting to server 
        if (!this.validator.allValid()) {
            console.log("Error in update address");
            this.validator.showMessages();
            this.forceUpdate(); // rerender to show messages for the first time
            return false;
        }
        analytics.logEvent("saveAddress");
        this.props.updateAddress();
    }

    render () {
        const { t } = this.props;
        const { isSaving, address_1, postcode, city, state } = this.state;
        return (
            <DefaultLayout
                back
                bottomGutter={false}
                title={t("text_change_address")} 
                toolbar={false}
                sideNav={false}
            >
                <div className="col-12 col-sm-5 mx-auto">
                    <div className="t-card shadow-sm">
                        <form method="post" onSubmit={this.saveAddress} id="address_form">
                            <div className="form-group">
                                <label>{t("text_address")}</label>
                                <input type="text"
                                    className="form-control"
                                    name="address_1"
                                    value={address_1}
                                    onChange={this.handleInputChange}
                                />
                                {this.validator.message("address", address_1, "required")}
                            </div>
                            <div className="form-group">
                                <label>{t("text_state")}</label>
                                <input type="text"
                                    className="form-control"
                                    placeholder={t("text_select_state")}
                                    name="state"
                                    value={state}
                                    onChange={this.handleInputChange}
                                />
                                {this.validator.message("state", state, "required")}
                            </div>
                            <div className="form-group">
                                <label>{t("text_district_city")}</label>
                                <input type="text"
                                    className="form-control"
                                    placeholder={t("text_select_district_city")}
                                    name="city"
                                    value={city}
                                    onChange={this.handleInputChange}
                                />
                                {this.validator.message("city", city, "required")}
                            </div>
                            <div className="form-group">
                                <label>{t("text_zipcode")}</label>
                                <input type="number"
                                    className="form-control"
                                    name="postcode"
                                    value={postcode}
                                    onChange={this.handleInputChange}
                                />
                                {this.validator.message("postcode", postcode, "required")}
                            </div>
                            <div className="form-group mb-0">
                                <button
                                    className="btn btn-success btn-block"
                                    disabled={isSaving}
                                    onClick={this.saveAddress}>
                                    {isSaving ? t("text_saving") : t("text_save")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </DefaultLayout>
        );
    }
}

AddressForm.displayName = "AddressForm";

const mapStateToProps = ({ userReducer }) => ({
    address: userReducer.user.address
});

const mapDispatchToProps = {
    updateAddress
};

export default connect(mapStateToProps, mapDispatchToProps)(withLanguage(AddressForm));