import React from "react";
import withLanguage from "../../language/LanguageController";
import BuffaloWhite from "./../../../assets/icons/buffalo-white.svg";

const BuffaloWhiteIcon = ({ t, dispatch, ...restProps  }) => {
    return <img src={BuffaloWhite} alt={t("text_buffalo")} {...restProps} />;
};

BuffaloWhiteIcon.defaultProps = {
    width: "25px"
};

export default withLanguage(BuffaloWhiteIcon);