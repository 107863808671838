import request from "../library/request";

export const activateAccount = async (activationKey, renew = false) => {
    return await request.post('/subscription/subscribe/activationKey', {
        withAuth: true,
        body: JSON.stringify({
            "activation_key": activationKey,
            "renew": renew
        })
    });
};

export default {
    activateAccount
};