import React, { Component } from 'react';
import PasswordMask from 'react-password-mask';
import { toast, loader } from "../../library/util";
import withLanguage from '../../language/LanguageController';
import SimpleValidator from '../../common/SimpleValidator';
import { errorHandler } from '../../library/response';
import analytics from '../../library/firebase/analytics';
import request from '../../library/request';
import { DefaultLayout } from '../common/Layout';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class ChangePassword extends Component {

  constructor(props) {
    super(props);
    this.formId = "change-password-form";
    this.state = {
      submitBtn: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleValidator();
  }

  componentDidMount () {
    analytics.setScreenName("ChangePassword");
  }

  handleInput = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    this.setState({
      [name]: value
    });
  }

  forgotPassword = async event => {
    event.preventDefault();
    try {
      analytics.logEvent("ChangePassword - ForgotPasswordOTP");
      loader.show(this.props.t("text_loading"));
      const { status, message } = await request.post('/auth/forgotPassword', {
        withAuth: true,
        body: JSON.stringify({
          "mobile": this.props.user.telephone
        })
      });
      if (status) {
        this.props.history.push({
          pathname: "/account/setPassword",
          state: {
            mobile: this.props.user.telephone,
            customer_id: this.props.user.customer_id,
            changePassword: true
          }
        });
      } else {
        throw message;
      }
    } catch(error) {
      errorHandler(error);
    } finally {
      loader.hide();
    }
  }

  async handleSubmit (event) {
    event.preventDefault();

    try {
      // validate form before submitting to server 
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate(); // rerender to show messages for the first time
        return false;
      }
      analytics.logEvent("changePassword");
      loader.show(this.props.t("text_verifying"));
      this.setState({
        submitBtn: true
      });      
      const { status, message } = await request.patch('/auth/changePassword', {
        withAuth: true,
        body: JSON.stringify({
          "old_password": this.state.old_password,
          "password": this.state.password,
          "password_confirmation": this.state.password_confirmation
        })
      });
      if (status) {
        toast(message);
        this.setState({
          old_password: "",
          password: "",
          password_confirmation: ""
        }, () => {
          this.validator.hideMessages();
        });
      } else {
        throw message;
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      loader.hide();
      this.setState({
        submitBtn: false
      });
    }
  }

  render() {
    const { submitBtn } = this.state;
    const { t } = this.props;
    return (
      <DefaultLayout
        title={t("text_change_password")}
        sideNav={false}
      >
        <div className="form-row">
          <div className="col col-md-6 col-lg-4 mx-auto">
            <div className="t-card shadow-sm">
              <form method="POST" id={this.formId} 
                onSubmit={this.handleSubmit.bind(this)}>
                <div className="form-group ">
                  <label htmlFor="old_password">{t("text_old_password")}</label>
                  <PasswordMask
                    id="old_password"
                    name="old_password"
                    inputClassName="form-control input-bottom-border"
                    buttonClassName="no-style"
                    placeholder={t("text_enter_old_password")}
                    value={this.state.old_password}
                    onChange={this.handleInput.bind(this)}
                    showButtonContent={<i className="fa fa-eye" />}
                    hideButtonContent={<i className="fa fa-eye-slash" />}
                  />
                  {this.validator.message('old_password', this.state.old_password, 'required')}
                  <small>
                    <Link to="/account/setPassword" onClick={this.forgotPassword}>
                      {t("text_forgot_password")}?
                    </Link>
                  </small>
                </div>
                <div className="form-group">
                  <label htmlFor="password">{t("text_new_password")}</label>
                  <PasswordMask
                    id="password"
                    name="password"
                    inputClassName="form-control input-bottom-border"
                    buttonClassName="no-style"
                    placeholder={t("text_enter_new_password")}
                    value={this.state.password}
                    onChange={this.handleInput.bind(this)}
                    showButtonContent={<i className="fa fa-eye" />}
                    hideButtonContent={<i className="fa fa-eye-slash" />}/>
                  {this.validator.message('new_password', this.state.password, [
                    "required",
                    "min:6,string",
                    "strong_password",
                    {
                      not_in: this.state.old_password
                    }
                  ])}
                </div>
                <div className="form-group">
                  <label htmlFor="password_confirmation">{t("text_confirm")}</label>
                  <PasswordMask
                    id="password_confirmation"
                    name="password_confirmation"
                    inputClassName="form-control input-bottom-border"
                    buttonClassName="no-style"
                    placeholder={t("text_enter_password_confirmation")}
                    value={this.state.password_confirmation}
                    onChange={this.handleInput.bind(this)}
                    showButtonContent={<i className="fa fa-eye" />}
                    hideButtonContent={<i className="fa fa-eye-slash" />} />
                  {this.validator.message('r_password_confirmation', this.state.password_confirmation, [
                    "required",
                    {
                      "in": this.state.password
                    }
                  ])}
                  <small className="text-grey-dark mt-3 display-inline-block">{t("text_password_help_min")}</small><br/>
                  <small className="text-grey-dark">{t("text_password_help_case_letter")}</small><br/>
                  <small className="text-grey-dark">{t("text_password_help_special_character")}</small>
                </div>
                <div className="form-group mb-0">
                  <input 
                    className="btn btn-success btn-block" 
                    type="submit"
                    disabled={submitBtn}
                    value={submitBtn ? t('text_updating') : t('text_update')} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state?.userReducer?.user
  }
};

export default connect(mapStateToProps)(
  withRouter(withLanguage(ChangePassword))
);