import React, { useEffect } from "react";
import { Box, Card, Grid, Typography, Checkbox, FormGroup, FormControlLabel, InputBase, Paper, Button } from "@material-ui/core";
import withLanguage from "../../../language/LanguageController";
import { useSelector, useDispatch } from 'react-redux';
import { fetchCustomerReward } from '../../../../actions/reward';
import { currencyFormat } from '../../../library/util';
import { PrimaryCheckbox } from './../../../core/Checkbox'
const Index = ({ t, onRedeemChange, redeem }) => {

  const { reward } = useSelector(state => state.reward);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchCustomerReward(dispatch);
  }, []);

  return (
    <Grid container justifyContent="center">
      {reward.points && <Grid item xs={12} >
        <Box className="mb-2">
          <Card className="">
            <Grid container>
              <Grid item>
                <PrimaryCheckbox
                  checked={redeem}
                  onChange={(e) => onRedeemChange(e, reward)}
                />
                <label>{t('text_redeem')}</label>
              </Grid>
              <Grid item xs>
                <Grid container direction="row-reverse">
                  <Grid item>
                    <Typography className="mr-3 mt-2">
                      {reward.points} {t("text_points")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Grid>}
    </Grid>
  )
}

export default withLanguage(Index)
