import { useLanguage } from "../../../language/LanguageController";
import { DefaultLayout } from "../../common/Layout";
import OrdersList from "./OrdersList";

const Orders = () => {
    const { t } = useLanguage();
    return (
        <DefaultLayout
            title={t("text_orders")}
            sideNav={false}
            bottomGutter={false}
        >
            <OrdersList />
        </DefaultLayout>
    );
};

export default Orders;