// @ts-nocheck
import { Component } from 'react';
import moment from "moment";
import config from '../../../config/config.json';
import { confirmPop, loader, toast } from '../../library/util';
import { errorHandler } from '../../library/response';
import analytics from '../../library/firebase/analytics';
import request from '../../library/request';
import Eventer from 'src/components/library/Eventer';

class MilkDetailTotalController extends Component {

    constructor(props) {
        super(props);
        this.getMilkDetail = this.getMilkDetail.bind(this);
        this.deleteMilk = this.deleteMilk.bind(this);
        this.state = {
            records: [],
            total: {
                "cow_morning": {
                    "milk": "",
                    "amount": "",
                    "rate": 0
                },
                "cow_evening": {
                    "milk": "",
                    "amount": "",
                    "rate": 0
                },
                "cow_total": {
                    "milk": "",
                    "amount": "",
                    "rate": 0
                },
                "buffalo_morning": {
                    "milk": "",
                    "amount": "",
                    "rate": 0
                },
                "buffalo_evening": {
                    "milk": "",
                    "amount": "",
                    "rate": 0
                },
                "buffalo_total": {
                    "milk": "",
                    "amount": "",
                    "rate": 0
                },
                "morning_total": {
                    "milk": "",
                    "amount": "",
                    "rate": 0
                },
                "evening_total": {
                    "milk": "",
                    "amount": "",
                    "rate": 0
                },
                "total_milk": "",
                "total_amount": 0,
            },
            isBrowser: false,
            ...this.getDefaultFilter()
        };
        this.settings = {
            dots: false,
            infinite: false,
            speed: 1000,
            swipeToSlide: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            touchMove: false
        };
    }

    getDefaultFilter(){
        return {
            customer_id: this.props.customerId,
            start_date: moment().startOf("month"),
            end_date: moment().endOf("month"),
            milk_type: 'cow',
            day_time: "morning"
        }
    }

    componentDidMount() {
        analytics.setScreenName("MilkDetailTotal");
        this.getMilkDetail();
        Eventer.on("milkAdded", this.getMilkDetail);
        Eventer.on("milkDeleted", this.getMilkDetail);

        this.props.onRef && this.props.onRef(this);
    }

    componentWillUnmount() {
        Eventer.off("milkAdded", this.getMilkDetail);
        Eventer.off("milkDeleted", this.getMilkDetail);
    }

    async getMilkDetail () {
        try {
            loader.show(this.props.t("text_loading"));
            const { start_date, end_date } = this.state;
            const filterStr = encodeURI(`start_date=${start_date.format(config.date_format)}&end_date=${end_date.format(config.date_format)}`);
            const { status, data } = await request.get('/diary/' + this.props.customerId + '/milkTotal?' + filterStr, {
                withAuth: true
            })
            let stateData = {
                records: []
            };
            if(status === true){
                stateData = {
                    records: data.records,
                    total: data.total
                };
            }
            this.setState(stateData);
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    dateChangeHandler = (name, selectedDate) => {
        this.setState({
            [name]: selectedDate
        }, () => {
            this.getMilkDetail();
        });
    }

    async deleteMilk (e, milk) {
        e.preventDefault();
        try {
            analytics.logEvent("deleteMilk");
            let confirm = await confirmPop(this.props.t("text_delete_milk_confirm"));
            if(confirm == 1){
                loader.show(this.props.t("text_deleting"));
                const { status, message } = await request.delete('/diary/milk/' + this.props.customerId, {
                    withAuth: true,
                    body: JSON.stringify({
                        date: milk.date,
                        milk_type: milk.milk_type,
                        day_time: milk.day_time
                    })
                });
                if(status === true){
                    toast(message);
                    this.getMilkDetail();
                } else {
                    throw message
                }
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    onDeviceChange = deviceTypes => {
        this.setState({
            ...deviceTypes
        });
    }
}

export default MilkDetailTotalController;
