import React from "react";
import { useSelector } from "react-redux";
import KisanCustomerMilkTotal from "./KisanCustomerMilkTotal";
import CustomerAccount from "../account/customer/CustomerAccount";
import KisanCustomerMilkDetail from "./KisanCustomerMilkDetail";
import { DefaultLayout } from "../../common/Layout";

const KisanDairyPage = () => {
    const dairyName = useSelector(state => state.dairy.dairy.name);
    return (
        <DefaultLayout
            bottomGutterClass="mb-5"
            title={dairyName}>
            <div className="form-row">
                <div className="col-12 col-sm-4">
                    <KisanCustomerMilkTotal
                        header={false}
                        bottomGutter={false}
                        toolbar={false} />
                </div>
                <div className="col-12 col-sm-5">
                    <KisanCustomerMilkDetail
                        header={false} />
                </div>
                <div className="col-12 col-sm-3">
                    <CustomerAccount
                        bottomGutter={false}
                        header={false}
                        innerNavigation={false} />
                </div>
            </div>
        </DefaultLayout>
    );
};

KisanDairyPage.displayName = "KisanDairyPage";

export default KisanDairyPage;