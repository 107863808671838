import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { TextField } from '@material-ui/core';

const PrimaryTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: green[600],
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: green[600],
      },
    },
    '& label.Mui-focused.Mui-error': {
      color: "#f44336"
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: green[600],
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderColor: '#f44336',
    }
  },
})(TextField);

export default PrimaryTextField;