import {
    CLR_LOWER_LIMIT,
    CLR_UPPER_LIMIT,
    FAT_LOWER_LIMIT,
    FAT_UPPER_LIMIT,
    MILK_LOWER_LIMIT,
    MILK_UPPER_LIMIT,
    SNF_LOWER_LIMIT,
    SNF_UPPER_LIMIT
} from "./rateList";

export const LITER_RULES = `required|numeric|min:${MILK_LOWER_LIMIT},num|max:${MILK_UPPER_LIMIT},num`;
export const FAT_RULES = `required|numeric|min:${FAT_LOWER_LIMIT},num|max:${FAT_UPPER_LIMIT},num`;
export const RATE_RULES = 'required|numeric';
export const SNF_RULES = `required|numeric|min:${SNF_LOWER_LIMIT},num|max:${SNF_UPPER_LIMIT},num`;
export const CLR_RULES = `required|numeric|min:${CLR_LOWER_LIMIT},num|max:${CLR_UPPER_LIMIT},num`;