// @ts-nocheck
import config from "../../config";
import { t } from "../language/LanguageController";

const smsApp = {
    send: function(number, message) {
        //CONFIGURATION
        // intent: 'INTENT' send SMS with the native android SMS messaging
        // intent: "" send SMS without opening any other app
        var options = {
            replaceLineBreaks: false, // true to replace \n by a new line, false by default
            android: {
                intent: config.sms_type
            }
        };
 
        var success = function () {
            console.log("send sms success");
            if(config.sms_type !== "INTENT") {
                alert(t("text_message_send"));
            }
        };
        var error = function (e) {
            console.log("Message Failed:" + e); 
            alert(t("text_message_send_error"));
        };
        console.log("window.sms.send")
        window.sms.send(number, message, options, success, error);
    },

    sendTextSms: function(number, message) {
        if(typeof window.sms === "undefined") {
            console.log("Number:", number);
            console.log(message);
            return;
        }
        if(config.sms_type === "INTENT") {
            smsApp.send(number, message);
        } else {
            var success = function (hasPermission) { 
                console.log("hasPermission", hasPermission);
                if (!hasPermission) {
                    window.sms.requestPermission(function() {
                        console.log('[OK] Permission accepted');
                        smsApp.send(number, message);
                    }, function(error) {
                        console.info('[WARN] Permission not accepted', error);
                    })
                } else {
                    smsApp.send(number, message);
                }
            };
            var error = function (e) { 
                console.log('Something went wrong:' + e);
            };
            window.sms.hasPermission(success, error);
        }
    },

    sendWhatsappSms: function(number, message) {
        if(typeof window.sms === "undefined") {
            console.log("Number:", number);
            console.log(message);
            return;
        }
        
        window.sms.sendWhatsappSms(number, message);
    }
};

export default smsApp;