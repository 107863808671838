// @ts-nocheck
import React, { useEffect } from "react";
import { useAndroid, useBrowser, useMobile, useTablet } from "src/components/library/detect-device";
import InnerHeaderSmall from "../InnerHeaderSmall.js";
import { DesktopSideNavigation } from "../../../common/Navigation";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { usePageHead } from "../../../helpers/head.js";

const useStyles = makeStyles(() => ({
    content: {
      flexGrow: 1,
      width: "calc(100% - 125px)"
    },
}));

const DefaultLayout = ({
    children,
    bottomGutter,
    bottomGutterClass,
    title,
    toolbar,
    back,
    header,
    onDeviceChange,
    sideNav,
    ...restProps
}) => {
    const pageHead = usePageHead(title);
    const classes = useStyles();

    const isMobile = useMobile();
    const isTablet = useTablet();
    const isBrowser = useBrowser();
    const isAndroid = useAndroid();
    useEffect(() => {
        onDeviceChange({
            isMobile,
            isTablet,
            isBrowser,
            isAndroid
        });
    }, [isMobile, isTablet, isBrowser, isAndroid, onDeviceChange]);
    
    const { user } = useSelector(state => state.userReducer);

    const customer_group_id = user?.customer_group_id;

    if(customer_group_id == 1) {
        sideNav = false;
    }    

    if(header) {
        return (
            <>
                {pageHead}
                <div className="container-fluid" {...restProps}>
                    <InnerHeaderSmall
                        className="fixed-header"
                        title={title}
                        toolbar={toolbar}
                        back={back}
                    />
                    <section className={`mt-75 ${bottomGutter && bottomGutterClass} d-flex`}>
                        {isBrowser && sideNav && <DesktopSideNavigation />}
                        <main className={`${isBrowser && sideNav ? classes.content : "w-100"} position-relative`}>
                            {children}
                        </main>
                    </section>
                </div>
            </>
        );
    } else {
        return (
            <>
                <section className={`${bottomGutter && bottomGutterClass} position-relative`}>
                    {children}
                </section>
            </>
        );
    }
};

DefaultLayout.displayName = "DefaultLayout";

DefaultLayout.defaultProps = {
    back: false,
    bottomGutter: true,
    bottomGutterClass: "mb-8",
    header: true,
    onDeviceChange: () => {},
    title: "Liter",
    toolbar: true,
    sideNav: true
}

export default DefaultLayout;

