import React,  { Component } from "react";
// import { Radio } from "react-onsenui";
import CustomeRateListSlotModal from "../../modalPopup/CustomRateListModal/CustomeRateListSlotModal";
import { copyObject, generateRandom } from '../../../../library/util';
import SimpleValidator from "../../../../common/SimpleValidator";
import { FAT_LOWER_LIMIT } from "../../../../../_constants/rateList";
import ErrorBoundary from "../../../../common/ErrorBoundary";
import CustomRateListSlot from "./CustomRateListSlot";
import withLanguage from "src/components/language/LanguageController";
import DefaultCLRSNF from "../DefaultCLRSNF";
import Eventer from "src/components/library/Eventer";
import { RadioPrimary } from "../../../../core/Radio";
import { HasPermission } from "../../../../common/AccessPermission";
import { ABILITY_DAIRY_RATE_LIST, PERMISSION_UPDATE } from "../../../../../_constants/permissions";

class CustomRateList extends Component {

    constructor (props) {
        super(props);
        this.state = {
            edit_slot: false,
            slot: {},
            slot_modal: false,
            slotIndex: null,
            slots: props.slots && props.slots.length ? props.slots : [],
        };
    }

    componentDidMount() {
        Eventer.on(`applyRateListPreset${this.props.rateListId}`, this.onRateListPresetApply)
    }

    componentWillUnmount() {
        Eventer.off(`applyRateListPreset${this.props.rateListId}`, this.onRateListPresetApply)
    }

    onRateListPresetApply = (event) => {
        const { detail } = event;
        this.props.validator.purgeFields();
        if(detail.rateListTypeId == 7) {
            this.setState({
                slots: detail.slots
            });
        }
    }

    toggleAddSlot = (e, action = "add") => {
        if(!this.state.slot_modal) {
            this.validator = new SimpleValidator();
        }
        this.setState({
            slot_modal: !this.state.slot_modal,
            edit_slot: false,
            slot: {}
        });
    }

    addSlot = (slot) => {
        const slots = copyObject(this.state.slots);
        slot.slot_id = generateRandom(3);
        slots.push(slot);
        this.setState({
            slots: slots,
            slot_modal: false,
            edit_slot: false,
            slot: {}
        });
    }

    updateSlot = slot => {
        const slots = copyObject(this.state.slots);
        slots[this.state.slotIndex] = slot;
        this.setState({
            slots: slots,
            slot_modal: false,
            edit_slot: false,
            slot: {}
        });
    }

    editSlot = (event, slotIndex) => {
        event.preventDefault();
        this.validator = new SimpleValidator();
        const slots = copyObject(this.state.slots);
        this.setState({
            edit_slot: true,
            slotIndex: slotIndex,
            slot: slots[slotIndex],
            slot_modal: true,
        });
    }

    deleteSlot = (event, slotIndex) => {
        event.preventDefault();
        const slots = copyObject(this.state.slots);
        this.setState({
            slots: [...slots.slice(0, slotIndex), ...slots.slice(parseInt(slotIndex) + 1)],
        });
    }

    setRateBy = event => {
        this.props.validator.purgeFields();
        this.props.onRateByChange(event.target.value);
    }

    componentDidCatch = (error, info) => {
        console.log(error, info);
    }

    render () {        
        const { t, rateListTypeId, validator, settings, rate_by, index } = this.props;
        const { slot_modal, slots, edit_slot, slot } = this.state;
        const lastSlot = slots.length ? slots.slice(-1)[0] : null;
        const newSlotFatFrom = lastSlot && lastSlot.fat_rate ? lastSlot.fat_rate.max : FAT_LOWER_LIMIT;
        const hasLastFat = slots.some(slot => {
            return slot?.fat_rate?.max == "15.0";
        });
        return (
            <ErrorBoundary>
                {this.props.dairyType !==8 && 
                    <HasPermission ability={ABILITY_DAIRY_RATE_LIST} access={PERMISSION_UPDATE}>
                        <div className="form-group position-absolute right-0 z-index-9">
                            <button
                                type="button"
                                className="btn btn-success" 
                                onClick={e => this.toggleAddSlot(e, 'add')}
                                disabled={hasLastFat}>
                                {t("text_add_slot")}
                            </button>
                        </div>
                    </HasPermission>
                }
                <div className="form-group">
                    <label className="block">{t("text_rate_based_on")}</label>
                    <div className="form-check form-check-inline">
                        <RadioPrimary
                            id={`rate_by_fat_${index}`}
                            name="rate_by" 
                            value="fat"
                            modifier='material form-check-input'
                            onChange={this.setRateBy}
                            checked={rate_by === "fat"}/>
                        <label htmlFor={`rate_by_fat_${index}`} className="form-check-label">
                            {t("text_only_fat")}
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <RadioPrimary
                            id={`rate_by_clr_${index}`}
                            name="rate_by" 
                            value="clr"
                            modifier='material form-check-input'
                            onChange={this.setRateBy}
                            checked={rate_by === "clr"} />
                        <label htmlFor={`rate_by_clr_${index}`} className="form-check-label">
                            {t("text_fat")}/{t("text_clr")}
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <RadioPrimary
                            id={`rate_by_snf_${index}`}
                            name="rate_by" 
                            value="snf"
                            modifier='material form-check-input'
                            onChange={this.setRateBy}
                            checked={rate_by === "snf"}/>
                        <label htmlFor={`rate_by_snf_${index}`} className="form-check-label">
                            {t("text_fat")}/{t("text_snf")}
                        </label>
                    </div>
                </div>
                <DefaultCLRSNF
                    key={rateListTypeId}
                    defaultRateBy={rate_by}
                    rateSettings={settings?.rate_settings}
                    validator={validator} />
                {this.props.dairyType !==8 && <table className="table">
                    <thead>
                        <tr>
                            <th>
                                {t("text_fat")}
                            </th>
                            <th>
                                {t("text_rate")}
                            </th>
                            <th width="65px"></th>
                        </tr>
                    </thead>
                    <tbody>                
                    {slots.map((slot, index) => {
                        return (
                            <CustomRateListSlot
                                key={slot.slot_id}
                                t={t}
                                index={index}
                                slot={slot}
                                onEdit={this.editSlot}
                                onDelete={this.deleteSlot} />
                        );
                    })}
                    </tbody>
                </table>}
                {slot_modal && <CustomeRateListSlotModal
                    t={t}
                    editSlot={edit_slot}
                    slot={slot}
                    rateBy={rate_by}
                    isOpen={slot_modal}
                    fatStartFrom={(parseFloat(newSlotFatFrom) + 0.1).toFixed(1)}
                    onAdd={this.addSlot}
                    onUpdate={this.updateSlot}
                    onClose={this.toggleAddSlot}
                    validator={this.validator}
                />}
            </ErrorBoundary>
        );
    }
}

export default withLanguage(CustomRateList);