import React from "react";
import { connect, useSelector } from "react-redux";
import languages from "../../language/language.json";

const withLanguage = (WrappedComponent) => {
    class LangHOC extends React.Component {
        t = (key = "") => {
            return key ? t(key) : key;
        };

        render () {
            const { forwardRef, language, ...rest } = this.props;
            return <WrappedComponent ref={forwardRef} {...rest} language={language} t={this.t} />
        }
    }

    const mapStateToProps = state => ({
        language: state.defaultReducer?.language || "hi"
    })

    return connect(mapStateToProps)(LangHOC);
};

export default withLanguage;

export const t = (key, langCode = null) => {
    langCode = langCode || (window.localStorage.getItem("language") || 'hi');
    return (languages[key]) && languages[key][langCode] 
        ? languages[key][langCode]
        : (languages[key] ? languages[key]["en"] : key);
};

export const getLang = _  => {
    let language = window.localStorage.getItem("language");
    return language ? language : 'hi';
}

export const useLanguage = () => {
    const { language = "hi" } = useSelector(state => state.defaultReducer);

    return {
        t: (key) => t(key, language),
        language
    }
}