import useStyles from "./DesktopSideNavigation.style";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import DesktopSideNavigationSubMenu from "./DesktopSideNavigationSubMenu";
import { useLanguage } from "../../language/LanguageController";
import { checkFeatureInSubscription } from "../FeatureFlag/FeatureFlag";
import { useRouteMatch } from "react-router-dom";

const DesktopSideNavigationMenuItem = ({ menuItem }) => {
    const { t } = useLanguage();
    const classes = useStyles();
    const subMenus = menuItem.subMenu ? menuItem.subMenu() : false;
    const [anchorEl, setAnchorEl] = useState(null);
    
    const handleClick = (event) => {
        if(menuItem.feature && !checkFeatureInSubscription(menuItem.feature)) {
            event.preventDefault();
            return false;
        }
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const match = useRouteMatch()
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(menuItem.activePaths.includes(match.path));
    }, [match, menuItem.activePaths]);

    return (
        <>
            <ListItem
                className={`${classes.menuItem} ${(Boolean(anchorEl) || isActive) && "menu-item-active"}`}
                button
                component={subMenus && subMenus.length > 1 ? "" : Link}
                to={menuItem.path}
                onClick={handleClick}
                selected={Boolean(anchorEl) || isActive}
            >
                <ListItemIcon className={classes.menuItemIcon}>
                    <img
                        src={menuItem.image}
                        style={{maxWidth: "30px",minWidth: "30px"}}
                        className={`mt-1 menu-item-icon-image`}
                        alt=""
                    />
                    <img
                        src={menuItem.imageActive}
                        style={{maxWidth: "30px",minWidth: "30px"}}
                        className={`mt-1 menu-item-icon-image-active`}
                        alt=""
                    />
                </ListItemIcon>
                <ListItemText className="text-center" primary={t(menuItem.text)} />
            </ListItem>
            {subMenus && subMenus.length > 1 && (
                <DesktopSideNavigationSubMenu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    handleClose={handleClose}
                    menus={subMenus}
                />
            )}
        </>
    );
};

export default DesktopSideNavigationMenuItem;