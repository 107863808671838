export const formatePreferences = (preferenceData) => {
    const newPreferenceData = { ...preferenceData }
    if(newPreferenceData?.offline_storage_password) {
        newPreferenceData.offline_storage_password = {
            ...newPreferenceData.offline_storage_password,
            value: JSON.parse(newPreferenceData.offline_storage_password.value)
        }
    }

    return newPreferenceData;
}
