// @ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from "react"
import Select from "react-select";
import MilkRateByAnimal from '../MilkRateByAnimal';
import CustomRateList from '../CustomRateList';
import RateListTypeSelector from "../RateListTypeSelector";
import { useLanguage } from "../../../../language/LanguageController";
import { delay, replaceAll } from "../../../../library/util";
import { checkFeatureLimit, getFeatureLimit } from "src/components/common/FeatureFlag/FeatureFlag";
import { useDispatch, useSelector } from "react-redux";
import { updateRateList } from "src/actions/dairyRateList";
import dairyTypes from '../../../../../config/dairy_type.json';
import { getRateChartSetUpTypes, getSelectedRateListType } from "src/components/helpers/rateListHelper";
import SimpleValidator from "src/components/common/SimpleValidator";
import { getCustomersForSelect } from "src/components/library/customer";
import CowWhiteIcon from "../../../../common/icons/CowWhiteIcon";
import CowBlackIcon from "../../../../common/icons/CowBlackIcon";
import BuffaloWhiteIcon from "../../../../common/icons/BuffaloWhiteIcon";
import BuffaloBlackIcon from "../../../../common/icons/BuffaloBlackIcon";
import RateListPreset from "../RateListPreset";
import Eventer from "src/components/library/Eventer";
import { getRateListName } from "../../../../helpers/rateListHelper";
import { fetchSettings } from "../../../../../actions/dairySettings";
import ImportExcelFile from '../ImportExcelFile';
import { getRateListSetupPaymentButton } from "../../../../../reducers";
import RazorpayPaymentButton from "../../../../common/RazorpayPaymentButton";
import RateListCommission from "../RateListCommission/RateListCommission";
import { Divider } from "@material-ui/core";
import { HasPermission } from "../../../../common/AccessPermission";
import { ABILITY_DAIRY_RATE_LIST, PERMISSION_UPDATE } from "../../../../../_constants/permissions";

const RateListTabContent = ({
    active,
    name,
    rate_list_id,
    rate_chart_setup_type,
    rate_chart_setup_type_cattle,
    configuration,
    onCustomerChange,
    selectedCustomersByRateLists,
    index,
    rateListPresets,
    onRateChartSetupTypeCattle,
}) => {
    const { t } = useLanguage();
    const dispatch = useDispatch();
    const formRef = useRef();
    
    const [rateBy, setRateBy] = useState("clr");
    const rateChartSetupTypes = getRateChartSetUpTypes();
    let rateChartSetupType = rateChartSetupTypes[0]
    if(rate_chart_setup_type) {
        rateChartSetupType = rateChartSetupTypes.find(rateChartSetupType => rateChartSetupType.value === rate_chart_setup_type);
    }
    const [selectedRateChartSetupType, setRateChartSetupType] = useState(rateChartSetupType);
    const [rateChartSetupTypeCattle, setRateChartSetupTypeCattle] = useState("cow");
    const rateChartBy = selectedRateChartSetupType.value === "by_cattle" ? rateChartSetupTypeCattle : selectedRateChartSetupType.value;
    const { dairy } = useSelector(state => state.dairy);
    const { customers } = useSelector(state => state.dairy_customers);
    const rateListSetupPaymentButton = useSelector(getRateListSetupPaymentButton);
    const rateListLimitToChange = getFeatureLimit("rate_list_change_limit");
    const rate_list_type_id = parseInt(configuration?.[rateChartBy]?.rate_list_type_id);
    const [rateListType, setRateListType] = useState(
        getSelectedRateListType(dairyTypes, rate_list_type_id)
    );
    const rateListTypeId = parseInt(rateListType.value);
    const settings = configuration?.[rateChartBy]?.[rateListTypeId] || {};
    const validator = useRef(new SimpleValidator())
    const [isError, setIsError] = useState(false);
    const isDefaultRateList = index < 2;

    const { settings: dairySettings } = useSelector(state => state.dairySettings);
    const { rate_list_change_limit = 0 } = dairySettings;
    const getInitialManualRateList = () => {
        const rateListdata = configuration?.[rate_chart_setup_type]?.[rate_list_type_id]?.["manual_rate_list"];
        return rateListdata ? JSON.parse(rateListdata) : null;
    }
    const [manualRateList, setManualRateList] = useState(getInitialManualRateList());

    useEffect(() => {
        if(settings?.rate_by) {
            setRateBy(settings.rate_by)
        }
    }, [settings?.rate_by]);

    useEffect(() => {
        const rateListTypeId = parseInt(configuration?.[rateChartBy]?.rate_list_type_id || 0);
        setRateListType(getSelectedRateListType(dairyTypes, rateListTypeId))
    }, [configuration, rateChartBy]);

    const handleRateListTypeChange = (rateList) => {
        setRateListType(rateList);
    }

    const rateListSubmitHandler = async e => {
        e.preventDefault();
        const isFeatureLimitValid = checkFeatureLimit("rate_list_change_limit", true, {
            message: t("text_rate_list_limit_expired")
        });
        if(isFeatureLimitValid) {
            if (!validator.current.allValid()) {
                validator.current.showMessages();
                setIsError(true);
                return false;
            }
            setIsError(false);
            const formData = new FormData(formRef.current);
            formData.append("rate_list_id", rate_list_id);
            formData.append("name", name);
            if(rateListTypeId === 8 && manualRateList != null){
                formData.append("manual_rate_list", JSON.stringify(manualRateList));
            }
            await updateRateList(dispatch, dairy.id, rate_list_id, formData);
            dispatch(fetchSettings(dairy.id, {
                key: "rate_list_change_limit"
            }));
        }
    }

    const getSelectCustomers = useCallback(() => {
        return getCustomersForSelect(customers);
    }, [customers]);

    const handleCustomerSelection = customers => {
        onCustomerChange(index, customers || []);
    };

    const isCustomerOptionDisabled = (customer) => {
        let customersToDisable = [];
        for(const rateListIndex in selectedCustomersByRateLists) {
            if(rateListIndex != index) {
                const rateListCustomers = selectedCustomersByRateLists?.[rateListIndex] || [];
                customersToDisable = [...customersToDisable, ...rateListCustomers];
            }
        }
        return customersToDisable
            .map(customer => customer.value)
            .includes(customer.value);
    }

    const [rateListApplied, setRateListApplied] = useState(false);

    const applyPreset = async (rateListPreset) => {
        if(rateListPreset?.rate_chart_setup_type) {
            const { rate_chart_setup_type, rate_chart_setup_type_cattle, configuration } = rateListPreset;
            const rateChartSetupType = rateChartSetupTypes.find(rateChartSetupType => rateChartSetupType.value === rate_chart_setup_type);
            const rateChartBy = rateChartSetupType.value === "by_cattle" ? rate_chart_setup_type_cattle : rateChartSetupType.value;
            const rateListTypeId = parseInt(configuration?.[rateChartBy]?.rate_list_type_id);
            setRateListType(
                getSelectedRateListType(dairyTypes, rateListTypeId)
            );
            setRateChartSetupType(rateChartSetupType);
            setRateChartSetupTypeCattle(rate_chart_setup_type_cattle);
            const settings = configuration?.[rateChartBy]?.[rateListTypeId] || {};
            setRateBy(settings.rate_by);
            await delay(500);
            setRateListApplied({...settings, rateListTypeId});
        }
    }

    useEffect(() => {
        if(rateListApplied !== false) {
            Eventer.emit(`applyRateListPreset${rate_list_id}`, rateListApplied);
        }
        return () => {
            setRateListApplied(false);
        }
    }, [rateListApplied, rate_list_id]);

    useEffect(() => {
        onRateChartSetupTypeCattle(rateChartSetupTypeCattle);
    }, [onRateChartSetupTypeCattle, rateChartSetupTypeCattle]);

    const handleExcelImport = (rateListJson) => {
        setManualRateList(rateListJson);
    }

    return (
        <div className="tab-content" id={`${rate_list_id}-tab-content`}>
            <div className={`tab-pane ${active && "fade show active"}`} role="tabpanel" aria-labelledby="default-tab">
                <div className="t-card shadow-sm mb-2 rounded-0">
                    <div className="row justify-content-between">
                        <div className="col">
                            <h2 className="h5">{getRateListName(name)}</h2>
                        </div>
                        <div className="col text-right">
                            {rateListPresets.length > 0 && <RateListPreset 
                                onPresetApply={applyPreset}
                                rateListPresets={rateListPresets}
                            />}
                        </div>
                    </div>
                    <form
                        ref={formRef}
                        onSubmit={rateListSubmitHandler}
                        encType="multipart/form-data">
                        {!isDefaultRateList && <div className="form-row z-index-1000 position-relative">
                            <div className="col-12">
                                <div className="form-group">
                                    <label>
                                        {t("text_select_customers")}
                                    </label>
                                    <Select
                                        noOptionsMessage={() => {
                                            return t("text_no_options");
                                        }}
                                        placeholder={t("text_select_customer") + "..."}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        value={selectedCustomersByRateLists?.[index] || []}
                                        options={getSelectCustomers()}
                                        onChange={handleCustomerSelection}
                                        isOptionDisabled={isCustomerOptionDisabled}
                                        name="dairy_customer_ids[]"/>
                                </div>
                            </div>
                        </div>}
                        <div className="form-group position-relative z-index-999">
                            <label>{t("text_how_to_setup_rate_chart")}</label>
                            <Select
                                options={rateChartSetupTypes}
                                value={selectedRateChartSetupType}
                                onChange={selected => {
                                    setRateChartSetupType(selected)
                                    if(selected.value === "general") {
                                        setRateChartSetupTypeCattle("");
                                    } else {
                                        setRateChartSetupTypeCattle("cow")
                                    }
                                }}
                                name="rate_chart_setup_type"
                            />
                            <small id="rate_chart_setup_typeHelp" className="form-text text-muted">
                                {t(`text_rate_chart_setup_type_${selectedRateChartSetupType.value}_help`)}
                            </small>
                        </div>
                        {selectedRateChartSetupType.value === "by_cattle" && <div className="form-group">
                            <label id="catle_selection_label" className="d-block">{t("text_select_cattle_to_setup_chart")}</label>
                            <div className="btn-group" role="group" aria-labelledby="catle_selection_label">
                                <button
                                    type="button"
                                    className={`btn ${rateChartSetupTypeCattle === "cow" ? "bg-light-blue text-white active" : "btn-light"}`}
                                    onClick={() => setRateChartSetupTypeCattle("cow")}>
                                   {rateChartSetupTypeCattle === "cow" ? <CowWhiteIcon /> : <CowBlackIcon />} 
                                   {" "}{t("text_cow")}
                                </button>
                                <input type="hidden" name="rate_chart_setup_type_cattle" value={rateChartSetupTypeCattle} />
                                <button
                                    type="button"
                                    className={`btn ${rateChartSetupTypeCattle === "buffalo" ? "bg-light-blue text-white active" : "btn-light"}`}
                                    onClick={() => setRateChartSetupTypeCattle("buffalo")}>
                                    {rateChartSetupTypeCattle === "buffalo" ? <BuffaloWhiteIcon /> : <BuffaloBlackIcon />}
                                    {" "}{t("text_buffalo")}
                                </button>
                            </div>
                        </div>}
                        <div key={`${selectedRateChartSetupType.value}${rateChartSetupTypeCattle}`}>
                            <div className="form-row">
                                <div className={`col-12`}>
                                    <RateListTypeSelector
                                        rateListType={rateListType}
                                        onChange={handleRateListTypeChange} />
                                </div>
                            </div>
                            <div className="dairy_settings">
                                {/* Custom Rate List/Chart id: 7 */}
                                {(rateListTypeId === 7 || rateListTypeId === 8) && <CustomRateList
                                    rate_by={rateBy}
                                    settings={settings}
                                    slots={settings?.slots || []}
                                    dairyType={rateListTypeId}
                                    onRateByChange={setRateBy}
                                    validator={validator.current}
                                    rateListId={rate_list_id}
                                    index={index}
                                />}
                                {/* FIX RATE BY ANIMAL Rate List/Chart id: 5 */}
                                {rateListTypeId === 5 && <MilkRateByAnimal
                                    t={t}
                                    milkRate={settings ? settings.milk_rate : null}
                                    fatEnabled={settings ? settings.is_fat_enabled : null}
                                    rateChartSetupTypeCattle={rateChartSetupTypeCattle}
                                    rateListId={rate_list_id}
                                />}
                                {rateListTypeId === 8 && <ImportExcelFile onImport={handleExcelImport}  rateBy={rateBy} />}
                                <Divider />
                                <RateListCommission {...configuration?.rate_commission} />
                                <div className="form-group text-center text-md-left mb-2">
                                    <HasPermission ability={ABILITY_DAIRY_RATE_LIST} access={PERMISSION_UPDATE}>
                                        <button className="btn btn-success mb-1">
                                            {t("text_save")}
                                        </button>
                                    </HasPermission>
                                    {rateListLimitToChange !== "unlimited" && 
                                        <div className="text-center text-md-left mt-1">
                                        {
                                            rate_list_change_limit >= rateListLimitToChange ? 
                                            t("text_rate_list_limit_expired") :
                                            replaceAll(t("text_rate_list_limit"), '{n}', rateListLimitToChange - rate_list_change_limit)
                                        }
                                        </div>
                                    }
                                </div>
                                <HasPermission ability={ABILITY_DAIRY_RATE_LIST} access={PERMISSION_UPDATE}>
                                    {rateListSetupPaymentButton?.enabled && <>
                                        <Divider />
                                        <div className="text-center text-md-left mt-3">
                                            <span className="d-inline-block" dangerouslySetInnerHTML={{ __html: rateListSetupPaymentButton?.help_text }} />
                                            <RazorpayPaymentButton id={rate_list_id} {...rateListSetupPaymentButton} />
                                        </div>
                                    </>}
                                </HasPermission>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default RateListTabContent;