import React, { useState } from "react";
import withLanguage from '../../../../language/LanguageController';

const TodayMilkMobile = ({ t, shift, todayMorningMilk, todayEveningMilk }) => {
    const [selectedShift, setSelectedShift] = useState(shift);
    const changeShift = (event, shift) => {
        event.preventDefault();
        setSelectedShift(shift);
    };
    return (
        <div className="milkdetail_tab mt-2">
            <ul className="nav nav-justified">
                <li className="nav-item">
                    <a className={`nav-link ${selectedShift == 0 && "active bg-light-blue text-white"}`}
                        data-toggle="pill"
                        href="#customer_milk_detail_0"
                        onClick={e => changeShift(e, 0)}>
                        {t("text_morning")}
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${selectedShift == 1 && "active bg-light-blue text-white"}`}
                        data-toggle="pill"
                        href="#customer_milk_detail_1"
                        onClick={e => changeShift(e, 1)}>
                        {t("text_evening")}
                    </a>
                </li>
            </ul>
            <div className="tab-content">
                <div className={`tab-pane ${selectedShift == 0 && "active"}`} id="customer_milk_detail_0">
                    <div className="table_container">
                        <table className="table table-striped mb-0 no-f-border">
                            <thead>
                                <tr>
                                    <th className="text-b">{t("text_animal")}</th>
                                    <th>{t("text_liter")}</th>
                                    <th>{t("text_fat")}</th>
                                    <th>{t("text_clr")}</th>
                                    <th>{t("text_rate")}</th>
                                    <th>{t("text_total")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(todayMorningMilk.length) ? todayMorningMilk : (
                                    <tr>
                                        <td colSpan={6} className="text-center">
                                            {t("text_no_data_available")}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={`tab-pane ${selectedShift == 1 && "active"}`} id="customer_milk_detail_1">
                    <div className="table_container">
                        <table className="table table-striped mb-0 no-f-border">
                            <thead>
                                <tr>
                                    <th>{t("text_animal")}</th>
                                    <th>{t("text_liter")}</th>
                                    <th>{t("text_fat")}</th>
                                    <th>{t("text_clr")}</th>
                                    <th>{t("text_rate")}</th>
                                    <th>{t("text_total")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(todayEveningMilk.length) ? todayEveningMilk : (
                                    <tr>
                                        <td colSpan={6} className="text-center">
                                            {t("text_no_data_available")}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

TodayMilkMobile.displayName = "TodayMilkMobile";

export default withLanguage(TodayMilkMobile);