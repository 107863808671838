import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import { PrimaryButton } from '../common/buttons/PrimaryButton';

const ConfirmationDialog = ({ open, options, onCancel, onConfirm, onClose }) => {
  const {
    title,
    description,
    content,
    confirmationText,
    cancellationText,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    allowClose,
  } = options;

  return (
    <Dialog fullWidth {...dialogProps} open={open} onClose={allowClose ? onClose : null}>
      {title && (
        <DialogTitle {...titleProps}>{title}</DialogTitle>
      )}
      {content ? (
        <DialogContent {...contentProps}>
          {content}
        </DialogContent>
      ) : (
        description && (
          <DialogContent {...contentProps}>
            <DialogContentText>{description}</DialogContentText>
          </DialogContent>
        )
      )}
      <DialogActions>
        <Button {...cancellationButtonProps} onClick={onCancel}>
          {cancellationText}
        </Button>
        <PrimaryButton color="primary" {...confirmationButtonProps} onClick={onConfirm} autoFocus>
          {confirmationText}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;