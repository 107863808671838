import React from 'react';
import moment from "moment";
import { loader } from "../../../../library/util";
import { errorHandler } from '../../../../library/response';
import { getBillingStartEndDate } from '../../../../library/dateUtils';
import analytics from '../../../../library/firebase/analytics';
import request from '../../../../library/request';

class CustomerPaymentHistoryController extends React.Component {

    constructor(props) {
        super(props);
        this.getPaymentHistory = this.getPaymentHistory.bind(this);
        this.state = {
            product_pay_modal: false,
            records: [],
            ...getBillingStartEndDate("monthly", moment())
        };
        this.settings = {
            dots: true,
            infinite: true,
            speed: 500,
            swipeToSlide: true,
            slidesToShow: 1,
            slidesToScroll: 1
        };
    }

    componentDidMount () {
        analytics.setScreenName("KisanCustomerPaymentHistory");
        this.getPaymentHistory();
    }

    onDateChange = ({ startDate, endDate }) => {
        this.setState({
            startDate,
            endDate
        }, () => {
            this.getPaymentHistory();
        });
    }

    async getPaymentHistory () {
        try {
            loader.show(this.props.t("text_loading"));
            const { startDate } = this.state;
            const { dairyId, dairyCustomerId } = this.props;
            const filterStr = encodeURI(`date=${startDate.format('MMMM YYYY')}`);
            const { status, data, message, error_code } = await request.get('/dairies/' + dairyId + `/accounts/paymentHistory/${dairyCustomerId}?${filterStr}`, {
                withAuth: true,
                version: "v2"
            });
            if (status) {
                this.setState({
                    records: data
                });
            } else if(error_code != 501){
                throw message
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    pushPage = (e, Compo, props) => {
        e.preventDefault();
        this
            .props
            .navigator
            .pushPage({
                component: Compo,
                props: props
            }, { animation: 'fade' });
    }
}

export default CustomerPaymentHistoryController;