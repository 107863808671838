import Model from "./Model";

class PlanFeatures extends Model{
    constructor(){
        super("plan_features");
    }

    static get tableName() {
        return "plan_features"; // define child's tableName here
    }
}

export default PlanFeatures;