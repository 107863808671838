// @ts-nocheck
/* eslint-disable no-console */
import React, { useCallback, useEffect } from "react";
import { useLanguage } from "../../language/LanguageController";
import PaymentImg from "../../../assets/icons/payment.svg";
import { useDispatch, useSelector } from "react-redux";
import { togglePremiumPopup } from "../../../actions";
import analytics from "../../library/firebase/analytics";
import ModalDialog from "../../common/ModalDialog";
import { PrimaryButton } from "../../common/buttons/PrimaryButton";
import { useHistory } from "react-router";
import { getHasSubscription } from "src/reducers/subscription";

const SubscriptionPurchaseModal = () => {
    const { t } = useLanguage();
    const { isPremiumModelOpen, premium_model } = useSelector(state => state.defaultReducer);
    const hasSubscription = useSelector(getHasSubscription);
    const dispatch = useDispatch();
    const message = premium_model && premium_model.message ? premium_model.message : t("text_body_subscribe_premium");
    const history = useHistory();
    
    useEffect(() => {
        if(isPremiumModelOpen) {
            analytics.logEvent("SubscriptionPurchaseModal - Displayed", {
                feature: premium_model?.feature
            });
        }
    }, [isPremiumModelOpen, premium_model?.feature]);

    const onClose = () => {
        analytics.logEvent("SubscriptionPurchaseModal - Close", {
            feature: premium_model?.feature
        });
        dispatch(togglePremiumPopup())
    }

    const getPath = useCallback(() => {
        return hasSubscription ? "/subscription" : "/subscription/plans";
    }, [hasSubscription])

    const handleContinue = useCallback(() => {
        dispatch(togglePremiumPopup());
        analytics.logEvent("SubscriptionPurchaseModal - GotoPlans", {
            feature: premium_model?.feature
        });
        history.push(getPath());
    }, [dispatch, premium_model?.feature, history, getPath]);

    return (
        <ModalDialog
            isOpen={isPremiumModelOpen}
            onClose={onClose}
            title={t("text_title_subscribe")}
            dividers={false}
            content={
                <div className="text-center">
                    <div className="mb-3">
                        <img src={PaymentImg} width="70" alt="" />
                    </div>
                    {message}
                </div>
            }
            dialogActions={
                <PrimaryButton
                    variant="contained"
                    onClick={handleContinue}
                >
                    {t("text_continue")}
                </PrimaryButton>
            }
        />
    );
};

export default SubscriptionPurchaseModal;