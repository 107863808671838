// @ts-nocheck
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from "moment";
import { Icon } from 'react-onsenui';
import NoData from '../../common/NoData';
import ProductForm from './ProductForm';
import { confirmPop, socialSharing } from '../../../library/util';
import User from '../../../models/User';
import { fetchProducts, deleteProduct } from '../../../../actions/dairyCustomerProduct';
import InnerNav from '../InnerNav';
import { productsTemplate } from '../../../library/smsTemplates';
import withLanguage from '../../../language/LanguageController';
import { getBillingStartEndDate } from '../../../library/dateUtils';
import analytics from '../../../library/firebase/analytics';
import CustomersSelect from '../../common/CustomersSelect';
import Select from 'react-select';
import MuiDatepicker from '../../../common/MuiDatepicker';
import { filterProductsBySearch, getProductsForDropdown } from "../../../services/products";
import { DAIRY_CUSTOMER_CODE_DEBOUNCE } from '../../../../_constants';
import config from "../../../../config";
import { DefaultLayout } from '../../common/Layout';
import { DefaultScrollbar } from "../../common/Scrollbar";
import Eventer from '../../../library/Eventer';
import { getCustomerById } from '../../../library/customer';
import { checkFeatureInSubscription } from '../../../common/FeatureFlag/FeatureFlag';
import SubscriptionLock from '../../../common/FeatureFlag/SubscriptionLock';
import { HasPermission } from '../../../common/AccessPermission';
import { ABILITY_PRODUCT_SALE, PERMISSION_SALE_DELETE } from "../../../../_constants/permissions";
import { selectDairyCustomers } from 'src/reducers/dairyCustomer';

class Product extends Component {
  constructor(props) {
    super(props);
    this.user = new User();
    this.index = null;
    this.state = {
      error: null,
      isLoaded: false,
      product: {
        date: moment().format(config.date_format)
      },
      is_edit: false,
      searchText: '',
      ...this.getDefaultFilter()
    }

    this.deleteProduct = this.deleteProduct.bind(this);
    this.handleCodeChange = _.debounce(this.handleCodeChange.bind(this), DAIRY_CUSTOMER_CODE_DEBOUNCE);
  }

  getDefaultFilter = () => {
    const { date: propStartDate, end_date: propEndDate, t, dairyCustomerId = "" } = this.props;
    const { startDate, endDate } = getBillingStartEndDate(this.props.billingCycle, moment());
    return {
      view: "customer",
      customer: {
        label: t("text_all_customers"),
        value: ''
      },
      product: {
        label: t("text_all_products"),
        value: ''
      },
      dairy_customer_id: dairyCustomerId,
      product_id: "",
      start_date: propStartDate || startDate,
      end_date: propEndDate || endDate
    }
  }

  getFilterData = () => ({
    dairy_customer_id: this.state.dairy_customer_id,
    product_id: this.state.product_id,
    view: this.state.view,
    start_date: this.state.start_date.format(config.date_format),
    end_date: this.state.end_date.format(config.date_format)
  })

  componentDidMount() {
    analytics.setScreenName("Product");
    this.fetchProducts();
    Eventer.on("salesAdded", this.fetchProducts);
  }

  componentWillUnmount() {
    Eventer.off("salesAdded", this.fetchProducts);
  }

  fetchProducts = () => {
    this.props.fetchProducts(this.props.dairyId, this.getFilterData());
  }

  async deleteProduct(e, productId) {
    e.preventDefault();
    analytics.logEvent("deleteProduct");
    const confirm = await confirmPop(this.props.t("text_delete_confirm"));
    if (confirm == 1) {
      this.props.deleteProduct(this.props.dairyId, productId, this.getFilterData());
    }
  }

  customerChangeHandler = customer => {
    this.setState({
      dairy_customer_id: customer.value,
      customer: customer
    }, () => {
      document.getElementById("customer_code").value = customer.code;
      this.fetchProducts()
    });
  }

  handleCodeChange(event) {
    const code = event.target.value;
    const customer = this.props.customers.find(customer => customer.code == code);
    if (customer) {
      this.customerChangeHandler(customer);
    } else {
      this.setState({
        dairy_customer_id: null,
        customer: {
          label: this.props.t("text_no_customer_for_code"),
          value: null
        }
      });
    }
  }

  productChangeHandler = product => {
    this.setState({
      product_id: product.value,
      product: product
    }, () => this.fetchProducts());
  }

  dateChangeHandler = (name, newDate) => {
    this.setState({
      [name]: newDate
    }, () => this.fetchProducts());
  }

  viewChangeHandler = (view) => {
    this.setState({
      view: view
    }, () => this.fetchProducts());
  }

  searchHandler = e => {
    this.setState({
      searchText: e.target.value
    });
  }

  pushPage = e => {
    e.preventDefault();
    this.props.navigator.pushPage({
      component: ProductForm,
      props: {
        dairyId: this.props.dairyId,
        customers: this.props.customers
      }
    }, {
      animation: 'fade'
    });
  }

  shareProducts = (e, customer, products, amount, quantity) => {
    e.preventDefault();
    analytics.logEvent("shareProducts");
    let message = productsTemplate(customer.name, products, amount, quantity);
    socialSharing(message);
  }

  onDeviceChange = devicesDetail => {
    this.setState({ ...devicesDetail });
  }

  render() {
    const {
      view,
      searchText,
      customer,
      product,
      start_date,
      end_date,
      isAndroid
    } = this.state;
    const { t, customers, dairy_products, isLoading, header, bottomGutter } = this.props;
    const products = filterProductsBySearch(searchText, this.props.products);
    return (
      <DefaultLayout
        title={t("text_customers_products")}
        toolbar={false}
        header={header}
        bottomGutter={bottomGutter}
        onDeviceChange={this.onDeviceChange}
        back
      >
        <div className="d-flex mb-2 position-relative z-index-1001">
          <div className="btn-group mr-1 btn-group-sm" role="group" aria-label="Product View">
            <button
              className={`btn ${view === "customer" ? "bg-light-blue text-white active" : "btn-light"}`}
              type="button"
              onClick={() => this.viewChangeHandler("customer")}>
              {t("text_customer")}
            </button>
            <button
              className={`btn ${view === "product" ? "bg-light-blue text-white active" : "btn-light"}`}
              type="button"
              onClick={() => this.viewChangeHandler("product")}>
              {t("text_product")}
            </button>
          </div>
          <div className="flex-fill">
            <Select
              noOptionsMessage={() => {
                return t("text_no_options");
              }}
              name="product_id"
              options={getProductsForDropdown(dairy_products)}
              value={product}
              onChange={this.productChangeHandler} />
          </div>
        </div>
        <div className="d-flex mb-2 position-relative zIndex-1000">
          <div className="form-row flex-fill">
            <div className="col-3 pr-1">
              <div className="form-group mb-0">
                <label htmlFor="customer_code" className="sr-only">{t("text_code")}</label>
                <input
                  className="form-control"
                  type="number"
                  id="customer_code"
                  placeholder={t("text_code")}
                  onChange={e => {
                    e.persist();
                    this.handleCodeChange(e);
                  }} />
              </div>
            </div>
            <div className="col pl-0">
              <CustomersSelect
                customers={customers}
                onChange={this.customerChangeHandler}
                value={customer}
                allOption />
            </div>
          </div>
        </div>
        <div className="d-flex mb-2 justify-content-between">
          <div className="input-group mr-1">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">{t("text_from")}</span>
            </div>
            <MuiDatepicker
              value={start_date}
              maxDate={end_date}
              onChange={selectedDate => this.dateChangeHandler("start_date", selectedDate)}
              name="start_date"
              inputId="start_date"
              inputClass="bg-white"
              arrowButtons={false}
            />
          </div>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">{t("text_to")}</span>
            </div>
            <MuiDatepicker
              value={end_date}
              minDate={start_date}
              maxDate={moment().endOf("month")}
              onChange={selectedDate => this.dateChangeHandler("end_date", selectedDate)}
              name="end_date"
              inputId="end_date"
              inputClass="bg-white"
              arrowButtons={false}
            />
          </div>
        </div>
        {products.length > 0 ? (
          <div className="form-row">
            <div className="col-md-12 mb-2">
              {t("text_qty")}: {this.props.total.quantity} &nbsp; {t("text_total")}: {this.props.total.amount}
            </div>
          </div>
        ) : null}
        <DefaultScrollbar
          className="dairy-product-sales-list-scrollbar">
            {products.map(product => {
              let parentName = product.name;
              if(view === "customer") {
                const customer = getCustomerById(this.props.customers, product.dairy_customer_id);
                parentName = `${customer?.code} - ${customer?.name}`;
              }
              return (<div className="t-card shadow-sm" key={product.id}>
                <div className="form-row">
                  <div className="col-10 text-ellipsis">
                    <h5 className="my-0 mb-1">
                      {parentName}
                    </h5>
                  </div>
                  <div className="col-2 text-right">
                    {view === "customer" && isAndroid && (
                      <button className="btn"
                        type="button"
                        title="Share"
                        style={{ lineHeight: 1 }}
                        onClick={e => this.shareProducts(e, product, product.childs, product.total, product.quantity)}>
                        <Icon icon="md-share" size={18} />
                      </button>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12">
                    <div className="cp-total">
                      <span style={{ verticalAlign: "top" }}>
                        {t("text_qty")}: {product.quantity}&nbsp;
                        {t("text_total")}: {product.total}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12">
                    <div className="table_container table-responsive bg-light shadow-sm">
                      <table className="table table-striped table-borderless min-width340">
                        <thead>
                          <tr>
                            <th style={{ width: "80px" }}>{t("text_date")}</th>
                            <th>{view === "product" ? t("text_customer") : t("text_product")}</th>
                            <th>{t("text_price")}</th>
                            <th>{t("text_qty")}</th>
                            <th>{t("text_total")}</th>
                            <HasPermission ability={ABILITY_PRODUCT_SALE} access={PERMISSION_SALE_DELETE}>
                              {view === "customer" ? (
                                <th></th>
                              ) : null}
                            </HasPermission>
                          </tr>
                        </thead>
                        <tbody>
                          {product.childs.map(record => {
                            let childName = record.name
                            if(view === "product") {
                              const customer = getCustomerById(this.props.customers, record.dairy_customer_id);
                              childName = `${customer?.code} - ${customer?.name}`;
                            }
                            return (<tr key={record.id}>
                              <td className="nowrap">{moment(record.date).format(config.display_date_format)}</td>
                              <td>{childName}</td>
                              <td>{record.price}</td>
                              <td>{record.quantity}</td>
                              <td>{record.total}</td>
                              <HasPermission ability={ABILITY_PRODUCT_SALE} access={PERMISSION_SALE_DELETE}>
                                {view === "customer" &&  (
                                  <td>
                                    <button className="btn btn-danger btn-sm" onClick={e => this.deleteProduct(e, record.id)}>
                                      <span className="sr-only">{t("text-delete")}</span>
                                      <i className="fa fa-trash" />
                                    </button>
                                  </td>
                                )}
                              </HasPermission>
                            </tr>)
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>)
            })}
            {products.length < 1 && !isLoading ? <NoData /> : null}
        </DefaultScrollbar>
        <InnerNav
          menu="product"
          page="productSummary"
          navigator={this.props.navigator} />
        {!checkFeatureInSubscription("product_management", false) && <SubscriptionLock/>}
      </DefaultLayout>
    );
  }
}

Product.displayName = "Product";

Product.defaultProps = {
  header: true,
  bottomGutter: true
};

const mapStateToProps = state => ({
  isLoading: state.dairyCustomerProduct.is_loading,
  products: state.dairyCustomerProduct.products,
  total: state.dairyCustomerProduct.total,
  dairy_products: state.productReducer.products,
  customers: selectDairyCustomers(state),
  dairyId: state.dairy.dairy.id,
  billingCycle: state.dairy.dairy.billing_cycle
});

const mapDispatchToProps = {
  fetchProducts,
  deleteProduct
}

export default connect(mapStateToProps, mapDispatchToProps)(withLanguage(Product));