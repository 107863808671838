import React, { PureComponent, useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useSelector } from "react-redux";
import Loader from "../../common/Loader";
import { useBrowser, useMobile } from "src/components/library/detect-device";
import withLanguage from "src/components/language/LanguageController";
function GraphSlider({t}) {
  const chartdata = useSelector((state) => state.dairy?.daily_total);
  const chartstyle = {
    height: 400,
    display: "flex",
    alignItems: " center",
    justifyContent: "center",
  
  };
  function getmax(Data, key) {
    var max = Math.max(...Data.map((item) => parseFloat(item[key]).toFixed(1)));
    return max;
  }
  const deviceTypes = {
    isMobile: useMobile(),
    isBrowser: useBrowser(),
  };

  const [filter, setFilter] = React.useState("liter");
  const [filterD, setFilterD] = React.useState("collection");
  const [openD, setOpenD] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [keyMorningCow, setKeyMorningCow] = useState("morningCowMilk");
  const [keyMorningBuffalo, setKeyMorningBuffalo] = useState(
    "morningBuffaloMilk"
  );
  const [keyMorningTotal, setKeyMorningTotal] = useState("morningTotalMilk");
  const [keyEveningCow, setKeyEveningCow] = useState("eveningCowMilk");
  const [keyEveningBuffalo, setKeyEveningBuffalo] = useState(
    "eveningBuffaloMilk"
  );
  const [keyEveningTotal, setKeyEveningTotal] = useState("eveningTotalMilk");
  const [keyDailytotal, setKeyDailytotal] = useState("dailyTotalMilk");
  const [yAxisMax, setYAxisMax] = useState("dailyTotalMilk");
  const [labelMorningCow, setLabelMorningCow] = useState("Morning Cow Milk");
  const [labelMorningBuffalo, setLabelMorningBuffalo] = useState(
    "Morning Buffalo Milk"
  );
  const [labelMorningTotal, setLabelMorningTotal] = useState(
    "Morning Total Milk"
  );
  const [labelEveningCow, setLabelEveningCow] = useState("Evening Cow Milk");
  const [labelEveningBuffalo, setLabelEveningBuffalo] = useState(
    "Evening Buffalo Milk"
  );
  const [labelEveningTotal, setLabelEveningTotal] = useState(
    "Evening Total Milk"
  );
  const [labelDailytotal, setLabelDailytotal] = useState("Daily Total Milk");
  const [unit, setUnit] = useState("Liter");
  const [activeIndex, setActiveIndex] = useState(0);
  const [filterData, setFilterData] = useState({});
  const activeItem = chartdata[filterD][activeIndex];
  const [filterOpen, setFilterOpen] = useState(false);
  const [prevIndex, setPrevIndex] = useState();
  const useStyles = makeStyles((theme) => ({
    div: {
      display: "flex",
      flexDirection: "row-reverse",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }));
  const classes = useStyles();
  const handleChange = (event) => {
    setFilter(event.target.value);
    setGraphFilter(event.target.value);
  };
  const handleChanged = (event) => {
    setFilterD(event.target.value);
  };

  const handleCloseD = () => {
    setOpenD(false);
  };

  const handleOpenD = () => {
    setOpenD(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleBarChange = (data) => {
    setFilterData(chartdata[filterD][data.activeTooltipIndex]);
    setGraphFilter(filter);
    setPrevIndex(data.activeTooltipIndex);
    if (prevIndex !== data.activeTooltipIndex) {
      setFilterOpen(true);
    } else {
      setFilterOpen(false);
    }
  };

  const setGraphFilter = (data) => {
    switch (data) {
      case "fat":
        setKeyMorningCow("avgMorningCowFat");
        setKeyMorningBuffalo("avgMorningBuffaloFat");
        setKeyMorningTotal("avgMorningTotalFat");
        setKeyEveningCow("avgEveningCowFat");
        setKeyEveningBuffalo("avgEveningBuffaloFat");
        setKeyEveningTotal("avgEveningTotalFat");
        setKeyDailytotal("avgDailyTotalFat");
        setYAxisMax("avgDailyTotalFat");
        setLabelMorningCow("Average Morning Cow Fat");
        setLabelMorningBuffalo("Average Morning Buffalo Fat");
        setLabelMorningTotal("Average Morning Total Fat");
        setLabelEveningCow("Average Evening Cow Fat");
        setLabelEveningBuffalo("Average Evening BuffaloFat");
        setLabelEveningTotal("Average Evening Total Fat");
        setLabelDailytotal("Average Daily Total Fat");
        setUnit("%");
        break;
      case "liter":
        setKeyMorningCow("morningCowMilk");
        setKeyMorningBuffalo("morningBuffaloMilk");
        setKeyMorningTotal("morningTotalMilk");
        setKeyEveningCow("eveningCowMilk");
        setKeyEveningBuffalo("eveningBuffaloMilk");
        setKeyEveningTotal("eveningTotalMilk");
        setKeyDailytotal("dailyTotalMilk");
        setYAxisMax("dailyTotalMilk");
        setLabelMorningCow("Morning Cow Milk");
        setLabelMorningBuffalo("Morning Buffalo Milk");
        setLabelMorningTotal("Morning Total Milk");
        setLabelEveningCow("Evening Cow Milk");
        setLabelEveningBuffalo("Evening Buffalo Milk");
        setLabelEveningTotal("Evening Total Milk");
        setLabelDailytotal("Daily Total Milk");
        setUnit("Liter");
        break;
      case "clr":
        setKeyMorningCow("avgMorningCowCLR");
        setKeyMorningBuffalo("avgMorningBuffaloCLR");
        setKeyMorningTotal("avgMorningTotalCLR");
        setKeyEveningCow("avgEveningCowCLR");
        setKeyEveningBuffalo("avgEveningBuffaloCLR");
        setKeyEveningTotal("avgEveningTotalCLR");
        setKeyDailytotal("avgDailyTotalCLR");
        setYAxisMax("avgDailyTotalCLR");
        setLabelMorningCow("Average Morning Cow CLR");
        setLabelMorningBuffalo("Average Morning Buffalo CLR");
        setLabelMorningTotal("Average Morning Total CLR");
        setLabelEveningCow("Average Evening Cow CLR");
        setLabelEveningBuffalo("Average Evening Buffalo CLR");
        setLabelEveningTotal("Average Evening Total CLR");
        setLabelDailytotal("Average Daily Total CLR");
        setUnit(null);
        break;
      case "snf":
        setKeyMorningCow("avgMorningCowSNF");
        setKeyMorningBuffalo("avgMorningBuffaloSNF");
        setKeyMorningTotal("avgMorningTotalSNF");
        setKeyEveningCow("avgEveningCowSNF");
        setKeyEveningBuffalo("avgEveningBuffaloSNF");
        setKeyEveningTotal("avgEveningTotalSNF");
        setKeyDailytotal("avgDailyTotalSNF");
        setYAxisMax("avgDailyTotalSNF");
        setLabelMorningCow("Average Morning Cow SNF");
        setLabelMorningBuffalo("Average Morning Buffalo SNF");
        setLabelMorningTotal("Average Morning Total SNF");
        setLabelEveningCow("Average Evening Cow SNF");
        setLabelEveningBuffalo("Average Evening Buffalo SNF");
        setLabelEveningTotal("Average Evening Total SNF");
        setLabelDailytotal("Average Daily Total SNF");
        setUnit("%");
        break;
      case "rate":
        setKeyMorningCow("avgMorningCowRate");
        setKeyMorningBuffalo("avgMorningBuffaloRate");
        setKeyMorningTotal("avgMorningTotalRate");
        setKeyEveningCow("avgEveningCowRate");
        setKeyEveningBuffalo("avgEveningBuffaloRate");
        setKeyEveningTotal("avgEveningTotalRate");
        setKeyDailytotal("avgDailyTotalRate");
        setYAxisMax("avgDailyTotalRate");
        setLabelMorningCow("Average Morning Cow Rate");
        setLabelMorningBuffalo("Average Morning Buffalo Rate");
        setLabelMorningTotal("Average Morning Total Rate");
        setLabelEveningCow("Average Evening Cow Rate");
        setLabelEveningBuffalo("Average Evening Buffalo Rate");
        setLabelEveningTotal("Average Evening Total Rate");
        setLabelDailytotal("Average Daily Total Rate");
        setUnit("Rs/L");
        break;
      case "total":
        setKeyMorningCow("morningCowAmount");
        setKeyMorningBuffalo("morningBuffaloAmount");
        setKeyMorningTotal("morningTotalAmount");
        setKeyEveningCow("eveningCowAmount");
        setKeyEveningBuffalo("eveningBuffaloAmount");
        setKeyEveningTotal("eveningTotalAmount");
        setKeyDailytotal("dailyTotalAmount");
        setYAxisMax("dailyTotalAmount");
        setLabelMorningCow("Average Morning Cow Amount");
        setLabelMorningBuffalo("Average Morning Buffalo Amount");
        setLabelMorningTotal("Average Morning Total Amount");
        setLabelEveningCow("Average Evening Cow Amount");
        setLabelEveningBuffalo("Average Evening Buffalo Amount");
        setLabelEveningTotal("Average Evening Total Amount");
        setLabelDailytotal("Average Daily Total Amount");
        setUnit("Rs");

        break;
      case "entries":
        setKeyMorningCow("morningCowEntries");
        setKeyMorningBuffalo("morningBuffaloEntries");
        setKeyMorningTotal("morningTotalEntries");
        setKeyEveningCow("eveningCowEntries");
        setKeyEveningBuffalo("eveningBuffaloEntries");
        setKeyEveningTotal("eveningTotalEntries");
        setKeyDailytotal("DailytotalEntries");
        setYAxisMax("dailyTotalEntries");
        setLabelMorningCow("Average Morning Cow Entries");
        setLabelMorningBuffalo("Average Morning Buffalo Entries");
        setLabelMorningTotal("Average Morning Total Entries");
        setLabelEveningCow("Average Evening Cow Entries");
        setLabelEveningBuffalo("Average Evening Buffalo Entries");
        setLabelEveningTotal("Average Evening Total Entries");
        setLabelDailytotal("Average Daily Total Entries");
        setUnit(null);
        break;
      default:
        break;
    }
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="m-2">
          <p>{`${label}`}</p>
        </div>
      );
    }
  
    return null;
  };
  

  useEffect(() => {}, [filter, filterD, activeIndex]);

  return (
    <>
      <div className={classes.div}>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-controlled-open-select-label">
              Select filter
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={openD}
              onClose={handleCloseD}
              onOpen={handleOpenD}
              value={filterD}
              onChange={handleChanged}
              defaultValue={filterD}
            >
              <MenuItem value="collection">Collection</MenuItem>
              <MenuItem value="distribution">Distribution</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-controlled-open-select-label">
              Select filter
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={filter}
              onChange={handleChange}
              defaultValue={filter}
            >
              <MenuItem value="liter">Liter</MenuItem>
              <MenuItem value="fat">Fat</MenuItem>
              <MenuItem value="clr">CLR</MenuItem>
              <MenuItem value="snf">SNF</MenuItem>
              <MenuItem value="rate">Rate</MenuItem>
              <MenuItem value="total">Total</MenuItem>
              <MenuItem value="entries">Entries</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {deviceTypes.isMobile && filterOpen && (
        <div className="data_container">
          <p>
            <span>Date</span>
            <span>{filterData.date}</span>
          </p>
          <p>
            <span>{labelMorningCow}</span>
            <span>{filterData[keyMorningCow]}</span>
          </p>
          <p>
            <span>{labelMorningBuffalo}</span>{" "}
            <span>{filterData[keyMorningBuffalo]}</span>
          </p>
          <p>
            <span>{labelMorningTotal}</span>
            <span>{filterData[keyMorningTotal]}</span>
          </p>
          <p>
            <span>{labelEveningCow}</span>{" "}
            <span>{filterData[keyEveningCow]}</span>
          </p>
          <p>
            <span>{labelEveningBuffalo}</span>{" "}
            <span>{filterData[keyEveningBuffalo]}</span>
          </p>
          <p>
            <span>{labelEveningTotal}</span>{" "}
            <span>{filterData[keyEveningTotal]}</span>
          </p>
          <p>
            <span>{labelDailytotal}</span>{" "}
            <span>{filterData[keyDailytotal]}</span>
          </p>
        </div>
      )}
      <div style={chartstyle} >
        {chartdata.collection.length==0 && chartdata.distribution.length==0 ? (
          <Loader />
        ) : chartdata && chartdata[filterD].length != 0 ? (
          <ResponsiveContainer>
            <AreaChart
              width={500}
              height={400}
              data={chartdata[filterD]}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 10,
              }}
              onClick={
                deviceTypes.isMobile && ((data) => handleBarChange(data))
              }
            >
              <CartesianGrid strokeDasharray="3 3" />
              <defs>
                <linearGradient id="colorPv1" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#f69697" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#f69697" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#f01e2c " stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#f01e2c " stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv3" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#c30010 " stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#c30010 " stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv4" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#5ced73 " stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#5ced73 " stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv5" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#00c04b " stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#00c04b " stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv6" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#008631 " stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#008631 " stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv7" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#F4D03F " stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#F4D03F " stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="date" name="Date"></XAxis>
              <YAxis
                type="number"
                domain={[0, 10 + getmax(chartdata[filterD], yAxisMax)]}
                tickFormatter={(values) => {
                  return values.toFixed(2);
                }}
                tickCount={10}
              />
              {!deviceTypes.isMobile && (
                <>
                  <Tooltip
                    label="Date"
                    formatter={(values, name) => {
                      return `${parseFloat(values).toFixed(2)}`;
                    }}
                    labelFormatter={(label) => {
                      return `Date : ${label}`;
                    }}
                  />
                  <Legend verticalAlign="bottom" />
                </>
                
              )}
              {deviceTypes.isMobile && (
                <>
                  <Tooltip
                   content={CustomTooltip}
                   cursor={{ stroke: 'black', strokeWidth: 2 }}
                   contentStyle={{border:'0px'}}
                    
                  />
                  <Legend verticalAlign="bottom" />
                </>
                
              )}
              <Area
                type="monotone"
                dataKey={keyMorningCow}
                stroke="#f69697"
                fill="url(#colorPv1)"
                fillOpacity={1}
                unit={unit}
                name={labelMorningCow}
              />
              <Area
                type="monotone"
                dataKey={keyMorningBuffalo}
                stroke="#f01e2c "
                fill="url(#colorPv2)"
                fillOpacity={1}
                unit={unit}
                name={labelMorningBuffalo}
              />
              <Area
                type="monotone"
                dataKey={keyMorningTotal}
                stroke="#c30010"
                fill="url(#colorPv3)"
                fillOpacity={1}
                unit={unit}
                name={labelMorningTotal}
              />
              <Area
                type="monotone"
                dataKey={keyEveningCow}
                stroke="#5ced73"
                fill="url(#colorPv4)"
                fillOpacity={1}
                unit={unit}
                name={labelEveningCow}
              />

              <Area
                type="monotone"
                dataKey={keyEveningBuffalo}
                stroke="#00c04b"
                fill="url(#colorPv5) "
                fillOpacity={1}
                unit={unit}
                name={labelEveningBuffalo}
              />

              <Area
                type="monotone"
                dataKey={keyEveningTotal}
                stroke="#008631"
                fill="url(#colorPv6)"
                fillOpacity={1}
                unit={unit}
                name={labelEveningTotal}
              />

              <Area
                type="monotone"
                dataKey={keyDailytotal}
                stroke="#F4D03F"
                fill="url(#colorPv7)"
                fillOpacity={1}
                unit={unit}
                name={labelDailytotal}
              />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <p className='no_data' >
         {t("text_no_data_available")}
          </p>
        )}
      </div>
    </>
  );
}

export default withLanguage(GraphSlider);
