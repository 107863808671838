import Model from './Model';

class Dairy extends Model{
    constructor(){
        super('dairy');
    }

    static get tableName() {
        return "dairy"; // define child's tableName here
    }
}

export default Dairy;