import * as Sentry from "@sentry/react";
import request from '../components/library/request';
/*
 * action types
 */

export const GET_PRODUCT = 'GET_PRODUCT';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const FETCH_PRODUCTS_BEGIN = 'FETCH_PRODUCTS_BEGIN';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';
export const RESET_DAIRY_PRODUCTS = "RESET_DAIRY_PRODUCTS";


/*
 * action creators
 */

export const addProduct = data => ({
    type: ADD_PRODUCT, 
    data: data 
});

export const updateProduct = (productId, data) => ({ 
    type: UPDATE_PRODUCT, 
    data: data,
    productId: productId 
});

export const deleteProduct = index => ({
    type: DELETE_PRODUCT,
    index: index
});

export const fetchProductsBegin = () => ({
    type: FETCH_PRODUCTS_BEGIN
});

export const fetchProductsSuccess = data => ({
    type: FETCH_PRODUCTS_SUCCESS,
    data: data
});

export const fetchProductsFailure = (error = {}) => ({
    type: FETCH_PRODUCTS_FAILURE,
    data: {}
});

export const resetDairyProducts = () => ({
    type: RESET_DAIRY_PRODUCTS
});

export function fetchProducts(dairyId){
    return async dispatch => {
        try {
            dispatch(fetchProductsBegin());
            const { status, data = [] } = await request.get("/dairies/" + dairyId + "/products", {
                withAuth: true
            });
            if(status) {
                dispatch(fetchProductsSuccess(data));
            }
        } catch (error) {
            Sentry.captureException(error);
            dispatch(fetchProductsFailure(error));
        }
    }
}
