import React from "react";
import PropTypes from "prop-types";
import { RadioPrimary } from "../../core/Radio";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

const LanguageSelectionRowOld = ({ setLang, selectedLang, language }) => {
    return (
        <ListItem>
            <div className='left'>
                <RadioPrimary
                    id={`checkbox_lang_${language.code}`}
                    value={language.code}
                    name="language"
                    onChange={e => setLang(e.target.value)}
                    checked={selectedLang === language.code}/>
            </div>
            <div className='center'>
                <div
                    className="clearfix"
                    style={{
                        width: "100%"
                    }}>
                    <label htmlFor={`checkbox_lang_${language.code}`}>
                        {language.display_name}
                    </label>
                </div>
            </div>
        </ListItem>
    );
};

const LanguageSelectionRow = ({ setLang, selectedLang, language }) => {
    const labelId = `checkbox_lang_${language.code}`;
    return (
        <ListItem
            role={undefined}
            dense
            button
            onClick={e => setLang(language.code)}
        >
            <ListItemIcon>
                <RadioPrimary
                    edge="start"
                    name="language"
                    checked={selectedLang === language.code}
                    inputProps={{ 'aria-labelledby': labelId }}/>
            </ListItemIcon>
            <ListItemText
                id={labelId} 
                primary={language.display_name}
            />
        </ListItem>
    );
};

LanguageSelectionRow.propTypes = {
    setLang: PropTypes.func.isRequired,
    selectedLang: PropTypes.string.isRequired,
    language: PropTypes.object.isRequired
};

export default LanguageSelectionRow;