import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    duration: {
        backgroundColor: "#ffffff",
        borderRadius: "5px 5px 0px 0px",
        marginBottom: "15px",
        color: "#000",
        borderBottom: "5px solid #CAD8FF"
    },
    durationSelected: {
        backgroundColor: "#738edf",
        color: "#fff",
        borderBottom : "5px solid #3e61ce"
    },
    durationRadioContainer: {
        height: "100%"
    },
    durationRadio: {
        color: "#bdbdbd"
    },
    durationRadioSelected :{
        color : "#fff"
    },
    saveOnPurchase: {
        fontWeight: 600,
        fontSize: "1rem",
        marginTop: "8px"
    },
    pricePerMonth : {
        fontSize: "1.25rem",
        fontWeight: 600
    },
    pricePerMonthText: {
        fontSize: "0.75rem"
    }
}));
