import React, { createRef, useCallback, useEffect, useState } from "react";
import withLanguage from '../../../../language/LanguageController';
import CustomerAccount from "../CustomerAccount";
import BillMilkTotal from "../../modalPopup/CustomerBillMilkTotalModal/BillMilkTotal";
import ProductDetailTable from "../../modalPopup/ProductModal/ProductDetailTable";
import AdvancePaymentDetail from "../../modalPopup/AdvancePaymentModal/AdvancePaymentDetail";
import { useSelector } from "react-redux";
import { DefaultLayout } from "src/components/pages/common/Layout";
import { DefaultScrollbar } from "src/components/pages/common/Scrollbar";
import CustomerPaymentHistory from "../CustomerPaymentHistory";
import { checkFeatureInSubscription } from "../../../../common/FeatureFlag/FeatureFlag";
import SubscriptionLock from "../../../../common/FeatureFlag/SubscriptionLock";
import { currencyFormat } from "src/components/library/util";

const ManageDairyCustomerBill = ({ t }) => {
    const [isFeatureSubscribed, setIsFeatureSubscribed] = useState(false);
    const [advancePaymentTotal, setAdvancePaymentTotal] = useState("");
    const [productTotal, setProductTotal] = useState("");
    useEffect(() => {
        setIsFeatureSubscribed(checkFeatureInSubscription("customer_billing", false));
    }, [])
    const [dairyCustomerId, setDairyCustomerId] = useState(null);
    const [selectedDate, setSelectedDate] = useState({
        startDate: "",
        endDate: ""
    });
    const { id: dairyId } = useSelector(state => state.dairy.dairy);
    const customerPaymentRef = createRef();
    const onDateChange = useCallback((selectedDate) => {
        setSelectedDate(selectedDate);
    }, [setSelectedDate]);

    useEffect(() => {
        if(customerPaymentRef.current) {
            customerPaymentRef.current.getPaymentHistory();
        }
    }, [customerPaymentRef, selectedDate]);

    return (
        <DefaultLayout
            title={t("text_customers_bill")}
            toolbar={false}
            bottomGutter={false}
            back>
            <div className="form-row">
                <div className="col-md-3">
                    <CustomerAccount
                        header={false}
                        bottomGutter={false}
                        onCustomerSelect={setDairyCustomerId}
                        onDateChange={onDateChange} />
                </div>
                <div className="col-md-9">
                    <div className="form-row">
                        <div className="col-6 mb-2">
                            <div className="t-card shadow-sm mx-0 h-320">
                                <h4>{t("text_milk_total")}</h4>
                                <DefaultScrollbar
                                    className="dairy-billing-customer-bill-milk-total-scrollbar">
                                    <BillMilkTotal
                                        key={dairyCustomerId}
                                        dairyId={dairyId}
                                        dairyCustomerId={dairyCustomerId}
                                        startDate={selectedDate.startDate}
                                        endDate={selectedDate.endDate}
                                        isFeatureSubscribed={isFeatureSubscribed}
                                    />
                                </DefaultScrollbar>
                                {!isFeatureSubscribed && <SubscriptionLock/>}
                            </div>
                        </div>
                        <div className="col-6 mb-2">
                            <div className="t-card shadow-sm mx-0 h-320">
                                <h4>
                                    {t("text_advance_payment")}
                                    <span className="float-right">
                                        {currencyFormat(advancePaymentTotal)}
                                    </span>
                                </h4>
                                <DefaultScrollbar
                                    className="dairy-billing-customer-bill-advance-scrollbar">
                                    <AdvancePaymentDetail
                                        key={dairyCustomerId}
                                        dairyId={dairyId}
                                        dairyCustomerId={dairyCustomerId}
                                        startDate={selectedDate.startDate}
                                        endDate={selectedDate.endDate}
                                        isFeatureSubscribed={isFeatureSubscribed}
                                        onAdvancePaymentTotal={setAdvancePaymentTotal}
                                    />
                                </DefaultScrollbar>
                                {!isFeatureSubscribed && <SubscriptionLock/>}
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-6">
                            <div className="t-card shadow-sm mx-0">
                                <h4>
                                    {t("text_product_purchase_detail")}
                                    <span className="float-right">
                                        {currencyFormat(productTotal)}
                                    </span>
                                </h4>
                                <DefaultScrollbar
                                    className="dairy-billing-customer-bill-product-purchase-detail"
                                >
                                    <ProductDetailTable
                                        key={dairyCustomerId}
                                        dairyId={dairyId}
                                        dairyCustomerId={dairyCustomerId}
                                        startDate={selectedDate.startDate}
                                        endDate={selectedDate.endDate}
                                        isFeatureSubscribed={isFeatureSubscribed}
                                        onProductTotal={setProductTotal}
                                    />
                                </DefaultScrollbar>
                                {!isFeatureSubscribed && <SubscriptionLock/>}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="t-card shadow-sm mx-0">
                                <h4>{t("text_customer_payment_statement")}</h4>
                                <DefaultScrollbar
                                    className="dairy-billing-customer-bill-customer-payment-statement"
                                >
                                    <CustomerPaymentHistory
                                        key={dairyCustomerId}
                                        header={false}
                                        dairyId={dairyId}
                                        dairyCustomerId={dairyCustomerId}
                                        startDate={selectedDate.startDate}
                                        endDate={selectedDate.endDate}
                                        filter={false}
                                        onRef={(instance) => {
                                            customerPaymentRef.current = instance
                                        }}
                                    />
                                </DefaultScrollbar>
                                {!isFeatureSubscribed && <SubscriptionLock/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    )
};

ManageDairyCustomerBill.displayName = "ManageDairyCustomerBill";

export default withLanguage(ManageDairyCustomerBill);