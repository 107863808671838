import React from "react";
import { Icon } from "react-onsenui";
import ErrorBoundary from "../../../../common/ErrorBoundary";

const CustomRateListSlot = ({t, index, slot, onEdit, onDelete}) => {
    return (
        <ErrorBoundary>
            <tr>
                <td>
                    {slot?.fat_rate?.min} - {slot?.fat_rate?.max}<br/>
                    <small>{t(`text_by_${slot.rate_type}`)}</small>
                </td>
                <td>
                    {slot?.fat_rate?.base_rate}
                </td>
                <td className="text-right px-0">
                    <button
                        type="button"
                        className="btn btn-sm text-green float-left"
                        onClick={e => onEdit(e, index)}>
                        <Icon icon="edit"/>
                    </button>
                    {index != 0 ? (
                        <button
                            type="button"
                            className="btn btn-sm text-red float-right"
                            onClick={e => onDelete(e, index)}>
                            <Icon icon="times"/>
                        </button>
                    ) : null}
                    <input type="hidden" name={`slots[${index}]`} value={JSON.stringify(slot)} />
                </td>
            </tr>
        </ErrorBoundary>
    )
};

export default CustomRateListSlot;