// @ts-nocheck
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { fetchKisanDairyByDairyId } from "src/actions/dairy";
import { fetchDairySubscription } from "src/actions/subscriptions";
import InitialLoader from '../../../common/InitialLoader';

const KisanDairyRoutesLayout = ({ children }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const { dairy, isFetchingKisanDairy } = useSelector(state => state.dairy);
    const { user: { customer_id, customer_group_id, } } = useSelector(state => state.userReducer)
    const { subscriptionFetched, subscriptionFecthing } = useSelector(state => state.subscription);
    
    useEffect(() => {
        if(!dairy.dairy_id) {
            const { dairy_id } = params;
            if(dairy_id && customer_id && customer_group_id == 1) {
                fetchKisanDairyByDairyId(dispatch, customer_id, dairy_id);
            }
        }
    }, [dispatch, dairy.dairy_id, params, customer_id, customer_group_id]);

    useEffect(() => {
        if(!subscriptionFetched && dairy.dairy_id) {
            fetchDairySubscription(dispatch, dairy.dairy_id);
        }
    }, [dispatch, subscriptionFetched, dairy.dairy_id]);

    if(isFetchingKisanDairy && subscriptionFecthing) {
        return <InitialLoader />
    }

    if(!dairy.dairy_id) {
        return null;
    }

    return children;
}

export default KisanDairyRoutesLayout;