// @ts-nocheck
import * as Sentry from "@sentry/react";
import { t } from "../language/LanguageController";
import { notification } from './util';

export const parseResponse = async (response, resType = "json") => {
    if (response.status >= 200 && response.status <= 299) {
        if(response.status === 204) {
            return {
                status: false,
                message: t("text_no_data_available")
            };
        }
        return response.data;
    }
}

export const errorHandler = (errors) => {
    if(errors.message && errors.message === "object"){
        const errorMessages = errors.message;
        const message = [];
        for(let key in errorMessages){
            if(errorMessages[key]){
                const errorMsg = Array.isArray(errorMessages[key]) ? errorMessages[key][0] : errorMessages[key];
                message.push(errorMsg);
            }
        }
        notification("Error", message.join(""));
    } else if(typeof errors === "object"){
        const message = [];
        if(errors instanceof TypeError
            || errors instanceof ReferenceError
            || errors.stack) {
            console.error(errors);
            Sentry.captureException(errors);
            message.push(t("error_unknown_error"));
        } else {
            for(let key in errors){
                if(errors[key]){
                    const errorMsg = Array.isArray(errors[key]) ? errors[key][0] : errors[key];
                    message.push(errorMsg);
                }
            }
        }
        notification("Error", message.join(""));
    } else if(typeof errors === "string"){
        if(errors != "no_connection") {
            notification("Error", errors);
        }
    } else {
        console.error(errors);
        Sentry.captureException(errors);
        notification("Error", t("error_unknown_error"));
    }
}