import React, { Fragment } from 'react';
import DairyLoanController from './DairyLoanDashboardController';
import CustomerLoanRecoverModal from '../modalPopup/CustomerLoanRecoverModal';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux';
import InnerNav from '../InnerNav';
import withLanguage from '../../../language/LanguageController';
import { DefaultLayout } from '../../common/Layout';
import { DefaultScrollbar } from '../../common/Scrollbar';
import { withRouter } from 'react-router';
import { PrimaryButton } from '../../../common/buttons/PrimaryButton';
import { IconButton } from '@material-ui/core';
import ShareIcon from "@material-ui/icons/Share"
import SubscriptionLock from '../../../common/FeatureFlag/SubscriptionLock';
import { currencyFormat } from '../../../library/util';
import { HasPermission } from '../../../common/AccessPermission';
import { ABILITY_LOAN, PERMISSION_RECOVER } from '../../../../_constants/permissions';
import { selectDairyCustomers } from 'src/reducers/dairyCustomer';

class DairyLoanDashboard extends DairyLoanController {

    getFixedComponents = e => {
        return (
            <Fragment>
                {this.state.is_recover_modal ? (
                    <CustomerLoanRecoverModal
                        t={this.props.t}
                        validator={this.loanValidator}
                        amount={this.state.amount}
                        submitBtn={this.state.loan_recover_btn}
                        isOpen={this.state.is_recover_modal}
                        onClose={this.toggleRecoverLoanModal}
                        onSubmit={this.recoverLoanHandler}/>
                ) : null}
            </Fragment>
        );
    }

    render() {
        const {
            balance_str,
            total_sanctioned,
            total_recovered,
            filtered_customers: customers,
            isBrowser,
            isAndroid,
            isFeatureSubscribed
        } = this.state;
        const { t, header, bottomGutter, innerNav } = this.props;
        
        return (
            <DefaultLayout
                back
                bottomGutter={bottomGutter}
                onDeviceChange={this.onDeviceChange}
                header={header}
                title={t("text_loan_dashboard")}
                toolbar={false}>
                <form className="mb-2">
                    <div className="bg-light rounded shadow-sm">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <button
                                    type="button" 
                                    className="btn btn-link text-warning">
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                            <label className="sr-only" htmlFor="search-bar">{t("text_search")}</label>
                            <input type="text"
                                placeholder={t("text_search")}
                                id="search-bar"
                                className="form-control border-0 bg-light"
                                onChange={this.filterHandler}/>
                        </div>
                    </div>
                </form>
                <DefaultScrollbar
                    isEnabled={isBrowser}
                    className="dairy-loan-dashboard-scrollbar">
                    <div className="t-card shadow-sm mb-2">
                        <div className="title right card__title card--material__title mt-0">
                            <div className="d-account-h1">{t("text_dairy_loan_total")}</div>
                        </div>
                        <div className="content card__content card--material__content">
                            <table className="table mb-2">
                                <tbody>
                                    <tr>
                                        <td>{t("text_total_sanctioned")}</td>
                                        <td className="text-right text-red">{currencyFormat(total_sanctioned)}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("text_total_recovered")}</td>
                                        <td className="text-right text-green">{currencyFormat(total_recovered)}</td>
                                    </tr>
                                    <tr className="size-18">
                                        <td><b>{t("text_balance")}</b></td>
                                        <td className="text-right text-red"><b>{currencyFormat(balance_str)}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {customers.map((customer, index) => {
                        return (
                            <div className="t-card mb-2 shadow-sm" key={customer.dairy_customer_id} onClick={e => this.changePage(e, customer.dairy_customer_id)}>
                                <div className={"content card__content card--material__content"}>
                                    <div className="clearfix">
                                        <span className="capitalize">
                                            {`${customer.customer_code} - ${customer.customer_name}`}
                                        </span>
                                        {parseFloat(customer.balance) > 0 ? (
                                            <HasPermission ability={ABILITY_LOAN} access={PERMISSION_RECOVER}>
                                                <div className="float-right">
                                                    <PrimaryButton
                                                        variant="contained"
                                                        size="small"
                                                        onClick={e => this.toggleRecoverLoanModal(e, customer, index)}
                                                    >
                                                        {t("text_recover")}
                                                    </PrimaryButton>
                                                </div>
                                            </HasPermission>
                                        ) : (
                                            isAndroid && (<div className="float-right">
                                                <IconButton
                                                    title="Share"
                                                    onClick={e => this.shareLoan(e, customer)}
                                                    size="small"
                                                >
                                                    <ShareIcon fontSize="small"/>
                                                </IconButton>
                                            </div>)
                                        )}
                                    </div>
                                    <div className="clearfix">
                                        <span className="lheight-28 text-red">
                                            {t("text_balance")}: &nbsp;&nbsp;{currencyFormat(customer.balance)}
                                        </span>
                                        <div className="float-right">
                                            {parseFloat(customer.balance) > 0 && isAndroid && (
                                                <IconButton
                                                    type="button" 
                                                    title="Share"
                                                    onClick={e => this.shareLoan(e, customer)}
                                                >
                                                    <ShareIcon fontSize="small"/>
                                                </IconButton>
                                            )}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="display-table">
                                        <div className="display-table-cell text-left">
                                            {t("text_sanctioned")}
                                            <span className="display-block">
                                                {currencyFormat(customer.sanctioned)}
                                            </span>
                                        </div>
                                        <div className="display-table-cell text-center">
                                            {t("text_recovered")}
                                            <span className="text-green display-block">
                                                {currencyFormat(customer.recovered)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </DefaultScrollbar>
                {innerNav ? <InnerNav
                    menu="loan"
                    page="loanDashboard"
                    navigator={this.props.navigator} /> : null}
                {this.getFixedComponents()}
                {!isFeatureSubscribed && <SubscriptionLock/>}
            </DefaultLayout>
        );
    }
}

DairyLoanDashboard.displayName = "DairyLoanDashboard";

DairyLoanDashboard.defaultProps = {
    header: true,
    bottomGutter: true,
    innerNav: true
};

const mapStateToProps = state => {
    return {
        customers: selectDairyCustomers(state),
        dairyId: state.dairy.dairy.id
    }
}

export default connect(mapStateToProps)(
    withRouter(withLanguage(DairyLoanDashboard))
);