// @ts-nocheck
import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { green, purple, red } from '@material-ui/core/colors';

const theme = createTheme({
    palette: {
      primary: {
        main: green[500],
      }
    },
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                color: 'white',
            },
        },
    }
  });

const AppThemeProvider = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}

export default AppThemeProvider;