import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { loginSuccess } from "src/actions/user";
import request from "src/components/library/request";

const useAdminLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { search } = useLocation();
    const history = useHistory();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const dispatch = useDispatch();

    const verifyAdminLogin = useCallback(async (adminToken) => {
        const { status, data } = await request.post("/admin/verifyCustomerLogin", {
            body: JSON.stringify({
                admin_token: adminToken
            })
        });
        if(status) {
            dispatch(loginSuccess(data));
        }
        history.replace("/");
        setIsLoading(false);
    }, [dispatch, history])

    const adminToken = searchParams.get("admin_token");
    const loginType = searchParams.get("login");
    if(adminToken && loginType && isLoading) {
        verifyAdminLogin(adminToken);
    } else if(isLoading){
        setIsLoading(false);
    }

    return [isLoading];
};


export default useAdminLogin;