// @ts-nocheck
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from "@material-ui/icons/Delete";
import { orderBy } from "lodash";
import moment from "moment";
import React from "react";
import { Fab, Icon, SpeedDial } from "react-onsenui";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ORDER_ASC, ORDER_DESC } from 'src/_constants';
import { RedButton } from "src/components/common/buttons/RedButton";
import { PrimaryCheckbox } from 'src/components/core/Checkbox';
import WhiteCheckBox from 'src/components/core/Checkbox/WhiteCheckBox';
import Spinner from "src/components/core/Spinner";
import Eventer from "src/components/library/Eventer";
import { DefaultLayout } from "src/components/pages/common/Layout";
import { togglePremiumPopup } from "../../../../../actions";
import config from "../../../../../config/config.json";
import { checkFeatureInSubscription } from "../../../../common/FeatureFlag/FeatureFlag";
import SubscriptionLock from "../../../../common/FeatureFlag/SubscriptionLock";
import withLanguage from "../../../../language/LanguageController";
import { isConnected } from "../../../../library/bluetoothPrinter";
import { getSelectedCustomerForSelect } from "../../../../library/customer";
import analytics from "../../../../library/firebase/analytics";
import request from "../../../../library/request";
import { errorHandler } from "../../../../library/response";
import {
  confirmPop,
  currencyFormat,
  loader,
  replaceAll,
  toast,
} from "../../../../library/util";
import { customerMilkDetailReceipt } from "../../../../templates/printerTemplates";
import BluetoothPrinter from "../../modalPopup/BluetoothPrinter";
import Filter from "./Filter";
import { selectDairyCustomers } from 'src/reducers/dairyCustomer';

class CustomerMilkDetail extends React.Component {
  constructor(props) {
    super(props);
    this.getMilk = this.getMilk.bind(this);
    this.printMilkDetail = this.printMilkDetail.bind(this);
    this.DeleteAllHandler = this.DeleteAllHandler.bind(this);
    this.state = {
      records: [],
      total: {},
      isBSetting: false,
      isFeatureSubscribed: checkFeatureInSubscription(
        "customer_daily_milk_summary",
        false
      ),
      ...this.getInitialSelectedCustomer(),
      ...this.getDefaultFilter(),
      isLoading: true,
      isChecked: false,
      selectedItems: [],
      isIndeterminate: false,
      res: [],
      x: [],
      ids: [],
      allCustomer: {
        milk_type: props.cattle,
        day_time: props.shift,
        dairy_customer_id: "",
        selectedItems: [],
        dairy_id: this.props.dairyId,
      },
    };

    props.forwardedRef && props.forwardedRef(this);
  }

  async DeleteAllHandler(e) {
    const { dairyId } = this.props;

    try {
      analytics.logEvent("deleteMilk");
      if (await confirmPop(this.props.t("text_delete_milk_confirm"))) {
        loader.show(this.props.t("text_deleting"));
        const { status, message } = await request.post(
          `/dairies/${dairyId}/milk/delete/customermultiplemilkentry`,
          {
            withAuth: true,
            version: "v2",
            body: JSON.stringify(this.state.allCustomer),
          }
        );
        if (status === true) {
          this.setState({
            isChecked: false,
            isIndeterminate: false,
            selectedItems: [],
          });
          toast(message);
          this.getMilk();
          Eventer.emit("milkDeleted", {
            shift: this.state.shift,
            cattle: this.state.cattle,
          });
        } else {
          this.setState({
            isChecked: false,
            isIndeterminate: false,
          });
          throw message;
        }
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      loader.hide();
    }
  }

  componentDidMount() {
    this.setState({
      ids: this.state.records.map((customer) => customer),
    });
  }

  handleSelectAll = () => {
    const { selectedItems, records } = this.state;
    if (selectedItems.length < records.length) {
      this.setState({
        selectedItems: records.map((item) => ({
          id: item.id,
          date: item.date,
        })),
        isChecked: true,
        isIndeterminate: false,
      });
    } else {
      this.setState({
        selectedItems: [],
        isChecked: false,
        isIndeterminate: false,
      }, () => {
      });
    }
  };
  
  handleClick = (e, data) => {
    const { selectedItems, records } = this.state;
    const selectedItemIds = selectedItems.map((item) => item.id);
  
    let newSelectedItems;
    if (selectedItemIds.includes(data.id)) {
      newSelectedItems = selectedItems.filter((item) => item.id !== data.id);
    } else {
      newSelectedItems = [...selectedItems, { id: data.id, date: data.date }];
    }
  
    this.setState({
      selectedItems: newSelectedItems,
      isChecked: newSelectedItems.length === records.length,
      isIndeterminate: newSelectedItems.length > 0 && newSelectedItems.length < records.length,
    });
  };
  
  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedItems !== this.state.selectedItems) {
      this.setState({
        allCustomer: {
          ...this.state.allCustomer,
          milk_type: this.state.cattle,
          day_time: this.state.shift,
          dairy_customer_id: this.state.records[0]?.dairy_customer_id,
          selectedItems: [...this.state.selectedItems],
        },
      });
    }
  }
  

  getIds() {
    for (let i = 0; i < this.state.records.length; i++) {
      if (this.state.records[i].id && this.state.records[i].liter) {
        this.ids.push(this.state.records[i].id);
      }
    }
  }

  getInitialSelectedCustomer = () => {
    const { match: { params: { dairy_customer_id = null } }, dairyCustomerId = null } = this.props;
    if (dairy_customer_id || dairyCustomerId) {
      const _dairyCustomerId = dairy_customer_id || dairyCustomerId;
      const customer = this.props.customers.find((customer) => customer.dairy_customer_id === _dairyCustomerId);
      if (customer) {
        return {
          customer: getSelectedCustomerForSelect(customer),
          dairy_customer_id: _dairyCustomerId,
        };
      }
    }
    return {
      customer: {},
      dairy_customer_id: dairy_customer_id || dairyCustomerId,
    };
  };

  getDefaultFilter = () => {
    const {
      cattle = null, shift = null, location, startDate, endDate,
    } = this.props;
    const locationStartDate = location?.state?.startDate || null;
    const locationEndDate = location?.state?.endDate || null;
    const filterStartDate = locationStartDate ? moment(locationStartDate) : moment().startOf("month");
    const filterEndDate = locationEndDate ? moment(locationEndDate) : moment();
    return {
      start_date: startDate || filterStartDate,
      end_date: endDate || filterEndDate,
      cattle: cattle || "cow",
      shift: shift || (moment().format("HH") >= 15 ? "1" : "0"),
    };
  };

  componentDidMount() {
    analytics.setScreenName("CustomerMilkDetail");
    this.getMilk();
  }

  async getMilk() {
    try {
      this.setState({
        isLoading: true,
      });
      const {
        start_date,
        end_date,
        cattle,
        shift,
        dairy_customer_id,
      } = this.state;
      const { dairyId } = this.props;
      const filterStr = `?milk_type=${cattle}&day_time=${shift}&date=${start_date.format(
        config.date_format
      )}:${end_date.format(config.date_format)}`;
      const { status, data, message } = await request.get(
        `/dairies/${dairyId}/customer_daily_milk_summary/${dairy_customer_id}${filterStr}`,
        {
          withAuth: true,
          version: "v2",
        }
      );
      if (status === true) {
        this.setState({
          records: data.result,
          total: data.total,
        });
      } else {
        throw message;
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  dateChangeHandler = (name, newDate) => {
    if (!this.state.isFeatureSubscribed) {
      this.props.dispatch(togglePremiumPopup());
      return false;
    }
    name = name === "date" ? "start_date" : name;
    this.setState(
      {
        [name]: newDate,
      },
      () => {
        this.getMilk();
      }
    );
  };

  customerChangeHandler = (customer) => {
    if (!this.state.isFeatureSubscribed) {
      this.props.dispatch(togglePremiumPopup());
      return false;
    }
    this.setState(
      {
        customer,
        dairy_customer_id: customer.value,
      },
      () => {
        this.getMilk();
        this.props.history.replace({ pathname: `/customers/milk/summary/${customer.value}` });
      }
    );
  };

  cattleChangeHandler = (cattle) => {
    if (!this.state.isFeatureSubscribed) {
      this.props.dispatch(togglePremiumPopup());
      return false;
    }
    this.setState(
      {
        cattle,
      },
      () => {
        this.getMilk();
      }
    );
  };

  shiftChangeHandler = (shift) => {
    if (!this.state.isFeatureSubscribed) {
      this.props.dispatch(togglePremiumPopup());
      return false;
    }
    this.setState(
      {
        shift,
      },
      () => {
        this.getMilk();
      }
    );
  };

  async printMilkDetail() {
    try {
      analytics.logEvent("printMilkDetail");
      if (!checkFeatureInSubscription("print")) {
        return false;
      }
      loader.show(this.props.t("text_printing"));
      const connected = await isConnected();
      if (connected) {
        const {
          cattle,
          start_date,
          end_date,
          dairy_customer_id,
        } = this.state;
        const { dairyName, customers, dairyId } = this.props;
        const customer = customers.find((customer) => customer.dairy_customer_id === dairy_customer_id);
        const customerName = customer.name;
        let filterStr = `?milk_type=${cattle}&date=${start_date.format(config.date_format)}:${end_date.format(config.date_format)}`;
        filterStr += "&sort_column=date&sort_order=asc";
        const { status, data, message } = await request.get(`/dairies/${dairyId}/customer_daily_milk_summary/${dairy_customer_id}${filterStr}`, {
          withAuth: true,
          version: "v2",
        });
        if (status) {
          await customerMilkDetailReceipt(
            dairyName,
            customerName,
            customer.code,
            cattle,
            data.result,
            start_date.format(config.date_format),
            end_date.format(config.date_format),
          );
        } else {
          throw message;
        }
      } else {
        this.setState({
          isBSetting: true,
        });
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      loader.hide();
    }
  }

  togglePrinterModel = () => {
    this.setState({
      isBSetting: !this.state.isBSetting,
    });
  };

  renderFixedContent = () => {
    const { isBSetting } = this.state;
    const { t } = this.props;
    if (isBSetting) {
      return (
        <BluetoothPrinter
          t={t}
          isOpen={isBSetting}
          onClose={this.togglePrinterModel}
        />
      );
    }
    return null;
  };

  onDeviceChange = (deviceDetail) => {
    this.setState({
      ...deviceDetail,
    });
  };

  handleSort = (column) => {
    const { sortColumn, sortOrder } = this.state;
    if (sortColumn === column) {
      this.setState({
        sortOrder: sortOrder === ORDER_ASC ? ORDER_DESC : ORDER_ASC,
      });
    } else {
      this.setState({
        sortColumn: column,
        sortOrder: ORDER_ASC,
      });
    }
  };


  sortRecords = (records, sortColumn, sortOrder) => {
    let sortedRecords = [...records];
    if (sortColumn) {
      sortedRecords = orderBy(sortedRecords, [(record) => {
        let value = record[sortColumn];
        if (sortColumn === 'date') {
          value = value ? new Date(value.replace(/,/g, "")) : null;
        }
        else {
          value = value !== null && value !== undefined ? parseFloat(value.replace(/,/g, "")) : null;
        }
        return value;
      }], [sortOrder]);
    }
    return sortedRecords;
  }

  render() {
    this.ids = [];
    this.getIds();
    const { t, customers, header, filter, bottomGutter, } = this.props;
    const { customer, start_date, end_date, cattle, shift, isFeatureSubscribed, isLoading, sortColumn, sortOrder } = this.state;
    const sortedRecords = this.sortRecords(this.state.records, sortColumn, sortOrder);
    const ascendingIcon = <ArrowDownwardSharpIcon className="mb-0.5 ml-1 text-white" fontSize="small" />;
    const descendingIcon = <ArrowUpwardIcon className="mb-0.5 ml-1 text-white " fontSize="small" />;

    const sortIcon = (column) => {
      if (sortColumn === column) {
        return sortOrder === ORDER_ASC ? ascendingIcon : descendingIcon;
      }
      return null;
    };

    return (
      <DefaultLayout
        title={(
          <>
            <span className="text-ellipsis display-inline-block max-width-200">{customer.label}</span>
            {replaceAll(t("text_customers_milk"), "{customer}", "")}
          </>
        )}
        toolbar={false}
        header={header}
        back
        onDeviceChange={this.onDeviceChange}
        bottomGutter={bottomGutter}
      >
        {filter ? (
          <Filter
            header={header}
            startDate={start_date}
            endDate={end_date}
            cattle={cattle}
            shift={shift}
            customers={customers}
            customer={customer}
            customerChangeHandler={this.customerChangeHandler}
            dateChangeHandler={this.dateChangeHandler}
            cattleChangeHandler={this.cattleChangeHandler}
            shiftChangeHandler={this.shiftChangeHandler}
          />
        ) : null}

        <div className="form-row">
          <div className="select-all">
            <RedButton
              style={{ marginLeft: "5px" }}
              className="RedButton"
              disabled={(!this.state.isChecked && !this.state.isIndeterminate)}
              variant="contained"
              type="button"
              onClick={this.DeleteAllHandler}
              startIcon={<DeleteIcon />}
            >
              {t("text_delete")}
            </RedButton>
          </div>
          <div className="col-12">
            {!isLoading ? (
              <div className="table_container bg-white shadow-sm mh-lg-auto customer-list-milk-detail-scrollbar">
                <table className="table table-striped table-borderless mb-0">
                  <thead>
                    <tr>
                      <th className="nowrap width20">
                        <WhiteCheckBox
                          className="checkbox_input"
                          name="selectAll"
                          id="selectAll"
                          indeterminate={this.state.isIndeterminate === true}
                          checked={this.state.isChecked === true}
                          onChange={this.handleSelectAll}
                        />
                      </th>

                      <th className='align-middle' onClick={() => this.handleSort('date')}>{t("text_date")} {sortColumn === 'date' &&
                        (sortOrder === ORDER_ASC ? (ascendingIcon) : (descendingIcon))}{!sortColumn && (descendingIcon)}</th>

                      <th className='align-middle' onClick={() => this.handleSort('liter')}>{t("text_liter")} {sortIcon('liter')}</th>
                      <th className='align-middle' onClick={() => this.handleSort('fat')}> {t("text_fat")} {sortIcon('fat')}</th>
                      <th className='align-middle' onClick={() => this.handleSort('clr')}> {t("text_clr")} {sortIcon('clr')}</th>
                      <th className='align-middle' onClick={() => this.handleSort('snf')}> {t("text_snf")} {sortIcon('snf')}</th>
                      <th className='align-middle' onClick={() => this.handleSort('rate')}>{t("text_rate")} {sortIcon('rate')}</th>
                      <th className='align-middle' onClick={() => this.handleSort('total')}>{t("text_amount")} {sortIcon('total')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedRecords.map((data, index) => (
                      <tr key={index}>
                        <td>
                          {data.liter
                            ? (
                              <PrimaryCheckbox
                              id={data.id}
                              checked={this.state.selectedItems.map((item) => item.id).includes(data.id)}
                              onChange={(e) => this.handleClick(e, data)}
                            />
                            )
                            : null}
                        </td>
                        <td className="text-nowrap align-middle">
                          {(data.date === null ? "-" : data.date)}
                        </td>
                        <td className='align-middle'>
                          {(data.liter === null || !isFeatureSubscribed ? "-" : currencyFormat(data.liter, false))}
                        </td>
                        <td className='align-middle'>
                          {(data.fat === null || !isFeatureSubscribed ? "-" : data.fat)}
                        </td>
                        <td className='align-middle'>
                          {(data.clr === null || !isFeatureSubscribed ? "-" : data.clr)}
                        </td>
                        <td className='align-middle'>
                          {(data.snf === null || !isFeatureSubscribed ? "-" : data.snf)}
                        </td>
                        <td className='align-middle'>
                          {(data.rate === null || !isFeatureSubscribed ? "-" : currencyFormat(data.rate))}
                        </td>
                        <td className='align-middle'>
                          {(data.total === null || !isFeatureSubscribed ? "-" : currencyFormat(data.total))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th />
                      <th>{t("text_total")}</th>
                      <th>{isFeatureSubscribed ? currencyFormat(this.state.total.total_milk, false) : "-"}</th>
                      <th>{isFeatureSubscribed ? this.state.total.fat_avg_total : "-"}</th>
                      <th>{isFeatureSubscribed ? this.state.total.clr_avg_total : "-"}</th>
                      <th>{isFeatureSubscribed ? this.state.total.snf_avg_total : "-"}</th>
                      <th>{isFeatureSubscribed ? currencyFormat(this.state.total.rate) : "-"}</th>
                      <th>{isFeatureSubscribed ? currencyFormat(this.state.total.total) : "-"}</th>
                    </tr>
                  </tfoot>
                </table>
                {!isFeatureSubscribed && <SubscriptionLock />}
              </div>
            ) : (
              <Spinner />
            )}
          </div>
        </div>
        <SpeedDial position="bottom right">
          <Fab
            style={{ backgroundColor: "#11b232", color: "#fff" }}
            onClick={this.printMilkDetail}
          >
            <Icon icon="md-print" />
          </Fab>
        </SpeedDial>
        {this.renderFixedContent()}
      </DefaultLayout>
    );
  }
}

CustomerMilkDetail.defaultProps = {
  header: true,
  filter: true,
  bottomGutter: false,
};

CustomerMilkDetail.displayName = "CustomerMilkDetail";

const mapStateToProps = (state) => ({
  customers: selectDairyCustomers(state),
  dairyName: state.dairy.dairy.name,
  dairyId: state.dairy.dairy.id,
});

const CustomerMilkDetailComp = connect(mapStateToProps)(
  withRouter(withLanguage(CustomerMilkDetail))
);

export default React.forwardRef(
  (props, ref) => (
    <CustomerMilkDetailComp
      {...props}
      forwardedRef={(compoRef) => {
        if (ref) {
          ref.current = compoRef;
        }
      }}
    />
  )
);