import { updateFCMToken } from "src/components/services/pushNotification";
import { captureException } from "../../errorTracking";
import Eventer from "../../Eventer";
import { delay } from "../../util";
import { getFCMId, getFCMToken, onMessageReceived, onTokenRefresh } from "./FCMFirebase";
import { initFirebaseWeb } from "./FCMFirebaseWeb";

const initFCMMessaging = async () => {
    console.log("initFCMMessaging");
    
    onTokenRefresh(async deviceFCMToken => {
        console.log("onTokenRefreshed", deviceFCMToken);
        try {
            const deviceFCMId = await getFCMId();
            if(deviceFCMToken && deviceFCMId) {
                await updateFCMToken(deviceFCMToken, deviceFCMId);
            }
        } catch (err) {
            captureException(err);
        }
    });

    onMessageReceived(handleMessage);

    initFirebaseWeb();
}

const handleMessage = (message) => {
    if(message.tap && message.path) {
        Eventer.emit("fcmRoute", {
            path: message.path
        });
    }
}

export const generateFCMToken = async () => {
    try {
        await delay(1000);
        const deviceFCMToken = await getFCMToken();
        const deviceFCMId = await getFCMId();
        if(deviceFCMToken && deviceFCMId) {
            await updateFCMToken(deviceFCMToken, deviceFCMId);
        }
    } catch (err) {
        console.log("err", err);
        captureException(err);
    }
}

export default initFCMMessaging;