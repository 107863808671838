import React, { Component } from 'react';
import { loader } from "../../library/util";
import { Page } from 'react-onsenui';
import LoginSetPassword from "./LoginSetPassword";
import withLanguage from '../../language/LanguageController';
import { errorHandler } from '../../library/response';
import SimpleValidator from '../../common/SimpleValidator';
import analytics from '../../library/firebase/analytics';
import request from '../../library/request';
import { withRouter } from 'react-router';

class ForgotPassword extends Component {

  constructor(props) {
    super(props);
    this.formId = "login-form";
    this.state = {
      is_verifying: false,
      mobile: props?.location?.state?.mobile || ""
    };
    this.validator = new SimpleValidator();
  }

  componentDidMount () {
    analytics.setScreenName("ForgotPassword");
  }

  handleInput = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    this.setState({
      [name]: value
    });
  }

  handleClose = (e) => {
    e.preventDefault();
    // if(this.props.navigator.pages.length > 1) {
    //   this.props.navigator.popPage();
    // }
  }

  async handleSubmit (event) {
    event.preventDefault();
    try {
      // validate form before submitting to server 
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate(); // rerender to show messages for the first time
        return false;
      }
      analytics.logEvent("sendForgotPasswordOTP");
      loader.show(this.props.t("text_verifying_mobile_number"));
      this.setState({is_verifying: true});
      const { status, data, message } = await request.post('/auth/forgotPassword', {
        withAuth: true,
        body: JSON.stringify({
          "mobile": this.state.mobile
        })
      });
      if (status) {
        this.props.history.push({
          pathname: "/setPassword",
          state: {
            mobile: this.state.mobile,
            customer_id: data.customer_id,
          }
        });
      } else {
        throw message;
      }
    } catch(error) {
      errorHandler(error);
    } finally {
      loader.hide();
      this.setState({
        is_verifying: false
      });
    }
  }

  render() {
    const { is_verifying } = this.state;
    const { t } = this.props;
    return (<Page className="page">
      <div className="login_mobile">
        <div className="clearfix">
          <div className="screen_mobile_number clearfix col-md-12">
            <div className="mt-100">
              <div className="m_login_otp_pass_header text-center mb-5">
                <h2 className="enter_code_heading">
                  {t('text_forgot_password')}
                </h2>
                <p>{t('text_forgot_password_text')}</p>
              </div>
              <div className="loginmobileform clearfix col-md-12 max-width-500 mx-auto">
                <div className="divSignupForm">
                  <form method="POST" id={this.formId}
                    onSubmit={this.handleSubmit.bind(this)}>
                    <div className="form-group">
                      <div className="inline_input col-md-12">
                        <div className="inline country_code">
                          <input
                            className="form-control input-bottom-border bg-white"
                            type="text"
                            defaultValue="+91"
                            readOnly
                            name="country_code" />
                        </div>
                        <div className="inline numberfield">
                          <input
                            className="form-control input-bottom-border"
                            type="number"
                            placeholder={t('text_enter_mobile')}
                            name="mobile"
                            value={this.state.mobile}
                            onChange={this.handleInput} />
                        </div>
                      </div>
                      {this.validator.message('r_mobil_number', this.state.mobile, 'required|numeric|size:10,string', {
                        className: "pl-15 pr-15 mt-2"
                      })}
                    </div>
                    <div className="form-group">
                      <div className="col-md-12">
                        <input
                          className="form-control"
                          type="submit"
                          disabled={is_verifying}
                          value={is_verifying ? t('text_verifying') : t('btn_send_otp')} />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>);
  }
}

export default withRouter(withLanguage(ForgotPassword));
