import { useSelector } from "react-redux";
import { getCustomerRateList, getRateListSettings } from "../../components/helpers/rateListHelper";
import { checkIsCLRSNFEnabled, getMilkRateBy, getRateListMilkTypes, isFatEnabled } from '../../components/library/milkUtils';
import { selectRateLists } from "../../reducers/dairyRateList";
import { selectDairyCustomers } from "../../reducers/dairyCustomer";
import { useMemo } from "react";
import { getCustomerForCollection, getDefaultSelectedCustomer } from '../../components/library/customer';

const useMilkCollection = ({
    dairyCustomerId = null
} = {}) => {
    const customers = useSelector(selectDairyCustomers);
    const defaultSelectedCustomer = useMemo(() => getDefaultSelectedCustomer(customers), [customers]);
    const selectedCustomer = useMemo(() => customers.find(customer => customer.dairy_customer_id == dairyCustomerId), [customers, dairyCustomerId]);
    const customer  = selectedCustomer ? {
        ...selectedCustomer,
        ...getCustomerForCollection(selectedCustomer)
    } : defaultSelectedCustomer;

    const { cattle, milkAnimals, personalRate, type: customerType, rate_list_id: rateListId } = customer;

    const rateLists = useSelector(selectRateLists);
    const rateList = getCustomerRateList(rateLists, rateListId, customerType);

    const { rateListTypeId, rateListSetting } = getRateListSettings(rateList, cattle);
    const milkTypes = getRateListMilkTypes(rateListSetting);
    const rateBy = getMilkRateBy(rateListTypeId, rateListSetting);
    const isCLRSNFEnabled = checkIsCLRSNFEnabled(rateListTypeId, rateListSetting);
    const fatEnabled = isFatEnabled(rateListTypeId, rateListSetting);
    const cattlesRate = getRateListMilkTypes(rateListSetting);

    return {
        milkTypes,
        rateBy,
        isCLRSNFEnabled,
        isFatEnabled: fatEnabled,
        cattlesRate,
        rateListId,
        rateListSetting,
        rateListTypeId,
        customerType,
        cattles: milkAnimals,
        personalRate,
        cattle
    }

}

export default useMilkCollection;