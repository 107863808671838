import config from "src/config";
import { t } from "../language/LanguageController";
import { currencyFormat } from "../library/util";
import { getDairyHeader } from "./common";

const getTemplate = ({
    total,
    date,
    customer_type,
    shift,
    cattle,
    customers,
    dairyName,
    dairyOwnerName,
    dairyOwnerMobile
}, reportName) => {
    let template = "<html><style>table tr td {text-align:center;}td, th {padding: 5px;font-size:14px;}</style>";
    template += getDairyHeader(reportName, dairyName, dairyOwnerName, dairyOwnerMobile);
    template += `<table style="width: 100%;margin-top: 15px;">
        <tbody>
            <tr>
                <td style="text-align: left;">
                    <h2>
                        ${t("text_date")}: ${date.format(config.display_date_format)}
                    </h2>
                </td>
                <td style="text-align: left;">
                    <h2>
                        ${t("text_customer_type")}: ${t("text_" + customer_type)}
                    </h2>
                </td>
                <td style="text-align: left;">
                    <h2>
                        ${t("text_cattle")}: ${t("text_" + cattle)}
                    </h2>
                </td>
                <td style="text-align: left;">
                    <h2>
                        ${t("text_shift")}: ${t("text_" + shift)}
                    </h2>
                </td>
            </tr>
        </tbody>
    </table>`;

    template += `<table style="width: 100%;"  border="1" cellspacing="0">
    <thead>
        <tr>
            <th style="text-align: left;">${t("text_customer_name")}</th>
            <th>${t("text_liter")}</th>
            <th>${t("text_fat")}</th>
            <th>${t("text_clr")}</th>
            <th>${t("text_snf")}</th>
            <th>${t("text_rate")}</th>
            <th>${t("text_amount")}</th>
        </tr>
    </thead>
    <tbody>`;

    template += customers.map(customer => 
        `<tr>
            <td style="text-align: left;">
                ${customer.code} - ${customer.name}
            </td>
            <td>
                ${customer.liter ? currencyFormat(customer.liter, false) : '-'}
            </td>
            <td>
                ${customer.fat ? customer.fat : '-'}
            </td>
            <td>
                ${customer.clr ? customer.clr : '-'}
            </td>
            <td>
                ${customer.snf ? customer.snf : '-'}
            </td>
            <td>
                ${customer.liter ? currencyFormat(customer.rate) : '-'}
            </td>
            <td>
                ${customer.total ? currencyFormat(customer.total) : '-'}
            </td>
        </tr>`
    ).join("");

    template += `<tr><td colspan="7" style="height:30px;"></td></tr>
    <tr>
        <th style="text-align:right;">${t("text_total")}</th>
        <th>
            ${currencyFormat(total.total_milk, false) || '0'}
        </th>
        <th>
            ${total.fat_avg_total || '0'}
        </th>
        <th>
            ${total.clr_avg_total || '0'}
        </th>
        <th>
            ${total.snf_avg_total || '0'}
        </th>
        <th>
            ${currencyFormat(total.rate) || '0'}
        </th>
        <th>
            ${currencyFormat(total.total) || '0'}
        </th>
    </tr>
    `;

    template += `</tbody></table></body></html>`;

    return template;
}

export default getTemplate;