import { formatCustomerForDropDown } from "src/components/library/customer";
import {
    FETCH_CUSTOMERS_SUCCESS,
    DELETE_CUSTOMER,
    UPDATE_CUSTOMER,
    ADD_CUSTOMER,
    FETCH_CUSTOMERS_REQUEST,
    FETCH_CUSTOMERS_ERROR,
    UPDATE_CUSTOMERS_RATELIST,
    RESET_CUSTOMERS
} from "../_constants";
import DairyModel from "../components/models/Dairy";
import { copyObject } from "src/components/library/util";
import _ from "lodash";

const dairyModal = new DairyModel();
const customers = dairyModal.get("customers");

const initialState = {
    customers: (customers) ? customers : [],
    latest_code: 0,
    isFetching: true,
    isFetched: false
};

function dairyCustomers(state = initialState, action) {
    switch (action.type) {
    case ADD_CUSTOMER:
        return {
            ...state,
            customers: [...action.data.customers, ...state.customers],
            latest_code: action.data.latest_code ? action.data.latest_code : state.latest_code
        };
    case UPDATE_CUSTOMER:
        let customers = [...state.customers];
        customers[action.index] = action.data.customer;
        return {
            ...state,
            customers: customers,
            latest_code: action.data.latest_code ? action.data.latest_code : state.latest_code
        };
    case DELETE_CUSTOMER:
        return {
            ...state,
            customers: [...state.customers.slice(0, action.index), ...state.customers.slice(action.index + 1)]
        };
    case FETCH_CUSTOMERS_REQUEST: {
        return {
            ...state,
            isFetching: true
        }
    }
    case FETCH_CUSTOMERS_SUCCESS: 
        dairyModal.set("customers", action.data.customers);
        return {
            ...state,
            customers: action.data.customers,
            latest_code: action.data.latest_code,
            isFetched: true,
            isFetching: false
        };
    case UPDATE_CUSTOMERS_RATELIST: 
        dairyModal.set("customers", action.data);
        return {
            ...state,
            customers: action.data
        };
    case FETCH_CUSTOMERS_ERROR: {
        return {
            ...state,
            isFetching: false,
            isFetched: true
        }
    }
    case RESET_CUSTOMERS: {
        return {
            ...copyObject(state),
            customers: [],
            isFetched: false,
            isFetching: true
        }
    }
    default:
        return state;
    }
}

export const selectDairyCustomers = (state) => _.cloneDeep(state.dairy_customers.customers);

export const selectCustomersForDropDown = (state) => {
    const customers = _.cloneDeep(state.dairy_customers.customers);
    return formatCustomerForDropDown(
        customers.filter(customer => Number(customer.status) === 1)
    )
}

export default dairyCustomers;
