// @ts-nocheck
import { openFile, saveBlob2File } from "./fileHelper";
import pdfMake from "pdfmake";


export const generatePDF = async (pdfContent, fileName) => {
    const opts = {
        documentSize: "A4",
        landscape: "portrait",
        type: "base64",
        fileName: fileName
    };
    try {
        // eslint-disable-next-line no-undef
        if (typeof window.pdf != "undefined") {
            const pdfBase64 = await window.pdf.fromData(pdfContent, opts);
            const base64Response = await fetch(`data:application/pdf;base64,${pdfBase64}`);
            const pdfBlob = await base64Response.blob();
            await downloadPDF(pdfBlob, fileName);
        } else if (typeof window.generatePdf !== "undefined") {
            window.generatePdf(pdfContent, fileName)
        } else if (typeof cordova === "undefined") {
            printHtml(pdfContent, fileName);
        }
    } catch (error) {
        throw error;
    }
};

const downloadPDF = async (fileBlob, fileName) => {
    const baseBath = encodeURI(window.cordova.file.dataDirectory);
    // Save blob file to filesystem before opening
    const fileEntry = await saveBlob2File(baseBath, 'Download', fileName, fileBlob);
    // open file
    await openFile(fileEntry.nativeURL, fileBlob.mimeType || fileBlob.type);
}

const printHtml = (htmlData, fileName) => {
    const height = window.screen.availHeight;
    const width = window.screen.availWidth;
    const myWindow = window.open('', fileName, `_blank=1,width=${width},height=${height}`);
    myWindow.document.write(htmlData);
    myWindow.document.close();
    myWindow.focus();
    myWindow.print();
}

export const downloadMakePDFNew = async (tableData) => {    
    const docDefinition = {
        pageOrientation: tableData.pageOrientation,
        footer: {
            columns: [
                { text: 'Powered by Liter', alignment: 'center' }
            ]
        },
        images: {
            logo: tableData.logo
        },
        content: [
            {
                image: "logo",
                width: 100,
                margin: [0, -20, 0, 0]
            },
            { text: tableData.dairyName, alignment: 'right', fontSize: 22, margin: [0, -40, 0, -10] },
            { text: tableData.nameAndNumber, alignment: 'right', fontSize: 20, margin: [0, 0, 0, 0] },
            {
                layout: {
                    hLineWidth: function () {
                        return 1;
                    },
                    vLineWidth: function () {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                    },
                    vLineColor: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                    },
                },
                fontSize: tableData.fontSize,
                margin: [0, 32, 0, 0],
                table: {
                    headerRows: 1,
                    widths:  tableData.columnsWidth,
                    body:  tableData.data
                }
            }
        ],
        defaultStyle: {
            font: "Mukta"
        }
    };
    pdfMake.fonts = {
        Mukta: {
            normal: 'https://app.liter.live/fonts/Mukta-Regular.ttf',
            bold: 'https://app.liter.live/fonts/Mukta-Bold.ttf',
            italics: 'https://app.liter.live/fonts/Mukta-Regular.ttf',
            bolditalics: 'https://app.liter.live/fonts/Mukta-Bold.ttf'
        }
    };

    return new Promise((resolve, reject) => {
        if(typeof cordova === "undefined") {
            pdfMake.createPdf(docDefinition, null).download(tableData.fileName, () => {
                console.log("download done");
                resolve(true)
            });
        } else {
            const pdfDocGenerator = pdfMake.createPdf(docDefinition, null);
            pdfDocGenerator.getBase64(async (data) => {
                try {
                    const base64Response = await fetch(`data:application/pdf;base64,${data}`);
                    const pdfBlob = await base64Response.blob();
                    await downloadPDF(pdfBlob, tableData.fileName);
                    resolve(true);
                } catch (error) {
                    reject(error);
                }
            });        
        }
    })
}