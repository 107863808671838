import React from "react";
import moment from "moment";
import CustomerLoanHistoryController from "./CustomerLoanHistoryController";
import { connect } from "react-redux";
import InnerNav from "../InnerNav";
import withLanguage from "../../../language/LanguageController";
import BillDatePicker from "../account/BillDatePicker";
import { currencyFormat } from "../../../library/util";
import CustomersSelect from "../../common/CustomersSelect";
import { DefaultLayout } from "../../common/Layout";
import { DefaultScrollbar } from "../../common/Scrollbar";
import { withRouter } from "react-router";
import SubscriptionLock from "../../../common/FeatureFlag/SubscriptionLock";
import { selectDairyCustomers } from "src/reducers/dairyCustomer";

class CustomerLoanHistory extends CustomerLoanHistoryController {

    render() {
        const {
            loan_history,
            loans,
            loan_total,
            customers,
            selectedCustomer,
            isBrowser,
            isFeatureSubscribed
        } = this.state;
        const { t, header, bottomGutter, innerNav } = this.props;
        
        return (
            <DefaultLayout
                back
                bottomGutter={bottomGutter}
                header={header}
                onDeviceChange={this.onDeviceChange}
                title={t("text_customers_loan")}
                toolbar={false}>
                <div className="mb-2">
                    <div className="form-row">
                        <div className="col-3">
                            <div className="form-group mb-0">
                                <label htmlFor="customer_code" className="sr-only">{t("text_code")}</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    ref={this.customerCodeRef}
                                    placeholder={t("text_code")}
                                    onChange={e => {
                                        e.persist();
                                        this.handleCodeChange(e);
                                    }} />
                            </div>  
                        </div>
                        <div className="col">
                            <CustomersSelect
                                customers={customers}
                                value={selectedCustomer}
                                onChange={this.customerChangeHandler}
                                parseCustomers={false}
                                className="shadow-sm" />
                        </div>
                    </div>
                </div>
                <DefaultScrollbar
                    isEnabled={isBrowser}
                    className="dairy-loan-customer-loan-history-scrollbar">
                    <div className="t-card shadow-sm mb-2">
                        <div className="title right card__title card--material__title mt-0">
                            <div className="mb-2">
                                <BillDatePicker
                                    billingCycle={"monthly"}
                                    onChange={this.onDateChange}
                                />
                            </div>
                            <div className="mb-2 clearfix">
                                <div className="float-left">
                                    {t("text_balance")}
                                </div>
                                <div className="float-right">
                                    <span className="text-red">
                                        {currencyFormat(loan_history.length ? loan_history[0].balance : 0)}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="content card__content card--material__content">
                            <div className="table-fix-header w-100">
                                <table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>{t("text_date")}</th>
                                            <th className="text-center">
                                                <span className="text-green">{t("text_cr")}</span> / <span className="text-red">{t("text_de")}</span>
                                            </th>
                                            <th className="text-right">{t("text_balance")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loan_history.map(row => {
                                                return (
                                                    <tr key={row.id}>
                                                        <td>
                                                            <div className="width100p weight-500 size-15 text-nowrap">
                                                                {moment(row.date).format("DD MMM, YY")}
                                                            </div>
                                                            <div className="width100p size-12 text-grey">
                                                                {row.payment_type === "milk_bill" ? t("text_from_milk_bill") : t("text_by_cash")}
                                                            </div>
                                                        </td>
                                                        <td >
                                                            <span className={`size-15 ${row.transaction_type === "credit" ? "text-green" : "text-red"}`}>
                                                                {currencyFormat(row.amount)}
                                                            </span>
                                                            <div className="">
                                                                {row.remark}
                                                            </div>
                                                        </td>
                                                        <td className="text-right">
                                                            <span className="size-15">
                                                                {currencyFormat(row.balance)}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                        { loan_history.length <= 0 
                                            ? (
                                                <tr>
                                                    <td colSpan={3} className="text-center">
                                                        <div className="">{t("text_no_data_available")}</div>
                                                    </td>
                                                </tr>
                                            ) 
                                            : null }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="t-card shadow-sm mb-2">
                        <div className="title right card__title card--material__title mt-0">
                            <span>
                                {t("text_loan_sanctioned")}
                            </span>
                            <div className="">
                                <span className="text-red">
                                    {currencyFormat(loan_total)}
                                </span>
                            </div>
                        </div>
                        <div className="content card__content card--material__content">
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th>{t("text_date")}</th>
                                        <th>{t("text_amount")}</th>
                                        <th className="text-right">{t("text_remark")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loans.map(loan => (
                                                <tr key={loan.id}>
                                                    <td>
                                                        <div className="width100p weight-500 size-15 text-nowrap">
                                                            {moment(loan.date).format("MMM DD")}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="text-red size-15">
                                                            {currencyFormat(loan.amount)}
                                                        </span>
                                                    </td>
                                                    <td className="text-right">
                                                        {loan.remark}
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    }
                                    { loans.length <= 0 
                                        ? (
                                            <tr>
                                                <td colSpan={3} className="text-center">
                                                    <div className="">{t("text_no_data_available")}</div>
                                                </td>
                                            </tr>
                                        ) 
                                        : null }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </DefaultScrollbar>
                {innerNav ? <InnerNav
                    menu="loan"
                    page="customerLoanHistory"
                    navigator={this.props.navigator} /> : null}
                {!isFeatureSubscribed && <SubscriptionLock/>}
            </DefaultLayout>
        );
    }
}

CustomerLoanHistory.displayName = "CustomerLoanHistory";

CustomerLoanHistory.defaultProps = {
    header: true,
    bottomGutter: true,
    innerNav: true
};

const mapStateToProps = state => ({
    customers: selectDairyCustomers(state),
    dairyId: state.dairy.dairy.id
});

export default connect(mapStateToProps)(
    withRouter(withLanguage(CustomerLoanHistory))
);