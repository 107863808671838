import React, { useEffect } from 'react';
import analytics from '../../../library/firebase/analytics';
import { DefaultLayout } from '../../common/Layout';
import { useLanguage } from '../../../language/LanguageController';
import UsersList from './usersList';
import UserForm from './userForm';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import useStyle from "./style";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDairyUsers } from '../../../../actions/dairyUsers';
import { HasPermission } from '../../../common/AccessPermission';
import { ABILITY_DAIRY_USERS, PERMISSION_ADD } from '../../../../_constants/permissions';

function DairyUsers () {
    const { t } = useLanguage();

    const classes = useStyle();
    const dispatch = useDispatch();
    const { id: dairyId } = useSelector(state => state.dairy.dairy);

    useEffect(() => {
        analytics.setScreenName("UpdateDairy");
    }, []);

    useEffect(() => {
        fetchDairyUsers(dispatch, dairyId);
    }, [dispatch, dairyId]);
    
    return (
        
        <DefaultLayout
            back
            bottomGutter={false}
            title={t("text_dairy_users_setting")}
            toolbar={false}
        >
            <div className="row">
                {/* <div className="col-6">
                    <UserForm />
                </div> */}
                <div className="col-12">
                    <UsersList />
                </div>
                <HasPermission ability={ABILITY_DAIRY_USERS} access={PERMISSION_ADD}>
                    <Fab className={classes.fab}>
                        <AddIcon />
                        <Link to="/dairy/setting/users/add" aria-label={t("text_add_user")} className="stretched-link" />
                    </Fab>
                </HasPermission>
            </div>
        </DefaultLayout>
    );
}
  
export default DairyUsers;