// @ts-nocheck
import React from 'react';
import Menu from '@material-ui/core/Menu';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { IconButton } from '@material-ui/core';

const DropdownMenu = ({
    id="dropdown-menu",
    label = "DropdownMenu",
    buttonType = "default",
    renderIcon = () => {},
    children
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {buttonType === "default" && <PrimaryButton aria-controls={id} aria-haspopup="true" onClick={handleClick}>
        {label}
      </PrimaryButton>}
      {buttonType === "icon" && <IconButton color="primary" aria-controls={id} aria-haspopup="true" onClick={handleClick}>
        {renderIcon()}
      </IconButton>}
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </>
  );
}

export default DropdownMenu;