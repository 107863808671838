import React from 'react';
import moment from "moment";
import { loader } from "../../../library/util";
import { errorHandler } from '../../../library/response';
import { getBillingStartEndDate } from '../../../library/dateUtils';
import analytics from '../../../library/firebase/analytics';
import request from '../../../library/request';
import { getSelectedCustomerForSelect } from '../../../library/customer';
import config from 'src/config';
import Eventer from '../../../library/Eventer';
import { checkFeatureInSubscription } from '../../../common/FeatureFlag/FeatureFlag';

class CustomerPaymentHistoryController extends React.Component {

    constructor(props) {
        super(props);
        this.getPaymentHistory = this.getPaymentHistory.bind(this);
        let selectedCustomer = this.props.customers[0] || {};
        if(this.props.dairyCustomerId) {
            selectedCustomer = this
                .props
                .customers
                .find(customer => customer.dairy_customer_id === this.props.dairyCustomerId);
        }
        this.state = {
            isLoading: true,
            product_pay_modal: false,
            records: [],
            selectedCustomer: {
                ...selectedCustomer,
                ...getSelectedCustomerForSelect(selectedCustomer)
            },
            isFeatureSubscribed: checkFeatureInSubscription("customer_billing", false),
            ...getBillingStartEndDate("monthly", moment())
        };
    }

    componentDidMount () {
        analytics.setScreenName("CustomerPaymentHistory");
        this.getPaymentHistory();
        Eventer.on("customerBillPaid", this.getPaymentHistory);
        Eventer.on("customerBillReceived", this.getPaymentHistory);

        this.props.onRef && this.props.onRef(this);
    }

    componentWillUnmount () {
        Eventer.off("customerBillPaid", this.getPaymentHistory);
        Eventer.off("customerBillReceived", this.getPaymentHistory);
    }

    async getPaymentHistory () {
        if(!this.state.isFeatureSubscribed) {
            this.setState({
                isLoading: false
            });
            return false;
        }
        try {
            this.setState({
                isLoading: true
            });
            let filterStr = `date=${moment().format(config.date_format)}`;
            const { 
                selectedCustomer:  { 
                    dairy_customer_id 
                } 
            } = this.state;
            const { status, data, error_code, message } = await request.get('/dairies/' + this.props.dairyId + `/accounts/paymentHistory/${dairy_customer_id}?${filterStr}`, {
                withAuth: true,
                version: "v2"
            });
            if (status) {
                this.setState({
                    records: data
                });
            } else if(error_code != 501) {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            this.setState({
                isLoading: false
            });
        }
    }

    onDateChange = ({ startDate, endDate }) => {
        this.setState({
            startDate,
            endDate
        }, () => {
            this.getPaymentHistory();
        });
    }

    customerChangeHandler = customer => {
        if(!checkFeatureInSubscription("customer_billing")) {
            return false;
        }
        this.setState({
            selectedCustomer: customer
        }, () => {
            this.getPaymentHistory();
        });
    }
}

export default CustomerPaymentHistoryController;