import React, { useEffect } from 'react';
import analytics from 'src/components/library/firebase/analytics';
import ModalDialog from '../../../../common/ModalDialog';
import ProductDetailTable from "./ProductDetailTable";

const ProductModal = ({
    t,
    isOpen,
    onClose,
    dairyId,
    dairyCustomerId,
    startDate,
    endDate
}) => {
    
    useEffect(() => {
        analytics.logEvent("ProductModal");
    }, []);

    return (
        <ModalDialog
            isOpen={isOpen}
            onClose={onClose}
            title={t("text_product_purchase_detail")}
            content={
                <div className="row">
                    <div className="col px-0">
                        <div className="table_container">
                            <ProductDetailTable
                                dairyId={dairyId}
                                dairyCustomerId={dairyCustomerId}
                                startDate={startDate}
                                endDate={endDate}
                            />
                        </div>
                    </div>
                </div>
            }
        />
    );
}

export default ProductModal;