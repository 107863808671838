/* eslint-disable react/prop-types */
// @ts-nocheck
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import withLanguage, { getLang } from "../../../language/LanguageController";

const useStyles = makeStyles((theme) => ({
    plan: {
      maxWidth: "150px",
      color: "#000",
      marginBottom: "5px",
      borderBottom: "5px solid #CAD8FF",
      borderRadius : "5px 5px 0px 0px",
      padding: "0px 30px",
    },
    planName: {
      color: "#000",
      fontSize: "1.2rem",
      fontWeight: "600"
    },
    planNameSelected: {
      color: "#fff",
      fontSize: "1.2rem",
      fontWeight: "600"
    },
    planSelected: {
      color: "#fff",
      background :"#738edf",
      borderBottom: "5px solid #3e61ce"
    },
    divider: {
      background: "#ffffff",
      margin: "8px 0px",
    },
    planPrice: {
      fontWeight: "bold !important",
      fontSize: "2rem",
      paddingTop: "15px !important",
      margin: "0 !important"
    },
    priceSymbol : {
      fontSize : "25px",
    },
    priceCaption : {
      fontSize: "1rem"
    }
}));

const Plan = ({ t, plan, readOnly, isSelected, onSelect }) => {
    const classes = useStyles();
    const lang = getLang();
    return (
    <Grid
      item
      mr={5}
      ml={5}
      id={`plan_box_${plan.plan_id}`}
      onClick={(e) => (!readOnly ? onSelect(e, plan) : "")}
    >
      <Paper elevation={2}
        className={`${classes.plan} ${isSelected ? classes.planSelected : ""}`}
      >
         <Box textAlign="center">
            <Typography component="h5" className={classes.planPrice}>
              <sup className={classes.priceSymbol}>₹&nbsp;</sup>
              {plan.daily_price}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom className={classes.priceCaption}>
              {t("text_per_day")}
            </Typography>
          </Box>
        <Box p={1} className={isSelected ? classes.boxPlanSelected : ""}>
          <Box textAlign="center">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={isSelected ? classes.planNameSelected : classes.planName}
            >
              {plan.name_local[lang] || plan.name}
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Grid>
    );
};

export default withLanguage(Plan);
