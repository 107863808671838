// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import MuiDatepicker from "../../../../common/MuiDatepicker";
import withLanguage from "../../../../language/LanguageController";
import moment from "moment";
import config from "../../../../../config";

const Filter = ({ 
    t,
    startDate,
    endDate,
    customerType,
    customerTypeChangeHandler,
    dateChangeHandler
}) => {
    return (
        <div className="d-flex mb-2 justify-content-between">
            <div className="btn-group btn-group-sm mr-1" role="group" aria-label="Customer Type">
                <button
                    className={`btn ${customerType === "seller" ? "bg-light-blue text-white active" : "btn-light"}`}
                    type="button"
                    onClick={() => customerTypeChangeHandler("seller")}>
                    {t("text_seller")}
                </button>
                <button
                    className={`btn ${customerType === "buyer" ? "bg-light-blue text-white active" : "btn-light"}`}
                    type="button"
                    onClick={() => customerTypeChangeHandler("buyer")}>
                    {t("text_buyer")}
                </button>
            </div>
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text text-sm px-2">{t("text_from")}</span>
                </div>
                <MuiDatepicker
                    value={startDate}
                    maxDate={endDate}
                    onChange={selectedDate => dateChangeHandler("start_date", selectedDate)}
                    name="start_date"
                    inputId="start_date"
                    inputClass="bg-white"
                    format={config.display_date_format_short}
                    arrowButtons={false}
                />
                <div className="input-group-prepend input-group-append">
                    <span className="input-group-text text-sm px-2">{t("text_to")}</span>
                </div>
                <MuiDatepicker
                    value={endDate}
                    minDate={startDate}
                    maxDate={moment().endOf("month")}
                    onChange={selectedDate => dateChangeHandler("end_date", selectedDate)}
                    name="end_date"
                    inputId="end_date"
                    inputClass="bg-white"
                    format={config.display_date_format_short}
                    arrowButtons={false}
                />
            </div>
        </div>
    );
};

Filter.propTypes = {
    t: PropTypes.func.isRequired,
    customerType: PropTypes.string.isRequired,
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    customerTypeChangeHandler: PropTypes.func.isRequired,
    dateChangeHandler: PropTypes.func.isRequired,
};

export default withLanguage(Filter);