// @ts-nocheck
import moment from "moment";
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Eventer from 'src/components/library/Eventer';
import { generatePDF } from 'src/components/library/pdfGenerator';
import { DefaultLayout } from 'src/components/pages/common/Layout';
import { togglePremiumPopup } from "../../../../../actions";
import { fetchDairyTodayTotal } from '../../../../../actions/dairy';
import config from '../../../../../config/config.json';
import { checkFeatureInSubscription } from '../../../../common/FeatureFlag/FeatureFlag';
import SubscriptionLock from '../../../../common/FeatureFlag/SubscriptionLock';
import Spinner from "../../../../core/Spinner";
import withLanguage from '../../../../language/LanguageController';
import { connectLastPrinter, isConnected } from '../../../../library/bluetoothPrinter';
import analytics from '../../../../library/firebase/analytics';
import request from '../../../../library/request';
import { errorHandler } from '../../../../library/response';
import { confirmPop, copyObject, currencyFormat, loader, toast, ucFirst } from "../../../../library/util";
import getDairyMilkCollectionTemplate from "../../../../pdfTemplates/dairyMilkCollection";
import { milkSummaryReceipt } from '../../../../templates/printerTemplates';
import InnerNav from '../../InnerNav';
import BluetoothPrinter from '../../modalPopup/BluetoothPrinter';
import DairyMilkDetailListView from "./DairyMilkDetailListView";
import { default as DairyMilkDetailTableView } from "./DairyMilkDetailTableView";
import Filter from "./Filter";
import dummyData from "./dummyData.json";
import { selectDairyCustomers } from "src/reducers/dairyCustomer";

class DairyMilkDetail extends React.Component {

    constructor(props) {
        super(props);
        this.deleteMilk = this.deleteMilk.bind(this);
        this.getDairyMilk = this.getDairyMilk.bind(this);
        const isFeatureSubscribed = checkFeatureInSubscription("dairy_milk_summary", false);
        this.state = {
            records: [],
            total: {},
            filter: '',
            isBSetting: false,
            view: "table",
            isLoading: true,
            isFeatureSubscribed: isFeatureSubscribed,
            ...this.getDefaultFilter()
        };
    }

    getDefaultFilter = () => {
        const startDate = moment();
        const endDate = moment();
        return {
            mode: "daily",
            start_date: startDate,
            end_date: endDate,
            cattle: "cow",
            shift: moment().format("HH") >= 15 ? "1" : "0",
            customer_type: "seller"
        }
    }

    componentDidMount() {
        analytics.setScreenName("DairyMilkDetail");
        this.getDairyMilk();
        connectLastPrinter().catch(console.log);

        Eventer.on("milkAdded", this.onMilkAdded);
        Eventer.on("milkEdited", this.onMilkAdded);
        Eventer.on("milkDeleted", this.onMilkDeleted);
    }

    componentWillUnmount() {
        Eventer.off("milkAdded", this.onMilkAdded);
        Eventer.off("milkEdited", this.onMilkAdded);
        Eventer.off("onMilkDeleted", this.onMilkDeleted);
    }

    onMilkAdded = e => {
        const { cattle, shift, date, customerType } = e.detail;
        this.setState({
            cattle,
            shift,
            start_date: moment(date),
            customer_type: customerType
        }, () => this.getDairyMilk());
    }

    onMilkDeleted = e => {
        const { cattle, shift, date, customerType } = e.detail;
        this.setState({
            cattle,
            shift,
            start_date: moment(date),
            customer_type: customerType
        }, () => {
            this.getDairyMilk();
            this.props.fetchDairyTodayTotal(this.propsdairyId);
        });
    }

    async getDairyMilk() {
        try {
            if (!this.state.isFeatureSubscribed) {
                this.setState({
                    records: dummyData.result,
                    total: dummyData.total,
                });
                return false;
            }
            this.setState({
                isLoading: true
            });
            const { mode, start_date, end_date, cattle, shift, customer_type } = this.state;
            const { dairyId } = this.props;
            const startDate = start_date.format(config.date_format);
            const endDate = (mode == "monthly") ? end_date.format(config.date_format) : start_date.format(config.date_format)
            let filterStr = '?milk_type=' + cattle;
            filterStr += '&start_date=' + startDate;
            filterStr += '&end_date=' + endDate;
            filterStr += '&day_time=' + shift;
            filterStr += '&customer_type=' + customer_type;
            const { status, data } = await request.get('/dairies/' + dairyId + '/milk' + filterStr, {
                withAuth: true,
                version: "v2"
            });
            if (status === true) {
                this.setState({
                    records: data.result,
                    customersToExclude: this.getCustomersIdToExclude(data.result) || [],
                    total: data.total
                });
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            this.setState({
                isLoading: false
            });
        }
    }

    getCustomersIdToExclude = records =>
        records.map(record => record.dairy_customer_id);

    editMilk = (e, customer) => {
        e.preventDefault();
        analytics.logEvent("gotoEditMilk");
        if (this.state.isMobile) {
            this.props.history.push({
                pathname: "/milk/collection",
                state: {
                    dairy_customer_id: customer.dairy_customer_id,
                    date: this.state.start_date.toString(),
                    cattle: this.state.cattle,
                    shift: this.state.shift == 0 ? "morning" : "evening"
                }
            });
        } else {
            Eventer.emit("editMilk", {
                dairyCustomerId: customer.dairy_customer_id,
                date: this.state.start_date.toString(),
                cattle: this.state.cattle,
                shift: this.state.shift == 0 ? "morning" : "evening",
            });
        }
    }

    updateData = () => {
        this.getDairyMilk()
    }

    customerTypeChangeHandler = (customerType) => {
        if (!this.state.isFeatureSubscribed) {
            this.props.togglePremiumPopup();
            return false;
        }
        this.setState({
            customer_type: customerType
        }, () => {
            this.getDairyMilk();
        });
    }

    dateChangeHandler = (name, newDate) => {
        if (!this.state.isFeatureSubscribed) {
            this.props.togglePremiumPopup();
            return false;
        }
        this.setState({
            [name]: newDate
        }, () => {
            this.getDairyMilk();
        });
    }

    cattleChangeHandler = cattle => {
        if (!this.state.isFeatureSubscribed) {
            this.props.togglePremiumPopup();
            return false;
        }
        this.setState({
            cattle: cattle,
        }, () => {
            this.getDairyMilk();
        });
    }

    shiftChangeHandler = shift => {
        if (!this.state.isFeatureSubscribed) {
            this.props.togglePremiumPopup();
            return false;
        }
        this.setState({
            shift: shift
        }, () => {
            this.getDairyMilk();
        });
    }

    filterChangeHandler = (event) => {
        this.setState({
            filter: event.target.value
        });
    }

    async deleteMilk(customer) {
        try {
            if (await confirmPop(this.props.t("text_delete_milk_confirm"))) {
                analytics.logEvent("deleteMilk");
                loader.show(this.props.t("text_deleting"));
                const { dairyId } = this.props;
                const { shift, cattle, customer_type, start_date } = this.state;
                const { status, message } = await request.delete('/dairies/' + dairyId + '/milk/' + customer.dairy_customer_id, {
                    withAuth: true,
                    version: "v2",
                    body: JSON.stringify({
                        date: customer.date,
                        milk_type: customer.milk_type,
                        day_time: shift
                    })
                });
                if (status) {
                    toast(message);
                    Eventer.emit("milkDeleted", {
                        shift,
                        cattle,
                        customerType: customer_type,
                        date: start_date.toString()
                    });
                } else {
                    throw message;
                }
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    togglePrinterModel = () => {
        this.setState({
            isBSetting: !this.state.isBSetting
        });
    }

    printMilkBill = e => {
        e.preventDefault();
        analytics.logEvent("printMilkBill");
        if (!checkFeatureInSubscription("print")) {
            return false;
        }
        (async () => {
            try {
                loader.show(this.props.t("text_printing"));
                const connected = await isConnected();
                if (connected) {
                    const { start_date, cattle, shift, records } = this.state;
                    const { dairyName } = this.props;
                    const dateN = start_date.format(config.display_date_format);
                    await milkSummaryReceipt(dairyName, dateN, cattle, shift, records);
                } else {
                    this.setState({
                        isBSetting: true
                    });
                }
            } catch (error) {
                errorHandler(error);
            } finally {
                loader.hide();
            }
        })();
    }

    renderFixed = () => {
        return this.state.isBSetting ? (
            <BluetoothPrinter
                t={this.props.t}
                isOpen={this.state.isBSetting}
                onClose={this.togglePrinterModel} />
        ) : null;
    }

    filterCustomers = customers => {
        let { filter } = this.state;
        return customers.filter(customer => {
            if (filter && filter.length) {
                filter = filter.toLowerCase();
                const name = (customer.name || "").toLowerCase();
                const code = (customer.code).toLowerCase();
                if (name.includes(filter)
                    || code.includes(filter)) {
                    return true;
                }
                return false;
            } else {
                return true;
            }
        });
    }

    mergeMilkCustomersWithNonMilk = () => {
        const { customersToExclude = [], records, customer_type, isFeatureSubscribed } = this.state;
        if (!isFeatureSubscribed) {
            return records
        }
        let customers = copyObject(this.props.customers);
        customers = customers.filter(customer => {
            return customer.type === customer_type &&
                customer.status == 1 &&
                !customersToExclude.includes(customer.dairy_customer_id);
        });

        return [...records, ...customers]
    }

    changeView = () => {
        this.setState({
            view: this.state.view === "list" ? "table" : "list"
        });
    }

    downloadCollectionDetail = async () => {
       
        const shift = this.state.shift == 0 ? "morning" : this.state.shift == 1 ? "evening" : "TotalMorningEvening"
        const reportName = `Dairy_Milk_Detail_${this.state.start_date.format(config.date_format)}_${ucFirst(this.state.customer_type)}_${ucFirst(this.state.cattle)}_${ucFirst(shift)}`;
        const downloadHtml = getDairyMilkCollectionTemplate({
            customers: this.state.records,
            ...this.state,
            date: this.state.start_date,
            shift: shift,
            dairyName: this.props.dairyName,
            dairyOwnerName: `${this.props.user.firstname} ${this.props.user.lastname}`,
            dairyOwnerMobile: this.props.user.telephone
        }, reportName);
        await generatePDF(downloadHtml, reportName);
    }

    onDeviceChange = deviceTypes =>
        this.setState({ ...deviceTypes });


    render() {
        let {
            records,
            cattle,
            shift,
            customer_type,
            total,
            start_date,
            end_date,
            view,
            isLoading,
            isFeatureSubscribed
        } = this.state;
        const { t, header } = this.props;
        const customers = this.mergeMilkCustomersWithNonMilk();
        const ViewToRenderForDetail = (view === "list") ? DairyMilkDetailListView : DairyMilkDetailTableView;
        return (

            <DefaultLayout
                title={t("text_collection_summary")}
                toolbar={false}
                header={header}
                bottomGutter={header}
                onDeviceChange={this.onDeviceChange}
                back>
                <Filter
                    customerType={customer_type}
                    cattle={cattle}
                    shift={shift}
                    startDate={start_date}
                    endDate={end_date}
                    customerTypeChangeHandler={this.customerTypeChangeHandler}
                    dateChangeHandler={this.dateChangeHandler}
                    cattleChangeHandler={this.cattleChangeHandler}
                    shiftChangeHandler={this.shiftChangeHandler}
                    filterChangeHandler={this.filterChangeHandler} />
                <div className="clearfix mt-0 position-relative">
                    {isLoading && <Spinner withBackground={true} />}
                    <div className="t-card mb-1 shadow-sm">

                        <div className={"content card__content card--material__content"}>
                            <div className="table-fix-header mt-1">
                                <table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>{t("text_entries")}</th>
                                            <th>{t("text_liter")}</th>
                                            <th>{t("text_avg_fat")}</th>
                                            <th>{t("text_avg_clr")}</th>
                                            <th>{t("text_avg_snf")}</th>
                                            <th>{t("text_rate")}</th>
                                            <th>{t("text_amount")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{records.length}</td>
                                            <td>
                                                {currencyFormat(total.total_milk, false) || '0'}
                                            </td>
                                            <td>
                                                {total.fat_avg_total || '0'}
                                            </td>
                                            <td>
                                                {total.clr_avg_total || '0'}
                                            </td>
                                            <td>
                                                {total.snf_avg_total || '0'}
                                            </td>
                                            <td>
                                                {currencyFormat(total.rate) || '0'}
                                            </td>
                                            <td>
                                                {currencyFormat(total.total) || '0'}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {records.length ?
                        <ViewToRenderForDetail
                            customerType={customer_type}
                            shift={shift}
                            cattle={cattle}
                            customers={this.filterCustomers(customers)}
                            editMilk={this.editMilk}
                            deleteMilk={this.deleteMilk}
                            isFeatureSubscribed={isFeatureSubscribed}
                            isLoading={this.isLoading}
                            startDate={start_date}
                            filterChangeHandler={this.filterChangeHandler}
                            downloadCollectionDetail={this.downloadCollectionDetail}
                            printMilkBill={this.printMilkBill}
                            changeView={this.changeView}
                            view={view}
                        />
                        : (
                            !isLoading && <div className="t-card mb-2 shadow-sm">
                                <div className={"content card__content card--material__content"}>
                                    <h5 className="text-center">
                                        {t("text_no_data_available")}
                                    </h5>
                                </div>
                            </div>
                        )}
                    {!isFeatureSubscribed && <SubscriptionLock />}
                </div>
                <InnerNav
                    menu="milk"
                    page="milkSummary"
                    navigator={this.props.navigator} />
                {this.renderFixed()}
            </DefaultLayout>
        );
    }
}

DairyMilkDetail.displayName = "DairyMilkDetail";

DairyMilkDetail.defaultProps = {
    header: true
};

const mapStateToProps = state => {
    return {
        customers: selectDairyCustomers(state),
        dairyId: state.dairy.dairy.id,
        dairyName: state.dairy.dairy.name,
        user: state.userReducer.user
    }
}

const mapDispatchToProps = {
    fetchDairyTodayTotal,
    togglePremiumPopup
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(withLanguage(DairyMilkDetail))
);