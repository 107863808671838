// @ts-nocheck
import React from 'react';
import { DefaultLayout } from "../../common/Layout";
import { useLanguage } from "../../../language/LanguageController";
import BillingCycle from "../BillingCycle";
import Checkout from "../Checkout";
import { useLocation } from 'react-router';

const CheckoutDesktop = () => {
    const { t } = useLanguage();
    const location = useLocation();
    const { plan } = location.state;
    return (
        <DefaultLayout title={t("text_checkout")} sideNav={false}>
            <div className="form-row">
                {plan?.plan_id && <div className="col">
                    <BillingCycle
                        header={false}
                    />
                </div>}
                <div className={plan?.plan_id ? "col" : "col-6 mx-auto"}>
                    <Checkout
                        header={false}
                    />
                </div>
            </div>
        </DefaultLayout>
    );
}

CheckoutDesktop.displayName = "CheckoutDesktop";

export default CheckoutDesktop;