import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import Eventer from "src/components/library/Eventer";

const useDeviceStatus = () => {
    const [devicesStatus, setDevicesStatus] = useState({});

    const fetchDevicesStatus = useCallback(async () => {
        const devicesStatus = await window?.literAPI?.autoCollectionAPI?.getDevicesStatus();
        setDevicesStatus(devicesStatus);
    }, []);

    const onDevicesStatus = useCallback(({ detail }) => {
        setDevicesStatus(detail);
    }, []);

    useEffect(() => {
        Eventer.on("deviceStatus", onDevicesStatus);
        return () => {
            Eventer.off("deviceStatus", onDevicesStatus);
        }
    }, [onDevicesStatus]);

    useEffect(() => {
        fetchDevicesStatus();
    }, [fetchDevicesStatus]);

    return {
        devicesStatus,
        fetchDevicesStatus
    }
}

export default useDeviceStatus;