import { t } from "../language/LanguageController";

export const getProductsForDropdown = (products = []) => ([
    {
        label: t("text_all_products"),
        value: ""
    }, 
    ...products.map(product => ({
        label: product.name,
        value: product.id
    }))
]);

export const filterProductsBySearch = (searchText, products) => {
    if(!searchText) {
        return products;
    }
    searchText = searchText.toLowerCase();
    
    products = products.filter(product => 
      checkInName(searchText, product)
      || checkInQuantity(searchText, product)
      || checkInTotal(searchText, product)
    );
};

const checkInName = (searchText, product) => 
    (product.name).toLowerCase().includes(searchText);

const checkInQuantity = (searchText, product) =>
    (product.quantity).toString().toLowerCase().includes(searchText);

const checkInTotal = (searchText, product) =>
    (product.total).toString().toLowerCase().includes(searchText);